import axios, { AxiosRequestConfig } from 'axios';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';

export const generatePresignedURL = (filePayload: {
    original_name: string;
    mime_type: string;
    size: number;
}) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `/file-upload/presign-url`,
        data: {
            ...filePayload,
        },
    });
};

export const uploadToAWSS3 = (presignedURL: string, file: File, opts?: AxiosRequestConfig) => {
    var options: AxiosRequestConfig = {
        headers: {
            'Content-Type': file.type,
        },
        ...opts,
    };

    return axios.put(presignedURL, file, options);
};

// export const uploadToAWSS3 = (presignedURL: string, file: File) => {
//     return APIRequest({
//         method: 'put',
//         url: presignedURL,
//         data: file,
//         headers: {
//             'Content-Type': file.type,
//         },
//         withCredentials: false,
//     });
// };

// UploadToAWSS3: (awsURL, file) => {
//     var options = {
//       headers: {
//         "Content-Type": file.type,
//       },
//     };

//     return axios.put(awsURL, file, options);
//   },
