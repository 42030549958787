// Core React libraries import
import { useEffect, useContext } from 'react';

// Core ANTD and 3rdparty libraries import
import { Alert, Button, Col, Divider, Form, Input, Row, Space } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
import { getSaleTypeSetting, updateTransactionSetting } from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import { SaleTypePreference } from 'types/admin';
import { showNotification } from 'utils/notificationUtil';
import { SALE_TYPES } from 'types/transaction';
import { InfoCircleOutlined } from '@ant-design/icons';
import { HomeContext } from 'context/EZContext';
import { updateOnboardingStep } from 'utils/onboardingUtil';
// EZ web-app components import

// EZ web-app styles and assets import

export const TransactionSaleTypeSetting: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();

    const HomeCtx = useContext(HomeContext);

    const _fetchTransactionSetting = () => {
        getSaleTypeSetting().then(({ data }) => {
            if (data) {
                const saleTypePreference = data as SaleTypePreference[];
                saleTypePreference.forEach(preferenceObj => {
                    form.setFieldValue(
                        [`sale_types`, `${preferenceObj.sale_type_id}`],
                        preferenceObj.sale_type_name
                    );
                });
            }
        });
    };

    const _onFinishForm = (values: any) => {
        updateTransactionSetting(values).then(() => {
            showNotification('success', 'New setting has been successfully applied.');

            // set trigger to refetch data by key
            HomeCtx.setTriggerRefetchDataList(prev => [...prev, 'sales_type']);
            // update onboarding step to completed
            updateOnboardingStep('customize_sales_type', HomeCtx.refetchOnboardingList);
        });
    };

    useEffect(() => {
        _fetchTransactionSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form form={form} onFinish={_onFinishForm}>
            <Row>
                <Col span={12} className="mb-5">
                    <Alert
                        message={`You need to hit the button "Save Changes" to save all the changes you have made.`}
                        type="info"
                        showIcon
                        action={
                            <Space>
                                <Button type="primary" onClick={() => form.submit()}>
                                    Save Changes
                                </Button>
                            </Space>
                        }
                    />
                </Col>
                <Col span={24}>
                    <Row gutter={[64, 64]}>
                        <Col span={12}>
                            <Row gutter={[0, 8]}>
                                <Col span={24} className="text-title-secondary">
                                    Sale Type:
                                </Col>
                                <Divider className="mt-2 mb-0" />

                                <Col span={24}>
                                    <Row>
                                        <Col
                                            span={24}
                                            className="mb-2"
                                            style={{ color: '#8C8C8C' }}
                                        >
                                            <InfoCircleOutlined /> You can customize the names of
                                            the Sale Types. Just select one below and type a new
                                            name.
                                        </Col>
                                        <Col
                                            span={10}
                                            style={{ color: '#8C8C8C', paddingLeft: '0' }}
                                        >
                                            Default Name
                                        </Col>
                                        <Col
                                            span={14}
                                            className="mb-2"
                                            style={{ color: '#8C8C8C' }}
                                        >
                                            New Name
                                        </Col>
                                        <Col span={24} className="">
                                            <Divider className="mb-1 mt-1" />
                                        </Col>
                                    </Row>
                                </Col>
                                {SALE_TYPES.map(saleType => (
                                    <Col span={24}>
                                        <Row>
                                            <Col span={10}>
                                                <Form.Item noStyle valuePropName="checked">
                                                    {saleType.saletype_name.replace(
                                                        '(customize in admin)',
                                                        ''
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item
                                                    name={[
                                                        `sale_types`,
                                                        `${saleType.sale_type_id}`,
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input
                                                        style={{ width: '75%' }}
                                                        size="middle"
                                                        placeholder="Default"
                                                        allowClear
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};
