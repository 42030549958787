// Core React libraries import
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Core ANTD and 3rdparty libraries import
import {
    Layout,
    Menu,
    Tabs,
    Avatar,
    Dropdown,
    Space,
    Typography,
    Badge,
    Button,
    Modal,
} from 'antd';
import {
    AppstoreOutlined,
    QuestionCircleOutlined,
    BellOutlined,
    PlusOutlined,
    LogoutOutlined,
    UpOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { Quill } from 'react-quill';

// EZ web-app utils import
import { clearLocalStoragePayload } from 'utils/ez_api/loginUtil';
import { getLoggedAccountData, getMyPermission } from 'utils/ez_api/accountAPIs';
import { getInitialFromFullname } from 'utils/commonUtil';
import { MSloginRequest, isDemoENV } from 'utils/envUtil';
// EZ web-app types import
import {
    IWebAppTabProps,
    TAB_CATEGORY,
    TransactionTabIcon,
    CalendarTabIcon,
    DocumentTabIcon,
    MAIN_TAB_INSTANCES,
    WEB_APP_MAIN_MENU,
    ProspectTabIcon,
} from '../types/webAppTab';
import {
    ACCOUNT_ROLE_HAS_ACCESS_TO_ADMIN_AREA,
    AccountDataType,
    AccountPermissionType,
} from 'types/account';
// EZ web-app components import
import { HomeContext, UserContextProvider } from '../context/EZContext';
import { EZSelectTransaction } from 'components';
import { DemoBanner } from 'components/general/demoBanner';
import { NewTransactionModal } from 'components/appPage/modals/newTransactionModal';
import { AddNewProspectModal } from './tabs/prospect/modal/Modal_addNewProspectModal';
import ViewDetailTransaction from './tabs/transaction/ViewDetailTransaction';
import ProspectDetail from './tabs/prospect/ProspectDetail';
import { HomeNotification } from './home/notification';
import { getAccountRole, hasPermission } from 'utils/permissionUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

// EZ web-app styles and assets import
import '../styles/home.scss';
import {
    getMyEmailCredential,
    requestNew3rdPartyEmailAccessToken,
    updateEmailCredential,
} from 'utils/ez_api/emailLogAPIs';
import { EmailCredentialType, SENDMAIL_ONBEHALF } from 'types/email';
import { useMsal } from '@azure/msal-react';
import { CodeTemplateMarker } from 'utils/quillUtils';
import { loadSubscriptionListFromServer } from 'utils/paymentUtil';
import { HOTJAR_EVENT_TYPE, hotjarEvent, hotjarIdentify } from 'utils/3rd_party_api/hotjarUtil';
import { TriggerRefetchDataKey } from 'types/triggerRefetchData';
import { EZOnboardingButton } from 'components/onboarding/OnboardingButton';
import { getOnboardingList } from 'utils/ez_api/onboardingAPIs';
import { saveObjectToLocalStorage } from 'utils/cacheUtil';
import { updateOnboardingStep } from 'utils/onboardingUtil';
import { captureException } from '@sentry/react';
import { capitalize, replaceSpecialChar } from 'utils/stringUtil';
import { getTransactionDetail } from 'utils/ez_api/transactionAPIs';
import { showNotification } from 'utils/notificationUtil';

declare global {
    interface Window {
        FreshworksWidget: any;
    }
}

window.FreshworksWidget = window.FreshworksWidget || {};

const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;
const { Text } = Typography;

Quill.register({
    'formats/CodeTemplateMarker': CodeTemplateMarker,
});

const QuickSearch: React.FC<{
    openTransactionDetailTab: (
        contractID: number | string,
        contractAddress: string | undefined,
        contractData: any | undefined,
        origin?: string
    ) => void;
}> = ({ openTransactionDetailTab }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const HomeCtx = useContext(HomeContext);

    const _onSelect = (contractID: number, option: any) => {
        HomeCtx.setActiveTabByButton(true);
        openTransactionDetailTab(contractID, option.selectedLabel, {}, 'Quick_search');
        setTimeout(() => {
            setIsModalOpen(false);
        }, 10);
        // update onboarding step to completed
        updateOnboardingStep('use_quick_search', HomeCtx.refetchOnboardingList);
    };
    const _showSearchModal = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <Button
                className="ez-action-btn"
                size="small"
                icon={<SearchOutlined />}
                onClick={() => _showSearchModal()}
            >
                Search
            </Button>
            <Modal
                width={700}
                closable
                maskClosable
                title="Quick Search for Transaction"
                visible={isModalOpen}
                okButtonProps={{
                    style: {
                        display: 'none',
                    },
                }}
                cancelText="Close"
                onCancel={() => setIsModalOpen(false)}
                destroyOnClose
            >
                <div>
                    <>
                        <EZSelectTransaction
                            placeholder="Search by address and choose a transaction"
                            onChange={value => {}}
                            mode={undefined}
                            autoFocus
                            onSelect={(val: any, option: any) => {
                                _onSelect(val, option);

                                Mixpanel.track('app.use_quick_search', {
                                    contract_id: val,
                                });
                            }}
                            showArrow={false}
                            size="large"
                        />
                    </>
                </div>
            </Modal>
        </>
    );
};

const QuickActionAddButtons: React.FC<{
    openTransactionDetailTab: (
        contractID: number | string,
        contractAddress: string | undefined,
        contractData: any
    ) => void;
}> = ({ openTransactionDetailTab }) => {
    const [isModalAddTransactionOpen, setIsModalAddTransactionOpen] = useState(false);
    const [isModalAddProspectOpen, setIsModalAddProspectOpen] = useState(false);

    const _quickButtonMenuAction = (key: string) => {
        switch (key) {
            case 'add-prospect':
                setIsModalAddProspectOpen(true);
                break;

            default:
                break;
        }
    };

    return (
        <>
            <Dropdown.Button
                type="primary"
                className="main-menu-add-action"
                onClick={() => setIsModalAddTransactionOpen(true)}
                trigger={['click']}
                overlay={
                    <Menu
                        onClick={info => _quickButtonMenuAction(info.key)}
                        items={[
                            {
                                label: 'Add Prospect',
                                key: 'add-prospect',
                                icon: <ProspectTabIcon />,
                            },
                            // {
                            //     label: 'Add new agenda',
                            //     key: '2',
                            //     icon: <CalendarTabIcon />,
                            // },
                            // {
                            //     label: 'Upload new document',
                            //     key: '3',
                            //     icon: <DocumentTabIcon />,
                            // },
                        ]}
                    />
                }
            >
                <PlusOutlined />
                Add transaction
            </Dropdown.Button>
            {/* <Button
                type="primary"
                className="main-menu-add-action"
                icon={<PlusOutlined />}
                onClick={() => setIsModalAddTransactionOpen(true)}
            >
                Add transaction
            </Button> */}
            <>
                <NewTransactionModal
                    isModalVisible={isModalAddTransactionOpen}
                    setIsModalVisible={setIsModalAddTransactionOpen}
                />
                <AddNewProspectModal
                    isModalVisible={isModalAddProspectOpen}
                    setIsModalVisible={setIsModalAddProspectOpen}
                />
            </>
        </>
    );
};

export const Home = function Home(props: any) {
    const history = useHistory();
    const location = useLocation();

    const queryParameters = useMemo(() => {
        return new URLSearchParams(location.search);
    }, [location.search]);

    const tabsUrl = capitalize(queryParameters.get('tabs')?.toString() || '');
    const idUrl = capitalize(queryParameters.get('id')?.toString() || '');
    const subTabsUrl = queryParameters.get('sub_tabs')?.toString() || '';

    const _generateNewTabIndex = (
        tabCategory: string,
        recordId?: string | number | undefined
    ): string => {
        return `${tabCategory.replaceAll(' ', '-').toLowerCase()}${
            recordId ? '&id=' + recordId.toString() : ''
        }`;
    };

    const defaultTabs = [MAIN_TAB_INSTANCES[0], MAIN_TAB_INSTANCES[1]];

    const [UserData, setUserData] = useState<AccountDataType>();
    const [UserPermission, setUserPermission] = useState<AccountPermissionType>();
    const [triggerRefetchDataList, setTriggerRefetchDataList] = useState<TriggerRefetchDataKey[]>(
        []
    );
    const [isOnboadingLoading, setIsOnboadingLoading] = useState(false);
    const [_activeTabByButton, _setActiveTabByButton] = useState(false);
    const [firstRender, setFirstRender] = useState(true);

    const accountrole_id = UserPermission?.accountrole_id;

    const hasAccessToAdminArea = accountrole_id
        ? ACCOUNT_ROLE_HAS_ACCESS_TO_ADMIN_AREA.includes(accountrole_id)
        : false;

    const [_appTabs, _setAppTabs] = useState<IWebAppTabProps[]>(
        defaultTabs.map(defaultTab => ({
            title: defaultTab.label,
            key: _generateNewTabIndex(defaultTab.tabCategory),
            tabIcon: defaultTab.tabIcon(),
            content: defaultTab.tabContent,
            closeable: defaultTab.tabCloseable,

            tabCategory: defaultTab.tabCategory,
            isMainMenu: true,
        }))
    );
    const [activeTabKey, setActiveTabKey] = useState(_appTabs[0].key);

    const fetchOnboardingList = () => {
        setIsOnboadingLoading(true);
        getOnboardingList()
            .then(({ data }) => {
                saveObjectToLocalStorage('onboarding_list', data);
            })
            .catch(() => {
                saveObjectToLocalStorage('onboarding_list', { learner: [] });
            })
            .finally(() => {
                setIsOnboadingLoading(false);
            });
    };

    const { instance: MSInstance } = useMsal();

    const _logout = () => {
        clearLocalStoragePayload();
        history.push('/');
    };

    const _handleChangeMenuTab = (menuTab: string) => {
        // hit hotjar event every click Tasks menu
        if (menuTab === 'Tasks') {
            hotjarEvent(HOTJAR_EVENT_TYPE.CLICK_TASKS_MENU);
            // update onboarding step to completed
            updateOnboardingStep('check_daily_task', fetchOnboardingList);
            return;
        }
    };

    const _openMainMenuTabByKey = (key: string) => {
        _handleChangeMenuTab(key);
        const selectedMenu = MAIN_TAB_INSTANCES.find(el => el.key === key);

        if (selectedMenu) {
            const activeTab = _appTabs.find(
                tab => tab.tabCategory.toString() === key && tab.isMainMenu && !tab.recordId
            );

            if (activeTab) {
                setActiveTabKey(activeTab.key);
            } else {
                _addTab({
                    title: selectedMenu.label,
                    key: '',
                    tabIcon: selectedMenu.tabIcon(),
                    content: selectedMenu.tabContent,
                    closeable: selectedMenu.tabCloseable,

                    tabCategory: key,
                    isMainMenu: true,
                });
            }
        }
    };

    const _mainMenuOnClick: MenuClickEventHandler = ({ key }) => {
        _openMainMenuTabByKey(key);
    };

    const _onChange = (newActiveKey: string) => {
        _setActiveTabByButton(true);
        setActiveTabKey(newActiveKey);
    };

    const _addTab = (tab: IWebAppTabProps, shiftFocus: boolean = true) => {
        if (!UserPermission || !hasPermission(UserPermission, tab.tabCategory, true)) {
            return;
        }

        tab.key = _generateNewTabIndex(tab.tabCategory, tab.recordId);

        const newPanes = [..._appTabs];
        newPanes.push(tab);
        _setAppTabs(newPanes);

        if (shiftFocus) setActiveTabKey(tab.key);
        setImmediate(() => {
            Mixpanel.track('app.add_tab', {
                title: tab.title,
                tabCategory: tab.tabCategory,
                recordId: tab.recordId,
                triggered_from: tab.origin,
            });
        });
    };

    const _openTransactionDetailTab = (
        contractID: number | string,
        contractAddress: string | undefined,
        contractData: any | undefined,
        origin?: string
    ) => {
        const tabTitle =
            contractAddress && contractAddress !== '-' ? contractAddress : "Address isn't set";

        const activeTransactionRecordTab = _appTabs?.find(
            tab => tab.recordId === contractID && tab.tabCategory === TAB_CATEGORY.TRANSACTION
        );

        //check whether the selected record has already an active tab
        if (activeTransactionRecordTab) {
            if (setActiveTabKey) setActiveTabKey(activeTransactionRecordTab.key);
        } else {
            _addTab(
                {
                    title: tabTitle,
                    key: '-', // this will be overridden by the key generator function inside this addTab function
                    tabIcon: <TransactionTabIcon />,
                    content: ViewDetailTransaction,
                    closeable: true,
                    tooltip: tabTitle,

                    tabCategory: TAB_CATEGORY.TRANSACTION,
                    isMainMenu: false,
                    recordId: contractID,

                    data: { ...contractData, contract_id: contractID },

                    origin,
                },
                true
            );
        }
    };

    const _openProspectDetailTab = (
        prospectID: number | string,
        prospectName: string | undefined,
        prospectData: any | undefined
    ) => {
        const tabTitle = prospectName && prospectName !== '-' ? prospectName : "Name isn't set";

        const activeProspectDetailTab = _appTabs?.find(
            tab => tab.recordId === prospectID && tab.tabCategory === TAB_CATEGORY.PROSPECT
        );

        //check whether the selected record has already an active tab
        if (activeProspectDetailTab) {
            if (setActiveTabKey) setActiveTabKey(activeProspectDetailTab.key);
        } else {
            _addTab(
                {
                    title: tabTitle,
                    key: '-', // this will be overridden by the key generator function inside this addTab function
                    tabIcon: <ProspectTabIcon />,
                    content: ProspectDetail,
                    closeable: true,
                    tooltip: tabTitle,

                    tabCategory: TAB_CATEGORY.PROSPECT,
                    isMainMenu: false,
                    recordId: prospectID,

                    data: { ...prospectData, prospect_id: prospectID },
                },
                true
            );
        }
    };

    const _openMainTab = (tabKey: TAB_CATEGORY, shiftFocus: boolean = true) => {
        const mainTab = MAIN_TAB_INSTANCES.find(tab => tab.key === tabKey);

        if (mainTab) {
            const tabTitle = mainTab.label;

            const activeTab = _appTabs?.find(tab => tab.tabCategory === tabKey);

            //check whether the selected record has already an active tab
            if (activeTab) {
                if (setActiveTabKey) setActiveTabKey(activeTab.key);
            } else {
                _addTab(
                    {
                        title: tabTitle,
                        key: '-', // this will be overridden by the key generator function inside this addTab function
                        tabIcon: mainTab.tabIcon(),
                        content: mainTab.tabContent,
                        closeable: mainTab.tabCloseable,
                        tooltip: tabTitle,

                        tabCategory: mainTab.tabCategory,
                        isMainMenu: true,
                    },
                    shiftFocus
                );
            }
        }
    };

    const remove = (targetKey: string) => {
        let newActiveKey = activeTabKey;
        let lastIndex = -1;
        _appTabs.forEach((pane, i) => {
            if (pane.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = _appTabs.filter(pane => pane.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        _setAppTabs(newPanes);
        setActiveTabKey(newActiveKey);
    };

    const _onEdit = (
        targetKey: React.MouseEvent | React.KeyboardEvent | string,
        action: 'add' | 'remove'
    ) => {
        if (action === 'add') {
            // add();
        } else {
            remove(targetKey.toString());
        }
    };

    const _reloadLoggedUserData = () => {
        getLoggedAccountData().then(({ data }) => {
            if (data) {
                setUserData(data);

                const formPayload = {
                    name: data?.fullname,
                    email: data?.email,
                    custom_fields: {
                        cf_account_id363837: data?.account_id?.toString(),
                    },
                };
                window.FreshworksWidget('prefill', 'ticketForm', formPayload);
            }
        });
        getMyPermission().then(({ data }) => {
            if (data) {
                setUserPermission(data);
            }
        });
    };
    //cek here
    const fetchTransactionDetailViaUrl = useCallback(
        (contract_id: number, subTabKey?: string) => {
            getTransactionDetail(contract_id, 'basic_info')
                .then(resp => {
                    if (resp.data) {
                        _openTransactionDetailTab(
                            contract_id,
                            resp.data?.address,
                            { ...resp.data, subTabKey },
                            'url'
                        );
                    }
                })
                .catch(() => {
                    showNotification(
                        'error',
                        'Error happened while reloading the transaction data.'
                    );
                });
        },
        [UserPermission]
    );

    const _setCSForm = () => {};

    const _checkEmailCredential = async () => {
        getMyEmailCredential().then(async resp => {
            const emailCredential = resp.data as EmailCredentialType;
            const now = new Date().getTime();

            if (emailCredential?.account_id) {
                if (
                    emailCredential.google_access_token &&
                    emailCredential.google_rt_expires_in &&
                    emailCredential.google_last_issued
                ) {
                    // If the refresh token from Google is expired, unset the tokens and given email. Otherwise, if the last issued date is more than 12 hours, request a new access token to prolong the refresh token
                    // Read more about google's refresh token expiry here - https://developers.google.com/identity/protocols/oauth2 (as of 22nd of May 2023 21.07 Jakarta local time)
                    // A refresh token might stop working for one of these reasons:
                    //      (i) The user has revoked your app's access.
                    //      (ii) The refresh token has not been used for six months.
                    //      (iii) The user changed passwords and the refresh token contains Gmail scopes.
                    //      And many more.
                    if (now >= new Date(emailCredential.google_rt_expires_in).getTime()) {
                        updateEmailCredential({
                            google_access_token: '',
                            google_refresh_token: '',
                            google_email: '',
                        });
                    } else if (
                        now - new Date(emailCredential.google_last_issued).getTime() >=
                        43200000
                    ) {
                        // request new access token
                        requestNew3rdPartyEmailAccessToken(SENDMAIL_ONBEHALF.GMAIL)
                            .then(() => {})
                            .catch((err: Error) => {
                                captureException(err);
                            });
                    }
                }

                if (
                    emailCredential.ms_access_token &&
                    emailCredential.ms_rt_expires_in &&
                    emailCredential.ms_last_issued
                ) {
                    // If the refresh token from Microsoft is expired, unset the tokens and given email. Otherwise, if the last issued date is more than 12 hours, request a new access token to prolong the refresh token
                    // Read more here -- https://learn.microsoft.com/en-us/azure/active-directory/develop/refresh-tokens#refresh-token-lifetime  (as of 22nd of May 2023 21.07 Jakarta local time)
                    // Access token expiry is 60 minutes / 1 hour
                    // The default lifetime for the refresh tokens is 24 hours for single page apps and 90 days for all other scenarios. Refresh tokens replace themselves with a fresh token upon every use.
                    if (now >= new Date(emailCredential.ms_rt_expires_in).getTime()) {
                        updateEmailCredential({
                            ms_access_token: '',
                            ms_refresh_token: '',
                            ms_email: '',
                        });
                    } else if (
                        now - new Date(emailCredential.ms_last_issued).getTime() >=
                        43200000
                    ) {
                        // request new access token
                        requestNew3rdPartyEmailAccessToken(SENDMAIL_ONBEHALF.MS_365)
                            .then(() => {})
                            .catch((err: Error) => {
                                captureException(err);
                            });
                    }
                }
            }
        });
    };

    useEffect(() => {
        if (activeTabKey !== _generateNewTabIndex(TAB_CATEGORY.TRANSACTION)) {
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabKey]);

    useEffect(() => {
        if (tabsUrl && UserPermission && !idUrl) {
            const selectedTab = MAIN_TAB_INSTANCES.find(el => el.key === tabsUrl);

            if (selectedTab) {
                const activeTab = _appTabs.find(
                    tab => tab.tabCategory.toString() === tabsUrl && tab.isMainMenu && !tab.recordId
                );

                if (activeTab) {
                    setActiveTabKey(activeTab.key);
                } else {
                    _addTab({
                        title: selectedTab.label,
                        key: '',
                        tabIcon: selectedTab.tabIcon(),
                        content: selectedTab.tabContent,
                        closeable: selectedTab.tabCloseable,

                        tabCategory: tabsUrl,
                        isMainMenu: true,
                    });
                }
            }
        }
    }, [tabsUrl, UserPermission, idUrl]);

    useEffect(() => {
        if (tabsUrl === 'Transactions' && UserPermission && idUrl && _activeTabByButton === false) {
            fetchTransactionDetailViaUrl(Number(idUrl), subTabsUrl);
        }
    }, [idUrl, tabsUrl, _activeTabByButton, UserPermission, subTabsUrl]);

    useEffect(() => {
        //START OF DESCRIPTION
        //Will always go through this line because we had defaultTabs for Summary Tabs
        //Need to check the first render so it doesn't go back to Summary Tabs whenever pass tabs with URL
        if (firstRender && activeTabKey === 'summary') {
            setFirstRender(false);
            return;
        }
        //END OF DESCRIPTION
        if (activeTabKey.includes('&id=') && !firstRender) {
            //string processing
            const splitActiveTabKey = activeTabKey.split('&id=');
            const getAddrressFromId = _appTabs.find(
                ({ recordId }) => recordId === Number(splitActiveTabKey[1])
            );
            const subTabKey = _appTabs.find(
                ({ recordId }) => recordId === Number(splitActiveTabKey[1])
            )?.data.subTabKey;

            // end of string processing
            queryParameters.set('tabs', splitActiveTabKey[0]);
            queryParameters.set('id', splitActiveTabKey[1]);
            splitActiveTabKey[0] === 'transactions'
                ? queryParameters.set('address', replaceSpecialChar(getAddrressFromId?.title))
                : queryParameters.set('fullname', replaceSpecialChar(getAddrressFromId?.title));
            subTabKey && queryParameters.set('sub_tabs', subTabKey);
            history.push({
                pathname: '/home',
                search: queryParameters.toString(),
            });
        } else {
            queryParameters.delete('id');
            queryParameters.delete('address');
            queryParameters.delete('sub_tabs');
            queryParameters.delete('fullname');
            queryParameters.set('tabs', activeTabKey);
            history.push({
                pathname: '/home',
                search: queryParameters.toString(),
            });
        }
    }, [activeTabKey]);

    useEffect(() => {
        _reloadLoggedUserData();
        _setCSForm();
        _checkEmailCredential();

        loadSubscriptionListFromServer();
        Mixpanel.track('app.access_home_page', {});
        hotjarIdentify();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('jwt');
        if (!token) {
            _logout();
        }
    });
    return (
        <UserContextProvider
            userInfo={UserData}
            userPermission={UserPermission}
            openTransactionDetailTab={_openTransactionDetailTab}
            openProspectDetailTab={_openProspectDetailTab}
            openMainTab={_openMainTab}
            setActiveTabByButton={_setActiveTabByButton}
            triggerRefetchDataList={triggerRefetchDataList}
            setTriggerRefetchDataList={setTriggerRefetchDataList}
            refetchOnboardingList={fetchOnboardingList}
            isOnboadingLoading={isOnboadingLoading}
            closeTab={remove}
        >
            <Layout className="home-layout">
                <EZOnboardingButton />
                <Header className="main-menu-header">
                    <div className="logo-wrapper">
                        <div className="logo" />
                    </div>

                    <Menu
                        style={{ float: 'left', width: '50%' }}
                        className="main-menu-header-left"
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        items={WEB_APP_MAIN_MENU}
                        onClick={_mainMenuOnClick}
                    />
                    <Space
                        className="main-menu-header-right"
                        align="center"
                        style={{ float: 'right' }}
                    >
                        <span>
                            <QuickActionAddButtons
                                openTransactionDetailTab={_openTransactionDetailTab}
                            />
                        </span>
                        <span className="cursor-pointer">
                            <HomeNotification />
                        </span>
                        <span>
                            <a
                                href="https://ezcoordinator.freshdesk.com/support/solutions"
                                target={'_blank'}
                                style={{ color: 'black' }}
                            >
                                <QuestionCircleOutlined style={{ fontSize: '18px' }} />
                            </a>
                        </span>
                        <span className="cursor-pointer pr-0">
                            <Dropdown
                                trigger={['click']}
                                overlay={
                                    <Menu
                                        style={{ width: 250 }}
                                        onClick={({ key }) => {
                                            if (key === 'avatar-menu-my-profile') {
                                                _openMainMenuTabByKey(TAB_CATEGORY.MY_PROFILE);
                                            } else if (key === 'avatar-menu-admin') {
                                                _openMainMenuTabByKey(TAB_CATEGORY.ADMIN_SETTING);
                                            }
                                        }}
                                        items={[
                                            {
                                                key: 'avatar-menu-0',
                                                label: (
                                                    <div>
                                                        <Text>
                                                            {UserData?.fullname ? (
                                                                <Text strong>
                                                                    {UserData.fullname}
                                                                </Text>
                                                            ) : (
                                                                <Text italic>
                                                                    Fullname isn't set
                                                                </Text>
                                                            )}
                                                        </Text>
                                                        <br />
                                                        <Text>
                                                            <Text
                                                                strong
                                                                style={{
                                                                    width: '250px',
                                                                    overflowWrap: 'break-word',
                                                                    wordBreak: 'break-all',
                                                                    inlineSize: '250px',
                                                                }}
                                                            >
                                                                {UserData?.email}
                                                            </Text>
                                                        </Text>
                                                        <br />
                                                        <Text>
                                                            <Text
                                                                strong
                                                                style={{
                                                                    width: '250px',
                                                                    overflowWrap: 'break-word',
                                                                    wordBreak: 'break-all',
                                                                    inlineSize: '250px',
                                                                }}
                                                            >
                                                                Role:{' '}
                                                                {getAccountRole(
                                                                    UserData?.accountrole_id
                                                                ) || '-'}
                                                            </Text>
                                                        </Text>
                                                    </div>
                                                ),
                                            },
                                            {
                                                type: 'divider',
                                            },
                                            {
                                                key: 'avatar-menu-my-profile',
                                                label: <Text>My Profile</Text>,
                                            },
                                            {
                                                type: 'divider',
                                                style: {
                                                    display: hasAccessToAdminArea
                                                        ? 'inherit'
                                                        : 'none',
                                                },
                                            },
                                            {
                                                key: 'avatar-menu-admin',
                                                label: <Text>Admin Area</Text>,
                                                style: {
                                                    display: hasAccessToAdminArea
                                                        ? 'inherit'
                                                        : 'none',
                                                },
                                            },
                                            {
                                                type: 'divider',
                                            },
                                            {
                                                key: 'avatar-menu-2',
                                                icon: <LogoutOutlined style={{ color: 'red' }} />,
                                                label: (
                                                    <span>
                                                        <Text type="danger">Logout</Text>
                                                    </span>
                                                ),
                                                onClick: () => _logout(),
                                            },
                                        ]}
                                    />
                                }
                                placement="bottomRight"
                                // arrow
                            >
                                <Avatar size={36}>
                                    {UserData?.fullname
                                        ? getInitialFromFullname(UserData.fullname)
                                        : 'USER'}
                                </Avatar>
                            </Dropdown>
                        </span>
                    </Space>
                </Header>
                <Content>
                    <div className="site-layout-content">
                        <Tabs
                            type="editable-card"
                            className="app-tab-wrapper"
                            onChange={_onChange}
                            activeKey={activeTabKey}
                            onEdit={_onEdit}
                            id="app-tab-wrapper"
                            animated={false}
                            tabBarExtraContent={
                                <Space direction="horizontal">
                                    <Button
                                        className="ez-action-btn"
                                        size="small"
                                        icon={<UpOutlined />}
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                        }}
                                    ></Button>
                                    <QuickSearch
                                        openTransactionDetailTab={_openTransactionDetailTab}
                                    />
                                </Space>
                            }
                        >
                            {_appTabs.map((pane, idx) => (
                                <TabPane
                                    closable={pane.closeable}
                                    tab={
                                        <span title={pane.title}>
                                            {pane.tabIcon || <AppstoreOutlined />}
                                            {pane.title}
                                        </span>
                                    }
                                    key={pane.key}
                                    id={pane.key}
                                    disabled={pane.disabled ? true : false}
                                >
                                    <div className="tab-pane-content-wrapper">
                                        <pane.content
                                            appTabs={_appTabs}
                                            addTab={_addTab}
                                            isCurrentTabActive={activeTabKey === pane.key}
                                            setActiveTabKey={setActiveTabKey}
                                            data={pane.data}
                                            tabKey={pane.key}
                                        />
                                    </div>
                                </TabPane>
                            ))}
                        </Tabs>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }} className="mt-5">
                    EZ Coordinator Version 2.0 ©2023 Crafted with love by the EZ team
                    {isDemoENV() && <DemoBanner account={UserData} />}
                </Footer>
            </Layout>
        </UserContextProvider>
    );
};
