// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Alert, Col, Form, Input, Radio, Row, Space, Typography } from 'antd';
import { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import { FormProps } from 'antd/es/form/Form';

// EZ web-app utils import
import { showMessage } from 'utils/notificationUtil';
// EZ web-app APIs import
// EZ web-app types import
import { AccountRoleType } from 'types/account';
import {
    getAccountRolePermission,
    updatePermissionByAccountRoleID,
} from 'utils/ez_api/permissionAPIs';
// EZ web-app components import

// EZ web-app styles and assets import

export const AccountRolePermission: React.FC<{ tabKey: string; activeTabKey: string }> = ({}) => {
    const [form] = Form.useForm();

    const [accountRoles, setAccountRoles] = useState<AccountRoleType[]>([]);

    const [isFetchingPermission, setIsFetchingPermission] = useState(false);

    const columns: ProColumns<AccountRoleType>[] = [
        {
            title: 'Role',
            dataIndex: 'accountrole',
            key: 'accountrole',
            width: '120px',
            formItemProps: (form, config) => {
                return {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                };
            },
            render: (_, record) => (
                <Typography.Text strong>
                    {record.accountrole}
                    <Form.Item
                        name={[record.accountrole_id, 'accountrole']}
                        initialValue={record.accountrole}
                        noStyle
                    >
                        <Input hidden />
                    </Form.Item>
                </Typography.Text>
            ),
        },
        {
            title: 'Edit',
            dataIndex: 'toggle',
            key: 'toggle',
            width: '120px',
            renderText: (_, record) => {
                return (
                    <Form.Item
                        name={[record.accountrole_id, 'toggle']}
                        initialValue={record.accountrole_permissions?.[0]?.toggle || 0}
                        noStyle
                    >
                        <Radio.Group
                            defaultValue={record.accountrole_permissions?.[0]?.toggle || 0}
                        >
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1}>View Only</Radio>
                                <Radio value={2}>Full Access</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                );
            },
        },
        {
            title: 'Contacts',
            dataIndex: 'contacts_toggle',
            key: 'contacts_toggle',
            width: '120px',
            renderText: (_, record) => {
                return (
                    <Form.Item
                        name={[record.accountrole_id, 'contacts_toggle']}
                        initialValue={record.accountrole_permissions?.[0]?.contacts_toggle || 0}
                        noStyle
                    >
                        <Radio.Group
                            defaultValue={record.accountrole_permissions?.[0]?.contacts_toggle || 0}
                            disabled={record.accountrole_permissions?.[0]?.toggle === 0}
                        >
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1}>View Only</Radio>
                                <Radio value={2}>Full Access</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                );
            },
        },
        {
            title: 'Documents',
            dataIndex: 'documents_toggle',
            key: 'documents_toggle',
            width: '120px',
            renderText: (_, record) => {
                return (
                    <Form.Item
                        name={[record.accountrole_id, 'documents_toggle']}
                        initialValue={record.accountrole_permissions?.[0]?.documents_toggle || 0}
                        noStyle
                    >
                        <Radio.Group
                            defaultValue={
                                record.accountrole_permissions?.[0]?.documents_toggle || 0
                            }
                            disabled={record.accountrole_permissions?.[0]?.toggle === 0}
                        >
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1}>View Only</Radio>
                                <Radio value={2}>Full Access</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                );
            },
        },
        {
            title: 'Tasks',
            dataIndex: 'tasks_toggle',
            key: 'tasks_toggle',
            width: '120px',
            renderText: (_, record) => {
                return (
                    <Form.Item
                        name={[record.accountrole_id, 'tasks_toggle']}
                        initialValue={record.accountrole_permissions?.[0]?.tasks_toggle || 0}
                        noStyle
                    >
                        <Radio.Group
                            defaultValue={record.accountrole_permissions?.[0]?.tasks_toggle || 0}
                            disabled={record.accountrole_permissions?.[0]?.toggle === 0}
                        >
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1}>View Only</Radio>
                                <Radio value={2}>Full Access</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                );
            },
        },
        {
            title: 'Notes',
            dataIndex: 'notes_toggle',
            key: 'notes_toggle',
            width: '120px',
            renderText: (_, record) => {
                return (
                    <Form.Item
                        name={[record.accountrole_id, 'notes_toggle']}
                        initialValue={record.accountrole_permissions?.[0]?.notes_toggle || 0}
                        noStyle
                    >
                        <Radio.Group
                            defaultValue={record.accountrole_permissions?.[0]?.notes_toggle || 0}
                            disabled={record.accountrole_permissions?.[0]?.toggle === 0}
                        >
                            <Space direction="vertical">
                                <Radio value={0}>None</Radio>
                                <Radio value={1}>View Only</Radio>
                                <Radio value={2}>Full Access</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                );
            },
        },
        {
            title: 'Assign Tasks',
            dataIndex: 'assigntasks_toggle',
            key: 'assigntasks_toggle',
            width: '120px',
            renderText: (_, record) => {
                return (
                    <Form.Item
                        name={[record.accountrole_id, 'assigntasks_toggle']}
                        initialValue={record.accountrole_permissions?.[0]?.assigntasks_toggle || 0}
                        noStyle
                    >
                        <Radio.Group
                            defaultValue={
                                record.accountrole_permissions?.[0]?.assigntasks_toggle || 0
                            }
                            disabled={record.accountrole_permissions?.[0]?.toggle === 0}
                        >
                            <Space direction="vertical">
                                <Radio value={0}>No</Radio>
                                <Radio value={1}>Yes</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                );
            },
        },
        {
            title: 'Email Function',
            dataIndex: 'emails_toggle',
            key: 'emails_toggle',
            width: '120px',
            renderText: (_, record) => {
                return (
                    <Form.Item
                        name={[record.accountrole_id, 'emails_toggle']}
                        initialValue={record.accountrole_permissions?.[0]?.emails_toggle || 0}
                        noStyle
                    >
                        <Radio.Group
                            defaultValue={record.accountrole_permissions?.[0]?.emails_toggle || 0}
                            disabled={record.accountrole_permissions?.[0]?.toggle === 0}
                        >
                            <Space direction="vertical">
                                <Radio value={0}>No</Radio>
                                <Radio value={1}>Yes</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                );
            },
        },
    ];

    const _onFinish = (value: any) => {
        console.log('🚀 ~ file: accountRoleSetting.tsx ~ line 90 ~ value', value);
    };

    const _onFieldsChange: FormProps['onFieldsChange'] = changedField => {
        const changedRoleField = changedField?.[0];

        if (changedRoleField) {
            const accountrole_id = parseInt((changedRoleField.name as string[])?.[0]);
            const updateAccountRolePermissionProps = form.getFieldValue(
                accountrole_id
            ) as AccountRoleType;

            updatePermissionByAccountRoleID(accountrole_id, updateAccountRolePermissionProps).then(
                () => {
                    // form.resetFields();
                    _fetchPermissionSetting();
                    setTimeout(() => {
                        showMessage(
                            'success',
                            `Permission setting for: ${updateAccountRolePermissionProps?.accountrole} has been successfully saved.`
                        );
                    }, 100);
                }
            );
        }
    };

    const _fetchPermissionSetting = () => {
        setIsFetchingPermission(true);
        getAccountRolePermission()
            .then(({ data }) => {
                setAccountRoles(
                    data
                        ? (data as AccountRoleType[]).filter(aRole => aRole.accountrole_id !== 1)
                        : []
                );
                setIsFetchingPermission(false);
            })
            .finally(() => {
                setIsFetchingPermission(false);
            });
    };

    useEffect(() => {
        _fetchPermissionSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={24} className="">
                <Row gutter={[0, 12]}>
                    <Col span={24} style={{ textAlign: 'left' }} className="text-help">
                        <Alert
                            message={
                                <div className="pl-4">
                                    <div>Tip:</div>
                                    <div>- Set permissions for each of your Roles below.</div>
                                    <div>
                                        - Any custom permission applied to individual user on "Sub
                                        Users" tab will override the below permission.
                                    </div>
                                </div>
                            }
                            type="info"
                            showIcon
                        />
                    </Col>
                </Row>
            </Col>
            <Col span={24} className="mt-3">
                <Form form={form} onFinish={_onFinish} onFieldsChange={_onFieldsChange}>
                    <ProTable
                        rowKey="accountrole_id"
                        showSorterTooltip={false}
                        search={false}
                        loading={isFetchingPermission}
                        columns={columns}
                        pagination={false}
                        dataSource={accountRoles}
                        options={{
                            fullScreen: false,
                            reload: false,
                            setting: false,
                            density: false,
                        }}
                    />
                </Form>
            </Col>
        </Row>
    );
};
