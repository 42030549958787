// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Button, Col, Divider, Form, Input, Popconfirm, Row, Typography } from 'antd';
import {
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    InfoCircleOutlined,
    SaveFilled,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';

// EZ web-app utils import
import { showMessage } from 'utils/notificationUtil';
// EZ web-app APIs import
import { getProspectMetaOptions } from 'utils/ez_api/prospect/prospectAPIs';
// EZ web-app types import
import { ProspectGroupType } from 'types/prospect';
import {
    addNewProspectGroup,
    deleteProspectGroup,
    updateProspectGroup,
} from 'utils/ez_api/prospect/prospectAdminAPIs';
// EZ web-app components import

// EZ web-app styles and assets import

export const ProspectGroupSetting: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();

    const [isFetchingData, setIsFetchingData] = useState(false);
    const [data, setData] = useState<ProspectGroupType[]>([]);

    const _fetchData = () => {
        setIsFetchingData(true);
        getProspectMetaOptions('groups')
            .then(({ data }) => {
                const respData = data as ProspectGroupType[];
                setData(respData || []);
            })
            .finally(() => {
                setIsFetchingData(false);
            });
    };

    const columns: ProColumns<ProspectGroupType>[] = [
        {
            title: 'Active?',
            dataIndex: 'active',
            key: 'active',
            width: '75px',
            valueType: 'switch',
            render: (text, record, index, action) => {
                return record.active ? 'Yes' : 'No';
            },
            fieldProps: (_, config) => {
                return {
                    checkedChildren: 'Yes',
                    unCheckedChildren: 'No',
                    defaultChecked: false,
                };
            },
            formItemProps: (_, config) => {
                return {};
            },
        },
        {
            title: 'Group Name',
            dataIndex: 'group_name',
            key: 'group_name',
            width: '160px',
            formItemProps: (form, config) => {
                return {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                };
            },
            render: (_, record) => <Typography.Text strong>{record.group_name}</Typography.Text>,
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '180px',
            key: 'x',
            valueType: 'option',
            render: (_, record, index, action) => (
                <span>
                    <Button
                        className="p-0"
                        type="link"
                        icon={<EditFilled />}
                        title="Edit"
                        size="small"
                        onClick={() => {
                            action?.startEditable(record.group_id);
                        }}
                    >
                        Edit
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        placement="topRight"
                        title={
                            <>
                                Delete <b>{record.group_name}</b>?
                            </>
                        }
                        onConfirm={() => _deleteData(record)}
                    >
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<DeleteOutlined />}
                            title="Delete this data"
                            size="small"
                            danger
                        ></Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const _deleteData = async (record: ProspectGroupType) => {
        await deleteProspectGroup(record.group_id);
        _fetchData();
        setTimeout(() => {
            showMessage(
                'success',
                `New group: ${record.group_name} has been successfully deleted.`
            );
        }, 100);
        return true;
    };

    const _updateData = (record: ProspectGroupType) => {
        return updateProspectGroup(record.group_id, {
            ...record,
        }).then(() => {
            _fetchData();
            setTimeout(() => {
                showMessage(
                    'success',
                    `Selected data: ${record.group_name} has been successfully saved.`
                );
            }, 100);
            return true;
        });
    };

    const _onFinish = (value: any) => {
        addNewProspectGroup({
            ...value,
        }).then(() => {
            _fetchData();
            form.resetFields();
            setTimeout(() => {
                showMessage(
                    'success',
                    `Selected data: ${value.group_name} has been successfully added.`
                );
            }, 100);
        });
    };

    useEffect(() => {
        _fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={10} className="mt-3 pt-3">
                <Row gutter={[0, 12]}>
                    <Col span={24}>
                        <Form layout="inline" form={form} onFinish={_onFinish} requiredMark={false}>
                            <Form.Item
                                name="group_name"
                                rules={[{ required: true, message: "Group name can't be empty" }]}
                            >
                                <Input
                                    style={{ width: '280px' }}
                                    allowClear
                                    placeholder="Enter the new group name"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Add Group
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={18} style={{ textAlign: 'left' }} className="text-help">
                        <InfoCircleOutlined /> Tip: Grouping is very useful when you want to
                        segmentize a set of prospects based on each particular purpose.
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}></Col>
                </Row>
            </Col>
            <Col span={14} className="mt-3">
                <ProTable
                    rowKey="group_id"
                    showSorterTooltip={false}
                    search={false}
                    loading={isFetchingData}
                    columns={columns}
                    pagination={{ pageSize: 100 }}
                    editable={{
                        actionRender: (_, __, defaultDom) => [defaultDom.save, defaultDom.cancel],
                        type: 'multiple',
                        onSave: async (_, newData: ProspectGroupType, oldData) => {
                            return _updateData({
                                ...newData,
                                active: newData.active ? 1 : 0,
                            });
                        },
                        saveText: (
                            <>
                                <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                    Save
                                </Button>
                            </>
                        ),
                        cancelText: (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0 text-grey"
                                    type="text"
                                    title="Cancel and back"
                                    icon={<CloseOutlined />}
                                ></Button>
                            </>
                        ),
                    }}
                    dataSource={data}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                />
            </Col>
        </Row>
    );
};
