// Core React libraries import
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// Core ANTD and 3rdparty libraries import
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    FormInstance,
    Input,
    Row,
    Select,
    Steps,
    Typography,
} from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';

// EZ web-app utils import
import { showMessage, showNotification } from 'utils/notificationUtil';
import { Mixpanel } from 'utils/mixPanelUtil';
import {
    checkAccountEmail,
    registerNewAccount,
    resendVerificationEmail,
    storeLoginPayload,
    verifyConfirmationCode,
} from 'utils/ez_api/loginUtil';
// EZ web-app APIs import
import { getAddressbookRoles } from 'utils/ez_api/contactAPIs';
// EZ web-app types import
import { PASSWORD_REGEX } from 'types/account';
// EZ web-app components import
import { EZAutocompleteCity, EZInputMasked, EZSelectUSState } from 'components';

// EZ web-app styles and assets import
import './registerByEmail.scss';
import {
    getAnnuallyPaidSubscriptionList,
    getMonthlyPaidSubscriptionList,
    getSubscriptionList,
} from 'utils/paymentUtil';
import { SubscriptionTypeItem } from 'types/payment';

declare global {
    interface Window {
        tolt: any;
        tolt_referral: any;
    }
}

window.tolt = window.tolt || {};
window.tolt_referral = window.tolt_referral || {};

const { Option, OptGroup } = Select;

const steps = [
    {
        title: 'Your credential',
        description: '',
        // description: 'Fill out all the fields.',
    },
    {
        title: 'Subscription',
        // description: 'Our system communicates with the DocuSign APIs.',
    },
    {
        title: `Finish`,
        // description: 'Arrange the signature field.',
    },
];

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const CredentialForm: React.FC<{}> = () => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="First Name"
                                name="firstname"
                                rules={[
                                    {
                                        required: true,
                                        message: "Firstname can't be empty",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter your first name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Last Name"
                                name="lastname"
                                rules={[
                                    {
                                        required: true,
                                        message: "Lastname can't be empty",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter your last name" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Your Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: "Email can't be empty",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter the email address" type="email" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Create Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: PASSWORD_REGEX,
                                        message: 'Weak password is detected.',
                                    },
                                ]}
                                help={
                                    <Row>
                                        <Col span={24}>
                                            <span>Password must:</span>
                                        </Col>
                                        <Col span={12}>
                                            <span>- have at least one letter</span>
                                            <br />
                                            <span>- have at least one capital letter</span>
                                            <br />
                                            <span>- have at least one number</span>
                                            <br />
                                        </Col>
                                        <Col span={12}>
                                            <span>
                                                - have at least one special symbol that is
                                                #?!@$%^&*-
                                            </span>
                                            <br />
                                            <span>- be at least 8 characters</span>
                                            <br />
                                        </Col>
                                    </Row>
                                }
                            >
                                <Input.Password placeholder="Enter your password" />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="mt-2">
                            <Form.Item
                                label="Organization Size"
                                name="organization_size"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field can't be empty",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select the size"
                                    style={{ width: '100%' }}
                                    allowClear
                                    options={[
                                        {
                                            value: 'type_1',
                                            label: '1 (Only me)',
                                        },
                                        {
                                            value: 'type_2',
                                            label: '2 - 12 Employees',
                                        },
                                        {
                                            value: 'type_3',
                                            label: '13 - 20 Employees',
                                        },
                                        {
                                            value: 'type_4',
                                            label: '21 - 40 Employees',
                                        },
                                        {
                                            value: 'type_5',
                                            label: '41+ Employees',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="mt-2">
                            <Form.Item label="Company Name (Optional)" name="company">
                                <Input placeholder="Enter your company name" />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="mt-2">
                            <Form.Item
                                label="Where did you hear about us?"
                                name="lead_info"
                                rules={[
                                    {
                                        required: true,
                                        message: "This field can't be empty",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select the answer"
                                    style={{ width: '100%' }}
                                    allowClear
                                    options={[
                                        {
                                            value: 'SEARCH_ENGINE',
                                            label: 'Search Engine (e.g., Google, Bing, Etc)',
                                        },
                                        {
                                            value: 'SOCIAL_MEDIA',
                                            label: 'Social Media (e.g., LinkedIn, Twitter, Facebook)',
                                        },
                                        {
                                            value: 'FRIEND_COLLEAGUE',
                                            label: 'Referral from a friend or colleague',
                                        },
                                        {
                                            value: 'ONLINE_ADS',
                                            label: 'Online Advertisement',
                                        },
                                        {
                                            value: 'EMAIL_MARKETING',
                                            label: 'Email Marketing/Campaigns',
                                        },
                                        {
                                            value: 'WEBINAR',
                                            label: 'Webinar or Online Workshop',
                                        },
                                        {
                                            value: 'ONLINE_ARTICLE',
                                            label: 'Blog Post or Online Article',
                                        },
                                        {
                                            value: 'PARTNER_REFERRAL',
                                            label: 'Partner Referral',
                                        },
                                        {
                                            value: 'OTHER',
                                            label: 'Other',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const SubscriptionForm: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const selectedSubscription = query.get('subscription');
    const promoCode = query.get('promo_code');

    const MONTHLY_PAID_SUBSCRIPTION_TYPES = getMonthlyPaidSubscriptionList();
    const ANNUALLY_PAID_SUBSCRIPTION_TYPES = getAnnuallyPaidSubscriptionList();
    let selectedArraySubscription = [];
    let selectedUserTypeId;
    const SUBSCRIPTION_TYPES = getSubscriptionList();
    if (selectedSubscription) {
        selectedArraySubscription = SUBSCRIPTION_TYPES.filter(
            (val: SubscriptionTypeItem) => val.chargify_handler === selectedSubscription?.toString()
        );
        selectedUserTypeId = selectedArraySubscription[0]?.user_type_id || '';
    }

    if (selectedUserTypeId) {
        form.setFieldValue('user_type_id', selectedUserTypeId);
    }

    if (promoCode) {
        form.setFieldValue('promo_code', promoCode?.toString());
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Subscription"
                                name="user_type_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a subscription',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select a subscription"
                                    style={{ width: '100%' }}
                                >
                                    {/* <Option value="2">Free</Option> */}
                                    <OptGroup label="Monthly">
                                        {MONTHLY_PAID_SUBSCRIPTION_TYPES.map(sub => (
                                            <Option value={sub.user_type_id}>
                                                {sub.plan_name_with_price}
                                            </Option>
                                        ))}
                                    </OptGroup>
                                    <OptGroup label="Annually (2-Months off)">
                                        {ANNUALLY_PAID_SUBSCRIPTION_TYPES.map(sub => (
                                            <Option value={sub.user_type_id}>
                                                {sub.plan_name_with_price}
                                            </Option>
                                        ))}
                                    </OptGroup>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="">
                            <Form.Item label="Promo Code (Optional)" name="promo_code">
                                <Input placeholder="Enter the promo code" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Divider orientation="left">
                                <b>
                                    <CreditCardOutlined /> Card Detail
                                </b>
                            </Divider>
                        </Col>
                        <Col span={24}>
                            <Alert
                                className="mb-3"
                                message="Your credit card will not be charged until the end of your 14 day trial. You may cancel at anytime."
                                type="info"
                                showIcon
                            />
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="First Name on Account"
                                name="firstname_card"
                                rules={[
                                    {
                                        required: true,
                                        message: "Firstname can't be empty",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter your first name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Last Name on Account" name="lastname_card">
                                <Input placeholder="Enter your last name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Card Number"
                                name="cardnumber"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Invalid card number',
                                    },
                                ]}
                            >
                                {/* <EZInputMasked
                                    placeholder="Enter the card number"
                                    mask={'0000 0000 0000 0000'}
                                /> */}
                                <Input placeholder="Enter the card number" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Month"
                                name="ccmonth"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Invalid',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select"
                                    options={months.map((m, idx) => ({
                                        label: `${idx < 9 ? `0${idx + 1}` : idx + 1} - ${m}`,
                                        value: idx + 1,
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Year"
                                name="ccyear"
                                rules={[
                                    {
                                        required: true,
                                        max: 4,
                                        message: 'Invalid',
                                    },
                                ]}
                            >
                                <EZInputMasked placeholder="YYYY" mask={'0000'} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                label="Address"
                                name="billing_address"
                            >
                                <Input placeholder="Enter the street detail" />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                label="City"
                                name="billing_city"
                            >
                                <EZAutocompleteCity placement="topRight" />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                label="State"
                                name="billing_state"
                            >
                                <EZSelectUSState placement="topRight" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                                label="ZIP"
                                name="billing_zip"
                            >
                                <Input placeholder="Enter the ZIP" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                className="mb-3 mt-3"
                                name="agree"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please check this if you agree to our Terms of Service and Privacy Policy',
                                    },
                                ]}
                            >
                                <Checkbox>
                                    By clicking "Register", you agree to our{' '}
                                    <a href="https://www.ezcoordinator.com/terms" target={'_blank'}>
                                        Terms of Service
                                    </a>{' '}
                                    and that you have read our{' '}
                                    <a
                                        href="https://www.ezcoordinator.com/privacy"
                                        target={'_blank'}
                                    >
                                        Privacy Policy
                                    </a>
                                    .
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const VerifyMessage: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
    const history = useHistory();

    const [isSubmittingData, setIsSubmittingData] = useState(false);

    const resendEmail = () => {
        setIsSubmittingData(true);
        resendVerificationEmail(form.getFieldValue('email'))
            .then(() => {
                showMessage('success', 'Verification email has been resent.');
            })
            .finally(() => {
                setIsSubmittingData(false);
            });
    };

    const verifyCode = () => {
        const email = form.getFieldValue('email');
        const verificationCode = (form.getFieldValue('verification_code') as string).replaceAll(
            /\s|_/g,
            ''
        );

        if (verificationCode.length !== 6) {
            return;
        }

        setIsSubmittingData(true);
        verifyConfirmationCode(email, verificationCode)
            .then(({ data }) => {
                showMessage(
                    'success',
                    'Account has been confirmed, and now we are redirecting you to the main home page.'
                );

                storeLoginPayload(data.jwt, data.loginPayload);

                try {
                    Mixpanel.setLoggedUserData(data.loginPayload);
                    if (window.tolt_referral && window.tolt) {
                        window.tolt.signup(email);
                    }
                } catch (error) {
                    console.log('🚀 ~ .then ~ error:', error);
                }

                setImmediate(() => {
                    history.push('/home');
                });
            })
            .catch(err => {
                if (err.response && err.response.status === 400) {
                    if (err.response.data?.message === 'invalid_code') {
                        showMessage('error', 'The code you provided is invalid.');
                    } else if (err.response.data?.message === 'expired_code') {
                        showMessage('error', 'The code you provided is expired.');
                    }
                }
            })
            .finally(() => {
                setIsSubmittingData(false);
            });
    };

    return (
        <>
            <Row className="mb-5">
                <Col span={24}>
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Typography.Title level={5}>You're almost there!</Typography.Title>

                        <div>
                            We have sent an email, containing 6-digits code to{' '}
                            <b>{form.getFieldValue('email')}</b>
                        </div>
                        <div className="">
                            If you don't see it, you may need to <b>check your spam folder</b>.
                        </div>
                        <div className="">
                            <Row className="mt-5 mb-5" justify="center" gutter={[12, 64]}>
                                <Col>
                                    <div>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter the code',
                                                },
                                            ]}
                                            label=""
                                            name="verification_code"
                                        >
                                            <EZInputMasked
                                                style={{ width: '120px' }}
                                                size="large"
                                                mask={'0 0 0 0 0 0'}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <Button
                                            loading={isSubmittingData}
                                            disabled={isSubmittingData}
                                            type="primary"
                                            size="large"
                                            onClick={() => verifyCode()}
                                        >
                                            Verify
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-4">
                            Still can't find the email? <a onClick={() => resendEmail()}>Resend</a>
                        </div>
                        {/* <div className="mt-4">
                            <Button
                                loading={isSubmittingData}
                                disabled={isSubmittingData}
                                onClick={() => resendEmail()}
                                type="primary"
                            >
                                Resend Email
                            </Button>
                        </div> */}
                        {/* <div className="mt-4">
                            Need help?{' '}
                            <a
                                href="https://ezcoordinator.freshdesk.com/support/tickets/new"
                                target={'_blank'}
                            >
                                Contact Us
                            </a>
                        </div> */}
                    </Col>
                </Col>
            </Row>
        </>
    );
};

export const RegisterByEmail: React.FC<{}> = () => {
    const [form] = Form.useForm();

    const [currentStep, setCurrentStep] = useState(0);
    const [isValidatingStep, setIsValidatingStep] = useState(false);
    const [isSubmittingData, setIsSubmittingData] = useState(false);

    const [formValues, setFormValues] = useState<any>({});

    const _reloadContactRoles = () => {
        getAddressbookRoles().then(response => {});
    };

    const _processForm = async () => {
        const updatedFormValues = { ...formValues, ...form.getFieldsValue() };
        const newStep = currentStep + 1;

        const isValid = await form.validateFields();

        if (!isValid) {
            return;
        }
        if (newStep === 1) {
            setIsValidatingStep(true);
            checkAccountEmail(updatedFormValues.email)
                .then(resp => {
                    if (resp.data?.data) {
                        if (resp.data?.data.available) {
                            setCurrentStep(newStep);
                            setFormValues(updatedFormValues);
                        } else {
                            showNotification(
                                'error',
                                `An existing account with the email: ${updatedFormValues.email} is found. Please use another email.`
                            );
                        }
                    } else {
                        showNotification(
                            'error',
                            `Unidentified error happened. Please try again later.`
                        );
                    }
                })
                .catch(() => {
                    showNotification(
                        'error',
                        `Unidentified error happened. Please try again later.`
                    );
                })
                .finally(() => {
                    setIsValidatingStep(false);
                });
        } else if (newStep === 2) {
            setIsValidatingStep(true);
            registerNewAccount(updatedFormValues)
                .then(({ data }) => {
                    setCurrentStep(newStep);
                    setFormValues(updatedFormValues);
                })
                .catch(err => {
                    showNotification(
                        'error',
                        `Unidentified error happened. Please try again later.`
                    );
                })
                .finally(() => {
                    setIsValidatingStep(false);
                });
        }
    };

    const _prevStep = (currentStep: number) => {
        switch (currentStep) {
            case 0:
                setCurrentStep(0);
                break;
            case 1:
                setCurrentStep(currentStep - 1);
                break;
            case 2:
                setCurrentStep(currentStep - 1);
                break;

            default:
                break;
        }
    };

    const _nextStep = (currentStep: number) => {
        switch (currentStep) {
            case 0:
                _processForm();
                break;
            case 1:
                _processForm();
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez-auth-register-by-email">
            <Form layout="vertical" form={form}>
                <Row>
                    <Col span={24}>
                        <Steps current={currentStep}>
                            {steps.map(item => (
                                <Steps.Step
                                    key={item.title}
                                    title={item.title}
                                    description={item.description}
                                />
                            ))}
                        </Steps>
                    </Col>
                </Row>
                <div className="mt-3 mb-3">
                    {currentStep === 0 ? (
                        <>
                            <CredentialForm />
                        </>
                    ) : currentStep === 1 ? (
                        <>
                            <SubscriptionForm form={form} />
                        </>
                    ) : currentStep === 2 ? (
                        <>
                            <VerifyMessage form={form} />
                        </>
                    ) : (
                        ''
                    )}
                </div>
                {currentStep !== steps.length - 1 && (
                    <Row gutter={[16, 0]} className="">
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button
                                type="default"
                                className="mr-3"
                                disabled={currentStep === 0 || currentStep === 2}
                                onClick={() => _prevStep(currentStep)}
                            >
                                Back
                            </Button>
                            <Button
                                disabled={currentStep === steps.length - 1 || isValidatingStep}
                                type="primary"
                                onClick={() => _nextStep(currentStep)}
                                loading={isValidatingStep}
                            >
                                {currentStep !== 1 ? 'Next' : 'Register'}
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    );
};
