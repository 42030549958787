import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

import { EmailSignatureType } from 'types/emailSignature';
import { DataTableQueryType } from 'types/base';

export const getEmailSignatures = (params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `email-signatures` + (params ? `?${params}` : ''),
    });
};

export const addNewEmailSignature = (record: Partial<EmailSignatureType>) => {
    Mixpanel.track('app.add_email_signature', { signature_name: record?.signature_name });

    return APIRequest({
        method: 'post',
        url: apiUrl + `email-signatures`,
        data: record,
        useDefaultErrorNotif: true,
    });
};

export const editEmailSignature = (record: Partial<EmailSignatureType>) => {
    Mixpanel.track('app.edit_email_signature', {
        email_signature_id: record.email_signature_id,
        action: 'edit',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `email-signatures/${record.email_signature_id}`,
        data: record,
    });
};

export const deleteEmailSignature = (email_signature_id: number) => {
    Mixpanel.track('app.delete_email_signature', {
        email_signature_id: email_signature_id,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `email-signatures/${email_signature_id}`,
    });
};
