import React, { useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import { Row, Col } from 'antd';

import ReactQuill, { QuillOptions, ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';

const BlockEmbed = Quill.import('blots/block/embed');

export { ReactQuill as EZRichTextEditorRef };

// const defaultToolbar: any[] = [
//     [{ header: [1, 2, false] }],
//     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//     [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
//     ['link', 'image'],
//     ['clean'],
// ];

export type EZRichTextEditorProps = ReactQuillProps & {
    showTemplateDropdown?: boolean;
    showEmailSignatureDropdown?: boolean;
    isRequired?: boolean;
    formMode?: 'ADD' | 'EDIT';
};

export type EZRichTextEditorForwardedRefProps = {
    getEditorInstance: () => Quill | undefined;
};

export const EZRichTextEditor = React.forwardRef<
    EZRichTextEditorForwardedRefProps,
    EZRichTextEditorProps | undefined
>(({ showTemplateDropdown, ...props }, forwardedRef) => {
    const reactQuillRef = useRef<ReactQuill>(null);

    useImperativeHandle(forwardedRef, () => ({
        getEditorInstance: () => reactQuillRef.current?.getEditor(),
    }));

    const defaultToolbar: any = {
        container: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            ['clean'],
        ],
        handlers: {},
    };

    if (showTemplateDropdown) {
        defaultToolbar?.container.push([
            {
                chooseTemplateDropdown: [
                    'Option 1',
                    'Option 2',
                    'Option 3',
                    'Option 4',
                    'Option 5',
                ],
            },
        ]);
        defaultToolbar.handlers = {
            chooseTemplateDropdown: function (value: string) {
                if (value) {
                    const quill = reactQuillRef?.current?.getEditor();
                    if (quill) {
                        const cursorPosition = quill.getSelection()!.index;
                        quill.insertText(cursorPosition, value);
                        quill.setSelection(cursorPosition + value.length, 0);
                    }
                }
            },
        };
    }

    const modules = useMemo(
        () => ({
            toolbar: defaultToolbar,
        }),
        []
    );

    useEffect(() => {
        if (showTemplateDropdown) {
            const placeholderPickerItems = Array.prototype.slice.call(
                document.querySelectorAll('.ql-chooseTemplateDropdown .ql-picker-item')
            );
            placeholderPickerItems.forEach(item => (item.textContent = item.dataset.value));
            if (document && document.querySelector('.ql-chooseTemplateDropdown .ql-picker-label')) {
                document.querySelector('.ql-chooseTemplateDropdown .ql-picker-label')!.innerHTML =
                    'Copy from Template' +
                    document?.querySelector('.ql-chooseTemplateDropdown .ql-picker-label')
                        ?.innerHTML;
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col className="ezrichtext-placeholder" span={24}>
                <ReactQuill
                    ref={reactQuillRef}
                    style={{ width: '100%' }}
                    // modules={{ toolbar: defaultToolbar }}
                    modules={modules}
                    theme="snow"
                    {...props}
                />
            </Col>
        </Row>
    );
});
