import { IDocument } from 'react-doc-viewer';

import { TransactionDocItemType } from './transaction';

export const enum DOCUMENT_TYPE {
    WEBLINK = 'Weblink',
    FILE = 'file',
}

export interface IDocumentTabInsanceProps {
    name: string;
}

export type DocViewerType = TransactionDocItemType &
    IDocument & {
        file_id: number;
        presignedURL: string;
        isPreviewAvailable?: boolean;
    };
