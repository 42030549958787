import { apiUrl } from '../../envUtil';
import { APIRequest } from '../../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getProspectTasks = (prospect_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `prospect/${prospect_id}/tasks` + (params ? `?${params}` : ''),
    });
};

export const getProspectTaskLists = (prospect_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `prospect/${prospect_id}/tasklists` + (params ? `?${params}` : ''),
    });
};

export const addNewProspectTask = (
    prospect_id: number,
    payload: any,
    formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
) => {
    Mixpanel.track('app.add_prospect_task', {
        prospect_id,
        ...payload,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `prospect/${prospect_id}/tasks?formType=${formType}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const updateProspectTask = (
    prospect_id: number,
    id: number,
    payload: any,
    formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
) => {
    Mixpanel.track('app.edit_prospect_task', {
        prospect_id,
        task_id: id,
        action: 'edit',
        ...payload,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `prospect/${prospect_id}/task/${id}?formType=${formType}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const deleteProspectTask = (prospect_id: number, id: number) => {
    Mixpanel.track('app.delete_prospect_task', {
        prospect_id,
        task_id: id,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `prospect/${prospect_id}/task/${id}`,
        useDefaultErrorNotif: true,
    });
};

export const getProspectTaskDetail = (prospect_id: number, id: number) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `prospect/${prospect_id}/task/${id}`,
        useDefaultErrorNotif: true,
    });
};
