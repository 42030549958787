// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Input, Form, FormInstance, Col, Select } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
import { getTaskTabsDetail } from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import { DefaultTaskTabType } from 'types/admin';
// EZ web-app components import

// EZ web-app styles and assets import

export const AddTasklistModalContent: React.FC<{
    form: FormInstance<any>;
    mode: 'EDIT' | 'ADD';
}> = ({ form, mode }) => {
    const [tasklistImport, setTasklistImport] = useState<boolean>(false);
    const [taskTabs, setTaskTabs] = useState<DefaultTaskTabType[]>([]);

    const _loadTasklistTemplateOptions = () => {
        getTaskTabsDetail()
            .then(({ data }) => {
                let respData = data as DefaultTaskTabType[];

                respData.forEach(defaultTasklist => {
                    defaultTasklist.defaulttasks = defaultTasklist.defaulttasks?.filter(
                        defaultTask =>
                            !defaultTask.defaulttask_preference ||
                            defaultTask.defaulttask_preference?.toggle
                                ? true
                                : false
                    );
                });

                setTaskTabs(respData || []);
            })
            .finally(() => {});
    };

    const _setFieldValues = () => {
        // form.resetFields();
    };

    useEffect(() => {
        _setFieldValues();

        _loadTasklistTemplateOptions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form layout="vertical" form={form} requiredMark={false}>
            <Row gutter={[16, 0]}>
                {!tasklistImport && (
                    <>
                        <Col span={24}>
                            <Form.Item
                                label="Tasklist Name"
                                name="tasklist_name"
                                rules={[{ required: true, message: "Name can't be empty" }]}
                            >
                                <Input placeholder="Enter the tasklist name" />
                            </Form.Item>
                        </Col>
                        {mode !== 'EDIT' && (
                            <Col span={24}>
                                <Form.Item label="Tasklist Name" name="tasklist_name" noStyle>
                                    OR{' '}
                                    <a onClick={() => setTasklistImport(true)}>
                                        <u>Choose from existing template</u>
                                    </a>
                                </Form.Item>
                            </Col>
                        )}
                    </>
                )}
                {tasklistImport && (
                    <>
                        <Col span={24}>
                            <Form.Item
                                label="Choose Tasklist Template Name"
                                name="defaulttasktab_id"
                                rules={[{ required: true, message: "Tasklist can't be empty" }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="label"
                                    style={{ width: '100%' }}
                                    placeholder="Select or search a tasklist"
                                    options={
                                        taskTabs?.map(tasklist => ({
                                            label: `${tasklist.name} - ${tasklist.defaulttasks?.length} task(s)`,
                                            value: tasklist.defaulttasktab_id,
                                        })) || []
                                    }
                                    // onChange={value => _changeCurrentContract(value as number)}
                                ></Select>
                            </Form.Item>
                        </Col>
                        {mode !== 'EDIT' && (
                            <Col span={24}>
                                <Form.Item label="Tasklist Name" name="tasklist_name" noStyle>
                                    <a onClick={() => setTasklistImport(false)}>
                                        <u>Back</u>
                                    </a>
                                </Form.Item>
                            </Col>
                        )}
                    </>
                )}
            </Row>
        </Form>
    );
};
