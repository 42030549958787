// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    InputNumber,
    Row,
    Space,
    Tabs,
    Typography,
} from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
import { getAlertSetting, updateAlertSetting } from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import { AlertSettingType } from 'types/admin';
import { showMessage, showNotification } from 'utils/notificationUtil';
// EZ web-app components import

// EZ web-app styles and assets import

export const AlertSetting: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();

    const [alertBeforeDates, setAlertBeforeDates] = useState<{
        close_date: boolean;
        expired_date: boolean;
        contingency_date: boolean;
    }>({
        close_date: true,
        expired_date: true,
        contingency_date: true,
    });

    const [isAlertEditMode, setIsAlertEditMode] = useState(true);

    const _fetchAlertSetting = () => {
        getAlertSetting().then(({ data }) => {
            if (data) {
                const alertSetting = data as AlertSettingType;

                form.setFieldsValue({
                    close_days: alertSetting.close_days || null,
                    expiration_days: alertSetting.expiration_days || null,
                    contingency_days: alertSetting.contingency_days || null,

                    alert_before_close_date: alertSetting.close_days ? true : false,
                    alert_before_expired_date: alertSetting.expiration_days ? true : false,
                    alert_before_contingency_date: alertSetting.contingency_days ? true : false,

                    past_close: alertSetting.past_close ? true : false,
                    past_expiration: alertSetting.past_expiration ? true : false,
                    past_contingency: alertSetting.past_contingency ? true : false,

                    tasks: alertSetting.tasks ? true : false,
                    newtransactions: alertSetting.newtransactions ? true : false,
                    newdocuments: alertSetting.newdocuments ? true : false,
                    newquedocuments: alertSetting.newquedocuments ? true : false,
                    activestatus: alertSetting.activestatus ? true : false,
                });
                setAlertBeforeDates({
                    close_date: alertSetting.close_days ? true : false,
                    expired_date: alertSetting.expiration_days ? true : false,
                    contingency_date: alertSetting.contingency_days ? true : false,
                });
            }
        });
    };

    const _onFinishForm = (values: any) => {
        const payload = {
            close_days: values.close_days || 0,
            expiration_days: values.expiration_days || 0,
            contingency_days: values.contingency_days || 0,

            past_close: values.past_close ? 1 : 0,
            past_expiration: values.past_expiration ? 1 : 0,
            past_contingency: values.past_contingency ? 1 : 0,

            tasks: values.tasks ? 1 : 0,
            newtransactions: values.newtransactions ? 1 : 0,
            newdocuments: values.newdocuments ? 1 : 0,
            newquedocuments: values.newquedocuments ? 1 : 0,
            activestatus: values.activestatus ? 1 : 0,
        };
        updateAlertSetting(payload).then(() => {
            showNotification('success', 'Alert setting has been successfully updated.');
        });
    };

    useEffect(() => {
        _fetchAlertSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form form={form} onFinish={_onFinishForm}>
            <Row>
                <Col span={24} className="mb-5">
                    <Alert
                        message={`You need to hit the button "Save Changes" to save all the changes you have made.`}
                        type="info"
                        showIcon
                        action={
                            <Space>
                                <Button type="primary" onClick={() => form.submit()}>
                                    Save Changes
                                </Button>
                            </Space>
                        }
                    />
                </Col>
                <Col span={24}>
                    <Row gutter={[64, 64]}>
                        <Col span={12}>
                            <Row gutter={[0, 8]}>
                                <Col span={24} className="text-title-secondary">
                                    Send Alerts on Active Statuses Only:
                                </Col>
                                <Divider className="mt-2 mb-3" />
                                <Col span={24}>
                                    <Form.Item name="activestatus" noStyle valuePropName="checked">
                                        <Checkbox disabled={!isAlertEditMode}>
                                            Active Status Only
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row gutter={[0, 8]}>
                                <Col span={24} className="text-title-secondary">
                                    Alerts (On/Off):
                                </Col>
                                <Divider className="mt-2 mb-3" />
                                <Col span={24}>
                                    <Form.Item name="tasks" noStyle valuePropName="checked">
                                        <Checkbox disabled={!isAlertEditMode}>Tasks</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="newtransactions"
                                        noStyle
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={!isAlertEditMode}>
                                            New Transactions (Daily)
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="newdocuments" noStyle valuePropName="checked">
                                        <Checkbox disabled={!isAlertEditMode}>
                                            New Documents (Daily)
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="newquedocuments"
                                        noStyle
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={!isAlertEditMode}>
                                            New Queue Documents (Daily)
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row gutter={[0, 8]}>
                                <Col span={24} className="text-title-secondary">
                                    Alerts before the dates occur:
                                </Col>
                                <Divider className="mt-2 mb-0" />
                                <Col span={24}>
                                    <Row>
                                        <Col span={12}></Col>
                                        <Col span={12} className="mb-2">
                                            No. of Days
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            {/* <Form.Item
                                                name="alert_before_close_date"
                                                noStyle
                                                valuePropName="checked"
                                            >
                                                <Checkbox
                                                    onChange={e =>
                                                        setAlertBeforeDates({
                                                            ...alertBeforeDates,
                                                            close_date: e.target.checked,
                                                        })
                                                    }
                                                >
                                                    Close Date
                                                </Checkbox>
                                            </Form.Item> */}
                                            Close Date
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="close_days" noStyle>
                                                <InputNumber
                                                    style={{ width: '70px' }}
                                                    size="small"
                                                    min={0}
                                                    // disabled={!alertBeforeDates.close_date}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col span={12}>
                                            {/* <Form.Item
                                                name="alert_before_expired_date"
                                                noStyle
                                                valuePropName="checked"
                                            >
                                                <Checkbox
                                                    onChange={e =>
                                                        setAlertBeforeDates({
                                                            ...alertBeforeDates,
                                                            expired_date: e.target.checked,
                                                        })
                                                    }
                                                >
                                                    Listing Expiration Date
                                                </Checkbox>
                                            </Form.Item> */}
                                            Listing Expiration Date
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="expiration_days" noStyle>
                                                <InputNumber
                                                    style={{ width: '70px' }}
                                                    size="small"
                                                    min={0}
                                                    // disabled={!alertBeforeDates.expired_date}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row>
                                        <Col span={12}>
                                            {/* <Form.Item
                                                name="alert_before_contingency_date"
                                                noStyle
                                                valuePropName="checked"
                                            >
                                                <Checkbox
                                                    onChange={e =>
                                                        setAlertBeforeDates({
                                                            ...alertBeforeDates,
                                                            contingency_date: e.target.checked,
                                                        })
                                                    }
                                                >
                                                    Contingency Date
                                                </Checkbox>
                                            </Form.Item> */}
                                            Contingency Date
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name="contingency_days" noStyle>
                                                <InputNumber
                                                    style={{ width: '70px' }}
                                                    size="small"
                                                    min={0}
                                                    // addonAfter="Day(s) before"
                                                    // disabled={!alertBeforeDates.contingency_date}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row gutter={[0, 8]}>
                                <Col span={24} className="text-title-secondary">
                                    Alerts after the dates occur:
                                </Col>
                                <Divider className="mt-2 mb-3" />
                                <Col span={24}>
                                    <Form.Item name="past_close" noStyle valuePropName="checked">
                                        <Checkbox disabled={!isAlertEditMode}>Close Date</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="past_expiration"
                                        noStyle
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={!isAlertEditMode}>
                                            Listing Expiration Date
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="past_contingency"
                                        noStyle
                                        valuePropName="checked"
                                    >
                                        <Checkbox disabled={!isAlertEditMode}>
                                            Contingency Date
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                {/* <Col span={24} className="mt-5 mb-0">
                    <Divider />
                </Col>
                <Col span={24} style={{ textAlign: 'left' }}>
                    <Button type="primary" onClick={() => form.submit()}>
                        Save Changes
                    </Button>
                </Col> */}
            </Row>
        </Form>
    );
};
