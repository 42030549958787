// Core React libraries import
import React, { useContext, useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Space,
    Menu,
    Button,
    Tabs,
    Typography,
    Dropdown,
    Tag,
    Tooltip,
    Popover,
    Divider,
    Select,
    UploadProps,
    Alert,
    Popconfirm,
    UploadFile,
    Collapse,
    Modal,
    Table,
    Form,
    Input,
} from 'antd';
import { ProColumns } from '@ant-design/pro-components';
import {
    DeleteOutlined,
    FieldTimeOutlined,
    InboxOutlined,
    CloseOutlined,
    EllipsisOutlined,
    SaveFilled,
    DownloadOutlined,
    FileSearchOutlined,
    DeliveredProcedureOutlined,
    DeleteFilled,
    FolderAddOutlined,
} from '@ant-design/icons';
import fileDownload from 'js-file-download';

// EZ web-app utils import
import { downloadFileRequest } from 'utils/apiUtil';
// EZ web-app APIs import
import { downloadLegacyDocument, getPresignedURLDoc } from 'utils/ez_api/documentAPIs';
import {
    addNewAccountDoc,
    addNewAccountDocFolder,
    deleteAccountDocument,
    deleteAccountDocuments,
    getAccountDocFolders,
    getAccountDocs,
    getPresignedURLAccountDocUpload,
    moveSelectedAccountDocs,
    updateAccountDocument,
} from 'utils/ez_api/accountDocumentAPIs';
// EZ web-app types import
import { AccountDocFolderType, AccountDocType } from 'types/accountDocument';
// EZ web-app components import
import { EZDateFormat, EZTable, EZUpload, UploadProgress } from 'components';
import {
    forwardRefEZTableProps,
    IOnChangeCallbackProps,
    ITableQueryParams,
} from 'components/table/EZProTable';
import { EZUploadProps } from 'components/upload/upload';
import { showMessage, showNotification } from 'utils/notificationUtil';
import { HomeContext } from 'context/EZContext';
import { previewDoc } from 'components/appPage/documents';

// Page level styles
import './docLibrary.scss';

const { Text, Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const defaultTableParam: ITableQueryParams = {
    searchKeyword: '',
    orderParams: [],
    // orderParams: [['$doc_folder.rank$', 'desc']],
    whereParams: {},
    whereFilterParams: {},
};

const AddNewFolderContent: React.FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
    const [form] = Form.useForm();

    const _onFinish = (values: any) => {
        const folder_name = values.folder_name.trim();

        addNewAccountDocFolder(folder_name).then(() => {
            onSuccess();
            form.resetFields();
            showMessage('success', 'New folder has been added.');
        });
    };

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Popover
            content={
                <Form form={form} onFinish={_onFinish} layout="vertical">
                    <Row gutter={[8, 0]} style={{ width: '400px' }} className="mt-2">
                        <Col span={20}>
                            <Form.Item
                                name="folder_name"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        pattern: new RegExp('^[A-Za-z0-9_-]+$'),
                                        message: 'Invalid folder name.',
                                    },
                                ]}
                                extra="Tip: Only alphanumeric characthers are allowed."
                            >
                                <Input placeholder="Enter the folder name" />
                            </Form.Item>
                        </Col>
                        <Col span={4} className="text-left">
                            <Button type="primary" htmlType="submit">
                                Add
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
            trigger={['click']}
            placement="bottomLeft"
        >
            <Button icon={<FolderAddOutlined />} className="mb-2 ez-action-btn">
                Add New Folder
            </Button>
        </Popover>
    );
};

const DocumentLibraryTab: React.FC<{}> = ({}) => {
    const HomeCtx = useContext(HomeContext);

    const UserPermission = HomeCtx.userPermission;

    const hasFullAccessPermission = [1, 3].includes(UserPermission?.accountrole_id || 0);

    const EZTableRef = useRef<forwardRefEZTableProps>();

    const [docFolders, setDocFolders] = useState<AccountDocFolderType[]>([]);

    const [fileUploadList, setFileUploadList] = useState<UploadFile[]>([]);

    const [queryParams, setQueryParams] = useState<ITableQueryParams>(defaultTableParam);

    const [docUploadFolderID, setDocUploadFolderID] = useState<number>();
    const [docFolderFilter, setDocFolderFilter] = useState<number>();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    // const [isUpdatingDoc, setIsUpdatingDoc] = useState<boolean>(false);

    async function _fetchData(EZTableQueryParams: string): Promise<any> {
        return getAccountDocs(EZTableQueryParams).then(result => result);
    }

    const _handleFileUploadChange: UploadProps['onChange'] = ({ file, fileList }) => {
        setFileUploadList([...fileList]);

        if (file.status === 'done') {
            addNewAccountDoc({
                accountfolder_id: docUploadFolderID,
                path: docFolders.find(f => f.accountfolder_id === docUploadFolderID)?.folder_name,

                filename: file.name,
                u_filename: file.response?.newFilename,
                bucket: file.response?.Bucket,
                key: file.response?.Key,
                size: file.size,
                content_type: file.type,
            }).then(() => {
                setQueryParams({
                    ...queryParams,
                    resetPagination: false,
                });
                _fetchDocFolders();
            });
            setFileUploadList(fileList.filter(f => f.uid !== file.uid));
        }
    };

    async function _fetchPresignURL(payload: any): Promise<any> {
        return getPresignedURLAccountDocUpload(payload);
    }

    const _onChangeCallback: IOnChangeCallbackProps = () => {};

    const _reloadSubTabData = (resetPagination?: boolean, silentLoading?: boolean) => {
        setQueryParams({
            ...queryParams,
            resetPagination,
            silentLoading: silentLoading || undefined,
        });
    };

    const _fetchDocFolders = () => {
        getAccountDocFolders().then(resp => {
            if (resp.data) {
                setDocFolders(resp.data);
                setDocUploadFolderID(resp.data?.[0]?.accountfolder_id);
            }
        });
    };

    const _downloadDocument = (doc: AccountDocType) => {
        showMessage('success', 'Download has been initiated. Please wait...');

        // if bucket and doc property has value, the doc will be downloaded from AWS S3
        if (doc.bucket && doc.key) {
            getPresignedURLDoc('contract_document', doc.document_id, 'download').then(resp => {
                downloadFileRequest(resp.data?.presignedURL, doc.filename || 'untitled');
            });
        } else {
            downloadLegacyDocument('contract_document', doc.document_id).then((resp: any) => {
                fileDownload(resp, doc.filename || 'untitled');
            });
        }
    };

    const _saveDocumentRecord = async (
        newData: AccountDocType,
        oldData: AccountDocType,
        formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
    ) => {
        // setDocIDsEdit([...docIDsEdit, { docID: newData.document_id }]);

        if (newData.filename === '' || newData.filename === null) {
            newData.filename = oldData.filename;
        }

        if (newData.document_id) EZTableRef.current?.modifyData(newData, newData.document_id);

        return updateAccountDocument(
            newData.document_id,
            {
                ...newData,
            },
            formType
        )
            .then(() => {
                showNotification(
                    'success',
                    `Document: "${newData.filename}" has been successfully updated.`
                );

                if (oldData.accountfolder_id !== newData.accountfolder_id) {
                    _reloadSubTabData(false, true);
                }

                return newData;
            })
            .catch(err => {
                throw err;
            });
    };

    const _applyFilter = (docFolderFilter?: number) => {
        const currentWhereFilterParams: any = {};
        // const currentWhereFilterParams = { ...queryParams.whereFilterParams };

        if (docFolderFilter === -1) {
            if (currentWhereFilterParams['$accountfolder.accountfolder_id$']) {
                delete currentWhereFilterParams['$accountfolder.accountfolder_id$'];
            }
        } else if (docFolderFilter) {
            currentWhereFilterParams['$accountfolder.accountfolder_id$'] = docFolderFilter;
        }

        setQueryParams({
            ...queryParams,
            whereFilterParams: { ...currentWhereFilterParams },
            resetPagination: true,
        });
    };

    const _columns: ProColumns<AccountDocType>[] = [
        {
            title: 'Document Name',
            dataIndex: 'filename',
            width: '140px',
            sorter: true,
            // ellipsis: true,
            fixed: 'left',
            render: (_, record) => (
                <>
                    <Text title={record.filename}>{record.filename || '-'}</Text>
                </>
            ),
        },
        {
            title: 'Folder',
            dataIndex: 'accountfolder_id',
            width: '100px',
            sorter: true,
            // hideInTable: docFolderFilter && docFolderFilter > 0 ? true : false,
            valueType: 'select',
            fieldProps: {
                options: docFolders.map((item, idx) => ({
                    label: item.folder_name,
                    key: item.accountfolder_id,
                    value: item.accountfolder_id,
                })),
            },
            render: (_, record) => (
                <>
                    <Tag>{record.accountfolder?.folder_name}</Tag>
                </>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '200px',
            sorter: true,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text title={record.description}>{record.description || '-'}</Text>
                </>
            ),
        },
        {
            title: 'Uploaded By',
            dataIndex: 'account.fullname',
            width: '70px',
            editable: false,
            sorter: false,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text title={record.account?.fullname}>{record.account?.fullname || '-'}</Text>
                </>
            ),
        },
        {
            title: 'Uploaded On',
            dataIndex: 'posted_date',
            width: '80px',
            editable: false,
            sorter: false,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text>
                        <EZDateFormat value={record.date_uploaded} format="MMM Do, YYYY hh:mma" />
                    </Text>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '85px',
            valueType: 'option',
            render: (_, record, index, action) =>
                hasFullAccessPermission ? (
                    <Space size="small" className="pl-1">
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<FieldTimeOutlined />}
                            title="Quick-edit this document"
                            onClick={() => {
                                action?.startEditable(record.document_id);
                            }}
                        >
                            Quick Edit
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            className="p-0 mr-1"
                            size="small"
                            type="link"
                            icon={<FileSearchOutlined />}
                            title="Preview"
                            onClick={() => {
                                previewDoc('doc_library', [
                                    {
                                        file_id: record.document_id,
                                        accountfolder_id: 0,
                                    },
                                ]);
                            }}
                        ></Button>
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu>
                                    <Menu.Item key="0" onClick={() => _downloadDocument(record)}>
                                        <DownloadOutlined className="mr-3" />
                                        <span className="">Download</span>
                                    </Menu.Item>
                                    <Menu.Divider />
                                    <Menu.Item
                                        key="2"
                                        danger
                                        onClick={() => _deleteDocument(record)}
                                    >
                                        <DeleteOutlined className="mr-3" />
                                        Delete Document
                                    </Menu.Item>
                                </Menu>
                            }
                            placement="bottomRight"
                        >
                            <span onClick={e => e.preventDefault()}>
                                <Button size="small" type="link" icon={<EllipsisOutlined />} />
                            </span>
                        </Dropdown>
                    </Space>
                ) : (
                    <Space size="small" className="pl-1">
                        {' '}
                        <Button
                            className="p-0 mr-1"
                            size="small"
                            type="link"
                            icon={<FileSearchOutlined />}
                            title="Preview"
                            onClick={() => {
                                previewDoc('doc_library', [
                                    {
                                        file_id: record.document_id,
                                        accountfolder_id: 0,
                                    },
                                ]);
                            }}
                        ></Button>
                    </Space>
                ),
            fixed: 'right',
        },
    ];

    const _doBulkAction_moveDocs = (
        selectedRowKeys: React.Key[],
        newFolderID: number,
        newFolderTitle?: string
    ) => {
        const noOfKeys = selectedRowKeys.length;

        moveSelectedAccountDocs(selectedRowKeys as number[], newFolderID).then(resp => {
            showNotification(
                'success',
                `${noOfKeys} selected doc(s) have been moved to ${newFolderTitle}.`
            );
            setImmediate(() => {
                _reloadSubTabData(false, true);

                setSelectedRowKeys([]);
            });
        });
    };

    const _deleteDocuments = (docIDs: number[]) => {
        deleteAccountDocuments(docIDs).then(() => {
            showNotification(
                'success',
                `${docIDs.length} selected docs have been successfully deleted.`
            );
            setImmediate(() => {
                _reloadSubTabData(false, true);

                setSelectedRowKeys([]);
            });
        });
    };

    const _deleteDocument = (record: AccountDocType) => {
        const deleteConfirmationModal = Modal.confirm({
            title: `Delete confirmation`,
            content: (
                <>
                    Are you sure to delete document/file: <b>{record.filename}</b>?
                </>
            ),
            onOk: () => {
                deleteConfirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Deleting...',
                });
                deleteAccountDocument(record.document_id).then(() => {
                    deleteConfirmationModal.destroy();

                    showNotification(
                        'success',
                        `Document/file: "${record.filename}" has been successfully deleted.`
                    );
                    _reloadSubTabData(false, true);
                });
                return true;
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _onEdit = (_: string, action: 'add' | 'remove') => {
        if (action === 'add') {
            const a = Modal.info({});
        }
    };

    const uploadProps: EZUploadProps = {
        fileList: fileUploadList,
        onChange: _handleFileUploadChange,
        getPresignedURL: _fetchPresignURL,
    };

    const _onSuccess = () => {
        _fetchDocFolders();
    };

    useEffect(() => {
        _applyFilter(docFolderFilter);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docFolderFilter]);

    useEffect(() => {
        _fetchDocFolders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez-component-documents ez-tab-doclibrary">
            <div className="mb-4 ez-sub-content-wrapper">
                <Row className="row-wrapper">
                    {hasFullAccessPermission && (
                        <Col span={24} className="mb-3">
                            <div>
                                <UploadProgress
                                    fileUploadList={fileUploadList}
                                    setFileUploadList={setFileUploadList}
                                />
                            </div>
                            <EZUpload dragger {...uploadProps}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    Click or drag file to this area to upload
                                </p>
                                <p className="ant-upload-hint">
                                    Support for a single or bulk upload.
                                </p>
                            </EZUpload>
                            <Select
                                style={{ textAlign: 'left', width: 300 }}
                                className="mb-2 default-upload-folder-selector"
                                value={docUploadFolderID}
                                disabled={
                                    fileUploadList.filter(file => file.status === 'uploading')
                                        .length > 0
                                }
                                onChange={(val: number) => {
                                    setDocUploadFolderID(val);
                                }}
                            >
                                {docFolders.map(item => (
                                    <Option
                                        value={item.accountfolder_id}
                                        key={`docfolder-select-${item.accountfolder_id}`}
                                    >
                                        <span>
                                            <Text>Upload To: </Text>
                                            <Text strong>{item.folder_name}</Text>
                                        </span>
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    )}
                    <Col span={24}>
                        <Tabs
                            type="card"
                            defaultActiveKey="-1"
                            onChange={val => {
                                const docFolderID = parseInt(val.replace('tab-docfolder-', ''));
                                setDocFolderFilter(docFolderID);
                                setSelectedRowKeys([]);
                            }}
                            // onEdit={(e: any, action: string) => _onEdit(e, 'add')}
                            tabBarExtraContent={
                                hasFullAccessPermission && (
                                    <AddNewFolderContent onSuccess={_onSuccess} />
                                )
                            }
                        >
                            <Tabs.TabPane
                                tab="All Documents and Files"
                                key="-1"
                                closable={false}
                            ></Tabs.TabPane>
                            {docFolders.map(item => (
                                <Tabs.TabPane
                                    tab={`${item.folder_name}`}
                                    key={`tab-docfolder-${item.accountfolder_id}`}
                                    closable={false}
                                ></Tabs.TabPane>
                            ))}
                        </Tabs>
                    </Col>
                    <Col span={24}>
                        <Row
                            className={`table-selected-indicator ${
                                selectedRowKeys?.length ? 'has-row-selected' : 'no-row-selected'
                            }`}
                        >
                            <Col span={24}>
                                <Alert
                                    style={{ minWidth: '500px' }}
                                    message={
                                        selectedRowKeys && selectedRowKeys.length ? (
                                            <Space>
                                                <Tooltip title="Move the selected doc(s)">
                                                    <Dropdown
                                                        trigger={['click']}
                                                        overlay={
                                                            <Menu
                                                                onClick={e =>
                                                                    _doBulkAction_moveDocs(
                                                                        selectedRowKeys,
                                                                        parseInt(e.key),
                                                                        docFolders.find(
                                                                            d =>
                                                                                d.accountfolder_id ===
                                                                                parseInt(e.key)
                                                                        )?.folder_name
                                                                    )
                                                                }
                                                                items={docFolders.map(
                                                                    (item, idx) => ({
                                                                        label: item.folder_name,
                                                                        key: item.accountfolder_id,
                                                                        disabled:
                                                                            item.accountfolder_id ===
                                                                            docFolderFilter,
                                                                    })
                                                                )}
                                                            />
                                                        }
                                                        placement="bottomLeft"
                                                    >
                                                        <Button
                                                            icon={<DeliveredProcedureOutlined />}
                                                        >
                                                            Move To
                                                        </Button>
                                                    </Dropdown>
                                                </Tooltip>
                                                <Divider type="vertical" />
                                                <Tooltip title="Remove the selected doc(s)">
                                                    <Popconfirm
                                                        arrowPointAtCenter
                                                        title="Are you sure to delete the selected document(s)?"
                                                        okText="Yes"
                                                        cancelText="No"
                                                        placement="bottomLeft"
                                                        onConfirm={() =>
                                                            _deleteDocuments(
                                                                selectedRowKeys as number[]
                                                            )
                                                        }
                                                    >
                                                        <Button
                                                            danger
                                                            icon={<DeleteFilled />}
                                                        ></Button>
                                                    </Popconfirm>
                                                </Tooltip>
                                            </Space>
                                        ) : (
                                            ''
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <EZTable
                            ref={EZTableRef}
                            className="eztable-hide-delete-editable"
                            pagination={{
                                pageSize: 50,
                                pageSizeOptions: [20, 50, 100],
                                position: ['bottomLeft', 'topLeft'],
                            }}
                            queryParams={queryParams}
                            columns={_columns}
                            size="small"
                            rowKey="document_id"
                            fetchData={_fetchData}
                            scroll={{ x: 2000 }}
                            rowSelection={
                                hasFullAccessPermission
                                    ? {
                                          selectedRowKeys: selectedRowKeys,
                                          onChange: (newSelectedRowKeys: React.Key[]) => {
                                              setSelectedRowKeys(newSelectedRowKeys);
                                          },
                                          alwaysShowAlert: true,
                                          columnWidth: '20px',
                                          selections: [Table.SELECTION_NONE],
                                      }
                                    : false
                            }
                            tableAlertRender={false}
                            toolBarComponents={
                                // <EZUpload {...uploadProps} key="ez-upload-1">
                                //     <Button
                                //         key={`toolBarComponents-2-task-list`}
                                //         className="ez-action-btn"
                                //     >
                                //         <PlusOutlined />
                                //         Upload New Document
                                //     </Button>
                                // </EZUpload>,

                                []
                            }
                            editable={{
                                actionRender: (_, __, defaultDom) => [
                                    defaultDom.save,
                                    defaultDom.cancel,
                                ],
                                type: 'multiple',
                                onSave: async (_, data: AccountDocType, oldData) => {
                                    return _saveDocumentRecord(
                                        {
                                            ...data,
                                        },
                                        oldData
                                    );
                                },
                                saveText: (
                                    <>
                                        <Button
                                            className="p-0 pl-1"
                                            type="link"
                                            icon={<SaveFilled />}
                                            title="Edit this doc"
                                        >
                                            Save
                                        </Button>
                                    </>
                                ),
                                cancelText: (
                                    <>
                                        <Divider type="vertical" />
                                        <Button
                                            className="p-0 text-grey"
                                            type="text"
                                            title="Cancel and back"
                                            icon={<CloseOutlined />}
                                        ></Button>
                                    </>
                                ),
                            }}
                            onChangeCallback={_onChangeCallback}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default DocumentLibraryTab;
