// Core React libraries import
import React, { useContext, useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    DeleteOutlined,
    EditOutlined,
    EllipsisOutlined,
    FileSearchOutlined,
    SendOutlined,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import {
    Button,
    ConfigProvider,
    Divider,
    Dropdown,
    Form,
    Menu,
    Modal,
    Tag,
    Typography,
} from 'antd';
import enUS from 'antd/lib/locale/en_US';

// EZ web-app utils import
import { showMessage, showNotification } from 'utils/notificationUtil';
// EZ web-app APIs import
import { deleteEmailDraft, getEmailLogs } from 'utils/ez_api/emailLogAPIs';
// EZ web-app types import
import { ITableQueryParams, forwardRefEZTableProps } from 'components/table/EZProTable';
import { EmailLogType } from 'types/email';
// EZ web-app components import
import { EZDateFormat, EZTable } from 'components';
import { EmailLogModalContent } from 'components/appPage/modals/emailLogDetail';
import { HomeContext } from 'context/EZContext';
import { Partial_EmailForm } from 'pages/tabs/email/partials/emailForm';
import { getContactRoles } from 'utils/ez_api/contactAPIs';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;

const defaultTableParam: ITableQueryParams = {
    searchKeyword: '',
    // orderParams: [],
    orderParams: [['created_at', 'desc']],
    whereParams: {},
    whereFilterParams: {},
};

type Partial_EmailLogsProps = {
    contractTaskID?: number;
    contractID?: number;
    isTransactionDeleted?: boolean;
    pageSize?: number;

    editLogDetail?: (emailLog: EmailLogType) => void;

    refreshCounter?: number;
};

export const Partial_EmailLogs: React.FC<Partial_EmailLogsProps> = ({
    contractID,
    contractTaskID,
    pageSize,
    editLogDetail,
    isTransactionDeleted,
    refreshCounter,
}) => {
    const HomeCtx = useContext(HomeContext);
    const UserInfo = HomeCtx.userInfo;

    const [form] = Form.useForm();

    const EZTableRef = useRef<forwardRefEZTableProps>();
    const [contactRoles, setContactroles] = useState<any>();
    const [queryParams, setQueryParams] = useState<ITableQueryParams>({
        ...defaultTableParam,
        orderParams: [['created_at', 'desc']],
        whereParams: {
            contract_id: contractID || undefined,
            contract_task_id: contractTaskID || undefined,
            $and: {
                $or: {
                    '!msg_subject': null,
                    '!msg_body': null,
                    '!msg_to': '[]',
                    '!msg_cc': '[]',
                    '!msg_bcc': '[]',
                },
            },
            account_id: !contractTaskID && !contractID ? UserInfo?.account_id : undefined,
        },
    });

    async function _fetchData(EZTableQueryParams: string): Promise<any> {
        return getEmailLogs(EZTableQueryParams).then(result => result);
    }

    const _deleteMessageLog = (record: EmailLogType) => {
        // if (!record.is_draft) {
        //     return showNotification(
        //         'error',
        //         `You can't delete email that has been dispatched/sent.`
        //     );
        // }
        if (UserInfo?.account_id !== record.account_id) {
            return showNotification(
                'error',
                `You are not the original owner of the selected email. Therefore, you can't delete it.`
            );
        }

        const deleteConfirmationModal = Modal.confirm({
            title: `Delete confirmation`,
            content: (
                <>
                    Are you sure to delete the selected email log with the subject:{' '}
                    <b>{record.msg_subject || '-'}</b>?
                </>
            ),
            onOk: () => {
                deleteConfirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Deleting...',
                });
                return deleteEmailDraft(record.message_id).then(() => {
                    deleteConfirmationModal.destroy();

                    showNotification(
                        'success',
                        `Selected email log has been successfully deleted.`
                    );
                    EZTableRef.current?.reload();
                    return true;
                });
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _columns: ProColumns<EmailLogType>[] = [
        {
            title: 'Subject',
            dataIndex: 'msg_subject',
            width: '82px',
            sorter: true,
            // fixed: 'left',
            render: (_, record) => (
                <>
                    <Text title={record.msg_subject}>{record.msg_subject || '-'}</Text>
                </>
            ),
        },
        {
            title: 'Is Draft?',
            dataIndex: 'is_draft',
            width: '24px',
            sorter: true,
            render: (_, record) => (
                <>
                    <Text>{record.is_draft ? <Tag color="warning">Yes</Tag> : <Tag>No</Tag>}</Text>
                </>
            ),
        },
        {
            title: 'To',
            dataIndex: 'msg_to',
            width: '82px',
            sorter: false,
            render: (_, record) => {
                const msg_to = record.msg_to?.map(msg => {
                    if (
                        msg.startsWith('ROLE_ID') &&
                        contactRoles &&
                        contactRoles.hasOwnProperty(msg)
                    ) {
                        return `{{ ${contactRoles[msg]} }}`;
                    }

                    return msg;
                });

                return <Text>{msg_to?.join(', ') || '-'}</Text>;
            },
        },
        {
            title: 'Dispached Time',
            dataIndex: 'relayed_at',
            width: '50px',
            editable: false,
            sorter: true,
            // ellipsis: true,
            render: (_, record) => (
                <>
                    <Text>
                        <EZDateFormat value={record.relayed_at} format="MMM Do, YYYY hh:mma" />
                    </Text>
                    {record.relayed_at && record.relayed_by_account?.fullname ? (
                        <> by {record.relayed_by_account?.fullname}</>
                    ) : (
                        <></>
                    )}
                </>
            ),
        },
        {
            title: 'Last Updated Time',
            dataIndex: 'updated_at',
            width: '50px',
            editable: false,
            sorter: true,
            // ellipsis: true,
            render: (_, record) => (
                <>
                    <Text>
                        <EZDateFormat value={record.updated_at} format="MMM Do, YYYY hh:mma" />
                    </Text>
                    {record.updated_by_account?.fullname ? (
                        <> by {record.updated_by_account?.fullname}</>
                    ) : (
                        <></>
                    )}
                </>
            ),
        },
        {
            title: 'Created Time',
            dataIndex: 'created_at',
            width: '50px',
            editable: false,
            sorter: true,
            // ellipsis: true,
            render: (_, record) => (
                <>
                    <Text>
                        <EZDateFormat value={record.created_at} format="MMM Do, YYYY hh:mma" />
                    </Text>
                    {record.account?.fullname ? <> by {record.account?.fullname}</> : <></>}
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: editLogDetail ? '35px' : '25px',
            valueType: 'option',
            fixed: 'right',
            render: (_, record, index, action) =>
                isTransactionDeleted ? (
                    <></>
                ) : (
                    // <Space size="small" className="pl-1">
                    <>
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<FileSearchOutlined />}
                            title="See Detail"
                            onClick={() => {
                                _showLogDetailModal(record, contactRoles);
                            }}
                        ></Button>
                        {editLogDetail ? (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0"
                                    type="link"
                                    icon={<EditOutlined />}
                                    title="Edit"
                                    onClick={() => {
                                        if (record.is_draft) {
                                            editLogDetail?.(record);
                                        } else
                                            showMessage(
                                                'error',
                                                'You can only edit the unsent draft.'
                                            );
                                    }}
                                    disabled={!record.is_draft}
                                ></Button>
                            </>
                        ) : (
                            <></>
                        )}
                        <Divider type="vertical" />
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="2"
                                        danger
                                        onClick={() => {
                                            _deleteMessageLog(record);
                                        }}
                                        // disabled={!record.is_draft}
                                    >
                                        <DeleteOutlined className="mr-3" />
                                        Delete {record.is_draft ? 'Draft' : 'Sent Item'}
                                    </Menu.Item>
                                </Menu>
                            }
                            placement="bottomRight"
                        >
                            <span onClick={e => e.preventDefault()}>
                                <Button size="small" type="link" icon={<EllipsisOutlined />} />
                            </span>
                        </Dropdown>
                    </>
                ),
            // </Space>
        },
    ];

    const _showLogDetailModal = (record: Partial<EmailLogType>, contactRoles: any) => {
        const editLogDetailModal = Modal.confirm({
            title: (
                <Typography.Title level={4}>
                    <span>Email Log Detail </span>
                    {record.is_draft ? <Tag style={{ verticalAlign: 'middle' }}>Draft</Tag> : <></>}
                </Typography.Title>
            ),
            content: (
                <EmailLogModalContent contactRoles={contactRoles} emailLog={record} form={form} />
            ),
            okText: 'Save',
            cancelText: 'Close',
            okButtonProps: {
                style: {
                    // display: record.is_draft ? 'initial' : 'none',
                    display: 'none',
                },
            },
            icon: <></>,
            style: { top: '40px' },
            width: '80%',
            autoFocusButton: null,
            onOk: async () => {
                editLogDetailModal.destroy();
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _showNewEmailForm = () => {
        const _onSendEmail = () => {
            editLogDetailModal.destroy();
            EZTableRef.current?.reload();
        };

        const editLogDetailModal = Modal.confirm({
            title: <Typography.Title level={4}>New Email Form</Typography.Title>,
            content: (
                <>
                    <Partial_EmailForm contractID={contractID} onSendEmail={_onSendEmail} />
                </>
            ),
            okText: 'Save',
            cancelText: 'Close',
            cancelButtonProps: {
                style: {
                    display: 'none',
                },
            },
            okButtonProps: {
                style: {
                    display: 'none',
                },
            },
            icon: <></>,
            style: { top: '40px' },
            width: '80%',
            autoFocusButton: null,
            onOk: async () => {
                editLogDetailModal.destroy();
            },
            onCancel: () => {
                EZTableRef.current?.reload();
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _fetchContactRoles = () => {
        getContactRoles().then((res: any) => {
            const roles = res.data.reduce((acc: any, role: any) => {
                acc[`ROLE_ID_${role.role_id}`] = role.role;
                return acc;
            }, {});

            setContactroles(roles);
        });
    };

    useEffect(() => {
        if (refreshCounter) {
            EZTableRef.current?.reload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshCounter]);

    useEffect(() => {
        _fetchContactRoles();
    }, []);

    return (
        <div className="ez_email_logs">
            <ConfigProvider locale={enUS}>
                <EZTable
                    ref={EZTableRef}
                    className="eztable-hide-delete-editable"
                    pagination={{
                        pageSize: pageSize || 20,
                        pageSizeOptions: [10, 20, 30],
                        position: ['bottomLeft', 'topLeft'],
                    }}
                    queryParams={queryParams}
                    columns={_columns}
                    size="small"
                    rowKey="message_id"
                    fetchData={_fetchData}
                    scroll={{ x: 1800 }}
                    tableAlertRender={false}
                    toolBarComponents={
                        contractID && !isTransactionDeleted
                            ? [
                                  <Button
                                      key={`toolBarComponents-2-task-list`}
                                      className="ml-2  ez-action-btn"
                                      onClick={() => _showNewEmailForm()}
                                  >
                                      <SendOutlined />
                                      Send New Email
                                  </Button>,
                              ]
                            : []
                    }
                />
            </ConfigProvider>
        </div>
    );
};
