import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import { AccountCompactDataType } from 'types/account';
import { AddressbookType } from 'types/contact';
import { ContactRoleType } from 'types/transaction';
import { getContactRoles } from 'utils/ez_api/contactAPIs';
import { getAllTeamMembers } from 'utils/ez_api/memberAPIs';

import type { DefaultOptionType, SelectProps } from 'antd/es/select';

type EZSelectEmailRecipientProps = SelectProps & {
    showTransactionOptions?: boolean;
    showMe?: boolean;
};

const _onSelectRecipient: SelectProps['onSelect'] = (value: string, option: any) => {
    // console.log(this);
    return false;
};

const _onChange: SelectProps['onChange'] = (value: string, option: any) => {
    // console.log(value, option);
};

const _onKeyDown = (e: React.KeyboardEvent) => {};

export const DefaultMeStrOptions: string[] = ['{{ Me }}'];
export const DefaultMeOptions: {
    value: string;
    label: string;
}[] = DefaultMeStrOptions.map(v => ({
    value: v,
    label: v,
}));

export const DefaultTransactionStrOptions_Listing: string[] = [
    '{{ Buyers }}',
    "{{ Buyer's agents }}",
    '{{ Sellers }}',
    "{{ Seller's agents }}",
];
export const DefaultTransactionStrOptions_Rental: string[] = [
    '{{ Tenants }}',
    '{{ Referral Source }}',
    '{{ Landlord }}',
    '{{ Leasing agents }}',
];

export const DefaultTransactionOptions: SelectProps['options'] = [
    {
        label: 'Listing',
        options: DefaultTransactionStrOptions_Listing.map(v => ({
            value: v,
            label: v,
        })),
    },
    {
        label: 'Rental',
        options: DefaultTransactionStrOptions_Rental.map(v => ({
            value: v,
            label: v,
        })),
    },
];

export const DefaultAdditionalContactStrOptions_Roles: string = 'ROLE_ID_';

let hasTeamMemberAPIBeenCalled = false;
let teamMembers: AccountCompactDataType[] = [];
let contactRoles: ContactRoleType[] = [];

export const EZSelectEmailRecipient: React.FC<EZSelectEmailRecipientProps> = ({
    showTransactionOptions,
    showMe,
    ...props
}) => {
    const opts: SelectProps['options'] = [];

    if (showMe) {
        opts.push(...DefaultMeOptions);
    }

    if (showTransactionOptions) {
        opts.push(...DefaultTransactionOptions);
    }

    const [selectOpts, setSelectOpts] = useState<DefaultOptionType[]>(opts);

    const _fetchTeamMembers = () => {
        return new Promise<DefaultOptionType>((resolve, reject) => {
            if (hasTeamMemberAPIBeenCalled) {
                resolve({
                    label: 'Team Members',
                    options: teamMembers.map(member => ({
                        label: `${member.fullname} - ${member.email}`,
                        value: member.email,
                    })),
                });
            } else {
                getAllTeamMembers()
                    .then(({ data }) => {
                        hasTeamMemberAPIBeenCalled = true;
                        teamMembers = (data as AccountCompactDataType[]) || [];
                        resolve({
                            label: 'Team Members',
                            options: teamMembers.map(member => ({
                                label: member.fullname,
                                value: member.email,
                            })),
                        });
                    })
                    .catch(error => {
                        reject(error);
                    });
            }
        });
    };

    const _fetchContactRoles = () => {
        return new Promise<DefaultOptionType>((resolve, reject) => {
            getContactRoles()
                .then(({ data }) => {
                    contactRoles = (data as ContactRoleType[]) || [];
                    resolve({
                        label: 'Additional Contact by Roles',
                        options: contactRoles.map(el => ({
                            label: `{{ ${el.role} }}`,
                            value: `ROLE_ID_${el.role_id}`,
                            groupLabel: 'Additional Contact by Roles',
                        })),
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    useEffect(() => {
        Promise.all([_fetchTeamMembers(), _fetchContactRoles()])
            .then(results => {
                setSelectOpts([...opts, ...results]);
            })
            .catch(error => {
                // Handle catch error
            });
    }, []);

    return (
        <Select<any, any>
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Choose from the list or enter the email address(es)"
            notFoundContent={''}
            options={selectOpts}
            allowClear
            {...props}
        ></Select>
    );
};
