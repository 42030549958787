import React, { HTMLAttributes } from 'react';

import { CopyTwoTone } from '@ant-design/icons';

import { showMessage } from 'utils/notificationUtil';

const EZCopyable: React.FC<HTMLAttributes<any> & { text: string }> = ({ text, className }) => {
    const _onClick: React.MouseEventHandler = () => {
        window.navigator.clipboard.writeText(text);
        showMessage('success', 'A text has been copied to clipboard!');
    };

    return (
        <span title="Copy the text" onClick={_onClick} className={`copyable ${className}`}>
            <CopyTwoTone />
        </span>
    );
};

export default EZCopyable;
