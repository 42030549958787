import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

import { EmailTemplateFolderType, EmailTemplateType } from 'types/emailTemplate';
import { DataTableQueryType } from 'types/base';

export const getEmailTemplates = (params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `email-templates` + (params ? `?${params}` : ''),
    });
};

export const getEmailTemplateDetail = (email_template_id: number) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `email-template/${email_template_id}`,
    });
};

export const getEmailTemplateFolders = (queryParams?: DataTableQueryType) => {
    const params: string = qs.stringify({ ...queryParams });
    return APIRequest({
        method: 'get',
        url: apiUrl + `email-template/folders` + (params ? `?${params}` : ''),
    });
};

export const generateEmailTemplates = () => {
    Mixpanel.track('app.generate_email_templates', {});

    return APIRequest({
        method: 'post',
        url: apiUrl + `email-template/generate-templates`,
    });
};

export const getFoldersWithEmailTemplates = (
    queryParams?: DataTableQueryType,
    withMessageBody: boolean = false
) => {
    const params: string = qs.stringify({ ...queryParams, withMessageBody });
    return APIRequest({
        method: 'get',
        url: apiUrl + `email-template/folders-with-templates` + (params ? `?${params}` : ''),
    });
};

export const addNewEmailTemplateFolder = (folder_name: string) => {
    Mixpanel.track('app.add_email_template_folder', { folder_name });

    return APIRequest({
        method: 'post',
        url: apiUrl + `email-template/folders`,
        data: { folder_name },
    });
};

export const editEmailTemplateFolder = (record: Partial<EmailTemplateFolderType>) => {
    Mixpanel.track('app.edit_email_template_folder', { folder_name: record.folder_name });

    return APIRequest({
        method: 'put',
        url: apiUrl + `email-template/folder/${record.folder_id}`,
        data: { folder_name: record.folder_name },
    });
};

export const addNewEmailTemplate = (record: Partial<EmailTemplateType>) => {
    Mixpanel.track('app.add_email_template', { template_name: record?.template_name });

    return APIRequest({
        method: 'post',
        url: apiUrl + `email-templates`,
        data: record,
        useDefaultErrorNotif: true,
    });
};

export const editEmailTemplate = (record: Partial<EmailTemplateType>) => {
    Mixpanel.track('app.edit_email_template', {
        email_template_id: record.email_template_id,
        action: 'edit',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `email-template/${record.email_template_id}`,
        data: record,
    });
};

export const deleteEmailTemplate = (email_template_id: number) => {
    Mixpanel.track('app.delete_email_template', {
        email_template_id: email_template_id,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `email-template/${email_template_id}`,
    });
};

export const deleteEmailTemplateFolder = (record: Partial<EmailTemplateFolderType>) => {
    Mixpanel.track('app.delete_email_template_folder', {
        folder_id: record.folder_id,
        folder_name: record.folder_name,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `email-template/folder/${record.folder_id}`,
    });
};

export const toggleSharingTemplateFolder = (record: Partial<EmailTemplateFolderType>) => {
    Mixpanel.track('app.toggle_sharing_email_template_folder', {
        folder_id: record.folder_id,
        folder_name: record.folder_name,
        is_shared: !record.is_shared,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `email-template/folder/${record.folder_id}`,
        data: { is_shared: !record.is_shared },
    });
};

// bulk actions
export const moveSelectedEmailTemplates = (
    email_template_ids: number[],
    template_folder_id: number
) => {
    Mixpanel.track('app.edit_email_template', {
        email_template_ids,
        action: 'move-templates',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `email-template/bulk-move-templates`,
        data: {
            email_template_ids: email_template_ids,
            template_folder_id: template_folder_id,
        },
        useDefaultErrorNotif: true,
    });
};
