// Core React libraries import
import { memo, useState } from 'react';
import { Link } from 'react-router-dom';

// Core ANTD libraries import
import { Button, Form, Input, Checkbox, Row, Col, Typography } from 'antd';

// EZ web-app utils import
import { loginUser, storeLoginPayload } from '../../utils/ez_api/loginUtil';
import { showNotification } from '../../utils/notificationUtil';

import { Mixpanel } from 'utils/mixPanelUtil';
import { isDemoENV } from 'utils/envUtil';

const { Text } = Typography;

const LoginEmail = memo(function (props: any) {
    const [form] = Form.useForm();

    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const { setError, userLoggedIn } = props;

    const _onFinish = (values: any) => {
        const { email, password, remember } = values;

        setIsLoggingIn(true);
        loginUser(email, password, remember)
            .then(res => {
                showNotification('success', 'Successful login, congrats!');
                setError(false);
                storeLoginPayload(res.data.jwt, res.data.loginPayload);
                userLoggedIn();

                Mixpanel.setLoggedUserData(res.data.loginPayload);
            })
            .catch(err => {
                Mixpanel.track('error.login', {
                    posted_date: new Date(),
                    email,
                });
                if (err.response && err.response.status === 400) {
                    if (err.response.data?.message === 'unverified_account') {
                        setError(true, 'Error', `Account hasn't been verified yet.`);
                    } else if (err.response.data?.message === 'unmigrated_v1_user') {
                        setError(
                            true,
                            'Error',
                            `Account hasn't been migrated to version 2.0. Please contact our support for help.`
                        );
                    } else if (err.response.data?.message === 'subscription_is_unpaid') {
                        setError(
                            true,
                            'Error',
                            `We couldn't find any active subscription on your account. Please contact our customer support to help you set up your subscription.`
                        );
                    } else setError(true, 'Error', 'Wrong email and password combination.');
                }
            })
            .finally(() => {
                setIsLoggingIn(false);
            });
    };

    return (
        <div className="login-details">
            <Form
                form={form}
                name="normal_login"
                // className="login-form"
                requiredMark={false}
                initialValues={{ remember: true }}
                layout="vertical"
                onFinish={_onFinish}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
                >
                    <Input type={'email'} placeholder="Enter your email" />
                </Form.Item>
                <Row style={{ paddingBottom: '.5em' }}>
                    <Col span={12}>
                        <Text strong>Password</Text>
                    </Col>
                    <Col span={12}>
                        <Link
                            to="/forgot-password"
                            style={{ float: 'right', fontWeight: 'bold' }}
                            className="login-form-forgot"
                        >
                            <div className="login-link">Forgot Password?</div>
                        </Link>
                    </Col>
                </Row>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password placeholder="Enter your password" />
                </Form.Item>
                <Form.Item>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>Remember me</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className="login-form-forgot text-right">
                                <div className="login-link">
                                    {isDemoENV() && (
                                        <a
                                            href="https://ezcoordinator.freshdesk.com/support/tickets/new"
                                            target={'_blank'}
                                            rel="noreferrer"
                                        >
                                            Forgot Password for Demo?
                                        </a>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {/* <Link
                        to="/forgot-password"
                        style={{ float: 'right' }}
                        className="login-form-forgot"
                    >
                        <div className="login-link">Forgot Password?</div>
                    </Link> */}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                        loading={isLoggingIn}
                    >
                        {isLoggingIn ? 'Logging in...' : 'Login'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
});

export default LoginEmail;
