// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Modal, Form, Input, Typography } from 'antd';
import { isNil, omitBy, pick } from 'lodash';

// EZ web-app utils import
// EZ web-app types import
import { ContactRoleType, TransactionContactItemType } from 'types/transaction';
// EZ web-app components import
import {
    EZAutocompleteCity,
    EZSelectUSState,
    EZSelectContactRole,
    EZAutocompleteParty,
} from 'components';
import {
    addNewTransactionContact,
    updateTransactionContact,
} from 'utils/ez_api/transactionContactAPIs';
import { showNotification } from 'utils/notificationUtil';
import { updateOnboardingStep } from 'utils/onboardingUtil';
import { HomeContext } from 'context/EZContext';

// EZ web-app styles and assets import

const { Text } = Typography;

export const ModalEditAdditionalContact: React.FC<{
    contractID: number;
    contact?: TransactionContactItemType;
    contactRoles?: ContactRoleType[];
    isModalVisible?: boolean;
    setIsModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    reloadSubTabData: () => void;
}> = ({
    contractID,
    contact,
    contactRoles = [],
    isModalVisible = false,
    setIsModalVisible = () => {},
    reloadSubTabData,
}) => {
    const isEditMode: boolean = contact && contact.contact_id ? true : false;

    const [form] = Form.useForm();

    const [submittingData, setSubmittingData] = useState(false);
    const HomeCtx = useContext(HomeContext);

    const _onSubmitForm = (formValues?: any) => {
        setSubmittingData(true);
        if (formValues.role_id) {
            formValues.role =
                contactRoles.find(role => role.role_id.toString() === formValues.role_id.toString())
                    ?.role || '';
        }

        const reqPromise = isEditMode
            ? updateTransactionContact(contractID, contact?.contact_id || 0, formValues)
            : addNewTransactionContact(contractID, formValues);

        reqPromise
            .then(resp => {
                showNotification(
                    'success',
                    isEditMode
                        ? 'Selected contact data has been updated.'
                        : 'New additional contact has been added.'
                );
                if (!isEditMode) {
                    // update onboarding step to completed
                    updateOnboardingStep('add_additional_contacts', HomeCtx.refetchOnboardingList);
                }
                reloadSubTabData();
                setIsModalVisible(false);

                // set trigger to refetch data by key
                HomeCtx.setTriggerRefetchDataList(prev => [...prev, 'additional_contact']);
            })
            .finally(() => {
                setSubmittingData(false);
            });
    };

    const _setFormFieldsValue = () => {
        if (isEditMode) {
            form.setFieldsValue(contact);
        } else {
            form.resetFields();
        }
    };

    const _resetModalStates = () => {
        setSubmittingData(false);
    };

    const _onAddNewRole = () => {
        reloadSubTabData();
    };

    const _onSelectName = (value: any, opt: any) => {
        form.setFieldsValue({
            ...omitBy(
                pick(opt, [
                    'email',
                    'email2',
                    'telephone',
                    'telephone2',
                    'fax',
                    'address',
                    'city',
                    'state',
                    'zip',
                    'company',
                    'role_id',
                ]),
                isNil
            ),
        });
    };

    useEffect(() => {
        if (isModalVisible) {
            _resetModalStates();
            _setFormFieldsValue();
        } else {
            _resetModalStates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal
                title={`${isEditMode ? 'Edit Contact' : 'Add New Contact'}`}
                visible={isModalVisible}
                onOk={() => form.submit()}
                onCancel={() => setIsModalVisible(false)}
                okText="Save Changes"
                confirmLoading={submittingData}
                width={700}
                style={{ top: 40 }}
                bodyStyle={{ overflowY: 'auto', height: '600px' }}
            >
                <Form layout="vertical" form={form} onFinish={_onSubmitForm} requiredMark={false}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item label="Role" name="role_id">
                                <EZSelectContactRole
                                    options={contactRoles.map(role => ({
                                        label: role.role,
                                        value: role.role_id,
                                    }))}
                                    onAddNewRole={_onAddNewRole}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Name"
                                name="contactname"
                                rules={[{ required: true, message: "Name can't be empty" }]}
                            >
                                <EZAutocompleteParty
                                    placeholder="Enter or search a name"
                                    style={{ width: '100%' }}
                                    onSelect={(value: any, option: any) => {
                                        _onSelectName(value, option);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Main Email" name="email" rules={[{ type: 'email' }]}>
                                <Input placeholder="Enter the main email" type="email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email 2" name="email2" rules={[{ type: 'email' }]}>
                                <Input placeholder="Enter the secondary email" type="email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Main Phone" name="telephone">
                                <Input placeholder="Enter the main phone number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Phone 2" name="telephone2">
                                <Input placeholder="Enter the secondary phone" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Company" name="company">
                                <Input placeholder="Enter the company name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Fax" name="fax">
                                <Input placeholder="Enter the fax" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label="Address" name="address">
                                <Input placeholder="Enter the address" />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label="City" name="city">
                                <EZAutocompleteCity />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label="State" name="state">
                                <EZSelectUSState />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="ZIP" name="zip">
                                <Input placeholder="Enter the ZIP" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Divider orientation="left">
                        <b>Contact's Notes</b>
                    </Divider> */}
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label="Notes" name="notes">
                                <Input.TextArea placeholder="Enter the notes" rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
