// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Button, Col, Divider, Form, Input, Popconfirm, Row, Switch, Typography } from 'antd';
import {
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    EyeInvisibleOutlined,
    EyeOutlined,
    InfoCircleOutlined,
    SaveFilled,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';

// EZ web-app utils import
import { showMessage } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    addNewDocumentFolder,
    deleteDocumentFolder,
    getDocumentFolders,
    updateDocumentFolder,
} from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import { DocFolderType } from 'types/transaction';
import { updateOnboardingStep } from 'utils/onboardingUtil';
import { HomeContext } from 'context/EZContext';
import { ExpandedRowComponent } from 'components/table/ExpandedRowTable';
// EZ web-app components import

// EZ web-app styles and assets import

const DOC_IDS_FIXED = [1, 4];

export const DocFolderSetting: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();
    const HomeCtx = useContext(HomeContext);

    const [isFetchingDocFolders, setIsFetchingDocFolders] = useState(false);
    const [docFolders, setDocFolders] = useState<DocFolderType[]>([]);
    const [expandedRecordIDs, setExpandedRecordIDs] = useState<React.Key[]>([]);

    const _fetchDocFolders = () => {
        setIsFetchingDocFolders(true);
        getDocumentFolders()
            .then(({ data }) => {
                const respData = data as DocFolderType[];
                setDocFolders(respData || []);
            })
            .finally(() => {
                setIsFetchingDocFolders(false);
            });
    };

    const columns: ProColumns<DocFolderType>[] = [
        {
            title: 'Status',
            dataIndex: 'toggle',
            key: 'toggle',
            width: '80px',
            editable: false,
            render: (_, record) => (
                <Form.Item
                    name={[`${record.folder_id}`, 'toggle']}
                    noStyle
                    valuePropName="checked"
                    initialValue={record.doc_folder_preference?.toggle === 0 ? false : true}
                >
                    <Switch
                        disabled={DOC_IDS_FIXED.includes(record.folder_id) ? true : false}
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                        defaultChecked={false}
                        onChange={(val: boolean) => {
                            _toggleDoc(record);
                        }}
                    />
                </Form.Item>
            ),
        },

        {
            title: 'Name',
            dataIndex: 'new_folder_name',
            key: 'new_folder_name',
            render: (_, record) => (
                <span>{record.doc_folder_preference?.folder_name || record.folder_name}</span>
            ),
            formItemProps: (form, config) => {
                return {
                    initialValue:
                        config.entity.doc_folder_preference?.folder_name ||
                        config.entity.folder_name,
                    // rules: [{ required: true }],
                };
            },
        },
        {
            title: 'Files Information',
            dataIndex: 'files_information',
            key: 'files_information',
            render: (_, record) => (
                <span>{record.doc_folder_preference?.files_information || '-'}</span>
            ),
            formItemProps: (form, config) => {
                return {
                    initialValue: config.entity.doc_folder_preference?.files_information || '-',
                };
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '180px',
            key: 'x',
            valueType: 'option',
            render: (_, record, index, action) =>
                !DOC_IDS_FIXED.includes(record.folder_id) ? (
                    <span>
                        <Button
                            className="p-0"
                            type="link"
                            icon={<EditFilled />}
                            title="Edit"
                            size="small"
                            onClick={() => {
                                action?.startEditable(record.folder_id);
                            }}
                        >
                            Edit
                        </Button>
                        {record.folder_id !== 2 ? (
                            <>
                                <Divider type="vertical" />
                                <Popconfirm
                                    placement="left"
                                    title={
                                        <>
                                            Delete Folder <b>{record.folder_name}</b>? deleting a
                                            Folder will also delete the Templates within it
                                        </>
                                    }
                                    onConfirm={() => _deleteFolder(record)}
                                >
                                    <Button
                                        className="p-0 mr-1"
                                        type="link"
                                        icon={<DeleteOutlined />}
                                        title="Delete this list"
                                        size="small"
                                        danger
                                    ></Button>
                                </Popconfirm>
                            </>
                        ) : (
                            <></>
                        )}
                    </span>
                ) : (
                    <span></span>
                ),
        },
    ];
    const onboardingCustomDocsFolder = () => {
        // update onboarding step to completed
        updateOnboardingStep('customize_document_folder', HomeCtx.refetchOnboardingList);
    };

    const _deleteFolder = async (folder: DocFolderType) => {
        await deleteDocumentFolder(folder.folder_id);
        _fetchDocFolders();
        setTimeout(() => {
            showMessage(
                'success',
                `New Folder: ${folder.folder_name} has been successfully deleted.`
            );
        }, 100);
        onboardingCustomDocsFolder();
        return true;
    };

    const _toggleDoc = (folder: DocFolderType) => {
        return updateDocumentFolder(folder.folder_id, {
            toggle: folder.doc_folder_preference?.toggle === 0 ? 1 : 0,
        }).then(() => {
            return true;
        });
    };

    const _saveEditFolder = (folder: DocFolderType) => {
        return updateDocumentFolder(folder.folder_id, {
            folder_name: folder.folder_name,
            files_information: folder.files_information,
        }).then(() => {
            _fetchDocFolders();
            setTimeout(() => {
                showMessage(
                    'success',
                    `New name: ${folder.folder_name} has been successfully saved.`
                );
            }, 100);
            onboardingCustomDocsFolder();
            return true;
        });
    };

    const _onFinish = () => {
        addNewDocumentFolder({
            folder_name: 'Untitled',
        }).then(() => {
            _fetchDocFolders();
            form.resetFields();
            setTimeout(() => {
                showMessage('success', `New Folder: Untitled has been successfully added.`);
            }, 100);
            onboardingCustomDocsFolder();
        });
    };

    useEffect(() => {
        _fetchDocFolders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={24} className="mb-4 mt-4">
                <Row gutter={[0, 12]}>
                    <Col span={10} className="text-help pl-4">
                        <InfoCircleOutlined /> Tip: Your documents folders will be the same in all
                        transactions. The Unassigned Documents and the Disclosure Doc folders cannot
                        be renamed nor deleted.
                    </Col>
                    <Col span={14} className="pr-4">
                        <Form
                            layout="inline"
                            style={{ float: 'right' }}
                            form={form}
                            onFinish={_onFinish}
                            requiredMark={false}
                        >
                            <Button
                                type="primary"
                                style={{ borderRadius: '0px' }}
                                htmlType="submit"
                            >
                                Add Folder
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Col>
            <Col span={24} className="mt-3">
                <ProTable
                    rowKey="folder_id"
                    showSorterTooltip={false}
                    search={false}
                    loading={isFetchingDocFolders}
                    columns={columns}
                    pagination={{ pageSize: 100 }}
                    expandable={{
                        expandedRowRender: (record: DocFolderType) => {
                            return <ExpandedRowComponent Folder={record} />;
                        },
                        expandedRowKeys: expandedRecordIDs,
                        onExpand: (expanded, record: DocFolderType) => {
                            if (expanded) {
                                setExpandedRecordIDs([record.folder_id]);
                            } else {
                                setExpandedRecordIDs([]);
                            }
                        },
                    }}
                    editable={{
                        actionRender: (_, __, defaultDom) => [defaultDom.save, defaultDom.cancel],
                        type: 'multiple',
                        onSave: async (_, newData: DocFolderType, oldData) => {
                            return _saveEditFolder({
                                ...newData,
                                folder_name: newData.new_folder_name,
                            });
                        },
                        saveText: (
                            <>
                                <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                    Save
                                </Button>
                            </>
                        ),
                        cancelText: (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0 text-grey"
                                    type="text"
                                    title="Cancel and back"
                                    icon={<CloseOutlined />}
                                ></Button>
                            </>
                        ),
                    }}
                    dataSource={docFolders}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                />
            </Col>
        </Row>
    );
};
