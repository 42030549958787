import axios, { AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';

import { apiUrl } from './envUtil';

import { showNotification } from 'utils/notificationUtil';

export type AxiosRequestConfigExtended = AxiosRequestConfig & {
    useDefaultErrorNotif?: boolean;
};

export const AxiosInstance = axios.create({
    baseURL: apiUrl,
    params: {}, // do not remove this, its added to add params later in the config
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('jwt');
        if (token) {
            config.headers.common['Authorization'] = 'Bearer ' + token;
            config.headers.common['Access-Control-Allow-Origin'] = '*';
        } else {
            config.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        return config;
    },
    function (error) {
        console.log('Request Error =>', error);
        return Promise.reject(error);
    }
);

AxiosInstance.interceptors.response.use(
    response => {
        console.debug('Request Successful!', response);
        return response.data;
    },
    error => {
        if (error.response) {
            // eslint-disable-next-line no-console
            console.log('Response Error =>', error.response);
            if (401 === error.response.status) {
                localStorage.removeItem('jwt');
                window.location.reload();
            }
        } else {
            // eslint-disable-next-line no-console
            console.log('Error =>', error);
        }
        return Promise.reject(error);
    }
);

export const APIRequest = (RequestParams: AxiosRequestConfigExtended, auth = false) => {
    if (RequestParams.useDefaultErrorNotif) {
        return AxiosInstance(RequestParams).catch(err => {
            if (err?.status < 400 && err?.status > 499)
                showNotification('error', `Unidentified error happened. Please try again later.`);
            return Promise.reject(err);
        });
    } else return AxiosInstance(RequestParams);
};

export async function getData(endPoint: string) {
    return await AxiosInstance.get(endPoint);
}

export async function postData(endPoint: string, data: any) {
    return await AxiosInstance.post(endPoint, data);
}

export async function deleteData(endPoint: string) {
    return await AxiosInstance.delete(endPoint);
}

export async function postDataWithFile(
    endPoint: string,
    data: any,
    config: AxiosRequestConfigExtended
) {
    return await AxiosInstance.post(endPoint, data, config);
}

export async function patchData(endPoint: string, data: any) {
    return await AxiosInstance.patch(endPoint, data);
}

export const downloadFileRequest = (url: string, fileName: string, useAuth: boolean = false) => {
    const headers: any = {};

    if (useAuth) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');
    }

    axios
        .request({
            url,
            method: 'GET',
            responseType: 'blob', //important
            headers: { ...headers },
        })
        .then(({ data }) => {
            fileDownload(data, fileName);
        });
};
