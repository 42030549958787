// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Modal,
    Form,
    Typography,
    Row,
    Col,
    Input,
    Button,
    Popconfirm,
    Divider,
    Tabs,
    Switch,
    Collapse,
    Alert,
} from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import moment from 'moment';

// EZ web-app utils import
import { getAllTeamMembers } from 'utils/ez_api/memberAPIs';
import { addNewTransaction, DetailType } from 'utils/ez_api/transactionAPIs';
// EZ web-app types import
import { TransactionDetailType } from 'types/transaction';
import { DefaultTaskTabType } from 'types/admin';
// EZ web-app components import
import { AccountCompactDataType } from 'types/account';
import { EditTransactionFormContent } from 'pages/tabs/transaction/modals/Modal_editTransactionInfo';
import { HomeContext } from 'context/EZContext';
import { showNotification } from 'utils/notificationUtil';
import { CheckOutlined, CloseOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import EZInputNumber from 'components/input/inputNumber';
import React from 'react';
import { getTaskTabsDetail } from 'utils/ez_api/AdminAPIs';
import { EZDatePicker } from 'components';
import { updateOnboardingStep } from 'utils/onboardingUtil';

// EZ web-app styles and assets import

const { Text } = Typography;

const colLabelSpan = 8;
const colValueSpan = 10;
const rowFormViewGutter: [Gutter, Gutter] = [12, 6];

export const NewTransactionModal: React.FC<{
    isModalVisible?: boolean;
    setIsModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isModalVisible = false, setIsModalVisible = () => {} }) => {
    const HomeCtx = useContext(HomeContext);

    const isEditMode = true;

    const [form] = Form.useForm();

    const [subUsers, setSubUsers] = useState<AccountCompactDataType[]>([]);
    const [taskTabs, setTaskTabs] = useState<DefaultTaskTabType[]>([]);

    const [isRental, setIsRental] = useState<boolean>(false);
    const [SubmittingData, SetSubmittingData] = useState(false);
    const [tabKey, setTabKey] = useState('ez_detail_general_information');

    const _onSubmitForm = (formValues?: any) => {
        SetSubmittingData(true);

        const assign_to_account_ids: number[] = formValues.assign_to_account_ids;

        const formValuesFormatted = {
            ...formValues,

            // dates
            contract_date: formValues.contract_date
                ? moment(formValues.contract_date).format('YYYY-MM-DD')
                : null,
            effective_date: formValues.effective_date
                ? moment(formValues.effective_date).format('YYYY-MM-DD')
                : null,
            close_date: formValues.close_date
                ? moment(formValues.close_date).format('YYYY-MM-DD')
                : null,
            listing_date: formValues.listing_date
                ? moment(formValues.listing_date).format('YYYY-MM-DD')
                : null,
            listing_live_date: formValues.listing_live_date
                ? moment(formValues.listing_live_date).format('YYYY-MM-DD')
                : null,
            expiration_date: formValues.expiration_date
                ? moment(formValues.expiration_date).format('YYYY-MM-DD')
                : null,
            inspection_contingency_date: formValues.inspection_contingency_date
                ? moment(formValues.inspection_contingency_date).format('YYYY-MM-DD')
                : null,
            appraisal_contingency_date: formValues.appraisal_contingency_date
                ? moment(formValues.appraisal_contingency_date).format('YYYY-MM-DD')
                : null,
            financing_contingency_date: formValues.financing_contingency_date
                ? moment(formValues.financing_contingency_date).format('YYYY-MM-DD')
                : null,

            application_date: formValues.application_date
                ? moment(formValues.application_date).format('YYYY-MM-DD')
                : null,
            lease_signed_date: formValues.lease_signed_date
                ? moment(formValues.lease_signed_date).format('YYYY-MM-DD')
                : null,
            lease_start_date: formValues.lease_start_date
                ? moment(formValues.lease_start_date).format('YYYY-MM-DD')
                : null,
            lease_end_date: formValues.lease_end_date
                ? moment(formValues.lease_end_date).format('YYYY-MM-DD')
                : null,
            move_in_date: formValues.move_in_date
                ? moment(formValues.move_in_date).format('YYYY-MM-DD')
                : null,

            contingency_dates:
                formValues.contingency_dates && formValues.contingency_dates.length
                    ? formValues.contingency_dates?.map((elm: any) => ({
                          ...elm,
                          val_date: elm.val_date ? moment(elm.val_date).format('YYYY-MM-DD') : null,
                      }))
                    : [],
        };

        const payload = {
            ...formValuesFormatted,
            sale_types_id: formValues.sale_types_id || null,
            assign_to_accounts: assign_to_account_ids
                ? subUsers
                      .filter(u => assign_to_account_ids.includes(u.account_id))
                      .map(su => ({
                          account_id: su.account_id,
                          accountrole_id: su.accountrole_id,
                      }))
                : [],
        };
        addNewTransaction(payload)
            .then(({ data }) => {
                showNotification(
                    'success',
                    `New transaction: ${'address'} has been successfully created.`
                );
                setIsModalVisible(false);
                // update onboarding step to completed
                updateOnboardingStep('add_new_transaction', HomeCtx.refetchOnboardingList);
                setTimeout(() => {
                    HomeCtx.openTransactionDetailTab(
                        data.contract_id,
                        data.address,
                        data,
                        'New_transaction_modal'
                    );
                }, 10);
            })
            .finally(() => {
                SetSubmittingData(false);
            });
    };

    const _fetchSubUsers = () => {
        getAllTeamMembers().then(({ data }) => {
            setSubUsers((data as AccountCompactDataType[]) || []);
        });
    };

    const _fetchTaskTabsDetail = () => {
        getTaskTabsDetail()
            .then(({ data }) => {
                let respData = data as DefaultTaskTabType[];

                respData.forEach(defaultTasklist => {
                    defaultTasklist.defaulttasks = defaultTasklist.defaulttasks?.filter(
                        defaultTask =>
                            !defaultTask.defaulttask_preference ||
                            defaultTask.defaulttask_preference?.toggle
                                ? true
                                : false
                    );
                });

                setTaskTabs(respData || []);
            })
            .finally(() => {});
    };

    const _setFormFieldsValue = () => {
        form.setFieldsValue({
            status_id: 1,
            flag_id: 1,
        });
    };

    useEffect(() => {
        form.resetFields();

        if (isModalVisible) {
            _fetchSubUsers();

            _fetchTaskTabsDetail();
            _setFormFieldsValue();

            setTabKey('ez_detail_general_information');
        } else {
            setIsRental(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal
                title={`Add New Transaction`}
                visible={isModalVisible}
                onOk={() => form.submit()}
                onCancel={() => setIsModalVisible(false)}
                okText="Save and Edit Detail"
                confirmLoading={SubmittingData}
                width={800}
                style={{ top: 40 }}
                className="add-new-transaction-modal"
                // bodyStyle={{ overflowY: 'auto', height: '600px' }}
            >
                <Form
                    layout="vertical"
                    // className="ez-tab-transaction-detail"
                    form={form}
                    onFinish={_onSubmitForm}
                    requiredMark={false}
                >
                    <EditTransactionFormContent subUsers={subUsers} setIsRental={setIsRental} />
                    <Row gutter={rowFormViewGutter} className="row-wrapper">
                        <Col span={24} className="">
                            <Tabs
                                className="ez-tab-transaction-detail-tab-wrapper"
                                style={{ marginBottom: 16 }}
                                // defaultActiveKey="ez_detail_general_information"
                                onChange={key => setTabKey(key)}
                                activeKey={tabKey}
                            >
                                <Tabs.TabPane
                                    tab={<b>Contract Information</b>}
                                    key="ez_detail_general_information"
                                >
                                    <Row gutter={rowFormViewGutter}>
                                        {/* <Col span={24} className="">
                                            <Row className="">
                                                <Col span={24} className="text-title-secondary">
                                                    <Divider>
                                                        <b>
                                                            General Information: Year Built and
                                                            Prices
                                                        </b>
                                                    </Divider>
                                                </Col>
                                            </Row>
                                        </Col> */}

                                        {isEditMode && !isRental && (
                                            <>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Year Built
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="year_built" noStyle>
                                                        <EZInputNumber
                                                            min={0}
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter the year"
                                                            step={1}
                                                            max={2100}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Divider />
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    List Price
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="listprice" noStyle>
                                                        <EZInputNumber
                                                            numberType="currency"
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter the price"
                                                            step={10000}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Sales Price
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="salesprice" noStyle>
                                                        <EZInputNumber
                                                            numberType="currency"
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter the price"
                                                            step={10000}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Closing Cost Credit
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="cccredit" noStyle>
                                                        <EZInputNumber
                                                            numberType="currency"
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter the price"
                                                            step={10000}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Deposit
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="deposits" noStyle>
                                                        <EZInputNumber
                                                            numberType="currency"
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter the price"
                                                            step={10000}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                {/* <Divider /> */}

                                                <Col span={24} className="">
                                                    <Row className="">
                                                        <Col
                                                            span={24}
                                                            className="text-title-secondary"
                                                        >
                                                            <Divider>
                                                                <b>Important Dates</b>
                                                            </Divider>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Contract Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="contract_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Contract Effective Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="effective_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Close Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="close_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Divider />
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Listing Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="listing_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Listing Live Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="listing_live_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Listing Expiration Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="expiration_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Divider />
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Inspection Contingency Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item
                                                        name="inspection_contingency_date"
                                                        noStyle
                                                    >
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Appraisal Contingency Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item
                                                        name="appraisal_contingency_date"
                                                        noStyle
                                                    >
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Financing Contingency Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item
                                                        name="financing_contingency_date"
                                                        noStyle
                                                    >
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}

                                        {isEditMode && isRental && (
                                            <>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Year Built
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="year_built" noStyle>
                                                        <EZInputNumber
                                                            min={0}
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter the year"
                                                            step={1}
                                                            max={2100}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Divider />
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Marketed Price
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="marketedprice" noStyle>
                                                        <EZInputNumber
                                                            numberType="currency"
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter the price"
                                                            step={10000}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Rental Price
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="rentalprice" noStyle>
                                                        <EZInputNumber
                                                            numberType="currency"
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter the price"
                                                            step={10000}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Credits
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="credits" noStyle>
                                                        <EZInputNumber
                                                            numberType="currency"
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter the price"
                                                            step={10000}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                {/* <Divider /> */}
                                                <Col span={24} className="">
                                                    <Row className="">
                                                        <Col
                                                            span={24}
                                                            className="text-title-secondary"
                                                        >
                                                            <Divider>
                                                                <b>Important Dates</b>
                                                            </Divider>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Application Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="application_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Lease Signed Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="lease_signed_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Lease Start
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="lease_start_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Lease End
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="lease_end_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Move In Date
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="move_in_date" noStyle>
                                                        <EZDatePicker
                                                            style={{ width: '100%' }}
                                                            format="MM/DD/YYYY"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Divider />
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Lease Term(s)
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item name="lease_term" noStyle>
                                                        <Input placeholder="Enter the lease term(s)" />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}

                                        <Divider />
                                        <Form.List name="contingency_dates">
                                            {(fields, { add, remove }) => (
                                                <>
                                                    {fields.map(
                                                        ({ key, name, ...restField }, index) => (
                                                            <React.Fragment
                                                                key={`contingency_dates_${index}`}
                                                            >
                                                                <Col
                                                                    span={colLabelSpan}
                                                                    className="ez-form-label"
                                                                >
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[
                                                                            name,
                                                                            'date_additional_id',
                                                                        ]}
                                                                        noStyle
                                                                        hidden
                                                                    >
                                                                        <Input hidden />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'date_name']}
                                                                        noStyle
                                                                    >
                                                                        <Input placeholder="Enter the field name" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={colValueSpan}>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'val_date']}
                                                                        noStyle
                                                                    >
                                                                        <EZDatePicker
                                                                            style={{
                                                                                width: '100%',
                                                                            }}
                                                                            format="MM/DD/YYYY"
                                                                        />
                                                                    </Form.Item>

                                                                    <Popconfirm
                                                                        placement="bottomRight"
                                                                        title={`Delete this date?`}
                                                                        okText="Yes"
                                                                        cancelText="Cancel"
                                                                        onConfirm={() =>
                                                                            remove(name)
                                                                        }
                                                                        overlayClassName="custom-contingency-remover-popup"
                                                                        // showArrow={false}
                                                                    >
                                                                        <MinusCircleOutlined className="custom-contingency-remover" />
                                                                    </Popconfirm>
                                                                </Col>
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                    <Col span={18}>
                                                        {' '}
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => add()}
                                                                block
                                                                icon={<PlusOutlined />}
                                                            >
                                                                Add new contingency date
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            )}
                                        </Form.List>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={<b>Task Templates</b>} key="ez_detail_tasks">
                                    <div style={{ minHeight: '250px' }}>
                                        <Row gutter={[16, 8]}>
                                            <Col span={24} className="mb-2">
                                                <Alert
                                                    message="To update the task templates, go to the Admin Area => Transaction => Task Templates."
                                                    type="info"
                                                    showIcon
                                                />
                                            </Col>
                                            {taskTabs?.length > 0 &&
                                                taskTabs.map(defaultTasklist => (
                                                    <Col span={24} className="">
                                                        <Form.Item
                                                            name={[
                                                                'default_tasklist_ids',
                                                                `${defaultTasklist.defaulttasktab_id}`,
                                                            ]}
                                                            noStyle
                                                            valuePropName="checked"
                                                            initialValue={
                                                                !defaultTasklist.defaulttasktab_preference ||
                                                                defaultTasklist
                                                                    .defaulttasktab_preference
                                                                    ?.toggle
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            <Switch
                                                                checkedChildren={<CheckOutlined />}
                                                                unCheckedChildren={
                                                                    <CloseOutlined />
                                                                }
                                                                defaultChecked={false}
                                                            />
                                                        </Form.Item>
                                                        <span className="ml-3">
                                                            <b>{defaultTasklist.name}</b>
                                                        </span>
                                                        <Collapse ghost>
                                                            <Collapse.Panel
                                                                header={
                                                                    <>
                                                                        Tasklists
                                                                        {` - ${defaultTasklist.defaulttasks?.length} task(s)`}
                                                                    </>
                                                                }
                                                                key="1"
                                                                style={{ marginLeft: '40px' }}
                                                            >
                                                                {defaultTasklist.defaulttasks &&
                                                                    defaultTasklist.defaulttasks
                                                                        .length > 0 && (
                                                                        <div>
                                                                            <ul>
                                                                                {defaultTasklist.defaulttasks.map(
                                                                                    defaultTask => (
                                                                                        <li>
                                                                                            {
                                                                                                defaultTask.title
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </div>
                                                                    )}
                                                            </Collapse.Panel>
                                                        </Collapse>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </div>
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
