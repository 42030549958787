// Core React libs import
import React from 'react';

// Core ANTD and 3rd party libs import
import { Select } from 'antd';
// import type { SelectProps, BaseOptionType, DefaultOptionType } from 'antd/es/select';
import type { SelectProps } from 'antd/es/select';

type EZSelectDocumentProps = SelectProps & {};

export const EZSelectDocument: React.FC<EZSelectDocumentProps> = ({ ...props }) => {
    return (
        <Select<any, any>
            style={{ width: '100%' }}
            mode="multiple"
            showSearch
            allowClear
            showArrow
            placeholder="Select the document(s)"
            fieldNames={{ label: 'filename', value: 'doc_id' }}
            optionFilterProp="filename"
            {...props}
        ></Select>
    );
};
