import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';

export const getAllOffices = (keyword?: string) => {
    const params: string = qs.stringify({
        keyword: keyword,
    });

    return APIRequest({
        method: 'get',
        url: apiUrl + 'offices?' + params,
    });
};
