// Core React libraries import
import { useEffect, useState, useContext } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Typography,
    List,
    Avatar,
    Button,
    Menu,
    Popconfirm,
    Tabs,
    Modal,
    Popover,
    Input,
} from 'antd';
import {
    ContainerOutlined,
    DeleteOutlined,
    EditOutlined,
    MailOutlined,
    PlusOutlined,
} from '@ant-design/icons';

// EZ web-app utils import
// EZ web-app APIs import
import {
    getTransactionNotes,
    getTransactionEmailNotes,
    updateContactNote,
    deleteContactNote,
    deleteContactEmailNote,
} from 'utils/ez_api/transactionNoteAPIs';
// EZ web-app types import
import { TransactionEmailNoteItemType, TransactionNoteItemType } from 'types/transaction';
// EZ web-app components import
import { EZList, EZDateFormat } from 'components';
import { IListQueryParams } from 'components/list/EZList';
import { HomeContext } from 'context/EZContext';
import { updateOnboardingStep } from 'utils/onboardingUtil';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;

const defaultQueryParams: IListQueryParams = {
    searchKeyword: '',
    orderParams: [['note_id', 'desc']],
    whereParams: {
        category: 'note',
    },
    whereFilterParams: {},
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const menu = (
    <Menu
        items={[
            {
                key: '1',
                icon: <MailOutlined />,
                label: 'Email Note',
            },
        ]}
    />
);

export enum NOTE_EMBEDDED_TYPE {
    TRANSACTION_DETAIL = 'transaction_detail',
    MAIN_MENU = 'main_menu',
    TRANSACTION_LIST = 'transaction_list',
}

const EditNoteComponent: React.FC<{
    noteRecord?: TransactionNoteItemType;
    isTransactionDeleted?: boolean;
    saveNote: (note: string, note_id?: number) => Promise<any>;
}> = ({ noteRecord, saveNote, isTransactionDeleted }) => {
    const isEditMode = noteRecord && noteRecord.note_id ? true : false;

    const [isVisible, setIsVisible] = useState(false);
    const [noteContent, setNoteContent] = useState('');
    const HomeCtx = useContext(HomeContext);

    const _saveNote = () => {
        if (noteContent.trim().length > 0) {
            saveNote(noteContent.trim(), noteRecord?.note_id).then(() => {
                setIsVisible(false);
                setNoteContent('');
                if (!isEditMode) {
                    // update onboarding step to completed
                    updateOnboardingStep('add_notes', HomeCtx.refetchOnboardingList);
                }
            });
        }
    };

    // the effect is to populate "noteContent" whenever there is a change of "noteRecord"
    useEffect(() => {
        setNoteContent(noteRecord ? noteRecord.notes : '');
    }, [noteRecord]);

    return (
        <Popover
            visible={isVisible}
            content={
                <div className="add-new-note-popover">
                    <Row gutter={[0, 10]}>
                        <Col span={24}>
                            <Input.TextArea
                                rows={4}
                                value={noteContent}
                                onChange={e => setNoteContent(e?.currentTarget?.value)}
                            ></Input.TextArea>
                        </Col>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button
                                type="default"
                                className="mr-2"
                                onClick={() => setIsVisible(false)}
                            >
                                Close
                            </Button>
                            <Button type="primary" onClick={() => _saveNote()}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
            overlayClassName=""
            title={
                <>{isEditMode ? <span>Edit Note</span> : <span>Add New Contract's Note</span>}</>
            }
            trigger={'click'}
            showArrow={false}
            placement="left"
        >
            {isEditMode && !isTransactionDeleted ? (
                <Button
                    className="p-0 mr-2"
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => setIsVisible(true)}
                    title="Edit this note"
                >
                    Edit
                </Button>
            ) : (
                !isTransactionDeleted && (
                    <Button
                        className="ez-action-btn ml-3"
                        icon={<PlusOutlined />}
                        onClick={() => setIsVisible(true)}
                    >
                        Add New Contract's Note
                    </Button>
                )
            )}
        </Popover>
    );
};

export const AppPageNotes: React.FC<{
    pageType: NOTE_EMBEDDED_TYPE;
    contractID?: number;
    isTransactionDeleted?: boolean;
    manualReloadCount: number;
    reloadTransactionStat?: (contract_id?: number) => void;
}> = ({ pageType, contractID, manualReloadCount, isTransactionDeleted, reloadTransactionStat }) => {
    const HomeCtx = useContext(HomeContext);
    const UserPermission = HomeCtx.userPermission;

    const [modal, contextHolder] = Modal.useModal();

    const [noteType, setNoteType] = useState<string>('contract_notes');
    const [queryParams, setQueryParams] = useState<IListQueryParams>(defaultQueryParams);

    const _fetchNotes: (EZQueryParams: string) => Promise<any> = async EZQueryParams => {
        const result = await getTransactionNotes(contractID || -1, EZQueryParams);
        return result;
    };

    const _fetchEmailNotes: (EZQueryParams: string) => Promise<any> = async EZQueryParams => {
        const result = await getTransactionEmailNotes(contractID || -1, EZQueryParams);
        return result;
    };
    const _reloadSubTabData = () => {
        if (noteType === 'from_emails') {
            setQueryParams({
                ...defaultQueryParams,
                orderParams: [['m_id', 'desc']],
                whereParams: {},
            });
        } else {
            setQueryParams({
                ...defaultQueryParams,
            });
        }
    };

    const _showEmailBody = (record: TransactionEmailNoteItemType) => {
        modal.info({
            content: <Paragraph style={{ whiteSpace: 'pre-wrap' }}>{record.msg_body}</Paragraph>,
            width: '70%',
            closable: true,
            wrapClassName: 'note-email-modal-wrapper',
            icon: <></>,
            okText: 'Close',
            maskClosable: true,
            title: `${record.msg_subject}`,
        });
    };

    const _saveNote = async (note: string, note_id?: number) => {
        return updateContactNote(contractID || 0, note, note_id)
            .then(() => {
                _reloadSubTabData();

                if (!note_id) {
                    reloadTransactionStat?.(contractID);
                }
            })
            .finally(() => {});
    };

    const _deleteNote = async (note_id: number) => {
        return deleteContactNote(contractID || 0, note_id)
            .then(() => {
                reloadTransactionStat?.(contractID);
                _reloadSubTabData();
            })
            .finally(() => {});
    };

    const _deleteEmailNote = async (m_id: number) => {
        return deleteContactEmailNote(contractID || 0, m_id)
            .then(() => {
                reloadTransactionStat?.(contractID);
                _reloadSubTabData();
            })
            .finally(() => {});
    };

    useEffect(() => {
        _reloadSubTabData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez-component-documents">
            <div>{contextHolder}</div>
            <Row gutter={[0, 18]}>
                {pageType !== NOTE_EMBEDDED_TYPE.TRANSACTION_LIST && (
                    <Col span={24} style={{ textAlign: 'center' }}>
                        <Tabs
                            type="card"
                            defaultActiveKey="contract_notes"
                            onChange={(val: string) => {
                                const noteType = val;
                                setNoteType(noteType);
                                if (noteType === 'from_emails') {
                                    setQueryParams({
                                        ...defaultQueryParams,
                                        orderParams: [['m_id', 'desc']],
                                        whereParams: {},
                                    });
                                } else {
                                    setQueryParams({
                                        ...defaultQueryParams,
                                    });
                                }
                            }}
                        >
                            <Tabs.TabPane
                                tab="Contract's Notes"
                                key="contract_notes"
                                closable={false}
                            ></Tabs.TabPane>
                            <Tabs.TabPane
                                tab="From Emails"
                                key="from_emails"
                                closable={false}
                            ></Tabs.TabPane>
                        </Tabs>
                    </Col>
                )}
                <Col span={24}>
                    {noteType === 'contract_notes' && (
                        <EZList
                            rowKey="note_id"
                            paginationConfig={{
                                pageSize: 20,
                            }}
                            queryParams={queryParams}
                            fetchData={_fetchNotes}
                            toolBarComponents={
                                UserPermission && UserPermission.notes_toggle === 2
                                    ? [
                                          <EditNoteComponent
                                              saveNote={_saveNote}
                                              isTransactionDeleted={isTransactionDeleted}
                                          />,
                                      ]
                                    : []
                            }
                            renderItem={(record: TransactionNoteItemType) => (
                                <List.Item
                                    actions={
                                        UserPermission && UserPermission.notes_toggle === 2
                                            ? [
                                                  <EditNoteComponent
                                                      isTransactionDeleted={isTransactionDeleted}
                                                      saveNote={_saveNote}
                                                      noteRecord={record}
                                                  />,
                                                  !isTransactionDeleted && (
                                                      <Popconfirm
                                                          placement="bottomRight"
                                                          title={`Delete this note?`}
                                                          okText="Yes"
                                                          cancelText="Cancel"
                                                          onConfirm={() =>
                                                              _deleteNote(record.note_id)
                                                          }
                                                      >
                                                          <Button
                                                              className="p-0"
                                                              type="link"
                                                              style={{ color: 'red' }}
                                                              icon={<DeleteOutlined />}
                                                          >
                                                              Delete
                                                          </Button>
                                                      </Popconfirm>
                                                  ),
                                              ]
                                            : []
                                    }
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                style={{ backgroundColor: '#E6F7FF' }}
                                                icon={
                                                    <ContainerOutlined
                                                        style={{ color: '#096DD9' }}
                                                    />
                                                }
                                                size={42}
                                            />
                                        }
                                        title={
                                            <span>
                                                <Text strong className="mr-1">
                                                    {record.posted_account?.fullname}
                                                </Text>
                                                <Text className="mr-1">
                                                    {record.posted_account?.email
                                                        ? `(${record.posted_account.email})`
                                                        : ``}
                                                </Text>
                                                <Text type="secondary" className="mr-2">
                                                    On{' '}
                                                    <EZDateFormat
                                                        value={record.date_posted}
                                                        format="MMM DD, YYYY - hh:mma"
                                                    />
                                                </Text>
                                            </span>
                                        }
                                        description={
                                            <Paragraph
                                                style={{ whiteSpace: 'pre-wrap' }}
                                                ellipsis={{
                                                    rows: 3,
                                                    expandable: true,
                                                }}
                                                title={`${record.notes}`}
                                            >
                                                {record.notes}
                                            </Paragraph>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    )}
                    {noteType === 'from_emails' && (
                        <EZList
                            rowKey="note_id"
                            paginationConfig={{
                                pageSize: 20,
                            }}
                            queryParams={queryParams}
                            fetchData={_fetchEmailNotes}
                            renderItem={(record: TransactionEmailNoteItemType) => (
                                <List.Item
                                    actions={
                                        UserPermission &&
                                        UserPermission.notes_toggle === 2 &&
                                        !isTransactionDeleted
                                            ? [
                                                  <Popconfirm
                                                      placement="bottomRight"
                                                      title={`Delete this email's note?`}
                                                      okText="Yes"
                                                      cancelText="Cancel"
                                                      onConfirm={() =>
                                                          _deleteEmailNote(record.m_id)
                                                      }
                                                  >
                                                      <Button
                                                          className="p-0"
                                                          type="link"
                                                          style={{ color: 'red' }}
                                                          icon={<DeleteOutlined />}
                                                      >
                                                          Delete
                                                      </Button>
                                                  </Popconfirm>,
                                              ]
                                            : []
                                    }
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                style={{ backgroundColor: '#E6F7FF' }}
                                                icon={<MailOutlined style={{ color: '#096DD9' }} />}
                                                size={42}
                                            />
                                        }
                                        title={
                                            <span>
                                                <Text strong className="mr-1">
                                                    {record.msg_sender || record.account?.fullname}
                                                </Text>
                                                {!record.msg_sender && record.account?.email ? (
                                                    <Text className="mr-1">
                                                        {record.account?.email
                                                            ? `(${record.account.email})`
                                                            : ``}
                                                    </Text>
                                                ) : (
                                                    ''
                                                )}
                                                <Text type="secondary" className="mr-2">
                                                    On{' '}
                                                    <EZDateFormat
                                                        value={record.msg_date}
                                                        format="MMM DD, YYYY - hh:mma"
                                                    />
                                                </Text>
                                            </span>
                                        }
                                        description={
                                            <>
                                                <div>
                                                    <Paragraph
                                                        style={{ whiteSpace: 'pre-wrap' }}
                                                        ellipsis={{
                                                            rows: 3,
                                                            expandable: true,
                                                        }}
                                                        title={`${record.msg_subject}`}
                                                    >
                                                        {record.msg_subject}
                                                    </Paragraph>
                                                </div>
                                                <div className="mt-2">
                                                    <Button
                                                        onClick={() => _showEmailBody(record)}
                                                        size="small"
                                                        type="ghost"
                                                    >
                                                        Show email body
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};
