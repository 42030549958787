import React, { useEffect, useState } from 'react';

import { Col, Form, FormInstance, Input, Row } from 'antd';
import { EZRichTextEditor } from 'components/richTextEditor/richTextEditor';
import { AccountDataType } from 'types/account';
import { EmailSignatureType } from 'types/emailSignature';

export const EditEmailSignatureModalContent: React.FC<{
    emailSignature: Partial<EmailSignatureType>;
    form: FormInstance<any>;
    UserInfo?: AccountDataType;
    mode: 'EDIT' | 'ADD';
}> = ({ emailSignature, form, UserInfo, mode }) => {
    const _setFieldValues = () => {
        form.resetFields();

        form.setFieldsValue({
            ...emailSignature,
        });
    };

    useEffect(() => {
        _setFieldValues();
    }, []);

    return (
        <Form layout="vertical" form={form} requiredMark={true}>
            <Row gutter={[16, 0]}>
                <Col span={24} id="signature-name">
                    <Form.Item
                        label="Signature Name"
                        name="signature_name"
                        rules={[{ required: true, message: "Signature name can't be empty" }]}
                    >
                        <Input placeholder="Enter the name" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="signature_body"
                        rules={[
                            { required: true, message: 'Signature body is required' },
                            {
                                validator: (_, value) => {
                                    const parser = new DOMParser();
                                    const { textContent } = parser.parseFromString(
                                        value,
                                        'text/html'
                                    ).documentElement;

                                    if (!textContent?.trim()) {
                                        return Promise.reject("Signature body can't be empty");
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}
                    >
                        <EZRichTextEditor isRequired />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
