// Core React libraries import
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Core ANTD and 3rdparty libraries import
import { Alert } from 'antd';

// EZ web-app utils import
// EZ web-app types import
// EZ web-app components import
// EZ web-app styles and assets import

export const DocusignRedirect: React.FC<{}> = () => {
    const urlParams: any = useParams();

    return (
        <div className="p-5">
            <Alert
                message="The processed document(s) has been sent. You can close this pop-up while waiting for the recipient(s)' signature. "
                type="success"
                showIcon
            />
        </div>
    );
};
