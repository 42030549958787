// Core React libraries import
import React, { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Form, Select, Popover, Typography, Alert, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import FullCalendar, {
    EventApi,
    EventClickArg,
    EventContentArg,
    EventInput,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import debounce from 'lodash/debounce';

// EZ web-app import

// EZ web-app utils import
import { getCalendarEvents } from 'utils/ez_api/calendarAPIs';
import { EZDateFormat, EZOverlaySpinner, EZSelectTransaction } from 'components';
import { HomeContext } from 'context/EZContext';

// Page level styles
import './Calendar.scss';
import { TAB_CATEGORY } from 'types/webAppTab';

type SelectedEventType = EventApi & {
    eventTitle?: string;
    address?: string;
    contract_id?: string;
};

const { Option, OptGroup } = Select;
const { Text } = Typography;

export default function CalendarTab(props: any) {
    const HomeCtx = useContext(HomeContext);

    const CalendarState = {
        weekendsVisible: true,
        currentEvents: [],
    };

    const [SelectedEvent, setSelectedEvent] = useState<SelectedEventType>();
    const [Events, setEvents] = useState<EventInput[]>([]);
    const [CalendarDuration, setCalendarDuration] = useState<{
        start_date: string;
        end_date: string;
    }>();
    const [TransactionFilter, setTransactionFilter] = useState<number[]>([]);
    const [ColumnTypeFilter, setColumnTypeFilter] = useState<string>('');

    const [IsLoadingCalendarEvents, setIsLoadingCalendarEvents] = useState<boolean>(false);
    // const [IsLoadingEventDetail, setIsLoadingEventDetail] = useState<boolean>(false);

    const handleEventClick = (clickInfo: EventClickArg) => {
        const event = clickInfo.event;
        const payload: SelectedEventType = clickInfo.event;

        const titleSplit = event.title.split(':');

        payload.eventTitle = titleSplit.length ? titleSplit[1] : event.title;
        payload.contract_id = event.extendedProps?.contract_id;
        payload.address = titleSplit.length ? titleSplit[0] : event.title;

        setSelectedEvent(payload);
    };

    const _openTransactionDetailTab = (event: EventApi, contract_id?: string, address?: string) => {
        if (HomeCtx.openTransactionDetailTab) {
            HomeCtx.setActiveTabByButton(true);
            HomeCtx.openTransactionDetailTab(
                contract_id || -1,
                address,
                {
                    subTabKey: String(SelectedEvent?.extendedProps?.ez_table).includes('todolist')
                        ? 'ez_detail_tasks'
                        : undefined,
                },
                TAB_CATEGORY.CALENDAR
            );
        }
    };

    const _reloadCalendarEvents = (start_date: string, end_date: string) => {
        const events: any[] = [];

        setIsLoadingCalendarEvents(true);

        Promise.all([
            getCalendarEvents({
                where: {
                    '>::<StartTime': [start_date, end_date],
                    contract_id: TransactionFilter.length ? TransactionFilter : undefined,
                    ez_table_column_name: ColumnTypeFilter
                        ? ColumnTypeFilter.replace('task.', '').replace('date.', '')
                        : undefined,
                },
                pageSize: 5000,
            }),
        ])
            .then(respArray => {
                respArray.forEach(resp => {
                    if (resp && resp.data) {
                        events.push(...resp.data);
                    }
                });
            })
            .finally(() => {
                setEvents(events);
                setIsLoadingCalendarEvents(false);
            });
    };

    const _triggerUpdateCalendarEventsDelayed = debounce(
        (filterType: string, value: string | number | number[]) => {
            switch (filterType) {
                case 'filter.transaction':
                    setTransactionFilter(value as number[]);
                    break;
                case 'filter.column_type':
                    setColumnTypeFilter(value as string);
                    break;

                default:
                    break;
            }
        },
        500
    );

    const EventPopover: React.FC<{
        event: EventApi;
        SetPopoverVisible: React.Dispatch<React.SetStateAction<boolean>>;
    }> = ({ event, SetPopoverVisible }) => {
        const titleSplit = event.title.split(':');

        const eventTitle = titleSplit.length ? titleSplit[1] : event.title;
        const contract_id = event.extendedProps?.contract_id;
        const address = titleSplit.length ? titleSplit[0] : event.title;

        return (
            <div>
                <Row>
                    <Col span={24}>
                        {/* {IsLoadingEventDetail && <EZOverlaySpinner />} */}
                        <Form layout="vertical">
                            <Form.Item label="Title:">{eventTitle || '-'}</Form.Item>
                            <Form.Item label="Listing Address:">
                                <span>
                                    {address || '-'}&nbsp;
                                    <Button
                                        title="Open in a new tab"
                                        // icon={<ArrowsAltOutlined />}
                                        size="small"
                                        className="ml-2 ez-action-btn"
                                        onClick={() => {
                                            SetPopoverVisible(true);
                                            _openTransactionDetailTab(event, contract_id, address);
                                            return false;
                                        }}
                                    >
                                        Open
                                    </Button>
                                </span>
                            </Form.Item>
                            <Form.Item
                                label={
                                    String(event.extendedProps?.ez_table).includes('todolist')
                                        ? 'Due Date:'
                                        : 'Date'
                                }
                            >
                                <EZDateFormat
                                    value={event.start?.toString()}
                                    format="MMM Do, YYYY"
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    };

    const CalendarEvent: React.FC<{
        event: EventApi;
    }> = ({ event }) => {
        // const [PopoverVisible, SetPopoverVisible] = useState<boolean>(false);

        return <div>{event.title}</div>;
        // return (
        //     <Popover
        //         trigger={['click']}
        //         content={<EventPopover event={event} SetPopoverVisible={SetPopoverVisible} />}
        //         title="Event Detail"
        //         placement="left"
        //         // getPopupContainer={node =>
        //         //     node.parentElement?.parentElement?.parentElement || document.body
        //         // }
        //         visible={PopoverVisible}
        //         onVisibleChange={visible => {
        //             SetPopoverVisible(visible);
        //         }}
        //         overlayClassName="ez-calendar-popover"
        //     >
        //         <div>{event.title}</div>
        //     </Popover>
        // );
    };

    const renderEventContent = function (eventContent: EventContentArg) {
        return (
            <>
                {/* <CalendarEvent event={eventContent.event} /> */}
                <div>{eventContent.event.title}</div>
            </>
        );
    };

    useEffect(() => {
        if (CalendarDuration && CalendarDuration.start_date) {
            _reloadCalendarEvents(CalendarDuration.start_date, CalendarDuration.end_date);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CalendarDuration, TransactionFilter, ColumnTypeFilter]);

    return (
        <div className="ez-tab-calendar">
            <div className="mb-5 ez-sub-content-wrapper">
                <Form>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name="transaction_filter" noStyle>
                                <EZSelectTransaction
                                    onChange={value =>
                                        _triggerUpdateCalendarEventsDelayed(
                                            'filter.transaction',
                                            value
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                        {/* <Col span={6}>
                            <Form.Item name="status_id" noStyle>
                                <EZSelectStatus
                                    maxTagCount={'responsive'}
                                    mode="multiple"
                                    optionFilterProp="label"
                                    className="mr-3"
                                    placeholder={
                                        <span>
                                            <Text>Transaction Status: </Text>
                                            <Text strong>All Statuses</Text>
                                        </span>
                                    }
                                    // onChange={value =>
                                    //     _triggerUpdateTableQueryDelayed('filter.status_id', value)
                                    // }
                                />
                            </Form.Item>
                        </Col> */}
                        <Col span={12}>
                            <Form.Item name="filter_by" noStyle>
                                <Select
                                    style={{ width: '100%' }}
                                    listHeight={400}
                                    placeholder={
                                        <span>
                                            <Text>Filter By: </Text>
                                            <Text strong>None</Text>
                                        </span>
                                    }
                                    allowClear
                                    // onChange={(value, option) => _setFilterBy(value)}
                                    onChange={value =>
                                        _triggerUpdateCalendarEventsDelayed(
                                            'filter.column_type',
                                            value
                                        )
                                    }
                                >
                                    <OptGroup label="Task">
                                        <Option value="task.duedate">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Task Due Date</Text>
                                            </span>
                                        </Option>
                                    </OptGroup>
                                    <OptGroup label="Important Dates">
                                        <Option value="date.close_date">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Closing Date</Text>
                                            </span>
                                        </Option>
                                        <Option value="date.contract_date">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Contract Date</Text>
                                            </span>
                                        </Option>
                                        <Option value="date.effective_date">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Contract Effective Date</Text>
                                            </span>
                                        </Option>
                                        <Option value="date.listing_date">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Listing Date</Text>
                                            </span>
                                        </Option>
                                        <Option value="date.expiration_date">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Listing Expiration Date</Text>
                                            </span>
                                        </Option>
                                        <Option value="date.inspection_contingency_date">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Inspection Contingency Date</Text>
                                            </span>
                                        </Option>
                                        <Option value="date.financing_contingency_date">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Financing Contingency Date</Text>
                                            </span>
                                        </Option>
                                        <Option value="date.appraisal_contingency_date">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Appraisal Contingency Date</Text>
                                            </span>
                                        </Option>
                                        <Option value="date.val_date">
                                            <span>
                                                <Text>Filter By: </Text>
                                                <Text strong>Custom Contingency Dates</Text>
                                            </span>
                                        </Option>
                                    </OptGroup>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <div className="">
                    <Row gutter={16}>
                        <Col span={SelectedEvent ? 18 : 24}>
                            {/* <Col span={SelectedEvent ? 18 : 24}> */}
                            <div className="ez-sub-content-wrapper" style={{ minHeight: '1100px' }}>
                                <Row>
                                    <Col span={24}>
                                        {IsLoadingCalendarEvents && <EZOverlaySpinner />}
                                        <FullCalendar
                                            // contentHeight={'150px'}
                                            customButtons={{
                                                refreshButton: {
                                                    text: 'Reload',
                                                    click: () => {
                                                        if (
                                                            CalendarDuration &&
                                                            CalendarDuration.start_date
                                                        ) {
                                                            _reloadCalendarEvents(
                                                                CalendarDuration.start_date,
                                                                CalendarDuration.end_date
                                                            );
                                                        }
                                                    },
                                                },
                                            }}
                                            dayMaxEventRows={true}
                                            views={{
                                                dayGrdayGridMonthid: {
                                                    dayMaxEventRows: 3,
                                                },
                                            }}
                                            plugins={[
                                                dayGridPlugin,
                                                timeGridPlugin,
                                                interactionPlugin,
                                            ]}
                                            headerToolbar={{
                                                left: 'prev,next today',
                                                center: 'title',
                                                right: 'dayGridMonth,dayGridWeek,timeGridDay refreshButton',
                                            }}
                                            initialView="dayGridMonth"
                                            editable={false}
                                            selectable={false}
                                            selectMirror={true}
                                            dayMaxEvents={true}
                                            weekends={CalendarState.weekendsVisible}
                                            events={Events}
                                            eventContent={renderEventContent} // custom render function
                                            eventClick={handleEventClick}
                                            datesSet={arg => {
                                                setCalendarDuration({
                                                    start_date: arg.startStr,
                                                    end_date: arg.endStr,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        {SelectedEvent && (
                            <Col span={6}>
                                <div
                                    className="ez-sub-content-wrapper"
                                    style={{ height: '100%', paddingTop: '20px' }}
                                >
                                    <Row className="mb-4" align="middle" justify="space-between">
                                        <Col span={12}>
                                            <Typography.Title level={4} className="mb-0">
                                                Event Detail
                                            </Typography.Title>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <CloseOutlined
                                                className="cursor-pointer"
                                                onClick={() => setSelectedEvent(undefined)}
                                            />
                                        </Col>
                                    </Row>
                                    {SelectedEvent ? (
                                        <Row
                                            style={{
                                                position: 'sticky',
                                                top: '60px',
                                                zIndex: 50,
                                            }}
                                        >
                                            <Col span={24}>
                                                {/* {IsLoadingEventDetail && <EZOverlaySpinner />} */}
                                                <Form layout="vertical">
                                                    <Form.Item label="Title:">
                                                        {SelectedEvent?.eventTitle || '-'}
                                                    </Form.Item>
                                                    <Form.Item label="Listing Address:">
                                                        <span>
                                                            {SelectedEvent?.address || '-'}
                                                            <br />
                                                            <Button
                                                                title="Open in a new tab"
                                                                // icon={<ArrowsAltOutlined />}
                                                                size="small"
                                                                className="ez-action-btn"
                                                                onClick={() =>
                                                                    _openTransactionDetailTab(
                                                                        SelectedEvent,
                                                                        SelectedEvent.contract_id,
                                                                        SelectedEvent.address
                                                                    )
                                                                }
                                                            >
                                                                Open
                                                            </Button>
                                                        </span>
                                                    </Form.Item>
                                                    <Form.Item
                                                        label={
                                                            String(
                                                                SelectedEvent?.extendedProps
                                                                    ?.ez_table
                                                            ).includes('todolist')
                                                                ? 'Due Date:'
                                                                : 'Date'
                                                        }
                                                    >
                                                        <EZDateFormat
                                                            value={SelectedEvent?.start?.toString()}
                                                            format="MMM Do, YYYY"
                                                        />
                                                    </Form.Item>
                                                </Form>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Alert
                                            message="No event is selected"
                                            type="info"
                                            showIcon
                                        />
                                    )}
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            </div>
        </div>
    );
}
