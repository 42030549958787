// Core React libs import
import React from 'react';

// Core ANTD and 3rd party libs import
import { Select } from 'antd';
// import type { SelectProps, BaseOptionType, DefaultOptionType } from 'antd/es/select';
import type { SelectProps } from 'antd/es/select';

type EZSelectTaskProps = SelectProps & {};

export const EZSelectTask: React.FC<EZSelectTaskProps> = ({ ...props }) => {
    return (
        <Select<any, any>
            style={{ width: '100%' }}
            mode="multiple"
            showSearch
            allowClear
            showArrow
            placeholder="Select the task(s)"
            fieldNames={{ label: 'title', value: 'id' }}
            optionFilterProp="title"
            {...props}
        ></Select>
    );
};
