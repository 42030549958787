import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { DataTableQueryType } from 'types/base';
import { Mixpanel } from 'utils/mixPanelUtil';
import { ContactRoleType } from 'types/transaction';

export const getAllAddressBooks = (objParam?: {} | string) => {
    const params: string = typeof objParam === 'string' ? objParam : qs.stringify(objParam);

    return APIRequest({
        method: 'get',
        url: apiUrl + 'addressbooks?' + params,
    });
};

export const addNewAddressbookContact = (payload: any) => {
    Mixpanel.track('app.add_addressbook', {});

    return APIRequest({
        method: 'post',
        url: apiUrl + `addressbooks`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateAddressbookContact = (addressbook_id: number, payload: any) => {
    Mixpanel.track('app.edit_addressbook', {});

    return APIRequest({
        method: 'put',
        url: apiUrl + `addressbook/${addressbook_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteAddressbookContact = (addressbook_id: number) => {
    Mixpanel.track('app.delete_addressbook', {});

    return APIRequest({
        method: 'delete',
        url: apiUrl + `addressbook/${addressbook_id}`,
        useDefaultErrorNotif: true,
    });
};

export const getAddressbookRoles = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `addressbooks/roles?` + qs.stringify({ pageSize: 250 }),
    });
};

export const getContactRoles = (params?: Partial<DataTableQueryType>) => {
    const finalParams: string = qs.stringify({ pageSize: 250, ...params } as DataTableQueryType);

    return APIRequest({
        method: 'get',
        url: apiUrl + `contact-roles` + (finalParams ? `?${finalParams}` : ''),
    });
};

export const addNewContactRole = (payload: Partial<ContactRoleType>) => {
    Mixpanel.track('app.add_contract_role', { ...payload });

    return APIRequest({
        method: 'post',
        url: apiUrl + `contact-roles`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};
