// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Typography, UploadFile, Collapse, Col, Row, Progress } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { EZUploadFileProps } from './upload';

// EZ web-app utils import
// EZ web-app components import

// EZ web-app styles and assets import

const { Panel } = Collapse;

export const UploadProgress: React.FC<{
    // fileUploadList: UploadFile<any>[];
    fileUploadList: EZUploadFileProps[];
    setFileUploadList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
}> = ({ setFileUploadList, fileUploadList }) => {
    const _removeFileUpload = (file: UploadFile) => {
        if (file.status === 'error' || file.status === 'removed') {
            setFileUploadList(fileUploadList.filter(f => f.uid !== file.uid));
        }
    };

    // const _getFileUploadList = () => {
    //     return fileList;
    // };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row className="home-file-upload-wrapper">
            {fileUploadList.filter(file => ['error', 'uploading'].includes(file.status || ''))
                .length ? (
                <Col span={24}>
                    <div className="file-uploads-progress-wrapper">
                        <Collapse defaultActiveKey={['1']}>
                            <Panel
                                header={`File upload window`}
                                // header={`Uploading ${
                                //     fileList?.filter(file => file.status === 'uploading').length
                                // } of ${fileList.length}`}
                                key="1"
                                extra={
                                    fileUploadList.filter(file => file.status === 'uploading')
                                        .length ? (
                                        ''
                                    ) : (
                                        <CloseOutlined
                                            onClick={event => {
                                                setFileUploadList([]);
                                                // If you don't want click extra trigger collapse, you can prevent this:
                                                event.stopPropagation();
                                            }}
                                        />
                                    )
                                }
                            >
                                <Row gutter={[6, 8]}>
                                    {fileUploadList
                                        .filter(file =>
                                            ['error', 'uploading'].includes(file.status || '')
                                        )
                                        .map(file => (
                                            <React.Fragment key={`fileList__${file.uid}`}>
                                                <Col span={16} style={{ lineHeight: '28px' }}>
                                                    <Typography.Text ellipsis>
                                                        {file.name}
                                                    </Typography.Text>
                                                </Col>
                                                <Col span={8} style={{ textAlign: 'right' }}>
                                                    <span
                                                        className="cursor-pointer"
                                                        title="Remove from Upload"
                                                        onClick={() => _removeFileUpload(file)}
                                                    >
                                                        <Progress
                                                            type="circle"
                                                            percent={file.percent}
                                                            width={28}
                                                            status={
                                                                file.error ? 'exception' : undefined
                                                            }
                                                        />
                                                    </span>
                                                </Col>
                                            </React.Fragment>
                                        ))}
                                </Row>
                            </Panel>
                        </Collapse>
                    </div>
                </Col>
            ) : (
                ''
            )}
        </Row>
    );
};
