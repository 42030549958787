// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Space, Button, Popconfirm, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';

// EZ web-app utils import
// EZ web-app APIs import
import {
    deleteProspectContact,
    getProspectContacts,
} from 'utils/ez_api/prospect/prospectContactAPIs';
import { getContactRoles } from 'utils/ez_api/contactAPIs';
// EZ web-app types import
import { ContactRoleType } from 'types/transaction';
import { ProspectDetailTabProps } from 'types/prospect';
import { ProspectContactType } from 'types/prospectContact';
// EZ web-app components import
import { EZCopyable, EZTable, EZDateFormat } from 'components';
import { ITableQueryParams } from 'components/table/EZProTable';
import { showNotification } from 'utils/notificationUtil';
import { ModalEditAdditionalProspectContact } from '../modal/Modal_showEditAdditionalContact';

// EZ web-app styles and assets import

const defaultTableParam = {
    searchKeyword: '',
    orderParams: [],
    whereParams: {},
    whereFilterParams: {},
};

export const ProspectSubTab_Contacts: React.FC<ProspectDetailTabProps> = ({
    prospectID,
    manualReloadCount,
    reloadProspectStat,
}) => {
    const [queryParams, setQueryParams] = useState<ITableQueryParams>(defaultTableParam);
    const [selectedContact, setSelectedContact] = useState<ProspectContactType>();
    const [contactRoles, setContactRoles] = useState<ContactRoleType[]>([]);

    const [isModalEditContactVisible, setIsModalEditContactVisible] = useState(false);

    async function _fetchData(EZTableQueryParams: string): Promise<any> {
        return getProspectContacts(prospectID, EZTableQueryParams).then(result => result);
    }

    const _reloadContactRoles = () => {
        getContactRoles().then(response => {
            setContactRoles(response.data || []);
        });
    };

    const _reloadSubTabData = () => {
        setQueryParams({ ...defaultTableParam });

        _reloadContactRoles();
    };

    const _showAddContactModal = () => {
        setIsModalEditContactVisible(true);
    };

    const _editSelectedContact = (contact: ProspectContactType) => {
        setIsModalEditContactVisible(true);
        setSelectedContact(contact);
    };
    const _deleteSelectedContact = (contact: ProspectContactType) => {
        deleteProspectContact(prospectID, contact.contact_id || 0).then(() => {
            showNotification('success', 'You have successfully deleted the selected contact.');
            _reloadSubTabData();
        });
    };

    const _columns: ProColumns<ProspectContactType>[] = [
        {
            title: 'Name',
            dataIndex: 'fullname',
            width: '200px',
            fixed: 'left',
            sorter: true,
            render: (_, record) => (
                <div>
                    {record.fullname ? (
                        <>
                            {record.fullname} <EZCopyable text={record.fullname} />
                        </>
                    ) : (
                        '-'
                    )}
                </div>
            ),
        },
        {
            title: 'Role',
            dataIndex: 'role_id',
            width: '200px',
            render: (_, record) => <div>{record.contact_role?.role || '-'}</div>,
        },
        {
            title: 'Email #1',
            dataIndex: 'email',
            width: '250px',
            render: (_, record) => (
                <div>
                    {record.email ? (
                        <>
                            {record.email} <EZCopyable text={record.email} />
                        </>
                    ) : (
                        '-'
                    )}
                </div>
            ),
            sorter: true,
        },
        {
            title: 'Telephone #1',
            dataIndex: 'telephone',
            width: '200px',
            render: (_, record) => (
                <div>
                    {record.telephone ? (
                        <>
                            {record.telephone} <EZCopyable text={record.telephone} />
                        </>
                    ) : (
                        '-'
                    )}{' '}
                </div>
            ),
            sorter: true,
        },
        {
            title: 'Company',
            dataIndex: 'company',
            width: '200px',
            render: (_, record) => (
                <div>{record.company || record.contact_company?.company || '-'}</div>
            ),
            sorter: true,
        },
        {
            title: 'Fax',
            dataIndex: 'fax',
            width: '200px',
            render: (_, record) => <div>{record.telephone || '-'}</div>,
            sorter: true,
        },
        {
            title: 'Address',
            dataIndex: 'address',
            width: '300px',
            render: (_, record) => (
                <div>
                    <div>{record.address || <i>Address isn't set</i>}</div>
                    <div>
                        {record.city || <i>City isn't set</i>},{' '}
                        {record.state || <i>State isn't set</i>},{' '}
                        {record.zip || <i>ZIP isn't set</i>}
                    </div>
                </div>
            ),
        },
        {
            title: 'Telephone #2',
            dataIndex: 'telephone2',
            width: '200px',
            render: (_, record) => <div>{record.telephone2 || '-'}</div>,
            sorter: true,
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            width: '300px',
            render: (_, record) => <div>{record.notes || '-'}</div>,
            sorter: false,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '160px',
            render: (_, record) => (
                <Space size="small" className="pl-1">
                    <Button
                        className="p-0 mr-2"
                        type="link"
                        icon={<EditOutlined />}
                        title="Edit this contact"
                        onClick={() => _editSelectedContact(record)}
                    >
                        Edit
                    </Button>
                    <Popconfirm
                        placement="bottomRight"
                        title={`Delete ${record.fullname || 'this contact'}?`}
                        okText="Yes"
                        cancelText="Cancel"
                        onConfirm={() => _deleteSelectedContact(record)}
                    >
                        <Button
                            className="p-0"
                            type="link"
                            style={{ color: 'red' }}
                            icon={<DeleteOutlined />}
                        >
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
            fixed: 'right',
        },
    ];

    useEffect(() => {
        _reloadSubTabData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    useEffect(() => {
        if (!isModalEditContactVisible) {
            setSelectedContact(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalEditContactVisible]);

    return (
        <div className="ez_detail_contacts">
            <Row gutter={[0, 0]}>
                <Col>
                    <ModalEditAdditionalProspectContact
                        prospectID={prospectID}
                        contact={selectedContact}
                        contactRoles={contactRoles}
                        isModalVisible={isModalEditContactVisible}
                        setIsModalVisible={setIsModalEditContactVisible}
                        reloadSubTabData={_reloadSubTabData}
                    />
                </Col>
            </Row>
            <Row gutter={[0, 0]}>
                <Col span={24}>
                    <EZTable
                        pagination={{
                            pageSize: 20,
                        }}
                        queryParams={queryParams}
                        columns={_columns}
                        rowKey="contact_id"
                        fetchData={_fetchData}
                        scroll={{ x: 1600 }}
                        toolBarComponents={[
                            <Button
                                className="ez-action-btn ml-2"
                                onClick={() => _showAddContactModal()}
                            >
                                <PlusOutlined />
                                Add New Contact
                            </Button>,
                        ]}
                    />
                </Col>
            </Row>
        </div>
    );
};
