// Core React libs import
import React, { useState } from 'react';

// Core ANTD and 3rd party libs import
import { Button, Dropdown, Input, Menu, Space, UploadProps } from 'antd';
import { TransactionDocItemType } from 'types/transaction';
import { DOCUMENT_TYPE } from 'types/document';
import {
    AuditOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    DownloadOutlined,
    EditOutlined,
    EyeOutlined,
    FileSyncOutlined,
    PlusOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { previewDoc } from 'components/appPage/documents';
import { showMessage } from 'utils/notificationUtil';
import { EZUpload } from 'components/upload/upload';
import { UploadChangeParam } from 'antd/lib/upload';

type EZDocumentUploadProps = {
    record: TransactionDocItemType;
    index: number;
    contractID: number;
    isTransactionDeleted?: boolean;
    uploadProps: UploadProps;
    _handleFileUploadOnTemplate: ({ file, fileList }: UploadChangeParam, doc_id: number) => void;
    _downloadDocument: (doc: TransactionDocItemType) => void;
    _initiateDocuSign: (doc: TransactionDocItemType) => void;
    _deleteFile: (record: TransactionDocItemType) => void;
    _hanldeUploadWeblink: (
        fileLink: string,
        doc_id: number,
        callbackIsWeblink: React.Dispatch<React.SetStateAction<number | null>>,
        callbackTemp: React.Dispatch<React.SetStateAction<string>>
    ) => void;
};
export const DocFilenameColumn: React.FC<EZDocumentUploadProps> = ({
    record,
    index,
    contractID,
    uploadProps,
    isTransactionDeleted,
    _handleFileUploadOnTemplate,
    _downloadDocument,
    _initiateDocuSign,
    _deleteFile,
    _hanldeUploadWeblink,
}) => {
    const [activeWeblink, setActiveWeblink] = useState<number | null>(null);
    const [weblinkTemp, setWeblinkTemp] = useState<string>('');

    if (record.date_uploaded && !activeWeblink) {
        // show data file and weblink
        return (
            <Space align="center" size="small" className="pl-1">
                {record.template_type !== DOCUMENT_TYPE.WEBLINK ? (
                    <Button
                        className="p-0"
                        type="link"
                        style={{ fontSize: 12 }}
                        icon={<EyeOutlined />}
                        title={record.filename}
                        onClick={() => {
                            previewDoc('contract_document', [
                                {
                                    file_id: record.doc_id,
                                    contract_id: contractID,
                                },
                            ]);
                        }}
                    >
                        <span className="ellipsis-filename">{record.filename || '-'}</span>
                    </Button>
                ) : (
                    // <span className="ellipsis-filename">{record.filename || '-'}</span>
                    <Button
                        className="p-0"
                        type="link"
                        style={{ fontSize: 12 }}
                        icon={<CopyOutlined />}
                        title={record.filename}
                        onClick={() => {
                            window.navigator.clipboard.writeText(record.filename || '');
                            showMessage('success', 'A text has been copied to clipboard!');
                        }}
                    >
                        <span className="ellipsis-filename">{record.filename || '-'}</span>
                    </Button>
                )}
                {record.template_type !== DOCUMENT_TYPE.WEBLINK ? (
                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu>
                                {!isTransactionDeleted && (
                                    <EZUpload
                                        {...uploadProps}
                                        style={{
                                            width: '50%',
                                        }}
                                        onChange={info =>
                                            _handleFileUploadOnTemplate(info, record.doc_id)
                                        }
                                    >
                                        <Menu.Item key="0">
                                            <FileSyncOutlined className="mr-3" />
                                            <span className="">Replace File</span>
                                        </Menu.Item>
                                    </EZUpload>
                                )}

                                <Menu.Item key="1" onClick={() => _downloadDocument(record)}>
                                    <DownloadOutlined className="mr-3" />
                                    <span className="">Download</span>
                                </Menu.Item>
                                {!isTransactionDeleted && (
                                    <Menu.Item key="2" onClick={() => _initiateDocuSign(record)}>
                                        <AuditOutlined className="mr-3" />
                                        <span className="">Send to DocuSign</span>
                                    </Menu.Item>
                                )}
                                <Menu.Divider />
                                {!isTransactionDeleted && (
                                    <Menu.Item key="3" danger onClick={() => _deleteFile(record)}>
                                        <DeleteOutlined className="mr-3" />
                                        Delete File
                                    </Menu.Item>
                                )}
                            </Menu>
                        }
                        placement="bottomRight"
                    >
                        <span onClick={e => e.preventDefault()}>
                            <Button size="small" type="text" icon={<DownOutlined />} />
                        </span>
                    </Dropdown>
                ) : !isTransactionDeleted ? (
                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key="0"
                                    onClick={() => {
                                        setActiveWeblink(index);
                                    }}
                                >
                                    <EditOutlined className="mr-3" />
                                    <span className="">Edit Weblink</span>
                                </Menu.Item>

                                <Menu.Divider />
                                <Menu.Item key="3" danger onClick={() => _deleteFile(record)}>
                                    <DeleteOutlined className="mr-3" />
                                    Delete Weblink
                                </Menu.Item>
                            </Menu>
                        }
                        placement="bottomRight"
                    >
                        <span onClick={e => e.preventDefault()}>
                            <Button size="small" type="text" icon={<DownOutlined />} />
                        </span>
                    </Dropdown>
                ) : (
                    <></>
                )}
            </Space>
        );
    } else if (record.date_uploaded && activeWeblink === index) {
        //edit weblink
        return (
            <Space size={[4, 4]} style={{ width: '100%' }}>
                <Input
                    placeholder="Please enter weblink"
                    name="filename"
                    defaultValue={record.date_uploaded && record.filename}
                    onChange={e => setWeblinkTemp(e.target.value)}
                />

                <Button
                    type="primary"
                    onClick={() =>
                        _hanldeUploadWeblink(
                            weblinkTemp,
                            record.doc_id,
                            setActiveWeblink,
                            setWeblinkTemp
                        )
                    }
                >
                    Save
                </Button>
                <Button
                    type="text"
                    onClick={() => {
                        setActiveWeblink(null);
                        setWeblinkTemp('');
                    }}
                >
                    X
                </Button>
            </Space>
        );
    }
    //show button upload file or weblink (empty file state)
    switch (record.template_type) {
        case DOCUMENT_TYPE.WEBLINK:
            return activeWeblink === index ? (
                <Space size={[4, 4]} style={{ width: '100%' }}>
                    <Input
                        placeholder="Please enter weblink"
                        name="filename"
                        defaultValue={record.date_uploaded && record.filename}
                        onChange={e => setWeblinkTemp(e.target.value)}
                    />

                    <Button
                        type="primary"
                        onClick={() =>
                            _hanldeUploadWeblink(
                                weblinkTemp,
                                record.doc_id,
                                setActiveWeblink,
                                setWeblinkTemp
                            )
                        }
                    >
                        Save
                    </Button>
                    <Button
                        type="text"
                        onClick={() => {
                            setActiveWeblink(null);
                            setWeblinkTemp('');
                        }}
                    >
                        X
                    </Button>
                </Space>
            ) : isTransactionDeleted ? (
                <></>
            ) : (
                <Button
                    onClick={() => {
                        setActiveWeblink(index);
                    }}
                    icon={<PlusOutlined />}
                    className="button-file"
                >
                    Add Weblink
                </Button>
            );
        default:
            return isTransactionDeleted ? (
                <></>
            ) : (
                <EZUpload
                    {...uploadProps}
                    onChange={info => _handleFileUploadOnTemplate(info, record.doc_id)}
                >
                    <Button icon={<UploadOutlined />} className="button-file">
                        Upload File
                    </Button>
                </EZUpload>
            );
    }
};
