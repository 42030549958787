import React from 'react';

import { Spin, SpinProps } from 'antd';

type EZSectionLoadingProps = SpinProps;

export const EZOverlaySpinner: React.FC<EZSectionLoadingProps> = ({ className, ...props }) => {
    return (
        <div className="ez-overlay-spinner-wrapper">
            <Spin {...props} className={`overlay-spinner ${className || ''}`} />
        </div>
    );
};
