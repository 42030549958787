/**
 *
 * LoginSocialMicrosoft
 *
 */
import React, { memo } from 'react';
import { InterfacePort } from '../../utils/ez_api/loginUtil';
import { IResolveParams, objectType } from './';
// import { useMsal } from '@azure/msal-react';
import { handleMSLogin } from '../../utils/3rd_party_api/microsoftUtils';

interface Props {
    className?: string;
    children?: React.ReactNode;
    onReject: (reject: string | objectType) => void;
    onResolve: ({ provider, data }: IResolveParams) => void;
}

export const LoginSocialMicrosoft = memo((props: Props) => {
    const { className, children, onReject, onResolve } = props;

    const handleLogin = () => {
        handleMSLogin()
            .then((res: any) => {
                console.log('got ms profile', res);
                onResolve({ provider: InterfacePort.microsoft, data: { ...res } });
            })
            .catch((err: any) => {
                onReject(err);
            });
    };

    return (
        <div className={className} onClick={handleLogin}>
            {children}
        </div>
    );
});

export default LoginSocialMicrosoft;
