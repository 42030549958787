import React from 'react';

// import { InputNumber, InputNumberProps } from 'antd';

import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';

type EZNumberFormatProps = NumberFormatPropsBase<any> & {
    ifEmptyText?: string;
};

export const EZNumberFormat: React.FC<EZNumberFormatProps> = ({
    ifEmptyText = '-',
    value,
    ...props
}) => {
    if (value) {
        return <NumberFormat value={value} decimalScale={2} {...props} />;
    } else {
        return <span>{ifEmptyText}</span>;
    }
};
