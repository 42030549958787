/**
 *
 * LoginSocialGoogle
 *
 */
import React, { memo, useCallback, useEffect, useState } from 'react';
import { InterfacePort } from '../../utils/ez_api/loginUtil';
import { objectType, IResolveParams } from './';

interface Props {
    scope?: string;
    prompt?: string;
    ux_mode?: string;
    client_id: string;
    className?: string;
    login_hint?: string;
    access_type?: string;
    redirect_uri?: string;
    cookie_policy?: string;
    hosted_domain?: string;
    discoveryDocs?: string;
    children?: React.ReactNode;
    onReject: (reject: string | objectType) => void;
    fetch_basic_profile?: boolean;
    onResolve: ({ provider, data }: IResolveParams) => void;
}

const SCOPE =
    'https://www.googleapis.com/auth/gmail.readonly ' +
    'https://www.googleapis.com/auth/gmail.send';
const JS_SRC = 'https://apis.google.com/js/api.js';
const SCRIPT_ID = 'google-login';
const _window = window as any;

const LoginSocialGoogle = memo(
    ({
        client_id,
        scope = 'email profile',
        prompt = 'select_account',
        ux_mode,
        className = '',
        login_hint = '',
        access_type = 'online',
        onReject,
        onResolve,
        redirect_uri = '/',
        cookie_policy = 'single_host_origin',
        hosted_domain = '',
        discoveryDocs = '',
        children,
        fetch_basic_profile = true,
    }: Props) => {
        const [isSdkLoaded, setIsSdkLoaded] = useState(false);
        const [isProcessing, setIsProcessing] = useState(false);

        useEffect(() => {
            !isSdkLoaded && load();
            console.log(isProcessing);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isSdkLoaded]);

        const checkIsExistsSDKScript = useCallback(() => {
            return !!document.getElementById(SCRIPT_ID);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const searchForToken = (data: any): any => {
            let keys = Object.keys(data);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (key === 'access_token') return data[key];
                else if (typeof data[key] !== 'string') {
                    const token = searchForToken(data[key]);
                    if (token) return token;
                }
            }
            return null;
        };

        const insertScriptGoogle = useCallback(
            (d: HTMLDocument, s: string = 'script', id: string, jsSrc: string, cb: () => void) => {
                const ggScriptTag: any = d.createElement(s);
                ggScriptTag.id = id;
                ggScriptTag.src = jsSrc;
                ggScriptTag.async = true;
                ggScriptTag.defer = true;
                const scriptNode = document.getElementsByTagName('script')![0];
                scriptNode &&
                    scriptNode.parentNode &&
                    scriptNode.parentNode.insertBefore(ggScriptTag, scriptNode);
                ggScriptTag.onload = cb;
            },
            []
        );

        const handleResponse = useCallback(
            (res: objectType) => {
                console.log(res);
                setIsProcessing(false);
                // const auth2 = _window.gapi.auth2.getAuthInstance();
                // var user = auth2.currentUser.get();
                // var auth = user.getAuthResponse();
                onResolve({
                    provider: InterfacePort.google,
                    data: { access_token: searchForToken(res) },
                });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [onResolve]
        );

        const handleError = useCallback(
            (err: objectType | string) => {
                console.log(err);
                setIsProcessing(false);
                onReject(err);
            },
            [onReject]
        );

        const load = useCallback(() => {
            if (checkIsExistsSDKScript()) {
                setIsSdkLoaded(true);
            } else {
                insertScriptGoogle(document, 'script', SCRIPT_ID, JS_SRC, () => {
                    const params = {
                        client_id,
                        cookie_policy,
                        login_hint,
                        hosted_domain,
                        fetch_basic_profile,
                        discoveryDocs,
                        ux_mode,
                        redirect_uri,
                        access_type,
                        scope: SCOPE,
                        immediate: true,
                    };
                    _window.gapi.load('auth2', () => {
                        const gapiAuth = _window.gapi.auth2;
                        !gapiAuth.getAuthInstance()
                            ? gapiAuth.init(params).then(() => {
                                  setIsSdkLoaded(true);
                              })
                            : onReject('not exist an instance');
                    });
                });
            }
        }, [
            ux_mode,
            onReject,
            client_id,
            login_hint,
            access_type,
            redirect_uri,
            discoveryDocs,
            cookie_policy,
            hosted_domain,
            insertScriptGoogle,
            fetch_basic_profile,
            checkIsExistsSDKScript,
        ]);

        const loginGoogle = useCallback(() => {
            //    if (isProcessing || !isSdkLoaded) return
            setIsProcessing(true);
            if (!_window.gapi) {
                setIsProcessing(false);
                load();
                onReject("Google SDK isn't loaded!");
            } else {
                const auth2 = _window.gapi.auth2.getAuthInstance();
                const options = {
                    prompt,
                    scope,
                    ux_mode,
                };
                // auth2.grantOfflineAccess(options).then((res: any) => {
                //   console.log(res);
                // });
                auth2.signIn(options).then(handleResponse).catch(handleError);
            }
        }, [
            load,
            scope,
            prompt,
            ux_mode,
            onReject,
            handleError,
            //  isSdkLoaded,
            //  isProcessing,
            handleResponse,
        ]);

        //  const handlelogin = () => {
        //      console.log("handle login")
        //  }

        return (
            //      <div>
            //          <div id="g_id_onload"
            //      data-client_id={client_id}
            //      data-callback={handlelogin}>
            // </div>
            <div className={className} onClick={loginGoogle}>
                {children}
            </div>
            //  </div>
        );
    }
);

export default LoginSocialGoogle;
