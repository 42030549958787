import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getLoggedAccountData = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'me',
        useDefaultErrorNotif: true,
    });
};

export const getMyPermission = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'me/permission',
        useDefaultErrorNotif: true,
    });
};

export const getSuperAdminInfo = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'superadmin-info',
        useDefaultErrorNotif: true,
    });
};

export const updateMyAccountData = (payload: any) => {
    Mixpanel.track('app.update_myaccount_data', {});

    return APIRequest({
        method: 'put',
        url: apiUrl + 'me',
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const changePassword = (payload: any) => {
    Mixpanel.track('app.change_password', {});

    return APIRequest({
        method: 'put',
        url: apiUrl + 'change-password',
        data: payload,
        useDefaultErrorNotif: true,
    });
};
