import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { DocType } from 'components/appPage/documents';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getAccountDocs = (params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `doc-library/documents` + (params ? `?${params}` : ''),
    });
};

export const getAccountDocFolders = (params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `doc-library/folders` + (params ? `?${params}` : ''),
    });
};

export const addNewAccountDocFolder = (folder_name: string) => {
    Mixpanel.track('app.add_doc_library_folder', { folder_name });

    return APIRequest({
        method: 'post',
        url: apiUrl + `doc-library/folders`,
        data: { folder_name },
    });
};

export const addNewAccountDoc = (docPayload: {
    accountfolder_id?: number;
    path?: string;

    filename?: string;
    u_filename?: string;
    bucket?: string;
    key?: string;
    content_type?: string;
    size?: number;
}) => {
    Mixpanel.track('app.add_doc_library', { ...docPayload });

    return APIRequest({
        method: 'post',
        url: apiUrl + `doc-library/documents`,
        data: docPayload,
    });
};

export const deleteAccountDocument = (doc_id: number) => {
    Mixpanel.track('app.delete_doc_library', { doc_id });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `doc-library/document/${doc_id}`,
        useDefaultErrorNotif: true,
    });
};

export const updateAccountDocument = (
    id: number,
    payload: any,
    formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
) => {
    Mixpanel.track('app.edit_doc_library', {
        doc_id: id,
        action: 'edit',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `doc-library/document/${id}?formType=${formType}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

// bulk actions
export const moveSelectedAccountDocs = (doc_ids: number[], folder_id: number) => {
    Mixpanel.track('app.edit_doc_library', {
        doc_ids,
        action: 'move-docs',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `doc-library/documents/bulk-move-docs`,
        data: {
            document_ids: doc_ids,
            accountfolder_id: folder_id,
        },
        useDefaultErrorNotif: true,
    });
};

export const deleteAccountDocuments = (doc_ids: number[]) => {
    Mixpanel.track('app.delete_doc_library', {
        doc_ids,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `doc-library/documents`,
        data: {
            document_ids: doc_ids,
        },
        useDefaultErrorNotif: true,
    });
};

export const getPresignedURLAccountDocUpload = (filePayload: {
    original_name: string;
    mime_type: string;
    size: number;
}) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `doc-library/file-upload/presign-url`,
        data: {
            ...filePayload,
            doc_type: 'doc_library',
        },
    });
};
