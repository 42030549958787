import React from 'react';

import qs from 'qs';
import { AxiosResponse } from 'axios';

import { Typography } from 'antd';
import type { SelectProps } from 'antd/es/select';

import { EZSelectParty } from './selectParty';
import { getAllTransactions } from 'utils/ez_api/transactionAPIs';
import { ITransactionListDataType } from 'types/transaction';

const { Text } = Typography;

export interface EZSelectTransactionProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    debounceTimeout?: number;
    placeholderOnFocus?: JSX.Element;
    defaultPlaceholder?: JSX.Element;
}

async function _fetchTransaction(searchKeyword: string): Promise<any[]> {
    return getAllTransactions(
        'compact',
        qs.stringify({
            keyword: searchKeyword,
        })
    ).then((response: AxiosResponse<any>) => {
        return response.data?.map((record: ITransactionListDataType) => ({
            label: (
                <span>
                    {[record.address || '-', record.city, record.state]
                        .filter(item => item)
                        .join(', ')}
                </span>
            ),
            selectedLabel: `${record.address}`,
            value: record.contract_id,
        }));
    });
}

export function EZSelectTransaction<
    ValueType extends {
        key?: string;
        label: React.ReactNode;
        selectedLabel?: React.ReactNode;
        value: string | number;
    } = any
>({
    debounceTimeout = 800,
    placeholderOnFocus,
    defaultPlaceholder,
    ...props
}: EZSelectTransactionProps<ValueType>) {
    return (
        <EZSelectParty
            style={{ width: '100%' }}
            maxTagCount={'responsive'}
            mode="multiple"
            fetchOptions={_fetchTransaction}
            optionFilterProp="label"
            className="mr-3"
            showSearch
            showArrow
            allowClear
            placeholderOnFocus={
                <span>
                    <Text type="secondary">Enter keyword to search a transaction </Text>
                </span>
            }
            defaultPlaceholder={
                <span>
                    <Text>Show: </Text>
                    <Text strong>All Transactions</Text>
                </span>
            }
            optionLabelProp="selectedLabel"
            {...props}
        />
    );
}
