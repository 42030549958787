import { EMAIL_INTEGRATION_ERR_CODE, EmailCredentialType, SENDMAIL_ONBEHALF } from 'types/email';

export const checkEmailCredential = (emailCredential: EmailCredentialType) => {
    const now = new Date().getTime();
    let emailIntegrationErrCode: EMAIL_INTEGRATION_ERR_CODE | undefined;
    let emailIntegrationIsSet = false;

    if (emailCredential && emailCredential.relay_type_id) {
        switch (emailCredential.relay_type_id) {
            case SENDMAIL_ONBEHALF.GMAIL:
                if (
                    emailCredential.google_access_token &&
                    emailCredential.google_rt_expires_in &&
                    now < new Date(emailCredential.google_rt_expires_in).getTime()
                ) {
                    emailIntegrationIsSet = true;
                } else if (emailCredential.google_access_token) {
                    emailIntegrationErrCode = EMAIL_INTEGRATION_ERR_CODE.GOOGLE_REFRESTOKEN_EXPIRED;
                } else {
                    emailIntegrationErrCode = EMAIL_INTEGRATION_ERR_CODE.RELAY_TYPE_IS_UNSET;
                }
                break;
            case SENDMAIL_ONBEHALF.MS_365:
                if (
                    emailCredential.ms_access_token &&
                    emailCredential.ms_rt_expires_in &&
                    now < new Date(emailCredential.ms_rt_expires_in).getTime()
                ) {
                    emailIntegrationIsSet = true;
                } else if (emailCredential.ms_access_token) {
                    emailIntegrationErrCode = EMAIL_INTEGRATION_ERR_CODE.MS365_REFRESHTOKEN_EXPIRED;
                } else {
                    emailIntegrationErrCode = EMAIL_INTEGRATION_ERR_CODE.RELAY_TYPE_IS_UNSET;
                }
                break;
            case SENDMAIL_ONBEHALF.CUSTOM_SMTP:
                if (
                    emailCredential.smtp_outgoing_server &&
                    emailCredential.smtp_outgoing_username
                ) {
                    emailIntegrationIsSet = true;
                } else {
                    emailIntegrationErrCode = EMAIL_INTEGRATION_ERR_CODE.RELAY_TYPE_IS_UNSET;
                }
                break;

            default:
                break;
        }
    } else {
        emailIntegrationErrCode = EMAIL_INTEGRATION_ERR_CODE.RELAY_TYPE_IS_UNSET;
    }

    return {
        emailIntegrationErrCode: emailIntegrationErrCode,
        isEmailIntegrationSet: emailIntegrationIsSet,
    };
};
