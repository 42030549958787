import qs from 'qs';

import { DataTableQueryType } from 'types/base';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { EmailPayloadType } from 'types/notification';

export const getLogs = (params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `logs` + (params ? `?${params}` : ''),
    });
};

export const getNotificationInfo = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `notification-info`,
    });
};

export const sendEmail = (payload: EmailPayloadType) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `emails`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};
