import React from 'react';

import { AppPageTasks, TASK_EMBEDDED_TYPE } from 'components/appPage/tasks';
import { ITabContentProps } from 'types/webAppTab';

import './Task.scss';

const TaskListTab: React.FC<ITabContentProps> = () => {
    return (
        <>
            <div className="ez-tab-tasks">
                <AppPageTasks manualReloadCount={1} pageType={TASK_EMBEDDED_TYPE.MAIN_MENU} />
            </div>
        </>
    );
};

export default TaskListTab;
