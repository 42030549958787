import type { TablePaginationConfig } from 'antd/es/table';
import { PaginationProps } from 'antd';
import { RouteProps } from 'react-router-dom';

export interface ISelectOption {
    label: string;
    value: string;
}

export interface ISelectOptionGroup {
    label: string;
    options?: ISelectOption[];
}

export type ITablePaginationConfig = TablePaginationConfig & {
    totalFiltered?: number;
};

export type IPaginationConfig = PaginationProps & {
    totalFiltered?: number;
};

export const DEBOUNCE_DURATION = 1000; // 1000 ms

export type DataTableQueryType = {
    page?: number;
    pageSize?: number;
    keyword?: string;
    order?: any;
    where?: any;
    whereFilter?: any;
};

export type EZRoute = {
    isPublic: boolean;
} & RouteProps;
