import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { MSloginRequest as loginRequest } from '../envUtil';
import { getMessages, getUserDetails, sendMSGraphEmail } from '../ez_api/graphService';

let instance: IPublicClientApplication, accounts: AccountInfo[];

export const setMSAccountDetails = (
    instanceRef: IPublicClientApplication,
    accountsRef: AccountInfo[]
) => {
    instance = instanceRef;
    accounts = accountsRef;
};

const formatMessages = (messages: any) => {
    return messages.map((message: any) => {
        return {
            from_name: message?.from?.emailAddress?.name,
            from_email: message?.from?.emailAddress?.address,
            subject: message?.subject,
            createdAt: message?.createdDateTime,
            to_name: message?.toRecipients?.[0].name,
            to_email: message?.toRecipients?.[0].address,
            cc_name: message?.ccRecipients?.[0]?.name,
            emails_attachments: [],
            html: message?.body?.content,
        };
    });
};

export const RequestAccessToken = async () => {
    const accessToken = localStorage.getItem('loginToken');
    if (accessToken) {
        return { accessToken };
    }
    const request = {
        ...loginRequest,
        account: accounts?.[0],
    };

    let response;
    try {
        // Silently acquires an access token which is then attached to a request for Microsoft Graph data
        response = await instance.acquireTokenSilent(request);
    } catch (e) {
        response = await instance.acquireTokenPopup(request);
    }
    return response;
};

export const sendMSEmail = async (formData: any) => {
    const access = await RequestAccessToken();
    return sendMSGraphEmail(access, formData);
};

export const readMails = async (emailFilterState: string) => {
    const access = await RequestAccessToken();
    return getMessages(access, emailFilterState)
        .then(res => res.value)
        .then(data => formatMessages(data));
};

export const getMyProfile = async () => {
    const access = await RequestAccessToken();
    return getUserDetails(access);
};

export const handleMSLogin = async () => {
    return instance
        .loginPopup(loginRequest)
        .then(res => {
            return {
                access_token: res?.accessToken,
            };
        })
        .catch(e => {
            console.error(e);
        });
};

export const handleMSLogout = () => {
    return instance.logoutPopup().then(res => {
        console.log('logged out', res);
    });
};
