// Core React libs import
import React, { useMemo, useState } from 'react';

// Core ANTD and 3rd party libs import
import { Avatar, Space, Tooltip, Typography } from 'antd';
import { TransactionDocItemType } from 'types/transaction';
import { EZOverlaySpinner } from 'components/spinner/overlaySpinner';

type EZSignatureTrackerProps = {
    onSubmit: (
        newData: TransactionDocItemType,
        oldData: TransactionDocItemType,
        callback: React.Dispatch<React.SetStateAction<boolean>>
    ) => void;
    data: any;
    isTransactionDeleted?: boolean;
    isLoading: boolean;
    record: TransactionDocItemType;
};
type CellSignatureProps = {
    B: number;
    S: number;
    SA: number;
    BA: number;
};
export const EZSignatureTracker: React.FC<EZSignatureTrackerProps> = ({
    onSubmit,
    data,
    isTransactionDeleted,
    isLoading,
    record,
}) => {
    const [cell, setCell] = useState<CellSignatureProps>(data);
    const [isModified, setIsModified] = useState(false);

    let newData = useMemo(() => {
        return {
            ...record,
            buyer_sign: cell.B,
            seller_sign: cell.S,
            seller_agent_sign: cell.SA,
            buyer_agent_sign: cell.BA,
        };
    }, [cell, record]);

    return (
        <Space wrap size={8}>
            {Object.entries(cell).map(([key, val], idx) => {
                let popoverText = '';
                switch (key) {
                    case 'S':
                        popoverText = 'Seller';
                        break;
                    case 'SA':
                        popoverText = 'Seller Agent';
                        break;
                    case 'B':
                        popoverText = 'Buyer';
                        break;
                    case 'BA':
                        popoverText = 'Buyer Agent';
                        break;
                }
                let signatures = cell;
                let value = val === 1 ? true : false;
                let classNameCustom = value ? 'avatar-signature-tracking-done ' : ' ';
                return (
                    <Tooltip title={isTransactionDeleted ? '' : popoverText} color="#1890ff">
                        <span
                            onClick={() => {
                                if (isTransactionDeleted) return;
                                setIsModified(true);
                                signatures = {
                                    ...signatures,
                                    [key]: value === true ? 0 : 1,
                                };
                                setCell(signatures);
                            }}
                        >
                            <Avatar
                                style={{ cursor: isTransactionDeleted ? 'not-allowed' : '' }}
                                className={`avatar-signature-tracking ${classNameCustom}`}
                                size="small"
                            >
                                {key}
                            </Avatar>
                        </span>
                    </Tooltip>
                );
            })}
            {isModified &&
                (isLoading ? (
                    <EZOverlaySpinner />
                ) : (
                    <Typography.Text
                        onClick={() => onSubmit(newData, record, setIsModified)}
                        className="avatar-signature-executor"
                    >
                        Execute
                    </Typography.Text>
                ))}
        </Space>
    );
};
