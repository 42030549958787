import React, { useContext, useRef, useState } from 'react';

import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import {
    Button,
    Col,
    Divider,
    Dropdown,
    Form,
    Menu,
    Modal,
    Popconfirm,
    Row,
    Space,
    Typography,
} from 'antd';
import { EZDateFormat } from 'components';
import { EZTable, ITableQueryParams, forwardRefEZTableProps } from 'components/table/EZProTable';
import { HomeContext } from 'context/EZContext';
import { EmailSignatureType } from 'types/emailSignature';
import {
    addNewEmailSignature,
    deleteEmailSignature,
    editEmailSignature,
    getEmailSignatures,
} from 'utils/ez_api/emailSignatureAPIs';
import { showNotification } from 'utils/notificationUtil';
import { EditEmailSignatureModalContent } from '../modal/emailSignatureEditDetail';
const { Text } = Typography;

const defaultTableParam: ITableQueryParams = {
    searchKeyword: '',
    orderParams: [['email_signature_id', 'desc']],
    whereParams: {},
    whereFilterParams: {},
};

export const SubTab_EmailSignatures: React.FC<{}> = ({}) => {
    const HomeCtx = useContext(HomeContext);
    const EZTableRef = useRef<forwardRefEZTableProps>();
    const UserInfo = HomeCtx.userInfo;

    const [form] = Form.useForm();
    const [queryParams, setQueryParams] = useState<ITableQueryParams>(defaultTableParam);

    const _columns: ProColumns<EmailSignatureType>[] = [
        {
            title: 'Signature Name',
            dataIndex: 'signature_name',
            width: '85px',
            sorter: true,
            render: (_, record) => (
                <>
                    <Text title={record.signature_name}>{record.signature_name || '-'}</Text>
                </>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'signature_body',
            width: '200px',
            sorter: true,
            render: (_, record) => (
                <>
                    <Text title={record.signature_body}>
                        {sanitizeText(record.signature_body) || '-'}
                    </Text>
                </>
            ),
        },
        {
            title: 'Created Time',
            dataIndex: 'created_at',
            width: '85px',
            editable: false,
            sorter: true,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text>
                        <EZDateFormat value={record.created_at} format="MMM Do, YYYY hh:mma" />
                    </Text>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '65px',
            valueType: 'option',
            fixed: 'right',
            render: (_, record, index, action) => (
                <Space size="small" className="pl-1">
                    <Button
                        className="p-0"
                        type="link"
                        disabled={record.is_active}
                        onClick={() => {
                            _setActiveSignature(record);
                        }}
                        style={{ minWidth: '70px', textAlign: 'left' }}
                    >
                        {record.is_active ? 'Active' : 'Set Active'}
                    </Button>
                    <Divider type="vertical" />
                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key="0"
                                    onClick={() => _showEditSignatureDetailModal(record, 'EDIT')}
                                    style={{ color: '#1890ff' }}
                                >
                                    <EditOutlined className="mr-3" />
                                    Edit
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key="2" danger onClick={() => _deleteSignature(record)}>
                                    <DeleteOutlined className="mr-3" />
                                    Delete
                                </Menu.Item>
                            </Menu>
                        }
                        placement="bottomRight"
                    >
                        <span onClick={e => e.preventDefault()}>
                            <Button size="small" type="link" icon={<EllipsisOutlined />} />
                        </span>
                    </Dropdown>
                </Space>
            ),
        },
    ];

    async function _fetchData(EZTableQueryParams: string): Promise<any> {
        return getEmailSignatures(EZTableQueryParams).then(result => result);
    }

    const _showEditSignatureDetailModal = (
        record: Partial<EmailSignatureType>,
        mode: 'EDIT' | 'ADD' = 'EDIT'
    ) => {
        const editTemplateDetailModal = Modal.confirm({
            title: (
                <Typography.Title level={4}>
                    {mode === 'ADD' ? 'Add New Signature' : 'Edit Signature Detail'}
                </Typography.Title>
            ),
            content: (
                <EditEmailSignatureModalContent
                    emailSignature={record}
                    form={form}
                    mode="EDIT"
                    UserInfo={UserInfo}
                />
            ),
            okText: 'Save',
            cancelText: 'Save and Set as Active',
            icon: <></>,
            style: { top: '40px' },
            width: '80%',
            autoFocusButton: null,
            closable: true,
            maskClosable: true,
        });

        const handleFormSubmit = async (
            setIsSaving: (isSaving: boolean) => void,
            isSetActive: boolean
        ) => {
            updateButtonProps(isSetActive, true, 'Loading...');

            try {
                setIsSaving(true);
                await form.validateFields();

                const formValues = form.getFieldsValue(true);
                const formParams = formValues as Partial<EmailSignatureType>;

                if (mode === 'ADD') {
                    formParams.is_active = isSetActive;

                    const newEmailSignatureRecord = await addNewEmailSignature(formParams);

                    EZTableRef.current?.reload();
                    showNotification('success', 'New signature created successfully.');
                    return newEmailSignatureRecord;
                } else {
                    formParams.email_signature_id = record.email_signature_id;
                    formParams.is_active = isSetActive ? true : record.is_active;

                    const updatedEmailSignatureRecord = await editEmailSignature(formParams);

                    EZTableRef.current?.reload();
                    showNotification('success', 'Signature details updated successfully.');
                    return updatedEmailSignatureRecord;
                }
            } catch (err) {
                console.error('Error saving email signature:', err);
                throw err;
            } finally {
                setIsSaving(false);
                updateButtonProps(isSetActive, false, 'Save');
            }
        };

        editTemplateDetailModal.update({
            onOk: () =>
                handleFormSubmit(
                    () => editTemplateDetailModal.update({ okButtonProps: { disabled: true } }),
                    false // save only
                ),
            onCancel: () =>
                handleFormSubmit(
                    () => editTemplateDetailModal.update({ cancelButtonProps: { disabled: true } }),
                    true // save with set active
                ),
        });

        const updateButtonProps = (isSetActive: boolean, isDisabled: boolean, text: string) => {
            const btnText = isDisabled ? text : isSetActive ? 'Save and Set as Active' : 'Save';

            if (isSetActive) {
                editTemplateDetailModal.update({
                    cancelButtonProps: {
                        disabled: isDisabled,
                    },
                    cancelText: btnText,
                });
            } else {
                editTemplateDetailModal.update({
                    okButtonProps: {
                        disabled: isDisabled,
                    },
                    okText: btnText,
                });
            }
        };
    };

    const _showAddNewSignatureModal = () => {
        _showEditSignatureDetailModal({}, 'ADD');
    };

    const _setActiveSignature = async (record: EmailSignatureType) => {
        const payload: EmailSignatureType = {
            ...record,
            is_active: true,
        };

        try {
            const resp = await editEmailSignature(payload);
            const emailSignatureRecord = resp.data as EmailSignatureType;

            EZTableRef.current?.reload();
            showNotification('success', 'New changes have been applied successfully.');
            return emailSignatureRecord;
        } catch (err) {
            throw err;
        }
    };

    const _deleteSignature = async (record: EmailSignatureType) => {
        const deleteConfirmationModal = Modal.confirm({
            title: `Delete Confirmation`,
            content: (
                <>
                    Are you sure to delete signature: <b>{record.signature_name}</b>?
                </>
            ),
            onOk: () => {
                deleteConfirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Deleting...',
                });
                deleteEmailSignature(record.email_signature_id).then(() => {
                    deleteConfirmationModal.destroy();

                    showNotification(
                        'success',
                        `Signature: "${record.signature_name}" has been successfully deleted.`
                    );
                    EZTableRef.current?.reload();
                });
                return true;
            },
            closable: true,
            maskClosable: true,
        });
    };

    const sanitizeText = (body: string) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = body;

        let imgElements = tempDiv.getElementsByTagName('img');
        for (let i = imgElements.length - 1; i >= 0; i--) {
            imgElements[i].outerHTML = '[Image]';
        }

        const sanitizedText = tempDiv.textContent || tempDiv.innerText || '';
        tempDiv.remove();

        return sanitizedText;
    };

    return (
        <div className="ez_email_logs">
            <Row gutter={24} className="mt-2 mb-3">
                <Col span={24}>
                    <EZTable
                        ref={EZTableRef}
                        className="eztable-hide-delete-editable"
                        pagination={{
                            pageSize: 20,
                            pageSizeOptions: [20, 50, 100],
                            position: ['bottomRight', 'topLeft'],
                        }}
                        queryParams={queryParams}
                        columns={_columns}
                        size="small"
                        rowKey="email_signature_id"
                        fetchData={_fetchData}
                        scroll={{ x: 900 }}
                        tableAlertRender={false}
                        toolBarComponents={[
                            <Button
                                key={`toolBarComponents-2-task-list`}
                                className="ml-2 mr-2 ez-action-btn"
                                onClick={() => _showAddNewSignatureModal()}
                            >
                                <PlusOutlined />
                                Add New Signature
                            </Button>,
                        ]}
                    />
                </Col>
            </Row>
        </div>
    );
};
