// Core React libraries import
import React, { useContext, useEffect, useMemo, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Button, Divider, Space, Form, Input, Alert, Select } from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import moment from 'moment';

// EZ web-app utils import
// EZ web-app APIs import
import { updateCustomFieldsByCategory } from 'utils/ez_api/transactionAPIs';
// EZ web-app types import
import {
    AdditionalInfoFieldType,
    contractAdditionalInfoCategoryType,
    TransactionDetailType,
} from 'types/transaction';
// EZ web-app components import
import { EZCurrencyFormat, EZDateFormat, EZDatePicker, EZInputNumber } from 'components';
import { showNotification } from 'utils/notificationUtil';
import { FormInstance } from 'antd/es/form/Form';
import { HomeContext } from 'context/EZContext';

// EZ web-app styles and assets import

const colLabelSpan = 14;
const colValueSpan = 10;
const rowFormViewGutter: [Gutter, Gutter] = [12, 6];
const section2RowGutter: [Gutter, Gutter] = [48, 48];

const CustomFieldComponent: React.FC<{
    isEditMode: boolean;
    form: FormInstance<any>;
    transactionData: TransactionDetailType | undefined;
    categoryObject: contractAdditionalInfoCategoryType;
    fieldObject: AdditionalInfoFieldType;
}> = ({ isEditMode, form, categoryObject, fieldObject }) => {
    const fieldName = [
        categoryObject.additional_info_fields_category_id,
        fieldObject.additional_info_field_id.toString(),
    ];

    useEffect(() => {
        if (isEditMode) {
            if (fieldObject.contract_additional_info_field.class === 'dropdown') {
                form.setFieldsValue({
                    [categoryObject.additional_info_fields_category_id]: {
                        [fieldObject.additional_info_field_id.toString()]:
                            fieldObject.field_option_id,
                    },
                });
            } else if (fieldObject.contract_additional_info_field.class === 'date') {
                const momentObj = moment(fieldObject.field_value);
                form.setFieldsValue({
                    [categoryObject.additional_info_fields_category_id]: {
                        [fieldObject.additional_info_field_id.toString()]:
                            fieldObject.field_value && momentObj.isValid()
                                ? momentObj.hours(10)
                                : '',
                    },
                });
            } else {
                form.setFieldsValue({
                    [categoryObject.additional_info_fields_category_id]: {
                        [fieldObject.additional_info_field_id.toString()]: fieldObject.field_value,
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // check the field class: date, dropdown, numeric, text. Default will use the text field
    if (fieldObject.contract_additional_info_field.class === 'date') {
        return (
            <Form.Item name={fieldName} noStyle>
                <EZDatePicker
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                    placement="topLeft"
                    placeholder={`Choose the date for ${fieldObject.newname}`}
                />
            </Form.Item>
        );
    } else if (fieldObject.contract_additional_info_field.class === 'dropdown') {
        return (
            <Form.Item name={fieldName} noStyle>
                <Select
                    style={{ width: '100%' }}
                    options={fieldObject.contract_additional_info_options.map(optObj => ({
                        value: optObj.additional_info_fields_option_id,
                        label: optObj.option_value,
                    }))}
                    placeholder={`Choose ${fieldObject.newname}`}
                    allowClear
                ></Select>
            </Form.Item>
        );
    } else if (fieldObject.contract_additional_info_field.class === 'numeric') {
        return (
            <Form.Item name={fieldName} noStyle>
                <EZInputNumber
                    style={{ width: '100%' }}
                    placeholder={`Enter the ${fieldObject.newname}`}
                    step={1}
                    min={undefined}
                />
            </Form.Item>
        );
    } else if (fieldObject.contract_additional_info_field.class === 'money') {
        return (
            <Form.Item name={fieldName} noStyle>
                <EZInputNumber
                    numberType="currency"
                    style={{ width: '100%' }}
                    placeholder={`Enter the ${fieldObject.newname}`}
                    step={10}
                    min={undefined}
                />
            </Form.Item>
        );
    }
    return (
        <Form.Item name={fieldName} noStyle>
            <Input style={{ width: '100%' }} placeholder={`Enter the ${fieldObject.newname}`} />
        </Form.Item>
    );
};

const CustomFieldCategory: React.FC<{
    form: FormInstance<any>;
    reloadSubTabData: () => void;
    transactionData: TransactionDetailType | undefined;
    categoryObject: contractAdditionalInfoCategoryType;
}> = ({ form, reloadSubTabData, categoryObject, transactionData }) => {
    const HomeCtx = useContext(HomeContext);

    const UserPermission = HomeCtx.userPermission;

    const [isEditMode, setIsEditMode] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const _saveFieldsChange = () => {
        const contractID = transactionData?.contract_id || 0;
        const categoryFieldValuesObj = form.getFieldValue(
            categoryObject.additional_info_fields_category_id
        );

        const payload: any = {};
        payload[categoryObject.additional_info_fields_category_id.toString()] = {};

        if (isSubmitting) return;
        setIsSubmitting(true);

        categoryObject.contract_additional_info_preferences.forEach(field => {
            if (field.newname) {
                if (
                    field.contract_additional_info_field.class === 'date' &&
                    categoryFieldValuesObj[field.additional_info_field_id] &&
                    categoryFieldValuesObj[field.additional_info_field_id]?.format
                ) {
                    payload[categoryObject.additional_info_fields_category_id.toString()][
                        field.additional_info_field_id
                    ] = categoryFieldValuesObj[field.additional_info_field_id].format('MM/DD/YYYY');
                } else {
                    payload[categoryObject.additional_info_fields_category_id.toString()][
                        field.additional_info_field_id
                    ] = categoryFieldValuesObj[field.additional_info_field_id];
                }
            }
        });

        updateCustomFieldsByCategory(
            contractID,
            categoryObject.additional_info_fields_category_id,
            payload
        )
            .then(async resp => {
                if (reloadSubTabData) {
                    reloadSubTabData();
                }

                showNotification('success', 'New data changes have been saved successfully');
                setIsEditMode(false);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };
    const isDeleted = useMemo(() => {
        return transactionData?.active === false || transactionData?.active === 0;
    }, [transactionData]);
    const isArchived = useMemo(() => {
        return transactionData?.is_archived === true;
    }, [transactionData]);

    return (
        <Row gutter={rowFormViewGutter}>
            <Col span={24} className="">
                <Row className="">
                    <Col span={12} className="text-title-secondary">
                        {categoryObject.category?.trim() || '-'}
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        {UserPermission && UserPermission?.toggle === 2 && !isEditMode && (
                            <Button
                                className="ez-action-btn"
                                size="small"
                                icon={<EditOutlined />}
                                disabled={isArchived || isDeleted}
                                onClick={() => setIsEditMode(true)}
                            >
                                Edit
                            </Button>
                        )}
                        {isEditMode && (
                            <Space>
                                <Button
                                    className="mr-2"
                                    type="default"
                                    size="small"
                                    onClick={() => setIsEditMode(false)}
                                    disabled={isSubmitting}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className=""
                                    size="small"
                                    icon={<SaveOutlined />}
                                    type="primary"
                                    loading={isSubmitting}
                                    onClick={() => _saveFieldsChange()}
                                >
                                    {isSubmitting ? 'Saving' : 'Save'}
                                </Button>
                            </Space>
                        )}
                    </Col>
                </Row>
            </Col>
            <Divider />
            <Col span={24}>
                <Row gutter={rowFormViewGutter} className="row-wrapper">
                    {categoryObject.contract_additional_info_preferences?.map(
                        (fieldObject, idx) => {
                            if (!fieldObject.newname) {
                                return null;
                            }

                            return (
                                <React.Fragment key={`contract_additional_info_preferences-${idx}`}>
                                    <Col span={colLabelSpan} className="ez-form-label">
                                        {fieldObject.newname || '-'}
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {!isEditMode ? (
                                            <span>
                                                {' '}
                                                {fieldObject.field_value ? (
                                                    fieldObject.contract_additional_info_field
                                                        .class === 'date' ? (
                                                        <EZDateFormat
                                                            noTimezone
                                                            value={fieldObject.field_value}
                                                        />
                                                    ) : fieldObject.contract_additional_info_field
                                                          .class === 'money' ? (
                                                        <EZCurrencyFormat
                                                            value={fieldObject.field_value}
                                                            thousandSeparator={true}
                                                            prefix={'$ '}
                                                            displayType={'text'}
                                                        />
                                                    ) : (
                                                        fieldObject.field_value
                                                    )
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        ) : (
                                            <CustomFieldComponent
                                                isEditMode={isEditMode}
                                                form={form}
                                                categoryObject={categoryObject}
                                                fieldObject={fieldObject}
                                                transactionData={transactionData}
                                            />
                                        )}
                                    </Col>
                                </React.Fragment>
                            );
                        }
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export const CustomFieldsSection: React.FC<{
    contractID: number;
    reloadSubTabData: () => void;
    transactionData: TransactionDetailType | undefined;
    isRentalProperty: boolean;
}> = ({ contractID, reloadSubTabData, transactionData, isRentalProperty }) => {
    const [form] = Form.useForm();

    const _onFinish = (val: any) => {};

    return (
        <Form form={form} onFinish={_onFinish}>
            <Row gutter={section2RowGutter}>
                {transactionData?.contract_additional_info_categories?.map(
                    (categoryObject, idx) => (
                        <Col span={16} key={`additional_info_fields-${idx}`}>
                            <CustomFieldCategory
                                form={form}
                                reloadSubTabData={reloadSubTabData}
                                categoryObject={categoryObject}
                                transactionData={transactionData}
                            />
                        </Col>
                    )
                )}

                {!transactionData?.contract_additional_info_categories ||
                transactionData?.contract_additional_info_categories.length === 0 ? (
                    <Col span={24}>
                        <Row gutter={rowFormViewGutter}>
                            <Col span={24} className="">
                                <Alert
                                    message={
                                        <div className="pl-2">
                                            Your custom fields will be rendered here.
                                            <br />
                                            Navigate to the Admin Area to start customizing your
                                            transaction to suit your needs.
                                        </div>
                                    }
                                    type="info"
                                    showIcon
                                />
                            </Col>
                        </Row>
                    </Col>
                ) : (
                    ''
                )}
            </Row>
        </Form>
    );
};
