// Core React libraries import
import React from 'react';

// Core ANTD and 3rdparty libraries import

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
import { TransactionDetailTabProps } from 'types/transaction';
// EZ web-app components import
import { AppPageTasks, TASK_EMBEDDED_TYPE } from 'components/appPage/tasks';

// EZ web-app styles and assets import

export const SubTab_Tasks: React.FC<TransactionDetailTabProps> = ({
    contractID,
    contractAddress,
    manualReloadCount,
    isTransactionDeleted,
    reloadTransactionStat,
}) => {
    return (
        <div className="ez_detail_tasks">
            <AppPageTasks
                pageType={TASK_EMBEDDED_TYPE.TRANSACTION_DETAIL}
                contractID={contractID}
                isTransactionDeleted={isTransactionDeleted}
                contractAddress={contractAddress}
                manualReloadCount={manualReloadCount}
                reloadTransactionStat={reloadTransactionStat}
            />
        </div>
    );
};
