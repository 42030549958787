import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';

import './App.scss';
import './styles/components.scss';
import { Login } from './pages/Login';
import { setMSAccountDetails } from './utils/3rd_party_api/microsoftUtils';
import { Alert } from 'antd';

export const App = () => {
    const { instance, accounts } = useMsal();

    useEffect(() => {
        setMSAccountDetails(instance, accounts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="App">
            <Login />
        </div>
    );
};

export default App;
