// Core React libs import
import React, { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rd party libs import
import { Select } from 'antd';
import { cloneDeep } from 'lodash';

// import type { SelectProps, BaseOptionType, DefaultOptionType } from 'antd/es/select';
import type { SelectProps } from 'antd/es/select';

// EZ level libs import
import { SALE_TYPES } from 'types/transaction';
import { SaleTypePreference } from 'types/admin';
import { getSaleTypeSetting } from 'utils/ez_api/AdminAPIs';
import { HomeContext } from 'context/EZContext';

export interface EZSelectSaleTypesProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {}

let defaultSaleTypes = cloneDeep(SALE_TYPES);

let hasSettingAPIBeenCalled = false;

export const TransactionSalesType: React.FC<{
    contract_id: number;
    sale_types_id: number | null;
}> = ({ contract_id, sale_types_id }) => {
    let saletype_name: string = '';
    if (defaultSaleTypes.length) {
        saletype_name =
            defaultSaleTypes.find(iSales => iSales.sale_type_id === sale_types_id)?.saletype_name ||
            '';
    } else {
        saletype_name =
            SALE_TYPES.find(iSales => iSales.sale_type_id === sale_types_id)?.saletype_name || '';
    }

    return <span>{saletype_name || '-'}</span>;
};

export function EZSelectSaleType({ ...props }) {
    const [options, setOptions] = useState<typeof SALE_TYPES>(defaultSaleTypes);

    const { triggerRefetchDataList, setTriggerRefetchDataList } = useContext(HomeContext);
    const hasTriggerRefetchData = triggerRefetchDataList.includes('sales_type');

    const _fetchSetting = () => {
        if (hasSettingAPIBeenCalled && !hasTriggerRefetchData) return;

        getSaleTypeSetting().then(({ data }) => {
            hasSettingAPIBeenCalled = true;
            if (data) {
                const saleTypePreference = data as SaleTypePreference[];
                saleTypePreference.forEach(preferenceObj => {
                    const saleType = defaultSaleTypes.find(
                        s => s.sale_type_id === preferenceObj.sale_type_id
                    );
                    if (saleType && preferenceObj.sale_type_name) {
                        saleType.saletype_name = preferenceObj.sale_type_name;
                    }
                });
                // defaultSaleTypes = [...options];
                setOptions([...defaultSaleTypes]);
            }
            if (hasTriggerRefetchData) {
                const newTriggerRefetchDataList = triggerRefetchDataList.filter(
                    key => key !== 'sales_type'
                );
                setTriggerRefetchDataList(newTriggerRefetchDataList);
            }
        });
    };

    useEffect(() => {
        _fetchSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            style={{ width: '100%' }}
            showSearch
            allowClear
            placeholder="Select Sale Type"
            fieldNames={{
                label: 'saletype_name',
                value: 'sale_type_id',
            }}
            {...props}
            options={options}
        ></Select>
    );
}
