import React from 'react';

import { message, notification } from 'antd';
import { ArgsProps } from 'antd/lib/message';

type NotificationType = 'success' | 'info' | 'warning' | 'error';
type MessageType = 'success' | 'info' | 'warning' | 'error';

/** Show a more obvious, bold drawer message */
export const showNotification = (
    notificationType: NotificationType,
    description: string,
    title?: string
) => {
    notification[notificationType]({
        message: title || 'Notification',
        description,
    });
};

/** Show a simple, one-line quick message */
export const showMessage = (
    messageType: MessageType,
    content: string | React.ReactNode,
    config: Omit<ArgsProps, 'content'> & {
        content?: any;
    } = { content: '' }
) => {
    config.content = content;
    // config.style = {
    //     marginTop: '40px',
    // };

    switch (messageType) {
        case 'success':
            message.success(config);
            break;
        case 'warning':
            message.warning(config);
            break;
        case 'error':
            message.error(config);
            break;

        default:
            message.info(config);
            break;
    }
};
