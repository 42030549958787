// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Input, Form, FormInstance, DatePicker, Col, Select, Badge } from 'antd';
import moment from 'moment';

// EZ web-app utils import
// EZ web-app APIs import
import { getTaskDetail } from 'utils/ez_api/taskAPIs';
// EZ web-app types import
import { ProspectTaskListType, ProspectTaskTodoListType } from 'types/prospectTask';
// EZ web-app components import

// EZ web-app styles and assets import

export const EditProspectTaskModalContent: React.FC<{
    prospectID: number;
    task: Partial<ProspectTaskTodoListType>;
    form: FormInstance<any>;
    taskListMenu: ProspectTaskListType[];
    mode: 'EDIT' | 'ADD';
}> = ({ task, form, prospectID, taskListMenu, mode }) => {
    const _getDetail = (taskID: number) => {
        getTaskDetail(prospectID, taskID).then(resp => {
            const data = resp.data as ProspectTaskTodoListType;
        });
    };

    const _setFieldValues = () => {
        const dueDateMoment = task.duedate ? moment(task.duedate) : '';

        form.resetFields();

        form.setFieldsValue({
            ...task,
            duedate: dueDateMoment && dueDateMoment.isValid() ? dueDateMoment : null,
            prio: task.prio,
        });
    };

    useEffect(() => {
        _setFieldValues();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form layout="vertical" form={form} requiredMark={false}>
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label="List"
                        name="list_id"
                        required
                        rules={[{ required: true, message: "List can't be empty" }]}
                    >
                        <Select
                            placeholder="Select the tasklist"
                            options={taskListMenu}
                            defaultActiveFirstOption
                            fieldNames={{
                                label: 'name',
                                value: 'id',
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Task Title"
                        name="title"
                        rules={[{ required: true, message: "Title can't be empty" }]}
                    >
                        <Input placeholder="Enter the title" />
                    </Form.Item>
                </Col>
                <Col span={10}>
                    <Form.Item label="Priority" name="prio" initialValue={0}>
                        <Select
                            placeholder="Select the priority"
                            options={[
                                {
                                    label: (
                                        <Badge
                                            count={'-1'}
                                            style={{ backgroundColor: '#2db7f5' }}
                                        />
                                    ),
                                    value: -1,
                                },
                                {
                                    label: (
                                        <Badge count={'±0'} style={{ backgroundColor: 'grey' }} />
                                    ),
                                    value: 0,
                                },
                                {
                                    label: (
                                        <Badge count={'+1'} style={{ backgroundColor: 'gold' }} />
                                    ),
                                    value: 1,
                                },
                                {
                                    label: (
                                        <Badge count={'+2'} style={{ backgroundColor: '#f50' }} />
                                    ),
                                    value: 2,
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col span={14}>
                    {/* TODO: To fix the momentJS timezone issue - Use the date format of YYYY-MM-DD */}
                    <Form.Item label="Due Date" name="duedate">
                        <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item label="Notes" name="note">
                        <Input.TextArea placeholder="Enter the notes" rows={4} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
