// Core React libraries import
import React, { useEffect, useState, useRef } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Space,
    Menu,
    Button,
    Tabs,
    Input,
    Form,
    Typography,
    Dropdown,
    Badge,
    Tag,
    Tooltip,
    Select,
    Divider,
    Modal,
} from 'antd';
import { RenderExpandIcon } from 'rc-table/lib/interface';
import {
    DeleteOutlined,
    PlusOutlined,
    SaveOutlined,
    DownOutlined,
    MenuUnfoldOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    RightOutlined,
    FieldTimeOutlined,
    EllipsisOutlined,
    MessageOutlined,
    CloseOutlined,
    SaveFilled,
    EditOutlined,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import moment from 'moment';

// EZ web-app utils import
import { showNotification } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    addNewProspectTask,
    deleteProspectTask,
    getProspectTaskLists,
    getProspectTasks,
    updateProspectTask,
} from 'utils/ez_api/prospect/prospectTaskAPIs';
// EZ web-app types import
import { ProspectDetailTabProps } from 'types/prospect';
import { ProspectTaskListType, ProspectTaskTodoListType } from 'types/prospectTask';
// EZ web-app components import
import { EZTable, EZDateFormat } from 'components';
import {
    forwardRefEZTableProps,
    IOnChangeCallbackProps,
    ITableQueryParams,
} from 'components/table/EZProTable';
import { EditProspectTaskModalContent } from '../modal/prospectTaskEditDetail';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;
const { Option } = Select;

const defaultTableParam: ITableQueryParams = {
    searchKeyword: '',
    orderParams: [
        ['ow', 'asc'],
        ['prio', 'desc'],
    ],
    whereParams: {},
    whereFilterParams: {},
    silentLoading: false,
};

export const ProspectSubTab_Tasks: React.FC<ProspectDetailTabProps> = ({
    prospectID,
    manualReloadCount,
    reloadProspectStat,
}) => {
    const EZTableRef = useRef<forwardRefEZTableProps>();

    const [form] = Form.useForm();

    const [taskListMenu, setTaskListMenu] = useState<ProspectTaskListType[]>([]);

    const [queryParams, setQueryParams] = useState<ITableQueryParams>(defaultTableParam);

    const [taskTypeFilter, setTaskTypeFilter] = useState<string>('all_tasks');
    const [taskMenuFilter, setTaskMenuFilter] = useState<number>();

    const [toggleAllRows, setToggleAllRows] = useState<boolean>(false);
    const [tableRecordIDs, setTableRecordIDs] = useState<React.Key[]>([]);
    const [expandedRecordIDs, setExpandedRecordIDs] = useState<React.Key[]>([]);

    const [isUpdatingTask, setIsUpdatingTask] = useState<boolean>(false);

    async function _fetchData(EZTableQueryParams: string): Promise<any> {
        return getProspectTasks(prospectID, EZTableQueryParams).then(result => result);
    }

    // expand or collapse the rows
    const _toggleAllRows = async (tAllRows: boolean) => {
        setToggleAllRows(tAllRows);

        if (!tAllRows) {
            setExpandedRecordIDs([]);
        } else {
            setExpandedRecordIDs([...tableRecordIDs]);
        }
    };

    const _expandSelectedRows = (rowKeys: number[]) => {
        const newRowIDs: number[] = [];
        for (let index = 0; index < rowKeys.length; index++) {
            const rowID = rowKeys[index];

            if (!expandedRecordIDs.find(elm => elm === rowID)) newRowIDs.push(rowID);
        }

        if (newRowIDs.length) setExpandedRecordIDs([...expandedRecordIDs, ...newRowIDs]);
    };

    const _onChangeCallback: IOnChangeCallbackProps = (data: ProspectTaskTodoListType[]) => {
        if (data?.length) {
            if (toggleAllRows) {
                setExpandedRecordIDs(
                    data
                        .filter(
                            record => record.compl !== 1 && record.note && record.note.trim() !== ''
                        )
                        .map(record => record.id)
                );
            }
            setTableRecordIDs(data.map(record => record.id));
        } else {
            setTableRecordIDs([]);
            setExpandedRecordIDs([]);
        }
    };

    const _expandIcon: RenderExpandIcon<ProspectTaskTodoListType> = ({
        expanded,
        onExpand,
        record,
    }) => {
        return expanded ? (
            <DownOutlined
                id={`ez-tr-icon-expandable-c_${prospectID}-task_${record.id}`}
                onClick={(e: any) => onExpand(record, e)}
            />
        ) : (
            <RightOutlined
                id={`ez-tr-icon-expandable-c_${prospectID}-task_${record.id}`}
                onClick={(e: any) => onExpand(record, e)}
            />
        );
    };

    const _reloadSubTabData = (resetPagination?: boolean, silentLoading?: boolean) => {
        setQueryParams({
            ...queryParams,
            resetPagination,
            silentLoading: silentLoading || undefined,
        });
    };

    const _fetchtaskListMenu = () => {
        getProspectTaskLists(prospectID).then(resp => {
            if (resp.data) {
                setTaskListMenu(resp.data);
            }
        });
    };

    const _saveTaskData = async (
        newData: ProspectTaskTodoListType,
        oldData: ProspectTaskTodoListType,
        formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
    ) => {
        setIsUpdatingTask(true);

        if (newData.id) EZTableRef.current?.modifyData(newData, newData.id);

        return updateProspectTask(
            prospectID,
            newData.id,
            {
                ...newData,
            },
            formType
        )
            .then(() => {
                if (prospectID) {
                    reloadProspectStat?.(prospectID);
                }

                if (newData.compl === 1 && oldData.compl === 0) {
                    showNotification(
                        'success',
                        `Task: "${newData.title}" has been marked as complete.`
                    );
                } else if (newData.compl === 0 && oldData.compl === 1) {
                    showNotification(
                        'success',
                        `Task: "${newData.title}" has been marked as incomplete.`
                    );
                } else {
                    showNotification(
                        'success',
                        `Task: "${newData.title}" has been successfully updated.`
                    );
                }

                if (oldData.list_id !== newData.list_id) {
                    _reloadSubTabData(false, true);
                }

                if (newData.id && newData.compl !== 1 && newData.note?.length) {
                    setExpandedRecordIDs([...expandedRecordIDs, newData.id]);
                }

                setIsUpdatingTask(false);

                return newData;
            })
            .catch(err => {
                setIsUpdatingTask(false);
                throw err;
            });
    };

    const _saveNewTaskData = async (
        payload: ProspectTaskTodoListType,
        formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
    ) => {
        setIsUpdatingTask(true);

        return addNewProspectTask(prospectID, payload, formType)
            .then(() => {
                if (prospectID) {
                    reloadProspectStat?.(prospectID);
                }

                showNotification(
                    'success',
                    `New task: "${payload.title}" has been successfully added.`
                );

                _reloadSubTabData(false, true);

                setIsUpdatingTask(false);

                return payload;
            })
            .catch(err => {
                setIsUpdatingTask(false);
                throw err;
            });
    };

    const _renderPriorityBadge = (record: ProspectTaskTodoListType) => {
        switch (record.prio) {
            case -1:
                return <Badge count={'-1'} style={{ backgroundColor: '#2db7f5' }} />;
            case 1:
                return <Badge count={'+1'} style={{ backgroundColor: 'gold' }} />;
            case 2:
                return <Badge count={'+2'} style={{ backgroundColor: '#f50' }} />;

            default:
                return <Text type="secondary">±0</Text>;
        }
    };

    const _applyFilter = (filterType: string, taskTypeFilter: string, taskMenuListID?: number) => {
        const currentWhereFilterParams: any = {};
        // const currentWhereFilterParams = { ...queryParams.whereFilterParams };
        const nowMoment = moment();

        switch (taskTypeFilter) {
            case 'completed_tasks':
                currentWhereFilterParams['compl'] = 1;
                break;
            case 'all_tasks':
                currentWhereFilterParams['compl'] = undefined;
                break;
            case 'incomplete_tasks':
                currentWhereFilterParams['compl'] = 0;
                break;

            default:
                break;
        }

        if (taskMenuListID === -1) {
            if (currentWhereFilterParams['$prospects_task_list.id$']) {
                delete currentWhereFilterParams['$prospects_task_list.id$'];
            }
        } else if (taskMenuListID) {
            currentWhereFilterParams['$prospects_task_list.id$'] = taskMenuListID;
        }

        setQueryParams({
            ...queryParams,
            whereFilterParams: { ...currentWhereFilterParams },
            resetPagination: true,
        });
    };

    const _deleteTask = async (record: ProspectTaskTodoListType) => {
        return deleteProspectTask(prospectID, record.id)
            .then(() => {
                if (reloadProspectStat && prospectID) {
                    reloadProspectStat(prospectID);
                }

                showNotification(
                    'success',
                    `Task: "${record.title}" has been successfully deleted.`
                );
                _reloadSubTabData(false, true);
                setIsUpdatingTask(false);

                return record;
            })
            .catch(err => {
                setIsUpdatingTask(false);
                throw err;
            });
    };

    const _showAddNewTaskModal = () => {
        _showEditTaskDetailModal(
            {
                id: 0,
                uuid: 'uuid-dummy',
                list_id: taskListMenu?.[0]?.id,
                d_created: 0,
                d_completed: 0,
                d_edited: 0,
                compl: 0,
                title: '',
                note: '',
                prio: 0,
                ow: 0,
                tags: '',
                tags_ids: '',
                alertnumdays: 1,
                prospects_defaulttask_id: 1,
                prospects_task_list: {
                    name: taskListMenu?.[0]?.name,

                    id: 0,
                    prospect_id: prospectID,
                    account_id: 0,
                },
            },
            'ADD'
        );
    };

    const _showEditTaskDetailModal = (
        record: ProspectTaskTodoListType,
        mode: 'EDIT' | 'ADD' = 'EDIT'
    ) => {
        const editTaskDetailModal = Modal.confirm({
            title: (
                <Typography.Title level={4}>
                    {mode === 'ADD' ? 'Add New Task' : 'Edit Task Detail'}
                </Typography.Title>
            ),
            content: (
                <EditProspectTaskModalContent
                    prospectID={prospectID}
                    task={record}
                    form={form}
                    taskListMenu={taskListMenu}
                    mode={mode}
                />
                // <></>
            ),
            okText: 'Save',
            icon: <></>,
            style: { top: '40px' },
            width: '750px',
            onOk: async () => {
                editTaskDetailModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Saving...',
                });
                return form
                    .validateFields()
                    .then(async values => {
                        if (mode === 'ADD')
                            return _saveNewTaskData(values)
                                .then(() => {
                                    editTaskDetailModal.destroy();
                                    return record;
                                })
                                .catch(err => {
                                    throw err;
                                });
                        return _saveTaskData({ ...record, ...values }, record, 'FULL')
                            .then(() => {
                                editTaskDetailModal.destroy();
                                return record;
                            })
                            .catch(err => {
                                throw err;
                            });
                    })
                    .catch(err => {
                        console.log('🚀 ~ file: tasks.tsx ~ line 487 ~ onOk: ~ err', err);
                        editTaskDetailModal.update({
                            okButtonProps: {
                                disabled: false,
                            },
                            okText: 'Save',
                        });

                        throw err;
                    });
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _doActionTaskMenu = (menuKey: string, record: ProspectTaskTodoListType) => {
        switch (menuKey) {
            case 'edit_task_detail':
                form.resetFields();

                _showEditTaskDetailModal(record);

                break;

            case 'delete_task':
                const deleteConfirmationModal = Modal.confirm({
                    title: `Delete confirmation`,
                    content: (
                        <>
                            Are you sure to delete task: <b>{record.title}</b>?
                        </>
                    ),
                    onOk: () => {
                        deleteConfirmationModal.update({
                            okButtonProps: {
                                disabled: true,
                            },
                            okText: 'Deleting...',
                        });
                        _deleteTask(record).finally(() => {
                            deleteConfirmationModal.destroy();
                        });
                        return true;
                    },
                    closable: true,
                    maskClosable: true,
                });
                break;
            default:
                break;
        }
    };

    const ExpandedRowComponent: React.FC<{ record: ProspectTaskTodoListType }> = ({ record }) => {
        const [note, setNote] = useState<string>(record.note?.trim() || '');

        const [editMode, setEditMode] = useState<boolean>(false);
        const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);

        const _saveNote = () => {
            setIsSubmittingForm(true);
            _saveTaskData({ ...record, note: note }, record).finally(() => {
                setIsSubmittingForm(false);
            });
        };

        return (
            <div className="doc-note-wrapper p-2">
                <div style={{ paddingLeft: '65px' }}>
                    <Row>
                        <Col span={12} className="pr-5">
                            <Row>
                                <Col span={24} className="">
                                    <Row className="">
                                        <Col span={12} className="">
                                            <Text strong>Notes</Text>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {!editMode && (
                                                <Button
                                                    className=""
                                                    size="small"
                                                    type="link"
                                                    icon={<EditOutlined />}
                                                    onClick={() => setEditMode(true)}
                                                >
                                                    Edit
                                                </Button>
                                            )}

                                            {editMode && (
                                                <Space>
                                                    <Button
                                                        className="mr-2"
                                                        type="default"
                                                        size="small"
                                                        onClick={() => setEditMode(false)}
                                                        disabled={isSubmittingForm}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        className=""
                                                        size="small"
                                                        icon={<SaveOutlined />}
                                                        type="primary"
                                                        loading={isSubmittingForm}
                                                        onClick={() => _saveNote()}
                                                    >
                                                        {isSubmittingForm ? 'Saving' : 'Save'}
                                                    </Button>
                                                </Space>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider className="mt-2 mb-3" />
                                <Col span={24}>
                                    {!editMode && (
                                        <Paragraph
                                            style={{ whiteSpace: 'pre-wrap' }}
                                            ellipsis={{
                                                rows: 5,
                                                expandable: true,
                                            }}
                                        >
                                            {note ? (
                                                note
                                            ) : (
                                                <Text italic type="secondary">
                                                    No note added
                                                </Text>
                                            )}
                                        </Paragraph>
                                    )}
                                    {editMode && (
                                        <div>
                                            <Input.TextArea
                                                style={{ width: '100%' }}
                                                rows={4}
                                                placeholder="Write down the note here..."
                                                className="mb-2"
                                                value={note}
                                                onChange={e => setNote(e.currentTarget.value)}
                                            />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    };

    const additionalColumns: ProColumns<ProspectTaskTodoListType>[] = [];

    additionalColumns.push({
        title: 'Completed On',
        dataIndex: 'd_completed',
        width: '70px',
        sorter: true,
        editable: false,
        render: (_, record) => (
            <>
                <Text type={record.compl === 1 ? 'secondary' : undefined}>
                    <EZDateFormat
                        value={record.compl === 1 ? record.d_completed : null}
                        format={'MMM DD, YYYY hh:mma'}
                    />
                </Text>
            </>
        ),
    });

    const _columns: ProColumns<ProspectTaskTodoListType>[] = [
        {
            title: '',
            dataIndex: 'compl',
            width: '20px',
            align: 'center',
            sorter: true,
            editable: false,
            render: (dom, record) => (
                <Text
                    type={record.compl === 1 ? 'secondary' : undefined}
                    className={`cursor-pointer task-completion-toggle ${
                        record.compl === 1 ? 'task-completed' : 'task-not-completed'
                    }`}
                    onClick={e => {
                        const currentTarget = e?.currentTarget;

                        if (currentTarget) {
                            currentTarget.classList.add('loading');
                        }
                        _saveTaskData({ ...record, compl: record.compl === 1 ? 0 : 1 }, record);
                    }}
                    title={`${
                        record.compl === 1 ? 'Mark task as incomplete' : 'Mark task as complete'
                    }`}
                >
                    {record.compl === 1 ? <CheckCircleFilled /> : <CheckCircleOutlined />}
                </Text>
            ),
        },
        {
            title: 'Task Title',
            dataIndex: 'title',
            width: '230px',
            sorter: true,
            // ellipsis: true,
            render: (_, record) => (
                <>
                    <Text type={record.compl === 1 ? 'secondary' : undefined} title={record.title}>
                        {record.title || '-'}
                    </Text>
                    {!taskMenuFilter || taskMenuFilter === -1 ? (
                        <Tag
                            className={`ml-2 mr-1  ${record.compl === 1 ? 'color-secondary' : ''}`}
                        >
                            {record.prospects_task_list?.name}
                        </Tag>
                    ) : (
                        ''
                    )}
                </>
            ),
        },
        {
            title: 'Quick Info',
            dataIndex: 'status',
            width: '45px',
            sorter: false,
            editable: false,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.note?.length ? 1 : 0} note`} placement="left">
                        <Tag
                            className={`tag-rounded ${
                                record.note?.length ? '' : 'color-secondary'
                            }`}
                            onClick={() => _expandSelectedRows([record.id])}
                        >
                            <MessageOutlined /> {record.note?.length ? 1 : 0}
                        </Tag>
                    </Tooltip>
                </>
            ),
        },
        {
            title: 'Priority',
            dataIndex: 'prio',
            width: '45px',
            valueType: 'select',
            sorter: true,
            fieldProps: {
                options: [
                    {
                        label: <Badge count={'-1'} style={{ backgroundColor: '#2db7f5' }} />,
                        value: -1,
                    },
                    {
                        label: <Badge count={'±0'} style={{ backgroundColor: 'grey' }} />,
                        value: 0,
                    },
                    {
                        label: <Badge count={'+1'} style={{ backgroundColor: 'gold' }} />,
                        value: 1,
                    },
                    {
                        label: <Badge count={'+2'} style={{ backgroundColor: '#f50' }} />,
                        value: 2,
                    },
                ],
            },
            render: (_, record, idx, action) => <div>{_renderPriorityBadge(record)}</div>,
        },
        {
            title: 'Due Date',
            dataIndex: 'duedate',
            valueType: 'date',
            width: '75px',
            render: (_, record, idx, action) => {
                const currentYear = new Date().getFullYear();
                const nowMoment = moment();

                const dueDateMoment = record.duedate ? moment(record.duedate) : null;
                const isDue =
                    record.compl !== 1 &&
                    dueDateMoment &&
                    dueDateMoment.isBefore(nowMoment.startOf('day'));
                const isWithin2Weeks = dueDateMoment?.isValid()
                    ? dueDateMoment.isBetween(moment().subtract(7, 'days'), moment().add(7, 'days'))
                    : false;

                const textType = isDue
                    ? 'danger'
                    : dueDateMoment && dueDateMoment.diff(nowMoment, 'day') === 0
                    ? 'success'
                    : dueDateMoment && dueDateMoment.diff(nowMoment, 'day') === 1
                    ? undefined
                    : record.compl === 1
                    ? 'secondary'
                    : undefined;

                const dueDateFormat =
                    dueDateMoment && dueDateMoment.year() === currentYear
                        ? 'MMM Do'
                        : 'MMM Do, YYYY';

                const customText =
                    isWithin2Weeks && dueDateMoment
                        ? dueDateMoment.diff(nowMoment, 'day') === 0
                            ? 'Today'
                            : dueDateMoment.diff(nowMoment, 'day') === 1
                            ? 'Tomorrow'
                            : dueDateMoment.diff(nowMoment, 'day') === -1
                            ? 'Yesterday'
                            : ''
                        : '';

                return (
                    <Text
                        type={textType}
                        strong={
                            ['Yesterday', 'Today', 'Tomorrow'].includes(customText) ? true : false
                        }
                    >
                        {isWithin2Weeks ? (
                            <Tooltip title={`${dueDateMoment?.format('MMM Do, YYYY')}`}>
                                <span>
                                    {customText ? (
                                        customText
                                    ) : (
                                        <EZDateFormat value={record.duedate} humanize />
                                    )}
                                </span>
                            </Tooltip>
                        ) : (
                            <EZDateFormat value={record.duedate} format={dueDateFormat} />
                        )}
                    </Text>
                );
            },
            fieldProps: {
                format: 'MMM Do, YYYY',
            },
            sorter: true,
        },
        ...additionalColumns,
        {
            title: 'Last Edited On',
            dataIndex: 'd_edited',
            width: '70px',
            sorter: true,
            editable: false,
            render: (_, record) => (
                <>
                    <Text type={record.compl === 1 ? 'secondary' : undefined}>
                        <EZDateFormat value={record.d_edited} format={'MMM DD, YYYY hh:mma'} />
                    </Text>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '85px',
            valueType: 'option',
            render: (_, record, index, action) => (
                <>
                    <Button
                        className="p-0 mr-1"
                        type="link"
                        icon={<FieldTimeOutlined />}
                        title="Edit this task"
                        onClick={() => {
                            action?.startEditable(record.id);
                        }}
                    >
                        Quick Edit
                    </Button>

                    <Divider type="vertical" />

                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu
                                items={[
                                    {
                                        icon: <MenuUnfoldOutlined className="mr-3" />,
                                        label: 'Edit Task Detail',
                                        key: 'edit_task_detail',
                                    },
                                    { type: 'divider' },
                                    {
                                        icon: <DeleteOutlined className="mr-3" />,
                                        label: 'Delete Task',
                                        key: 'delete_task',
                                        danger: true,
                                    },
                                ]}
                                onClick={({ key }) => _doActionTaskMenu(key, record)}
                            />
                        }
                        placement="bottomRight"
                    >
                        <span onClick={e => e.preventDefault()}>
                            <Button
                                className="p-0"
                                type="link"
                                icon={<EllipsisOutlined />}
                                title="More action"
                            ></Button>
                        </span>
                    </Dropdown>
                </>
            ),
            fixed: 'right',
        },
    ];

    useEffect(() => {
        _applyFilter('', taskTypeFilter, taskMenuFilter);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskTypeFilter, taskMenuFilter]);

    useEffect(() => {
        _reloadSubTabData();
        _fetchtaskListMenu();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez-component-tasks">
            <Row gutter={[0, 0]} className="row-wrapper">
                <Col span={24}>
                    <Tabs
                        type="card"
                        defaultActiveKey="-1"
                        // addIcon={
                        //     <Tooltip title="Edit the todo lists on the Admin area">
                        //         <SettingOutlined />
                        //         {/* <span className="mr-2">Add New Tasklist</span> */}
                        //     </Tooltip>
                        // }
                        onChange={val => {
                            const taskListID = parseInt(val.replace('tab-tasklist-', ''));
                            setTaskMenuFilter(taskListID);
                        }}
                        tabBarExtraContent={
                            <div>
                                <Select
                                    style={{ textAlign: 'left', width: 300 }}
                                    value={taskTypeFilter}
                                    onChange={val => setTaskTypeFilter(val)}
                                    className="mb-2"
                                >
                                    <Option value="incomplete_tasks">
                                        <span>
                                            <Text>Show: </Text>
                                            <Text strong>Incomplete Tasks</Text>
                                        </span>
                                    </Option>
                                    <Option value="completed_tasks">
                                        <span>
                                            <Text>Show: </Text>
                                            <Text strong>Completed Tasks</Text>
                                        </span>
                                    </Option>
                                    <Option value="all_tasks">
                                        <span>
                                            <Text>Show: </Text>
                                            <Text strong>All Tasks</Text>
                                        </span>
                                    </Option>
                                </Select>
                            </div>
                        }
                    >
                        <Tabs.TabPane tab="All tasks" key="-1" closable={false}></Tabs.TabPane>
                        {taskListMenu.map(taskListItem => (
                            <Tabs.TabPane
                                tab={`${taskListItem.name}`}
                                key={`tab-tasklist-${taskListItem.id}`}
                                closable={false}
                            ></Tabs.TabPane>
                        ))}
                    </Tabs>
                </Col>
                <Col span={24}>
                    <EZTable
                        className="eztable-hide-delete-editable"
                        ref={EZTableRef}
                        pagination={{
                            pageSize: 100,
                            pageSizeOptions: [20, 50, 100],
                            position: ['bottomLeft', 'topLeft'],
                        }}
                        queryParams={queryParams}
                        expandable={{
                            columnWidth: '16px',
                            expandedRowRender: (record: ProspectTaskTodoListType) => {
                                return <ExpandedRowComponent record={record} />;
                            },
                            expandIcon: _expandIcon,
                            showExpandColumn: true,
                            expandedRowKeys: expandedRecordIDs,
                            onExpand: (expanded, record: ProspectTaskTodoListType) => {
                                if (expanded) {
                                    setExpandedRecordIDs([...expandedRecordIDs, record.id]);
                                } else {
                                    setExpandedRecordIDs([
                                        ...expandedRecordIDs.filter(id => id !== record.id),
                                    ]);
                                }
                            },
                        }}
                        columns={_columns}
                        size="small"
                        rowKey="id"
                        fetchData={_fetchData}
                        scroll={{ x: 1650 }}
                        toolBarComponents={[
                            <Button
                                key={`toolBarComponents-1-task-list`}
                                className="ml-2 mr-2 ez-action-btn"
                                icon={toggleAllRows ? <RightOutlined /> : <DownOutlined />}
                                onClick={() => _toggleAllRows(!toggleAllRows)}
                            >
                                {toggleAllRows ? 'Collapse' : 'Expand'} all rows
                            </Button>,
                            <Button
                                key={`toolBarComponents-2-task-list`}
                                className="ez-action-btn"
                                onClick={() => _showAddNewTaskModal()}
                            >
                                <PlusOutlined />
                                Add New Task
                            </Button>,
                        ]}
                        editable={{
                            actionRender: (row, config, defaultDom) => [
                                defaultDom.save,
                                defaultDom.cancel,
                                // defaultDom.delete,
                            ],
                            type: 'multiple',
                            onSave: async (_, data: ProspectTaskTodoListType, oldData) => {
                                let newDate = null;

                                if (data.duedate) {
                                    let newDateMoment = moment(data.duedate, 'MMM Do, YYYY', true);

                                    if (newDateMoment.isValid()) {
                                        newDate = newDateMoment.format('YYYY-MM-DD');
                                    } else {
                                        newDateMoment = moment(data.duedate, 'YYYY-MM-DD', true);
                                        if (newDateMoment.isValid()) {
                                            newDate = newDateMoment.format('YYYY-MM-DD');
                                        }
                                    }
                                }

                                return await _saveTaskData(
                                    {
                                        ...data,
                                        duedate: newDate || undefined,
                                    },
                                    oldData
                                );
                            },
                            saveText: (
                                <>
                                    <Button
                                        className="p-0 pl-1"
                                        type="link"
                                        icon={<SaveFilled />}
                                        title="Edit this task"
                                    >
                                        Save
                                    </Button>
                                </>
                            ),
                            deletePopconfirmMessage: 'Are you sure to delete this task?',
                            deleteText: (
                                <>
                                    <Divider type="vertical" />
                                    <Button
                                        className="p-0"
                                        type="text"
                                        title="Delete"
                                        danger
                                        icon={<DeleteOutlined />}
                                    ></Button>
                                </>
                            ),
                            onDelete: (key, record) => _deleteTask(record),
                            cancelText: (
                                <>
                                    <Divider type="vertical" />
                                    <Button
                                        className="p-0 text-grey mr-2"
                                        type="text"
                                        title="Cancel and back"
                                        icon={<CloseOutlined />}
                                    ></Button>
                                </>
                            ),
                        }}
                        onChangeCallback={_onChangeCallback}
                    />
                </Col>
            </Row>
        </div>
    );
};
