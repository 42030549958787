import { Image } from 'antd';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface MDXProps {
    content: string;
}
interface CustomImageProps {
    alt: string;
    src: string;
}

const CustomImage: React.FC<CustomImageProps> = ({ alt, src }) => {
    return <Image alt={alt} src={src} />; // Use Ant Design's Image component
};
export const MarkdownRenderer: React.FC<MDXProps> = ({ content }) => {
    return (
        <ReactMarkdown components={{ img: CustomImage as any }} rehypePlugins={[rehypeRaw as any]}>
            {content}
        </ReactMarkdown>
    );
};
