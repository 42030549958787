// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Button, Col, Form, Input, Popconfirm, Row, Tag, Typography } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';

// EZ web-app utils import
import { showMessage } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    addNewDropdownOption,
    deleteDropdownOption,
    updateDropdownOption,
    getDefaultQuestions,
} from 'utils/ez_api/prospect/prospectAdminAPIs';
import { generateDefaultQuestions } from 'utils/ez_api/prospect/prospectAPIs';
// EZ web-app types import
import { ProspectQuestionOptionType, ProspectQuestionType } from 'types/prospect';
import {
    AdditionalInfoFieldType,
    contractAdditionalInfoCategoryType,
    ContractAdditionalInfoOption,
} from 'types/transaction';
// EZ web-app components import

// EZ web-app styles and assets import

type GroupedQuestionsType = {
    key: number;
    section_name: string;
    questions: ProspectQuestionType[];
};

export const ProspectQuestionSetting: React.FC<{}> = ({}) => {
    const [isFetchingSections, setIsFetchingSections] = useState(false);
    const [groupedQuestions, setGroupedQuestions] = useState<GroupedQuestionsType[]>([]);

    const [expandedRecordIDs, setExpandedRecordIDs] = useState<React.Key[]>([]);

    const _fetchDefaultQuestions = () => {
        setIsFetchingSections(true);
        generateDefaultQuestions()
            .then(() => {
                return getDefaultQuestions().then(({ data }) => {
                    const questions = data as ProspectQuestionType[];
                    if (questions?.length) {
                        setGroupedQuestions([
                            {
                                key: 1,
                                section_name: 'Looking For',
                                questions: questions.filter(q => q.accordion_id === 1),
                            },
                            {
                                key: 2,
                                section_name: 'Household Profile',
                                questions: questions.filter(q => q.accordion_id === 2),
                            },
                        ]);
                    }
                    return data;
                });
            })
            .catch(err => {
                console.log('🚀 ~ file: questionSetting.tsx:69 ~ err', err);
            })
            .finally(() => {
                setIsFetchingSections(false);
            });
    };

    const columns: ProColumns<GroupedQuestionsType>[] = [
        {
            title: 'Section Name',
            dataIndex: 'section_name',
            key: 'section_name',
            width: '320px',
            render: (_, record) => (
                <Typography.Text
                    onClick={() => setExpandedRecordIDs([record.key])}
                    strong
                    underline
                >
                    {record.section_name}
                </Typography.Text>
            ),
        },
        {
            title: 'Section Information',
            dataIndex: '',
            key: 'x',
            editable: false,
            render: (_, record) => (
                <Tag
                    onClick={() => setExpandedRecordIDs([record.key])}
                    className="tag-rounded cursor-pointer"
                >
                    {record.questions?.length} Questions
                </Tag>
            ),
        },
    ];

    const _dropdownValueHandler = debounce(
        (question_id: number, option_id: number, option_value: string) => {
            return updateDropdownOption(question_id, option_id, option_value).then(resp => {
                showMessage('success', 'Option has been updated.');
                return resp;
            });
        },
        3000
    );

    const DropdownFieldComponent: React.FC<{
        question: ProspectQuestionType;
        options: ProspectQuestionOptionType[];
    }> = ({ question, options }) => {
        const [form] = Form.useForm();

        const _redrawSections = () => {
            const options = form.getFieldValue('dropdown_options') as ProspectQuestionOptionType[];

            if (options) {
                question.prospects_questions_options = options;
            } else {
                question.prospects_questions_options = [];
            }
            setGroupedQuestions([...groupedQuestions]);
        };

        useEffect(() => {
            form.setFieldsValue({
                dropdown_options: options,
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <Form form={form}>
                <Row>
                    <Form.List name="dropdown_options">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }, index) => (
                                    <Col span={24} className="mb-2" style={{ textAlign: 'left' }}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            name={[name, 'option_id']}
                                            noStyle
                                            hidden
                                        >
                                            <Input hidden />
                                        </Form.Item>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                            name={[name, 'option_value']}
                                            noStyle
                                        >
                                            <Input
                                                placeholder="Enter the option"
                                                style={{ width: '280px' }}
                                                allowClear
                                                onChange={evt => {
                                                    const val = evt.target.value?.trim();
                                                    if (val) {
                                                        _dropdownValueHandler(
                                                            question.question_id,
                                                            form.getFieldValue([
                                                                'dropdown_options',
                                                                name,
                                                                'option_id',
                                                            ]),
                                                            val
                                                        )?.then(() => {
                                                            _redrawSections();
                                                        });
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                        <Popconfirm
                                            title="Delete this option?"
                                            onConfirm={() => {
                                                deleteDropdownOption(
                                                    question.question_id,
                                                    form.getFieldValue([
                                                        'dropdown_options',
                                                        name,
                                                        'option_id',
                                                    ])
                                                ).then(() => {
                                                    remove(name);
                                                    showMessage(
                                                        'success',
                                                        'Option has been deleted.'
                                                    );
                                                    _redrawSections();
                                                });
                                            }}
                                        >
                                            <Button
                                                className="p-0 ml-1"
                                                type="link"
                                                icon={<DeleteOutlined />}
                                                title="Delete this option?"
                                                size="small"
                                                danger
                                            ></Button>
                                        </Popconfirm>
                                    </Col>
                                ))}
                                <Col span={24} style={{ textAlign: 'left' }}>
                                    <Form.Item noStyle>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                addNewDropdownOption(
                                                    question.question_id,
                                                    'Untitled'
                                                ).then(({ data }) => {
                                                    add(data);
                                                    showMessage(
                                                        'success',
                                                        'New option has been added.'
                                                    );
                                                    _redrawSections();
                                                });
                                            }}
                                            block
                                            icon={<PlusOutlined />}
                                            style={{ width: '280px' }}
                                        >
                                            Add option
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Form.List>
                </Row>
            </Form>
        );
    };

    const ExpandedRowComponent: React.FC<{ section: GroupedQuestionsType }> = ({ section }) => {
        const expandedTableColumns: ProColumns<ProspectQuestionType>[] = [
            {
                title: '#',
                dataIndex: 'name',
                key: 'name',
                width: '5px',
                renderText(text, record, index) {
                    return index + 1;
                },
            },
            {
                title: 'Question',
                dataIndex: 'name',
                key: 'name',
                width: '180px',
                renderText(text) {
                    return text + ':';
                },
            },
            {
                title: 'Options',
                dataIndex: 'class',
                key: 'class',
                editable: false,
                width: '270px',
                render: (_, record) => {
                    return (
                        <DropdownFieldComponent
                            question={record}
                            options={record.prospects_questions_options || []}
                        />
                    );
                },
            },
        ];

        return (
            <div className="expanded-row-with-table">
                <ProTable
                    size="small"
                    rowKey="option_id"
                    pagination={false}
                    showSorterTooltip={false}
                    search={false}
                    columns={expandedTableColumns}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                    dataSource={section.questions || []}
                />
            </div>
        );
    };

    useEffect(() => {
        _fetchDefaultQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={24} className="mt-3">
                <ProTable
                    rowKey="key"
                    showSorterTooltip={false}
                    search={false}
                    loading={isFetchingSections}
                    columns={columns}
                    pagination={false}
                    expandable={{
                        expandedRowRender: (record: GroupedQuestionsType) => {
                            return <ExpandedRowComponent section={record} />;
                        },
                        expandedRowKeys: expandedRecordIDs,
                        onExpand: (expanded, record: GroupedQuestionsType) => {
                            if (expanded) {
                                setExpandedRecordIDs([record.key]);
                            } else {
                                setExpandedRecordIDs([]);
                            }
                        },
                    }}
                    dataSource={groupedQuestions}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                />
            </Col>
        </Row>
    );
};
