// Core React libs import
import React, { useContext, useEffect, useMemo, useState } from 'react';

// Core ANTD and 3rd party libs import
import { Button, Popover, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { EZOnboardingListContent } from './OnboardingListContent';
import { OnboardingStepType } from 'types/onboarding';
import { EZOnboardingDetailModal } from './OnboardingDetailModal';
import { getObjectFromLocalStorage } from 'utils/cacheUtil';
import isEmpty from 'lodash/isEmpty';
import { HomeContext } from 'context/EZContext';
import { differenceInWeeks } from 'date-fns';
import { Mixpanel } from 'utils/mixPanelUtil';

// EZ level libs import

const defaultOnboardingDetail: OnboardingStepType = {
    id: 0,
    user_type_id: 0,
    code: '',
    title: '',
    type: '',
    description: '',
    content: '',
    onboarding_step_completed: false,
    is_admin_area: false,
    is_team_only: false,
};

export const EZOnboardingButton: React.FC<{}> = () => {
    const [isPopoverOpen, setPopoverOpen] = useState(false);
    const [selectedOnboarding, setSelectedOnboarding] =
        useState<OnboardingStepType>(defaultOnboardingDetail);

    const accountCreatedDate = localStorage.getItem('date_posted') || '';
    const accountCreatedDurationByWeeks = differenceInWeeks(
        new Date(),
        new Date(accountCreatedDate)
    );
    // popover always open for New users, when Date posted ≤ 2 weeks
    const isAlwaysOpenPopover = accountCreatedDurationByWeeks <= 2;

    const HomeCtx = useContext(HomeContext);
    const { refetchOnboardingList, isOnboadingLoading } = HomeCtx;

    const handlePopoverToogle = () => {
        setPopoverOpen(!isPopoverOpen);
    };

    const handleSelectedOnboading = (item: OnboardingStepType) => {
        setSelectedOnboarding(item);

        Mixpanel.track('app.onboarding', {
            action: item.code,
        });
    };

    const isModalOpen = Boolean(selectedOnboarding.code);
    const _onboardingList = getObjectFromLocalStorage('onboarding_list');

    const onboardingList = useMemo(() => {
        if (isEmpty(_onboardingList) && !isOnboadingLoading) {
            refetchOnboardingList();
            return [];
        }
        return _onboardingList;
    }, [refetchOnboardingList, _onboardingList, isOnboadingLoading]);

    useEffect(() => {
        setPopoverOpen(isAlwaysOpenPopover);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                position: 'fixed',
                bottom: '1.85em',
                right: '8em',
                zIndex: 1000,
            }}
        >
            <EZOnboardingDetailModal
                isOpen={isModalOpen}
                onClose={() => setSelectedOnboarding(defaultOnboardingDetail)}
                title={selectedOnboarding.title}
                content={selectedOnboarding.content ? selectedOnboarding.content : ''}
            />
            <Popover
                content={
                    <EZOnboardingListContent
                        onboardingList={onboardingList}
                        onSelectOnboading={handleSelectedOnboading}
                        onClose={handlePopoverToogle}
                    />
                }
                title={
                    <Row justify="space-between" align="middle">
                        Getting Started <DownOutlined onClick={handlePopoverToogle} />
                    </Row>
                }
                overlayStyle={{
                    position: 'fixed',
                    zIndex: 1000,
                }}
                trigger="click"
                showArrow={false}
                placement="topRight"
                visible={isPopoverOpen}
            >
                <Button
                    type="primary"
                    style={{
                        backgroundColor: '#25517d',
                        borderColor: '#25517d',
                        borderRadius: '2em',
                    }}
                    onClick={handlePopoverToogle}
                >
                    Get Started
                </Button>
            </Popover>
        </div>
    );
};
