import qs from 'qs';

import { DataTableQueryType } from 'types/base';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';

export const getAllTeamMembers = (queryParams?: DataTableQueryType) => {
    const params: string = qs.stringify(queryParams);

    return APIRequest({
        method: 'get',
        url: apiUrl + 'members?' + params,
    });
};
