import qs from 'qs';

import { apiUrl } from '../../envUtil';
import { APIRequest } from '../../apiUtil';
import { DataTableQueryType } from 'types/base';
import { ProspectDetailType, ProspectOptionType } from 'types/prospect';
import { Mixpanel } from 'utils/mixPanelUtil';

export const generateDefaultQuestions = () => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `prospects/generate-default-questions`,
        useDefaultErrorNotif: true,
    });
};

export const getProspectMetaOptions = (
    option_type: ProspectOptionType,
    active_only: boolean = false
) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `prospect-option/${option_type}?${active_only && 'active_only=yes'}`,
        useDefaultErrorNotif: true,
    });
};

export const getAllProspects = (params?: string | DataTableQueryType) => {
    return APIRequest({
        method: 'get',
        url:
            apiUrl +
            `prospects` +
            (params ? `?${typeof params === 'string' ? params : qs.stringify(params)}` : ''),
        useDefaultErrorNotif: true,
    });
};

export const getProspectDetail = (
    prospect_id: number,
    detail_type: ProspectDetailType = 'basic_info'
) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `prospect/${prospect_id}?detail_type=${detail_type}`,
        useDefaultErrorNotif: true,
    });
};

export const getProspectQuickStats = (prospect_id: number) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `prospect/${prospect_id}?detail_type=quick_stats`,
        useDefaultErrorNotif: true,
    });
};

export const addNewProspect = (payload: any) => {
    Mixpanel.track('app.add_prospect', {});

    return APIRequest({
        method: 'post',
        url: apiUrl + `prospects`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateProspectDetail = (prospect_id: number, payload: any) => {
    Mixpanel.track('app.edit_prospect', {
        prospect_id,
        sub_tab: 'basic',
        action: 'edit',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `prospect/${prospect_id}/basic-detail`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateProspectQuestionValues = (prospect_id: number, payload: any) => {
    Mixpanel.track('app.edit_prospect', {
        prospect_id,
        sub_tab: 'preference_household_profile',
        action: 'edit_question_values',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `prospect/${prospect_id}/questions`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const getProspectQuickStatsByIDs = (prospect_ids: number[]) => {
    const params: string = qs.stringify({
        prospect_ids: prospect_ids,
    });

    return APIRequest({
        method: 'get',
        url: apiUrl + `prospects/quick-stat-by-ids?${params}`,
        useDefaultErrorNotif: true,
    });
};

export const archiveProspect = (prospect_id: number) => {
    Mixpanel.track('app.archive_prospect', {
        prospect_id,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `prospect/${prospect_id}`,
        useDefaultErrorNotif: true,
    });
};
