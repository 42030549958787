// Core React libraries import
import React, { useContext, useEffect, useRef, useState } from 'react';

// Core ANTD and other libs import
import { Badge, Col, Popover, Row, Select, Tabs, Typography } from 'antd';
import { BellOutlined } from '@ant-design/icons';

// EZ web-app types import
import { NotificationInfoType } from 'types/notification';

// EZ web-app APIs import
import { getNotificationInfo } from 'utils/ez_api/notificationAPIs';

// Core EZ app level import
import { HomeContext } from 'context/EZContext';
import { IListQueryParams } from 'components/list/EZList';
import { AppActivities } from './activities';
import { AppMyInbox } from './myinbox';

const { Text, Paragraph } = Typography;
const { Option } = Select;

const defaultQueryParams: IListQueryParams = {
    searchKeyword: '',
    orderParams: [],
    whereParams: {},
    whereFilterParams: {},
};

const NotificationPopOver: React.FC<{ notificationInfo?: NotificationInfoType }> = ({
    notificationInfo,
}) => {
    return (
        <Row style={{ width: '400px', minHeight: '500px' }}>
            <Col span={24}>
                <Tabs defaultActiveKey="1" className="home-notification-tabs">
                    <Tabs.TabPane tab="My Notification" key="1">
                        <AppMyInbox notificationInfo={notificationInfo} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Logs" key="2">
                        <AppActivities />
                    </Tabs.TabPane>
                </Tabs>
            </Col>
        </Row>
    );
};

export const HomeNotification: React.FC<{}> = () => {
    const HomeCtx = useContext(HomeContext);

    const [notificationInfo, setNotificationInfo] = useState<NotificationInfoType>();

    const _fetchNotificationInfo = () => {
        getNotificationInfo()
            .then(({ data }) => {
                setNotificationInfo(data);
            })
            .catch(err => {});
    };

    useEffect(() => {
        _fetchNotificationInfo();

        let intervalId = setInterval(_fetchNotificationInfo, 30000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div className="">
            <Popover
                content={<NotificationPopOver notificationInfo={notificationInfo} />}
                arrowPointAtCenter
                showArrow={false}
                title=""
                placement="bottomLeft"
                // visible={true}
                trigger={['click']}
                className=""
                overlayClassName="home-notification-overlay-popover"
            >
                <Badge count={notificationInfo?.docReview || undefined} size="small">
                    <BellOutlined style={{ fontSize: '18px' }} />
                </Badge>
            </Popover>
        </div>
    );
};
