import React from 'react';

import moment from 'moment';

type EZDateFormatProps = {
    ifEmptyText?: string;
    format?: string;
    value: string | null | undefined | Date | number;
    humanize?: boolean;
    noTimezone?: boolean;
};

export const EZDateFormat: React.FC<EZDateFormatProps> = props => {
    const { ifEmptyText = '-', format = 'MM/DD/YYYY', value, humanize, noTimezone } = props;
    if (value) {
        const m =
            typeof value === 'number'
                ? moment.unix(value)
                : noTimezone
                ? moment.utc(value)
                : moment(value);
        if (!m.isValid()) return <span>{ifEmptyText}</span>;

        if (humanize) {
            const now = new Date();

            return (
                <span>
                    {m
                        .hour(now.getHours())
                        .minute(now.getMinutes())
                        .second(now.getSeconds())
                        .millisecond(now.getMilliseconds())
                        .fromNow()}
                </span>
            );
        } else return <span>{m.format(format)}</span>;
    } else {
        return <span>{ifEmptyText}</span>;
    }
};
