import { Alert, Button, Space } from 'antd';

import { AccountDataType } from 'types/account';

export const DemoBanner: React.FC<{ account?: AccountDataType }> = ({ account }) => {
    return (
        <Alert
            className={`demo-banner ${account?.account_id ? 'with-action' : ''}`}
            message="You are currently accessing the Demo version."
            type="info"
            action={
                account?.account_id ? (
                    <Space>
                        <Button size="small" type="link">
                            {/* <a
                                href={`https://docs.google.com/forms/d/e/1FAIpQLSc_KXgn2Z4YE8NJ6jY2S5UovJItL2wsQlOV_h38AthWhhTd0Q/viewform?usp=pp_url&entry.359900509=${encodeURIComponent(
                                    account?.fullname || '-'
                                )}&entry.1112551051=${account?.email}&entry.162893942=${
                                    account?.account_id
                                }`}
                                target="_blank"
                            > */}
                            <a
                                href={`https://docs.google.com/forms/d/e/1FAIpQLSe-rtW1gIpk_LXqC-pT5p_eFH9Afkuwn4jKGHydDaTiePF9oA/viewform`}
                                target="_blank"
                            >
                                <u>Migration Form</u>
                            </a>
                        </Button>
                    </Space>
                ) : (
                    <></>
                )
            }
            showIcon
        />
    );
};
