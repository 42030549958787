// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Col, Row, Tabs } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
// EZ web-app components import
import { CustomFieldSetting } from './transaction/customFieldsetting';
import { AlertSetting } from './transaction/alertSetting';
import { TaskSetting } from './transaction/taskSetting';
import { DocFolderSetting } from './transaction/newDocFolderSetting';
import { TransactionStatusSetting } from './transaction/transactionStatus';
import { TransactionPropTypeSetting } from './transaction/transactionPropType';
import { TransactionSaleTypeSetting } from './transaction/transactionSaleType';
import { HomeContext } from 'context/EZContext';
import { ACCOUNT_ROLE_HAS_ACCESS_TO_DETAIL_ADMIN_AREA } from 'types/account';

// EZ web-app styles and assets import

export const TransactionSetting: React.FC<{}> = ({}) => {
    const [activeTabKey, setActiveTabKey] = useState('admin-alert-setting');
    const HomeCtx = useContext(HomeContext);
    const accountrole_id = HomeCtx.userPermission?.accountrole_id;

    const hasAccessToDetailAdmin = accountrole_id
        ? ACCOUNT_ROLE_HAS_ACCESS_TO_DETAIL_ADMIN_AREA.includes(accountrole_id)
        : false;

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Tabs
                    className="tab-team-member-setting"
                    defaultActiveKey="admin-alert-setting"
                    type="card"
                    activeKey={activeTabKey}
                    onChange={key => setActiveTabKey(key)}
                    destroyInactiveTabPane
                >
                    <Tabs.TabPane tab="Alerts" key="admin-alert-setting">
                        <AlertSetting />
                    </Tabs.TabPane>
                    {hasAccessToDetailAdmin ? (
                        <>
                            <Tabs.TabPane tab="Transaction Status" key="admin-transaction-status">
                                <TransactionStatusSetting />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Property Types" key="admin-transaction-prop-type">
                                <TransactionPropTypeSetting />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Sales Types" key="admin-transaction-sale-type">
                                <TransactionSaleTypeSetting />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab="Custom Section and Fields"
                                key="admin-custom-field-setting"
                            >
                                <CustomFieldSetting />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Task Templates" key="admin-task-setting">
                                <TaskSetting />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Document Template" key="admin-doc-folder-setting">
                                <DocFolderSetting />
                            </Tabs.TabPane>
                        </>
                    ) : (
                        <></>
                    )}
                </Tabs>
            </Col>
        </Row>
    );
};
