// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import\
import { Row, Col, Typography, List, Avatar } from 'antd';
import { SnippetsOutlined } from '@ant-design/icons';

// EZ web-app utils import
// EZ web-app APIs import
import { getLogs } from 'utils/ez_api/notificationAPIs';
// EZ web-app types import
import { AppLogItemType, AppLogTransactionData } from 'types/notification';
// EZ web-app components import
import { EZDateFormat, EZList } from 'components';
import { IListQueryParams } from 'components/list/EZList';
import { HomeContext } from 'context/EZContext';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;

const defaultQueryParams: IListQueryParams = {
    searchKeyword: '',
    orderParams: [['note_id', 'desc']],
    whereParams: {
        category: 'log',
    },
    whereFilterParams: {},
};

export const AppActivities: React.FC<{}> = ({}) => {
    const HomeCtx = useContext(HomeContext);

    const [queryParams, setQueryParams] = useState<IListQueryParams>(defaultQueryParams);

    const _fetchNotes: (EZQueryParams: string) => Promise<any> = async EZQueryParams => {
        const result = await getLogs(EZQueryParams);
        return result;
    };

    const _openTransactionDetailTab = (contract?: AppLogTransactionData) => {
        if (contract) {
            HomeCtx.openTransactionDetailTab(
                contract.contract_id,
                contract.address,
                {
                    ...contract,
                },
                'Notification_menu'
            );
        }
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez_detail_changelogs">
            <Row gutter={[0, 0]}>
                <Col span={24} className="mb-3">
                    <EZList
                        rowKey="note_id"
                        paginationConfig={{
                            pageSize: 5,
                            size: 'small',
                        }}
                        queryParams={queryParams}
                        fetchData={_fetchNotes}
                        disableNativeSearch
                        renderItem={(record: AppLogItemType) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            style={{ backgroundColor: '#E6F7FF' }}
                                            icon={<SnippetsOutlined style={{ color: '#096DD9' }} />}
                                            size={32}
                                        />
                                    }
                                    title={
                                        <span>
                                            <Text strong className="mr-1">
                                                {record.posted_account?.fullname}
                                            </Text>
                                            <Text
                                                type="secondary"
                                                className="mr-2"
                                                style={{ fontSize: '90%' }}
                                            >
                                                On{' '}
                                                <EZDateFormat
                                                    value={record.date_posted}
                                                    format="MMM DD, YYYY - hh:mma"
                                                />
                                            </Text>
                                        </span>
                                    }
                                    description={
                                        <>
                                            <div>
                                                <Paragraph
                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                    ellipsis={{
                                                        rows: 3,
                                                        expandable: true,
                                                    }}
                                                    title={`${record.notes}`}
                                                >
                                                    {record.notes}
                                                </Paragraph>
                                            </div>
                                            <div style={{ fontSize: '90%' }}>
                                                Transaction &#9679;{' '}
                                                <Text
                                                    className="cursor-pointer"
                                                    type="secondary"
                                                    strong
                                                    onClick={() =>
                                                        _openTransactionDetailTab(record.contract)
                                                    }
                                                >
                                                    ${record.contract?.address}
                                                </Text>
                                            </div>
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </div>
    );
};
