import { Button, Typography, Form, Input } from 'antd';
import { memo, useState } from 'react';
import { forgotPassword } from '../../utils/ez_api/loginUtil';
import '../../styles/login.scss';
import { Link } from 'react-router-dom';
import { showNotification } from 'utils/notificationUtil';
import { SocialBoxWrapper } from './Fragments/SocialBoxWrapper';

const { Text, Title } = Typography;

export const ForgotPassword = memo(function ForgotPassword(props: any) {
    const [emailToResend, setEmailToResend] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [form] = Form.useForm();

    const sendForgotEmail = (values: any) => {
        const { email } = values;

        forgotPassword(email)
            .then(res => {
                setEmailToResend(email);
                showNotification(
                    'success',
                    'New reset password link has been sent to your email successfully!',
                    'Successfully'
                );
            })
            .catch(err => {
                const message = err?.response?.data?.message;
                setErrorMessage(message);
            });
    };

    const resendEmail = () => {
        forgotPassword(emailToResend).then(res => {
            showNotification(
                'success',
                'New reset password link has been sent to your email successfully!',
                'Successfully Resent'
            );
        });
    };

    if (emailToResend) {
        return (
            <SocialBoxWrapper>
                <Title level={3} style={{ textAlign: 'center' }}>
                    Thanks, check your email for the link to reset your password
                </Title>
                <Text type="secondary" style={{ textAlign: 'center' }}>
                    Didn't get the email? Check your spam folder or
                </Text>
                <Button type="link" onClick={resendEmail}>
                    Resend email
                </Button>
            </SocialBoxWrapper>
        );
    }

    return (
        <SocialBoxWrapper>
            <Title level={3}>Forgot Password</Title>
            <Text type="secondary" style={{ textAlign: 'center', marginBottom: '1em' }}>
                Enter the email address associated with your account
                <br /> and we'll send you a link to reset your password
            </Text>
            <Form
                form={form}
                name="forgot_password"
                requiredMark={false}
                layout="vertical"
                style={{ width: '100%' }}
                onFinish={sendForgotEmail}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: 'Please input your email!',
                        },
                    ]}
                    validateStatus={errorMessage && 'error'}
                    help={errorMessage}
                >
                    <Input type={'email'} placeholder="Enter your email" />
                </Form.Item>
                <Button
                    htmlType="submit"
                    type="primary"
                    style={{ width: '100%', marginTop: '1em' }}
                >
                    Request Password Reset
                </Button>
            </Form>

            <span style={{ paddingTop: '1em' }}>
                Already have an account?{' '}
                <Link to="/login">
                    <span className="">Sign In</span>
                </Link>
            </span>
        </SocialBoxWrapper>
    );
});
