import { hotjar } from 'react-hotjar';
import { calculateDuration } from 'utils/data_manipulate/date/calculateDuration';

export enum HOTJAR_EVENT_TYPE {
    ADD_NEW_TRANSACTION = 'add_new_transaction',
    LOAD_TEMPLATES = 'load_templates_on_transaction',
    CLICK_TRANSACTION_TASKS = 'click_transaction_tasks',
    CLICK_TASKS_MENU = 'click_tasks_menu',
}

export const hotjarEvent = (event: HOTJAR_EVENT_TYPE) => {
    hotjar.event(event);
};

export const hotjarIdentify = () => {
    const [hotjarActiveDetail] = JSON.parse(localStorage.getItem('hjActiveViewportIds') ?? '[{}]');
    const hotjarUserId = hotjarActiveDetail?.id || '';

    const hotjarInitialized = hotjar.initialized();
    if (!hotjarInitialized || !hotjarUserId) {
        const timeout = setTimeout(hotjarIdentify, 3000);
        return () => {
            clearTimeout(timeout);
        };
    }
    const accountCreatedDate = localStorage.getItem('date_posted') || '';
    const accountCreatedDateDuration = calculateDuration(accountCreatedDate, new Date(), true);

    const userDetail = {
        user_type_id: localStorage.getItem('user_type_id'),
        account_id: localStorage.getItem('account_id'),
        account_type: localStorage.getItem('account_type'),
        status: localStorage.getItem('status'),
        email: localStorage.getItem('email'),
        parent_account_id: localStorage.getItem('parent_account_id'),
        account_created_date: accountCreatedDate,
        account_created_date_duration: accountCreatedDateDuration,
    };

    hotjar.identify(hotjarUserId, { ...userDetail });
};
