// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Col, Row, Tabs } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
// EZ web-app components import
import { ProspectGroupSetting } from './prospect/prospectGroupSetting';
import { ProspectFollowupCodeSetting } from './prospect/prospectFollowupCodeSetting';
import { ProspectFlagSetting } from './prospect/prospectFlagSetting';
import { ProspectTaskSetting } from './prospect/prospectTaskSetting';
import { ProspectQuestionSetting } from './prospect/questionSetting';

// EZ web-app styles and assets import

export const ProspectSetting: React.FC<{}> = ({}) => {
    const [activeTabKey, setActiveTabKey] = useState('prospect-questions');

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Tabs
                    className="tab-team-member-setting"
                    defaultActiveKey="prospect-groups"
                    type="card"
                    activeKey={activeTabKey}
                    onChange={key => setActiveTabKey(key)}
                    destroyInactiveTabPane
                >
                    <Tabs.TabPane tab={`Questions`} key={`prospect-questions`}>
                        <ProspectQuestionSetting />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`Follow-up Codes`} key={`prospect-followup-codes`}>
                        <ProspectFollowupCodeSetting />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`Groups`} key={`prospect-groups`}>
                        <ProspectGroupSetting />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`Flags`} key={`prospect-flags`}>
                        <ProspectFlagSetting />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`Task Templates`} key={`prospect-tasks`}>
                        <ProspectTaskSetting />
                    </Tabs.TabPane>
                </Tabs>
            </Col>
        </Row>
    );
};
