// Core React libraries import
import React, { Key, useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Form,
    Select,
    Typography,
    Alert,
    Button,
    Tree,
    Tag,
    Skeleton,
    Divider,
    Drawer,
    List,
    Avatar,
    Tooltip,
    Input,
    Modal,
} from 'antd';
import { FileDoneOutlined, FlagTwoTone, MessageOutlined, SendOutlined } from '@ant-design/icons';
import { DataNode, TreeProps } from 'antd/es/tree';
import qs from 'qs';

// EZ web-app import

// EZ web-app utils import
import {
    DocFolderType,
    DocNotesType,
    TransactionDetailType,
    TransactionDocItemType,
    TRANSACTION_FLAGS,
} from 'types/transaction';
import { EZDateFormat, EZList, EZSelectFlag } from 'components';

// Page level styles
import './DocReview.scss';
import {
    addNewNote,
    getDocNotes,
    getPresignedURLDocsRead,
    getTransactionDocs,
    getTransactionFolders,
    updateDocument,
} from 'utils/ez_api/documentAPIs';
import { getAllTransactions, updateTransactionDetail } from 'utils/ez_api/transactionAPIs';
import { ALLOWED_CONTENT_TYPES, SIMPLE_EMAIL_REGEX } from 'static/consts';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { DocViewerType } from 'types/document';
import { getInitialFromFullname } from 'utils/commonUtil';
import { IListQueryParams } from 'components/list/EZList';
import { showMessage, showNotification } from 'utils/notificationUtil';
import { EmailPayloadType } from 'types/notification';
import { HomeContext } from 'context/EZContext';
import { SelectProps } from 'antd/es/select';
import { sendEmail } from 'utils/ez_api/notificationAPIs';

const { Text } = Typography;
const { DirectoryTree } = Tree;

const defaultQueryParams: IListQueryParams = {
    searchKeyword: '',
    orderParams: [['doc_note_id', 'desc']],
    whereParams: {},
    whereFilterParams: {},
};

const REVIEW_STATUS = [
    { review_label: 'N/A', tag_color: 'grey', status_id: 1 },
    { review_label: 'Approved', tag_color: 'green', status_id: 2 },
    { review_label: 'Needs Attention', tag_color: 'red', status_id: 3 },
];

const BROKER_FLAG = TRANSACTION_FLAGS.find(f => f.flag_id === 3);

const createRevewStatusTag = (review_status: number, applyTagColor: boolean = false) => {
    const config: any = {};

    if (applyTagColor) {
        config.color = REVIEW_STATUS[review_status - 1]?.tag_color || 'grey';
    }

    return (
        <Tag
            className="ml-0 mr-0"
            // style={{ lineHeight: '22px' }}
            {...config}
        >
            {REVIEW_STATUS[review_status - 1]?.review_label || 'N/A'}
        </Tag>
    );
};

const DocNotesDrawer: React.FC<{ selectedDoc?: TransactionDocItemType }> = ({ selectedDoc }) => {
    const [notesCount, setNotesCount] = useState(selectedDoc?.doc_notes?.length || 0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [queryParams, setQueryParams] = useState<IListQueryParams>(defaultQueryParams);
    const [noteContent, setNoteContent] = useState('');

    const _fetchDocNotes: (EZQueryParams: string) => Promise<any> = async EZQueryParams => {
        const result = await getDocNotes(selectedDoc?.doc_id || 0, EZQueryParams);
        return result;
    };

    const _openNotesDrawer = () => {
        setIsDrawerOpen(true);
    };

    const _onDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    const _addNewNote = () => {
        const trimmedNote = noteContent.trim();
        if (selectedDoc?.doc_id && trimmedNote.length > 0) {
            addNewNote(selectedDoc.contract_id, selectedDoc.doc_id, trimmedNote).then(resp => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const newNote = resp.data as DocNotesType;
                setQueryParams({ ...defaultQueryParams });
                setNoteContent('');
                setNotesCount(notesCount + 1);
            });
        }
    };

    return (
        <>
            <Tooltip
                title={`This doc has ${
                    selectedDoc?.doc_notes?.length || 0
                } note(s). Click to see the notes.`}
                placement="topRight"
            >
                <Button
                    className="ml-2"
                    icon={<MessageOutlined />}
                    onClick={() => _openNotesDrawer()}
                >
                    {`${notesCount}`}
                </Button>
            </Tooltip>
            <Drawer
                title={`Notes for Document/File: ${selectedDoc?.filename}`}
                placement="right"
                width={600}
                onClose={_onDrawerClose}
                visible={isDrawerOpen}
            >
                <Row gutter={[16, 12]}>
                    <Col span={24}>
                        <Input.TextArea
                            rows={3}
                            value={noteContent}
                            onChange={e => setNoteContent(e?.currentTarget?.value)}
                        />
                    </Col>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={() => _addNewNote()}>
                            Add New Note
                        </Button>
                    </Col>
                    <Divider className="mt-2 mb-2" />
                    <Col span={24}>
                        <EZList
                            rowKey="note_id"
                            paginationConfig={{
                                pageSize: 5,
                            }}
                            queryParams={queryParams}
                            fetchData={_fetchDocNotes}
                            renderItem={(record: DocNotesType) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar size={42}>
                                                {record.account && record.account.fullname
                                                    ? getInitialFromFullname(
                                                          record.account.fullname
                                                      )
                                                    : 'N/A'}
                                            </Avatar>
                                        }
                                        title={
                                            <span>
                                                <Text strong className="mr-1">
                                                    {record.account?.fullname}
                                                </Text>
                                                <Text className="mr-1">
                                                    {record.account?.email
                                                        ? `(${record.account.email})`
                                                        : ``}
                                                </Text>
                                                <Text type="secondary" className="mr-2">
                                                    On{' '}
                                                    <EZDateFormat
                                                        value={record.date_posted}
                                                        format="MM/DD/YYYY - hh:mma"
                                                    />
                                                </Text>
                                            </span>
                                        }
                                        description={
                                            <Typography.Paragraph
                                                style={{ whiteSpace: 'pre-wrap' }}
                                                ellipsis={{
                                                    rows: 2,
                                                    expandable: true,
                                                }}
                                                title={`${record.notes}`}
                                            >
                                                {record.notes}
                                            </Typography.Paragraph>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Drawer>
        </>
    );
};

const DocPreview: React.FC<{ selectedDocToPreview?: DocViewerType }> = ({
    selectedDocToPreview,
}) => {
    return (
        <DocViewer
            config={{
                header: {
                    disableHeader: true,
                },
            }}
            documents={selectedDocToPreview ? [selectedDocToPreview] : []}
            pluginRenderers={DocViewerRenderers}
            style={{ minHeight: '620px' }}
        />
    );
};

const SendEmailForm: React.FC<{
    meta?: {
        contractAddress: string;
    };
    emailPayload?: Partial<EmailPayloadType>;
    onFormFinish?: (formValues: any) => void;
}> = ({ emailPayload, onFormFinish }) => {
    const [form] = Form.useForm();

    const _onSelectRecipient: SelectProps['onSelect'] = (value: string, option: any) => {
        const recipientEmails: string[] = form.getFieldValue('recipients');
        form.setFieldValue(
            'recipients',
            recipientEmails.filter(email => SIMPLE_EMAIL_REGEX.test(email))
        );
    };

    const _onFinish = (values: any) => {
        onFormFinish?.(values);
    };

    useEffect(() => {
        if (emailPayload) {
            form.setFieldsValue({
                ...emailPayload,
                recipients: emailPayload?.recipients?.map(r => r.email),
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailPayload]);

    return (
        <Form form={form} onFinish={_onFinish} layout="vertical" requiredMark={false}>
            <Row gutter={20}>
                <Col span={24}>
                    <Form.Item
                        label="Recipients"
                        name="recipients"
                        rules={[
                            {
                                required: true,
                                message: "Recipient(s) can't be empty",
                            },
                        ]}
                        extra={`Tip: Hit enter after writing down an email address.`}
                    >
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}
                            placeholder="Enter the email address(es)"
                            onSelect={_onSelectRecipient}
                            notFoundContent={''}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Subject"
                        name="subject"
                        rules={[
                            {
                                required: true,
                                message: "Subject can't be empty",
                            },
                        ]}
                    >
                        <Input placeholder="Enter your the subject" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Message"
                        name="text"
                        rules={[
                            {
                                required: true,
                                message: "Message can't be empty",
                            },
                        ]}
                    >
                        <Input.TextArea rows={5} placeholder="Enter your the message" />
                    </Form.Item>
                </Col>
                <Col span={24} className="text-right">
                    <Form.Item noStyle>
                        <Button type="primary" htmlType="submit">
                            Send
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

const MemoizedDocPreview = React.memo(DocPreview);

const DocumentReviewTab: React.FC = () => {
    const HomeCtx = useContext(HomeContext);

    const UserInfo = HomeCtx.userInfo;

    const [form] = Form.useForm();

    const [isFetchingContracts, setIsFetchingContracts] = useState(false);
    const [isFetchingDirNodes, setIsFetchingDirNodes] = useState(false);
    const [isFetchingSelectedFile, setIsFetchingSelectedFile] = useState(false);

    const [currentContract, setCurrentContract] = useState<TransactionDetailType>();
    const [contractsToReview, setContractsToReview] = useState<TransactionDetailType[]>([]);

    const [docTreeNodes, setDocTreeNodes] = useState<DataNode[]>([]);
    const [docs, setDocs] = useState<TransactionDocItemType[]>([]);
    const [selectedDoc, setSelectedDoc] = useState<TransactionDocItemType>();
    const [selectedDocToPreview, setSelectedDocToPreview] = useState<DocViewerType>();

    const _docOnSelect: TreeProps['onSelect'] = (_, info) => {
        const doc_id = info.node.key.toString().includes('doc_id-')
            ? info.node.key.toString().replace('doc_id-', '')
            : null;

        if (selectedDoc?.doc_id.toString() === doc_id) return;

        if (doc_id) {
            const doc = docs.find(d => d.doc_id.toString() === doc_id);

            if (doc) {
                setSelectedDoc(doc);
                setIsFetchingSelectedFile(true);
                getPresignedURLDocsRead('contract_document', [
                    { contract_id: currentContract?.contract_id, file_id: doc.doc_id },
                ])
                    .then(({ data }) => {
                        const docList = (data as DocViewerType[]).map(doc => {
                            doc.uri = doc.presignedURL;
                            doc.fileType = doc.content_type;

                            return doc;
                        });
                        if (docList.length) {
                            setSelectedDocToPreview(docList[0]);
                        }
                    })
                    .finally(() => {
                        setIsFetchingSelectedFile(false);
                    });
            } else {
                setSelectedDoc(undefined);
            }
        } else {
            setSelectedDoc(undefined);
        }
    };

    const _fetchAllDocs = () => {
        if (!currentContract || !currentContract.contract_id) return;
        setIsFetchingDirNodes(true);
        getTransactionDocs(
            currentContract.contract_id,
            qs.stringify({
                pageSize: 500,
            })
        )
            .then(async response => {
                const docs = response.data as TransactionDocItemType[];

                const resp = await getTransactionFolders(currentContract.contract_id);
                if (resp.data) {
                    const treeNodes: DataNode[] =
                        resp.data?.map((folder: DocFolderType) => ({
                            title: folder.folder_name,
                            key: folder.folder_id,
                            children: docs
                                .filter(doc => doc.folder_id === folder.folder_id)
                                .map((doc_1: TransactionDocItemType) => ({
                                    title: (
                                        <>
                                            <Text
                                                title={doc_1.filename}
                                                ellipsis
                                                style={{ maxWidth: '200px' }}
                                            >
                                                {doc_1.filename}
                                            </Text>
                                            <span className="ml-2" style={{ float: 'right' }}>
                                                {createRevewStatusTag(doc_1.review_status, true)}
                                            </span>
                                        </>
                                    ),
                                    key: `doc_id-${doc_1.doc_id}`,
                                    isLeaf: true,
                                    icon: <FileDoneOutlined />,
                                })),
                        })) || [];

                    setDocTreeNodes(treeNodes);
                    setDocs(docs || []);
                } else {
                    setDocTreeNodes([]);
                    setDocs([]);
                }
            })
            .finally(() => {
                setIsFetchingDirNodes(false);
            });
    };

    const _fetchContractsToReview = () => {
        setIsFetchingContracts(true);
        getAllTransactions(
            'compact',
            qs.stringify({
                where: {
                    flag_id: 3,
                },
            })
        )
            .then(response => {
                const contractsToReviewResp = response.data as TransactionDetailType[];
                if (contractsToReviewResp && contractsToReviewResp.length) {
                    setContractsToReview(contractsToReviewResp);
                    setCurrentContract(contractsToReviewResp[0]);
                    // setContractsToReview([]);
                    // setCurrentContract(undefined);
                } else {
                    setContractsToReview([]);
                    setCurrentContract(undefined);
                }
            })
            .finally(() => {
                setIsFetchingContracts(false);
            });
    };

    const _changeCurrentContract = (contract_id: number) => {
        const selectedContract = contractsToReview.find(c => c.contract_id === contract_id);

        if (selectedContract) {
            setCurrentContract(selectedContract);
        }
    };

    const _changeReviewStatus = (newReviewStatus: number) => {
        if (!selectedDoc) return;

        const reviewStatusNote: string = form.getFieldValue('notes');
        if (selectedDoc.doc_id && reviewStatusNote && reviewStatusNote.length > 0) {
            addNewNote(selectedDoc.contract_id, selectedDoc.doc_id, reviewStatusNote);
        }

        return updateDocument(
            selectedDoc?.contract_id || -1,
            selectedDoc?.doc_id || -1,
            {
                review_status: newReviewStatus,
            },
            'PARTIAL'
        ).then(() => {
            showNotification('success', 'You have successfully changed the review status.');

            setSelectedDoc({ ...selectedDoc, review_status: newReviewStatus });

            _updateDirTreeNode(selectedDoc?.doc_id || -1, {
                review_status: newReviewStatus,
            });

            return true;
        });
    };

    const _updateDirTreeNode = (doc_id: number, docPayload: Partial<TransactionDocItemType>) => {
        for (let index = 0; index < docTreeNodes.length; index++) {
            const parentNode = docTreeNodes[index];
            const docNode = parentNode.children?.find(child => child.key === `doc_id-${doc_id}`);
            if (docNode) {
                const doc = docs.find(d => d.doc_id === doc_id);
                if (doc) {
                    docNode.title = (
                        <>
                            <Text title={doc.filename} ellipsis style={{ maxWidth: '200px' }}>
                                {doc.filename}
                            </Text>
                            <span className="ml-2" style={{ float: 'right' }}>
                                {createRevewStatusTag(docPayload.review_status || 1, true)}
                            </span>
                        </>
                    );
                    Object.assign(doc, { ...docPayload });

                    setDocTreeNodes([...docTreeNodes]);
                    setDocs([...docs]);
                    break;
                }
            }
        }
    };

    const _onReviewStatusChange = (newReviewStatus: number) => {
        form.resetFields();
        Modal.confirm({
            closable: true,
            title: <>Review Status Change Confirmation</>,
            width: 600,
            content: (
                <Form form={form}>
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            You are about to change the status:{' '}
                            <span>
                                {createRevewStatusTag(selectedDoc?.review_status || 1, true)}
                            </span>
                            <span className="mr-2 ml-2">to</span>
                            <span>{createRevewStatusTag(newReviewStatus, true)}</span>
                        </Col>
                        <Col span={24}>
                            <Form.Item noStyle name="notes">
                                <Input.TextArea
                                    placeholder="Write down your note for this change here"
                                    rows={3}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            ),
            onOk: close => {
                return _changeReviewStatus(newReviewStatus);
            },
        });
    };

    const _confirmContractStatusChange = (new_flag_id: number) => {
        const flag = TRANSACTION_FLAGS.find(f => f.flag_id === new_flag_id);

        Modal.confirm({
            closable: true,
            title: <>Transaction Status Change Confirmation</>,
            width: 600,
            content: (
                <Form form={form}>
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            You are about to change the transaction's status: <br />
                            <span>
                                <FlagTwoTone
                                    twoToneColor={currentContract?.contract_flag?.flag_color}
                                />
                                <span className="ml-2">{currentContract?.contract_flag?.flag}</span>
                            </span>
                            <span className="mr-2 ml-2">to</span>
                            <span>
                                <FlagTwoTone twoToneColor={flag?.flag_color} />
                                <span className="ml-2">{flag?.flag}</span>
                            </span>
                        </Col>
                    </Row>
                </Form>
            ),
            onOk: () => {
                if (currentContract) {
                    return updateTransactionDetail(currentContract?.contract_id, {
                        flag_id: new_flag_id,
                    }).then(res => {
                        if (res.data) {
                            showNotification(
                                'success',
                                `Flag type for contract with address: ${currentContract.address} has been successfully updated.`
                            );
                        }
                        currentContract.flag_id = new_flag_id;
                        setCurrentContract({ ...currentContract });
                        return true;
                    });
                } else {
                    return Promise.resolve(true);
                }
            },
        });
    };

    const _showSendEmailForm = () => {
        const contractAddress = `${currentContract?.address}, ${currentContract?.city}, ${currentContract?.state}, ${currentContract?.zip}`;

        const emailPayload: Partial<EmailPayloadType> = {
            sender: {
                email: UserInfo!.email,
                name: UserInfo?.fullname,
            },
            recipients: currentContract?.assigned_accounts?.map(account => ({
                email: account.email,
            })),
            subject: `Broker Review: ${contractAddress}`,
        };

        const _onEmailFormFinish = (values: any) => {
            const payload = {
                ...emailPayload,
                ...values,
                recipients: values?.recipients?.map((r: string) => ({ email: r })),
                text: `Hi,<br><br>Your transaction at ${contractAddress} has been reviewed by ${
                    UserInfo!.email
                }. Below are their comments: <br><br>${values.text}`,
            };

            sendEmail(payload).then(({ data }) => {
                if (data === false) {
                    showMessage('error', 'Error happened. Please check again your form.');
                    return;
                }
                emailModalInstance?.destroy();
            });
        };

        const emailModalInstance = Modal.info({
            icon: <SendOutlined />,
            closable: true,
            maskClosable: true,
            title: <>Send Email Form</>,
            width: 700,
            content: (
                <SendEmailForm emailPayload={emailPayload} onFormFinish={_onEmailFormFinish} />
            ),
            okButtonProps: {
                style: {
                    display: 'none',
                },
            },
        });
    };

    useEffect(() => {
        if (currentContract?.contract_id) {
            setSelectedDoc(undefined);
            setSelectedDocToPreview(undefined);
            _fetchAllDocs();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentContract]);

    useEffect(() => {
        _fetchContractsToReview();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez-tab-doc-review">
            <div
                className="mb-4 ez-sub-content-wrapper"
                data-testid="document-review-header-section"
            >
                <Row gutter={64} align="middle">
                    <Col span={24}>
                        {!isFetchingContracts && !contractsToReview.length ? (
                            <Alert
                                description={
                                    <div>
                                        <span>No transaction flagged with </span>
                                        <span>
                                            <FlagTwoTone twoToneColor={BROKER_FLAG?.flag_color} />
                                            <span className="ml-2">{BROKER_FLAG?.flag}</span>
                                        </span>
                                    </div>
                                }
                                className="mb-3"
                                showIcon
                                type="info"
                            />
                        ) : (
                            ''
                        )}
                    </Col>
                    <Col span={8} data-testid="document-review-contract-selector">
                        <Select
                            showSearch
                            optionFilterProp="label"
                            style={{ width: '100%' }}
                            placeholder="Select a transaction"
                            options={
                                contractsToReview?.map(contract => ({
                                    label: contract.address,
                                    value: contract.contract_id,
                                })) || []
                            }
                            value={currentContract?.contract_id}
                            onChange={value => _changeCurrentContract(value as number)}
                        ></Select>
                    </Col>
                    <Col span={16} data-testid="document-review-flag-status">
                        <Row justify="space-between" align="middle" wrap={false} gutter={16}>
                            <span
                                className="ml-2"
                                style={{ display: 'inline-block', textAlign: 'left' }}
                            >
                                - All transactions flagged{' '}
                                <span>
                                    <FlagTwoTone twoToneColor={BROKER_FLAG?.flag_color} />
                                    <span className="ml-2">{BROKER_FLAG?.flag}</span>
                                </span>{' '}
                                will be displayed here.
                                <br /> - Change the Flag to Missing Documents or Signatures to alert
                                the assigned agents.
                            </span>
                            <EZSelectFlag
                                style={{ textAlign: 'left', width: '250px' }}
                                allowClear={false}
                                value={currentContract?.flag_id}
                                onSelect={(value: number) => _confirmContractStatusChange(value)}
                                disabled={!isFetchingContracts && !currentContract}
                            />
                        </Row>
                    </Col>
                </Row>
            </div>
            <div>
                <Row gutter={16}>
                    <Col span={8}>
                        {currentContract ? (
                            <div
                                className="mb-4 ez-sub-content-wrapper"
                                data-testid="document-review-email-section"
                            >
                                <div>
                                    <Button
                                        className="ez-action-btn"
                                        style={{ width: '100%' }}
                                        onClick={() => _showSendEmailForm()}
                                    >
                                        Send Email To Agent(s)
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div
                            className="mb-5 ez-sub-content-wrapper"
                            style={{ minHeight: '200px' }}
                            data-testid="document-review-directory-section"
                        >
                            {isFetchingDirNodes && <Skeleton active />}
                            {!isFetchingDirNodes && (
                                <div>
                                    <div>
                                        {docTreeNodes.length ? (
                                            <DirectoryTree
                                                multiple={false}
                                                autoExpandParent={true}
                                                defaultExpandedKeys={[
                                                    `doc_id-${selectedDoc?.doc_id}` as Key,
                                                ]}
                                                defaultCheckedKeys={[
                                                    `doc_id-${selectedDoc?.doc_id}` as Key,
                                                ]}
                                                onSelect={_docOnSelect}
                                                treeData={docTreeNodes}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            )}
                            {!isFetchingContracts && !contractsToReview.length ? (
                                <Alert
                                    description="No transaction is selected."
                                    showIcon
                                    type="info"
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </Col>
                    <Col span={16} data-testid="document-review-preview-section">
                        <div className="mb-5 ez-sub-content-wrapper" style={{ minHeight: '720px' }}>
                            <Row>
                                <Col span={24}>
                                    {!isFetchingSelectedFile &&
                                    selectedDocToPreview &&
                                    ALLOWED_CONTENT_TYPES.includes(
                                        selectedDocToPreview?.content_type || ''
                                    ) ? (
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={16}>
                                                        <Typography.Title level={5}>
                                                            {selectedDocToPreview.filename}
                                                        </Typography.Title>
                                                    </Col>
                                                    <Col span={8} style={{ textAlign: 'right' }}>
                                                        <Select
                                                            bordered={false}
                                                            optionFilterProp="label"
                                                            style={{
                                                                width: '150px',
                                                                textAlign: 'left',
                                                            }}
                                                            value={selectedDoc?.review_status}
                                                            onChange={value =>
                                                                _onReviewStatusChange(value)
                                                            }
                                                        >
                                                            {REVIEW_STATUS.map((elm, idx) => (
                                                                <Select.Option value={idx + 1}>
                                                                    <Tag
                                                                        className="ml-0 mr-0"
                                                                        color={elm.tag_color}
                                                                        style={{
                                                                            lineHeight: '22px',
                                                                        }}
                                                                    >
                                                                        {elm.review_label}
                                                                    </Tag>
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                        <DocNotesDrawer selectedDoc={selectedDoc} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <Typography.Text>
                                                    Uploaded on{' '}
                                                    <EZDateFormat
                                                        value={selectedDoc?.date_uploaded}
                                                        format="MM/DD/YYYY hh:mma"
                                                    />{' '}
                                                    By {selectedDoc?.account?.fullname || '-'}
                                                </Typography.Text>
                                                <br />
                                                <Typography.Text>
                                                    Description: {selectedDoc?.description || '-'}
                                                </Typography.Text>
                                            </Col>
                                            <Divider className="mt-2 mb-4" />
                                            <Col span={24}>
                                                <MemoizedDocPreview
                                                    selectedDocToPreview={selectedDocToPreview}
                                                />
                                            </Col>
                                        </Row>
                                    ) : (
                                        ''
                                    )}
                                    {!isFetchingSelectedFile &&
                                    selectedDoc &&
                                    !ALLOWED_CONTENT_TYPES.includes(
                                        selectedDocToPreview?.content_type || ''
                                    ) ? (
                                        <Alert
                                            description="Preview is unavailable for the selected document(s)."
                                            showIcon
                                            type="info"
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {!isFetchingSelectedFile && !selectedDoc ? (
                                        <Alert
                                            description="No document is selected."
                                            showIcon
                                            type="info"
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {isFetchingSelectedFile && <Skeleton active />}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col></Col>
                </Row>
            </div>
        </div>
    );
};

export default DocumentReviewTab;
