// Core React libs import
import React from 'react';

// Core ANTD and 3rd party libs import
import { Select } from 'antd';
// import type { SelectProps, BaseOptionType, DefaultOptionType } from 'antd/es/select';
import type { SelectProps } from 'antd/es/select';

// EZ level libs import
import { US_STATES } from 'static/US_States';

export interface EZSelectPropertyTypeProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {}

export function EZSelectUSState({ ...props }) {
    return (
        <Select
            style={{ width: '100%' }}
            options={US_STATES}
            optionFilterProp="state_name"
            fieldNames={{
                label: 'state_name',
                value: 'state_code',
            }}
            showSearch
            allowClear
            showArrow
            placeholder={'Select or search the state'}
            {...props}
        ></Select>
    );
}
