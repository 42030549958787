import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { DataTableQueryType } from 'types/base';

export const getCalendarEvents = (params?: DataTableQueryType) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `calendar/events` + (params ? `?${qs.stringify(params)}` : ''),
        useDefaultErrorNotif: true,
    });
};
