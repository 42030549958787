// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Alert,
    Button,
    Col,
    Collapse,
    Divider,
    Form,
    Input,
    Popconfirm,
    Popover,
    Row,
    Select,
    Space,
    Switch,
    Tag,
    Typography,
} from 'antd';
import {
    CheckSquareOutlined,
    CloseOutlined,
    CodeOutlined,
    DeleteFilled,
    DeleteOutlined,
    DownOutlined,
    EditFilled,
    EyeInvisibleOutlined,
    EyeOutlined,
    InfoCircleOutlined,
    PlusOutlined,
    SaveFilled,
    UpOutlined,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import debounce from 'lodash/debounce';

// EZ web-app utils import
import { showMessage } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    addNewContractCustomField,
    addNewContractCustomSection,
    addNewDropdownOption,
    bulkUpdateCustomFieldPositions,
    deleteContractCustomField,
    deleteContractCustomSection,
    deleteDropdownOption,
    getContractCustomFields,
    updateContractCustomField,
    updateContractCustomSection,
    updateDropdownOption,
} from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import {
    AdditionalInfoFieldType,
    contractAdditionalInfoCategoryType,
    ContractAdditionalInfoOption,
} from 'types/transaction';
import { updateOnboardingStep } from 'utils/onboardingUtil';
import { HomeContext } from 'context/EZContext';
// EZ web-app components import

// EZ web-app styles and assets import

const toggleDropdownElementByCustomFieldID = (fieldID: number, show: boolean) => {
    const dropdownElementDiv = document.getElementById(`dropdown-custom-field-${fieldID}`);
    if (dropdownElementDiv) {
        dropdownElementDiv.style.display = show ? 'block' : 'none';
    }
};

export const CustomFieldSetting: React.FC<{}> = ({}) => {
    const [isFetchingSections, setIsFetchingSections] = useState(false);
    const [sections, setSections] = useState<contractAdditionalInfoCategoryType[]>([]);

    const [expandedRecordIDs, setExpandedRecordIDs] = useState<React.Key[]>([]);
    const HomeCtx = useContext(HomeContext);

    const _fetchCustomSections = () => {
        setIsFetchingSections(true);
        getContractCustomFields()
            .then(({ data }) => {
                setSections(data || []);
            })
            .finally(() => {
                setIsFetchingSections(false);
            });
    };

    const columns: ProColumns<contractAdditionalInfoCategoryType>[] = [
        {
            title: 'Section Name',
            dataIndex: 'category',
            key: 'category',
            width: '320px',
            formItemProps: (form, config) => {
                return {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                };
            },
            render: (_, record) => (
                <Typography.Text
                    onClick={() =>
                        setExpandedRecordIDs([record.additional_info_fields_category_id])
                    }
                    strong
                    underline
                >
                    {record.category}
                </Typography.Text>
            ),
        },
        {
            title: 'Fields Information',
            dataIndex: '',
            key: 'x',
            editable: false,
            render: (_, record) => (
                <Tag
                    onClick={() =>
                        setExpandedRecordIDs([record.additional_info_fields_category_id])
                    }
                    className="tag-rounded cursor-pointer"
                >
                    {record.contract_additional_info_preferences?.length} Field(s)
                </Tag>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '220px',
            key: 'x',
            valueType: 'option',
            render: (_, record, index, action) => (
                <span>
                    <Button
                        className="p-0"
                        type="link"
                        icon={<EditFilled />}
                        title="Delete this section?"
                        size="small"
                        disabled={record.additional_info_fields_category_id === 1}
                        onClick={() => {
                            action?.startEditable(record.additional_info_fields_category_id);
                        }}
                    >
                        Edit Section Name
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        placement="bottomLeft"
                        title={
                            <>
                                Delete <b>{record.category}</b>?
                            </>
                        }
                        disabled={record.additional_info_fields_category_id === 1}
                        onConfirm={() => _deleteSection(record.additional_info_fields_category_id)}
                    >
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<DeleteOutlined />}
                            title="Delete this section?"
                            size="small"
                            disabled={record.additional_info_fields_category_id === 1}
                            danger
                        ></Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const modifySectionData = (sectionID: number, newRecordData: any) => {
        try {
            const section = sections.find(t => t.additional_info_fields_category_id === sectionID);

            if (section) {
                if (newRecordData.category) {
                    section.category = newRecordData.category;
                    setSections([...sections]);
                }
            }
        } catch (error) {
            console.log(
                '🚀 ~ file: customFieldsetting.tsx ~ line 210 ~ modifySectionData ~ error',
                error
            );
        }
    };

    const deleteSection = (categoryID: number) => {
        try {
            const categoryIndex = sections.findIndex(
                t => t.additional_info_fields_category_id === categoryID
            );

            if (categoryIndex !== undefined && categoryIndex >= 0) {
                sections.splice(categoryIndex, 1);
                setSections([...sections]);
            }
        } catch (error) {
            console.log(
                '🚀 ~ file: customFieldsetting.tsx ~ line 240 ~ deleteSection ~ error',
                error
            );
        }
    };

    const addNewSection = (section: contractAdditionalInfoCategoryType) => {
        try {
            sections.unshift(section);
            setSections([...sections]);
        } catch (error) {
            console.log(
                '🚀 ~ file: customFieldsetting.tsx ~ line 274 ~ addNewSection ~ error',
                error
            );
        }
    };

    const _saveSectionData = (section: contractAdditionalInfoCategoryType, newRecordData: any) => {
        return updateContractCustomSection(
            section.additional_info_fields_category_id,
            newRecordData
        ).then(() => {
            showMessage('success', 'Selected section data has been updated.');
            setTimeout(() => {
                modifySectionData(section.additional_info_fields_category_id, {
                    ...section,
                    ...newRecordData,
                });
            }, 0);
            return true;
        });
    };

    const _deleteSection = (sectionID: number) => {
        return deleteContractCustomSection(sectionID).then(({ data }) => {
            showMessage('success', 'Selected section has been deleted.');
            deleteSection(sectionID);
        });
    };

    const _saveNewSection = () => {
        const payload: any = {
            category: 'Untitled',
        };

        return addNewContractCustomSection(payload).then(({ data }) => {
            showMessage('success', `New section: "Untitled" has been added.`);
            addNewSection(data);
            // update onboarding step to completed
            updateOnboardingStep('add_new_custom_section', HomeCtx.refetchOnboardingList);
        });
    };

    const _dropdownValueHandler = debounce(
        (
            additional_info_fields_category_id: number,
            additional_info_field_id: number,
            additional_info_fields_option_id: number,
            option_value: string
        ) => {
            return updateDropdownOption(
                additional_info_fields_category_id,
                additional_info_field_id,
                additional_info_fields_option_id,
                option_value
            );
        },
        5000
    );

    const DropdownFieldComponent: React.FC<{
        section: contractAdditionalInfoCategoryType;
        field: AdditionalInfoFieldType;
        isEditing: boolean;
    }> = ({ section, field, isEditing = false }) => {
        const [form] = Form.useForm();

        field.contract_additional_info_options_temp = field.contract_additional_info_options.length
            ? field.contract_additional_info_options.map(obj => ({ ...obj }))
            : [];

        useEffect(() => {
            if (isEditing) {
                form.setFieldsValue({
                    dropdown_options: field.contract_additional_info_options,
                });
            } else {
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isEditing]);

        return (
            <Form form={form}>
                <Collapse ghost>
                    <Collapse.Panel
                        header="Click to toggle droprown options"
                        key={`field_${field.additional_info_field_id}`}
                    >
                        <Row>
                            <Col>
                                {!isEditing &&
                                field.contract_additional_info_options.length === 0 ? (
                                    <>
                                        No options added. Click "Edit" to start adding the
                                        Dropdown's options.
                                    </>
                                ) : (
                                    <></>
                                )}

                                {!isEditing ? (
                                    <ul>
                                        {field.contract_additional_info_options.map(opt => (
                                            <li>{opt.option_value}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <></>
                                )}

                                {isEditing && field.contract_additional_info_options.length > 0 ? (
                                    <Col>
                                        <Alert
                                            type="warning"
                                            message="Avoid deleting the option(s) that is used by active transactions."
                                            className="mb-2"
                                            showIcon
                                        />
                                    </Col>
                                ) : (
                                    <></>
                                )}

                                {isEditing && (
                                    <Form.List name="dropdown_options">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(
                                                    ({ key, name, ...restField }, index) => (
                                                        <Col
                                                            span={24}
                                                            className="mb-2"
                                                            style={{ textAlign: 'left' }}
                                                        >
                                                            <>
                                                                <Form.Item
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                        },
                                                                    ]}
                                                                    name={[
                                                                        name,
                                                                        'additional_info_fields_option_id',
                                                                    ]}
                                                                    {...field}
                                                                    noStyle
                                                                    hidden
                                                                >
                                                                    <Input hidden />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                        },
                                                                    ]}
                                                                    name={[name, 'option_value']}
                                                                    {...field}
                                                                    noStyle
                                                                >
                                                                    <Input
                                                                        placeholder="Enter the option"
                                                                        style={{ width: '200px' }}
                                                                        allowClear
                                                                        onChange={evt => {
                                                                            const val =
                                                                                evt.target.value?.trim();
                                                                            if (
                                                                                val &&
                                                                                field
                                                                                    .contract_additional_info_options_temp?.[
                                                                                    name
                                                                                ]
                                                                            ) {
                                                                                field.contract_additional_info_options_temp[
                                                                                    name
                                                                                ].option_value =
                                                                                    val;
                                                                            }
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                                <Popconfirm
                                                                    title="Delete this option?"
                                                                    onConfirm={() => {
                                                                        if (
                                                                            field
                                                                                .contract_additional_info_options_temp?.[
                                                                                name
                                                                            ]
                                                                        ) {
                                                                            field.contract_additional_info_options_temp.splice(
                                                                                name,
                                                                                1
                                                                            );
                                                                            remove(name);
                                                                        }
                                                                    }}
                                                                >
                                                                    <Button
                                                                        className="p-0 ml-1"
                                                                        type="link"
                                                                        icon={<DeleteOutlined />}
                                                                        title="Delete this option?"
                                                                        size="small"
                                                                        danger
                                                                    ></Button>
                                                                </Popconfirm>
                                                            </>
                                                        </Col>
                                                    )
                                                )}
                                                <Col span={24} style={{ textAlign: 'left' }}>
                                                    <Form.Item noStyle>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => {
                                                                const newOption: ContractAdditionalInfoOption =
                                                                    {
                                                                        additional_info_fields_option_id:
                                                                            -1 * Date.now(),
                                                                        additional_info_field_id:
                                                                            field.additional_info_field_id,
                                                                        option_value: 'Untitled',
                                                                        account_id: -1,
                                                                        parent_account_id: -1,
                                                                    };

                                                                add(newOption);

                                                                if (
                                                                    field.contract_additional_info_options_temp
                                                                ) {
                                                                    field.contract_additional_info_options_temp.push(
                                                                        newOption
                                                                    );
                                                                } else {
                                                                    field.contract_additional_info_options_temp =
                                                                        [];
                                                                    field.contract_additional_info_options_temp.push(
                                                                        newOption
                                                                    );
                                                                }
                                                            }}
                                                            block
                                                            icon={<PlusOutlined />}
                                                            style={{ width: '200px' }}
                                                        >
                                                            Add option
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        )}
                                    </Form.List>
                                )}
                            </Col>
                        </Row>
                    </Collapse.Panel>
                </Collapse>
            </Form>
        );
    };

    const DataTypeFieldComponent: React.FC<{
        section: contractAdditionalInfoCategoryType;
        field: AdditionalInfoFieldType;
        isEditing: boolean;
    }> = ({ section, field, isEditing = false }) => {
        useEffect(() => {
            if (isEditing) {
                if (field.contract_additional_info_field.class === 'dropdown') {
                    toggleDropdownElementByCustomFieldID(field.additional_info_field_id, true);
                } else {
                    toggleDropdownElementByCustomFieldID(field.additional_info_field_id, false);
                }
            } else {
                if (field.contract_additional_info_field.class !== 'dropdown') {
                    toggleDropdownElementByCustomFieldID(field.additional_info_field_id, false);
                } else {
                    toggleDropdownElementByCustomFieldID(field.additional_info_field_id, true);
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isEditing]);

        return (
            <Row align="middle">
                <Col span={8}>
                    {!isEditing && (
                        <span style={{ textTransform: 'capitalize' }}>
                            {field.contract_additional_info_field.class}
                        </span>
                    )}
                    {isEditing && (
                        <div>
                            <Select
                                style={{ width: '180px' }}
                                defaultValue={field.contract_additional_info_field.class}
                                onChange={val => {
                                    field.contract_additional_info_field.tempFieldType = val;
                                    // field.contract_additional_info_field.class = val;

                                    if (val === 'dropdown') {
                                        toggleDropdownElementByCustomFieldID(
                                            field.additional_info_field_id,
                                            true
                                        );
                                    } else {
                                        toggleDropdownElementByCustomFieldID(
                                            field.additional_info_field_id,
                                            false
                                        );
                                    }
                                }}
                            >
                                <Select.Option value="text">Text</Select.Option>
                                <Select.Option value="numeric">Numeric</Select.Option>
                                <Select.Option value="money">Money</Select.Option>
                                <Select.Option value="date">Date</Select.Option>
                                <Select.Option value="dropdown">Dropdown</Select.Option>
                            </Select>
                        </div>
                    )}
                </Col>
                <Col span={16}>
                    <div
                        style={{
                            display:
                                field.contract_additional_info_field.class === 'dropdown'
                                    ? 'block'
                                    : 'none',
                        }}
                        id={`dropdown-custom-field-${field.additional_info_field_id}`}
                    >
                        <DropdownFieldComponent
                            section={section}
                            field={field}
                            isEditing={isEditing}
                        />
                    </div>
                </Col>
            </Row>
        );
    };

    const NewFieldForm: React.FC<{
        section: contractAdditionalInfoCategoryType;
        sectionFields: AdditionalInfoFieldType[];
        setSectionFields: React.Dispatch<React.SetStateAction<AdditionalInfoFieldType[]>>;
    }> = ({ section, sectionFields, setSectionFields }) => {
        const [visible, setVisible] = useState(false);
        const [form] = Form.useForm();

        const _saveNewField = (payload: {
            newname: string;
            class: string;

            toggle: number;
            rank: number;
        }) => {
            return addNewContractCustomField(
                section.additional_info_fields_category_id,
                payload
            ).then(({ data }) => {
                sectionFields.unshift(data);
                setSectionFields([...sectionFields]);

                setTimeout(() => {
                    showMessage(
                        'success',
                        `New field: "Untitled" has been added to section: "${section.category}".`
                    );
                }, 0);
            });
        };

        const _onFinish = (values: any) => {
            _saveNewField({
                newname: values.newname,
                class: values.class,
                toggle: 1,
                rank: sectionFields.length ? (sectionFields[0].rank || 0) - 1 : -1,
            }).then(() => {
                setVisible(false);
            });
        };

        useEffect(() => {
            if (!visible) {
                form.resetFields();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [visible]);

        return (
            <Popover
                content={
                    <Form form={form} layout="vertical" onFinish={_onFinish} requiredMark={false}>
                        <Row gutter={20} className="mr-1 ml-1" style={{ width: '400px' }}>
                            <Col span={24}>
                                <Form.Item
                                    label="Field Name"
                                    name="newname"
                                    rules={[{ required: true, message: "Name can't be empty" }]}
                                >
                                    <Input placeholder="Enter the Field Name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Type"
                                    name="class"
                                    rules={[{ required: true, message: "Type can't be empty" }]}
                                >
                                    <Select
                                        placeholder="Select Field Type"
                                        style={{ width: '100%' }}
                                    >
                                        <Select.Option value="text">Text</Select.Option>
                                        <Select.Option value="numeric">Numeric</Select.Option>
                                        <Select.Option value="money">Money</Select.Option>
                                        <Select.Option value="date">Date</Select.Option>
                                        <Select.Option value="dropdown">Dropdown</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Divider />
                            <Col span={24} className="mb-2 text-right">
                                <Button
                                    type="default"
                                    className="mr-3"
                                    onClick={() => setVisible(false)}
                                >
                                    Close
                                </Button>
                                <Button type="primary" onClick={() => form.submit()}>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                }
                visible={visible}
                title={
                    <>
                        Add New Field within Section: <b>{section.category}</b>
                    </>
                }
                trigger="click"
                placement="left"
            >
                <Button size="small" key="3" type="primary" onClick={() => setVisible(true)}>
                    <CodeOutlined />
                    New Field
                </Button>
            </Popover>
        );
    };

    const ExpandedRowComponent: React.FC<{ section: contractAdditionalInfoCategoryType }> = ({
        section,
    }) => {
        const [editableKeysField, setEditableKeysField] = useState<React.Key[]>([]);
        const [sectionFields, setSectionFields] = useState<AdditionalInfoFieldType[]>(
            section.contract_additional_info_preferences || []
        );

        const modifyFieldData = (fieldID: number, newRecordData: any) => {
            try {
                const fieldIndex = sectionFields?.findIndex(
                    t => t.additional_info_field_id === fieldID
                );

                if (fieldIndex >= 0 && sectionFields[fieldIndex]) {
                    sectionFields![fieldIndex] = {
                        ...sectionFields[fieldIndex],
                        ...newRecordData,
                    };

                    if (sectionFields[fieldIndex].contract_additional_info_field.tempFieldType) {
                        sectionFields[fieldIndex].contract_additional_info_field.tempFieldType =
                            undefined;
                    }

                    if (
                        sectionFields[fieldIndex].contract_additional_info_options_temp !==
                        undefined
                    ) {
                        sectionFields[fieldIndex].contract_additional_info_options_temp = undefined;
                    }

                    setSectionFields([...sectionFields]);
                }
            } catch (error) {
                console.log('🚀 ~ file: customFieldsetting.tsx ~ line 195 ~ error', error);
            }
        };

        const _saveFieldData = (field: AdditionalInfoFieldType, newRecordData: any) => {
            return updateContractCustomField(
                section.additional_info_fields_category_id,
                field.additional_info_field_id,
                newRecordData
            ).then(({ data }) => {
                const updatedFieldResult = data;

                modifyFieldData(field.additional_info_field_id, {
                    ...field,
                    ...newRecordData,
                    ...updatedFieldResult,
                });
                showMessage('success', 'Selected field information has been updated.');
                return true;
            });
        };

        const _moveUp = (index: number, taskRecord: AdditionalInfoFieldType) => {
            let tempTaskRecord = taskRecord;
            sectionFields[index] = sectionFields[index - 1];
            sectionFields[index - 1] = tempTaskRecord;
            setSectionFields([...sectionFields]);

            // update the corresponding order positions
            bulkUpdateCustomFieldPositions(section.additional_info_fields_category_id, [
                {
                    additional_info_field_id: sectionFields[index].additional_info_field_id,
                    rank: sectionFields[index - 1].rank || index,
                },
                {
                    additional_info_field_id: sectionFields[index - 1].additional_info_field_id,
                    rank: sectionFields[index].rank || index,
                },
            ]);
        };

        const _moveDown = (index: number, taskRecord: AdditionalInfoFieldType) => {
            let tempTaskRecord = taskRecord;
            sectionFields[index] = sectionFields[index + 1];
            sectionFields[index + 1] = tempTaskRecord;
            setSectionFields([...sectionFields]);

            // update the corresponding order positions
            bulkUpdateCustomFieldPositions(section.additional_info_fields_category_id, [
                {
                    additional_info_field_id: sectionFields[index].additional_info_field_id,
                    rank: sectionFields[index + 1].rank || index,
                },
                {
                    additional_info_field_id: sectionFields[index + 1].additional_info_field_id,
                    rank: sectionFields[index].rank || index,
                },
            ]);
        };

        const deleteField = (section: contractAdditionalInfoCategoryType, fieldID: number) => {
            try {
                const fieldIndex = sectionFields?.findIndex(
                    t => t.additional_info_field_id === fieldID
                );

                if (fieldIndex !== undefined && fieldIndex >= 0) {
                    sectionFields.splice(fieldIndex, 1);
                    setSectionFields([...sectionFields]);
                }
            } catch (error) {
                console.log('🚀 ~ file: customFieldsetting.tsx:666 ~ deleteField ~ error:', error);
            }
        };

        const _deleteSelectedField = (
            section: contractAdditionalInfoCategoryType,
            fieldID: number
        ) => {
            return deleteContractCustomField(
                section.additional_info_fields_category_id,
                fieldID
            ).then(() => {
                showMessage('success', 'Selected field has been deleted.');

                deleteField(section, fieldID);
            });
        };

        const expandedTableColumns: ProColumns<AdditionalInfoFieldType>[] = [
            {
                title: 'Order',
                dataIndex: 'rank',
                key: 'rank',
                width: '68px',
                editable: false,
                render(dom, entity, index, action, schema) {
                    return (
                        <Space>
                            <Button
                                icon={<DownOutlined />}
                                size="small"
                                disabled={index === sectionFields.length - 1}
                                onClick={() => {
                                    _moveDown(index, entity);
                                }}
                            />
                            <Button
                                icon={<UpOutlined />}
                                size="small"
                                disabled={index === 0}
                                onClick={() => {
                                    _moveUp(index, entity);
                                }}
                            />
                        </Space>
                    );
                },
            },
            {
                title: 'On/Off',
                dataIndex: 'toggle',
                key: 'toggle',
                width: '50px',

                editable: false,
                render(dom, entity, index, action, schema) {
                    return (
                        <Switch
                            size="small"
                            checkedChildren={<EyeOutlined />}
                            unCheckedChildren={<EyeInvisibleOutlined />}
                            defaultChecked={entity.toggle ? true : false}
                            onChange={(val: boolean) => {
                                setTimeout(() => {
                                    _saveFieldData(entity, {
                                        toggle: val,
                                    });
                                }, 500);
                            }}
                        />
                    );
                },
            },
            {
                title: 'Field Name',
                dataIndex: 'newname',
                key: 'newname',
                width: '400px',
                fieldProps: (_, config) => {
                    return {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {},
                    };
                },
                formItemProps: () => {
                    return {
                        rules: [
                            {
                                required: true,
                            },
                        ],
                    };
                },
            },
            {
                title: 'Type',
                dataIndex: 'fieldType',
                key: 'fieldType',
                editable: false,
                width: '500px',
                render: (_, record) => {
                    return (
                        <>
                            <DataTypeFieldComponent
                                section={section}
                                field={record}
                                isEditing={
                                    editableKeysField.find(
                                        k => k === record.additional_info_field_id
                                    )
                                        ? true
                                        : false
                                }
                            />
                        </>
                    );
                },
                align: 'left',
                valueType: 'select',
                formItemProps: (_, config) => {
                    return {
                        initialValue: config.entity.contract_additional_info_field.class,
                    };
                },
                fieldProps: (_, config) => {
                    return {
                        style: {
                            width: 110,
                        },
                        allowClear: false,
                        placeholder: 'Select',
                        options: [
                            {
                                label: 'Text',
                                value: 'text',
                            },
                            {
                                label: 'Numeric',
                                value: 'numeric',
                            },
                            {
                                label: 'Money',
                                value: 'money',
                            },
                            {
                                label: 'Date',
                                value: 'date',
                            },
                            {
                                label: 'Dropdown',
                                value: 'dropdown',
                            },
                        ],
                        onChange: (val: string) => {},
                    };
                },
            },
            {
                title: 'Action',
                width: '150px',
                dataIndex: '',
                valueType: 'option',
                fixed: 'right',
                render: (_, record, index, action) => (
                    <span>
                        <Button
                            className="p-0"
                            type="link"
                            icon={<EditFilled />}
                            title="Edit this row"
                            size="small"
                            onClick={() => {
                                setEditableKeysField([
                                    // ...editableKeysField,
                                    record.additional_info_field_id,
                                ]);
                            }}
                        >
                            Edit
                        </Button>
                    </span>
                ),
            },
        ];

        return (
            <div className="expanded-row-with-table">
                <ProTable
                    size="small"
                    rowKey="additional_info_field_id"
                    pagination={{ pageSize: 100 }}
                    showSorterTooltip={false}
                    search={false}
                    columns={expandedTableColumns}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                    scroll={{ x: 1500 }}
                    dataSource={sectionFields}
                    editable={{
                        // editableKeys: section.contract_additional_info_preferences?.map(
                        //     t => t.additional_info_field_id
                        // ),
                        editableKeys: editableKeysField,
                        // actionRender: (_, __, defaultDom) => [defaultDom.delete],
                        type: 'multiple',
                        onSave: async (key, newData: any, oldData) => {
                            if (newData?.contract_additional_info_field?.tempFieldType) {
                                newData.class =
                                    newData?.contract_additional_info_field?.tempFieldType;
                            } else {
                                newData.class = newData?.contract_additional_info_field?.class;
                            }
                            if (newData?.contract_additional_info_options_temp) {
                                newData.contract_additional_info_options =
                                    newData.contract_additional_info_options_temp;
                            }
                            return _saveFieldData(oldData, newData).then(() => {
                                setEditableKeysField([
                                    ...editableKeysField.filter(keyItem => keyItem !== key),
                                ]);
                                return true;
                            });
                        },
                        onDelete: (_, row) => {
                            return _deleteSelectedField(section, row.additional_info_field_id);
                        },
                        onCancel: (key, record, originRow, newLineConfig) => {
                            setEditableKeysField([
                                ...editableKeysField.filter(keyItem => keyItem !== key),
                            ]);
                            return Promise.resolve(true);
                        },
                        saveText: (
                            <>
                                <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                    Save
                                </Button>
                            </>
                        ),
                        deleteText: (
                            <>
                                <Button
                                    className="p-0"
                                    type="link"
                                    icon={<DeleteFilled />}
                                    title="Delete this field?"
                                    size="small"
                                    danger
                                ></Button>
                            </>
                        ),
                        deletePopconfirmMessage: 'Delete this field?',
                        cancelText: (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0 text-grey"
                                    type="text"
                                    title="Cancel and back"
                                    icon={<CloseOutlined />}
                                ></Button>
                            </>
                        ),
                    }}
                    toolBarRender={() => [
                        <NewFieldForm
                            section={section}
                            sectionFields={sectionFields}
                            setSectionFields={setSectionFields}
                        />,
                    ]}
                />
            </div>
        );
    };

    useEffect(() => {
        _fetchCustomSections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={18} style={{ textAlign: 'left' }} className="text-help">
                <InfoCircleOutlined /> Tip: Add custom sections, and custom fields to those section.
                Track all this info on <b>"Contract Information"</b> tab on your
                transaction/contract detail.
            </Col>
            <Col span={6} style={{ textAlign: 'right' }}>
                <Button key="3" type="primary" onClick={() => _saveNewSection()}>
                    <PlusOutlined />
                    New Section
                </Button>
            </Col>
            <Col span={24} className="mt-3">
                <ProTable
                    rowKey="additional_info_fields_category_id"
                    showSorterTooltip={false}
                    search={false}
                    tableLayout="fixed"
                    loading={isFetchingSections}
                    columns={columns}
                    expandable={{
                        expandedRowRender: (record: contractAdditionalInfoCategoryType) => {
                            return <ExpandedRowComponent section={record} />;
                        },
                        expandedRowKeys: expandedRecordIDs,
                        onExpand: (expanded, record: contractAdditionalInfoCategoryType) => {
                            if (expanded) {
                                setExpandedRecordIDs([record.additional_info_fields_category_id]);
                            } else {
                                setExpandedRecordIDs([]);
                            }
                        },
                    }}
                    editable={{
                        actionRender: (_, __, defaultDom) => [defaultDom.save, defaultDom.cancel],
                        type: 'multiple',
                        onSave: async (_, newData: contractAdditionalInfoCategoryType, oldData) => {
                            return _saveSectionData(oldData, {
                                category: newData.category,
                            });
                        },
                        onDelete: (key, row) => {
                            return _deleteSection(row.additional_info_fields_category_id);
                        },
                        saveText: (
                            <>
                                <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                    Save
                                </Button>
                            </>
                        ),
                        cancelText: (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0 text-grey"
                                    type="text"
                                    title="Cancel and back"
                                    icon={<CloseOutlined />}
                                ></Button>
                            </>
                        ),
                    }}
                    dataSource={sections}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                />
            </Col>
        </Row>
    );
};
