import mixpanel from 'mixpanel-browser';

import { AccountDataType } from 'types/account';
import { getParentAccountID } from './commonUtil';

import { isDemoENV, isProductionENV } from './envUtil';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || 'INVALID_TOKEN');

// let env_check = isDemoENV() || isProductionENV();
let env_check = true;

const setPeopleProfile = (account: AccountDataType) => {
    const parent_account_id = getParentAccountID(account);

    mixpanel.people.set({
        $name: account.fullname,
        $email: account.email,

        PARENT_ACCOUNT_ID: parent_account_id,
        IS_PARENT_ACCOUNT: account.account_id === parent_account_id,

        USER_TYPE_ID: account.user_type_id,
        ACCOUNT_TYPE: account.account_type,
        ACCOUNT_ROLE_ID: account.accountrole_id,
    });
};

let actions = {
    identify: (accountID: number) => {
        if (env_check) mixpanel.identify(accountID.toString());
    },
    alias: (accountID: number) => {
        if (env_check) mixpanel.alias(accountID.toString());
    },
    track: (name: string, props: any) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (account: AccountDataType) => {
            if (env_check) {
                setPeopleProfile(account);
            }
        },
    },

    setLoggedUserData: (account: AccountDataType) => {
        if (env_check) {
            mixpanel.identify(account.account_id.toString());
            // mixpanel.people.set({ ...account, parent_account_id: getParentAccountID(account) });

            mixpanel.people.set_once({
                ACCOUNT_ID: account.account_id,
            });

            setPeopleProfile(account);

            mixpanel.set_group('PARENT_ACCOUNT_ID', getParentAccountID(account));
        }
    },
};

export let Mixpanel = actions;
