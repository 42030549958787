// Core React libraries import
import { useContext, useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Typography,
    Button,
    Badge,
    Divider,
    Modal,
    Form,
    Input,
    Alert,
    Checkbox,
    Radio,
    Space,
} from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import { useReactToPrint } from 'react-to-print';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
import {
    GreenSheetType,
    TransactionContactItemType,
    TransactionDetailTabProps,
    TransactionDetailType,
} from 'types/transaction';
import {
    getTransactionStatusCategory,
    isRentalProperty,
    transformContractCustomFields,
} from 'utils/webAppUtil';
import { TransactionSalesType } from 'components/select/selectSaleType';
import { EditOutlined, FlagTwoTone, PrinterOutlined, StopOutlined } from '@ant-design/icons';
import { CommissionInfo, EZCurrencyFormat, EZDateFormat, EZOverlaySpinner } from 'components';
import {
    getTransactionDetail,
    getGreensheet,
    updateGreensheet,
} from 'utils/ez_api/transactionAPIs';
import { showMessage, showNotification } from 'utils/notificationUtil';
import React from 'react';
import { EZSelectContact } from 'components/select/selectTransactionContact';
import { DefaultOptionType, SelectProps } from 'antd/es/select';
import { getTransactionContacts } from 'utils/ez_api/transactionContactAPIs';
import { HomeContext } from 'context/EZContext';
// EZ web-app components import

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;

const colLabelSpan = 10;
const colValueSpan = 14;
const rowFormViewGutter: [Gutter, Gutter] = [12, 4];

const marginBottomHeaderTitle = 24;

let hasContactAPIBeenCalled = false;

const GreenSheetEditModalContent: React.FC<{
    additionalContacts: TransactionContactItemType[] | [];
    onSelectTitlecoContact: (value: any, opt: any) => void;
    onSelectLendercoContact: (value: any, opt: any) => void;
}> = ({ additionalContacts, onSelectTitlecoContact, onSelectLendercoContact }) => {
    return (
        <Row>
            <Col span={24}>
                <Row gutter={[64, 0]}>
                    <Col span={24}>
                        <Alert
                            message={
                                <>
                                    To save the changes, hit the <b>'Save Setting'</b> button on the
                                    footter section of this pop-up.
                                </>
                            }
                            type="info"
                            showIcon
                        />
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">
                            <b>
                                <Form.Item
                                    name="titleco_contact_id"
                                    style={{ margin: 0, width: '350px', textAlign: 'left' }}
                                >
                                    <EZSelectContact
                                        onSelect={onSelectTitlecoContact}
                                        onClear={() => onSelectTitlecoContact(null, null)}
                                        placeholder="Title Company"
                                        allowClear
                                        options={additionalContacts?.map(additionalContact => ({
                                            label: `${additionalContact.contact_role?.role} (${additionalContact.contactname})`,
                                            value: additionalContact.contact_id,
                                        }))}
                                        fieldNames={undefined}
                                    />
                                </Form.Item>
                            </b>
                        </Divider>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[64, 0]}>
                            <Col span={24}>
                                <Form.Item label="Company" name="titleco_company">
                                    <Input placeholder="Enter the company name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Closing Officer" name="titleco_closing_officer">
                                    <Input placeholder="Enter the closing officer name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Address" name="titleco_address">
                                    <Input placeholder="Enter the title co's company address" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Fax" name="titleco_fax">
                                    <Input placeholder="Enter the title co's fax" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[64, 0]}>
                            <Col span={24}>
                                <Form.Item label="E-mail" name="titleco_email">
                                    <Input placeholder="Enter the title co's email" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Office #" name="titleco_office_num">
                                    <Input placeholder="Enter the title co's office #" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Mobile" name="titleco_mobile">
                                    <Input placeholder="Enter the title co's mobile" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[64, 0]} align="middle">
                    <Col span={24}>
                        <Divider orientation="left">
                            <b>
                                <Form.Item
                                    name="lenderco_contact_id"
                                    style={{ margin: 0, width: '350px', textAlign: 'left' }}
                                >
                                    <EZSelectContact
                                        options={additionalContacts?.map(additionalContact => ({
                                            label: `${additionalContact.contact_role?.role} (${additionalContact.contactname})`,
                                            value: additionalContact.contact_id,
                                        }))}
                                        onSelect={onSelectLendercoContact}
                                        onClear={() => onSelectLendercoContact(null, null)}
                                        placeholder="Lender Company"
                                        allowClear
                                        fieldNames={undefined}
                                    />
                                </Form.Item>
                            </b>
                        </Divider>
                    </Col>

                    <Col span={12}>
                        <Row gutter={[64, 0]}>
                            <Col span={24}>
                                <Form.Item label="Cash Deal" name="lenderco_cash_deal">
                                    <Radio.Group>
                                        <Radio value={1}>Yes</Radio>
                                        <Radio value={0}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Company" name="lenderco_company">
                                    <Input placeholder="Enter the company name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Loan Officer" name="lenderco_loan_officer">
                                    <Input placeholder="Enter the loan officer name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Address" name="lenderco_address">
                                    <Input placeholder="Enter the lender co's company address" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Fax" name="lenderco_fax">
                                    <Input placeholder="Enter the lender co's fax" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[64, 0]}>
                            <Col span={24}>
                                <Form.Item label="E-mail" name="lenderco_email">
                                    <Input placeholder="Enter the lender co's email" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Office #" name="lenderco_office_num">
                                    <Input placeholder="Enter the lender co's office #" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Mobile" name="lenderco_mobile">
                                    <Input placeholder="Enter the lender co's mobile" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[64, 0]}>
                    <Col span={24}>
                        <Divider orientation="left">
                            <b>Additional Info (Custom Sections and Fields)</b>
                        </Divider>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[64, 0]}>
                            <Col span={24}>
                                <Form.Item
                                    label=""
                                    name="show_additional_info"
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Checkbox>Show additional info</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Row gutter={[64, 0]}>
                    <Col span={24}>
                        <Divider orientation="left">
                            <b>Notes</b>
                        </Divider>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[64, 0]}>
                            <Col span={24}>
                                <Form.Item label="Notes" name="notes">
                                    <Input.TextArea
                                        style={{ width: '100%' }}
                                        rows={4}
                                        placeholder="Please provide info on commission splits, referrals and any other deductions here."
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export const SubTab_Greensheet: React.FC<TransactionDetailTabProps> = ({
    contractID,
    isTransactionDeleted,
    manualReloadCount,
}) => {
    const [form] = Form.useForm();
    const componentRef = useRef<HTMLDivElement>(null);

    const [isLoadingTransactionDetail, setIsLoadingTransactionDetail] = useState<boolean>(false);
    const [isSavingGreensheetSetting, setIsSavingGreensheetSetting] = useState<boolean>(false);

    const [transactionData, setTransactionData] = useState<TransactionDetailType>();
    const [greensheet, setGreensheet] = useState<GreenSheetType>();
    const [isRental, setIsRental] = useState<boolean>(false);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [contactOptions, setContactOptions] = useState<TransactionContactItemType[]>([]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Green Sheet - ${transactionData?.address}`,
    });

    const _reloadTransactionDetail = async () => {
        setIsLoadingTransactionDetail(true);

        Promise.all([
            getTransactionDetail(contractID, 'basic_info').then(({ data }) => data),
            getTransactionDetail(contractID, 'contract_info').then(({ data }) => data),
            getGreensheet(contractID).then(({ data }) => data),
        ])
            .then(values => {
                const transactionData: TransactionDetailType = { ...values[0], ...values[1] };

                transformContractCustomFields(transactionData);

                setGreensheet(values[2]);
                setTransactionData(transactionData);
                setIsRental(isRentalProperty(transactionData.status_id));
            })
            .catch(() => {
                showNotification('error', 'Error happened while reloading the transaction data.');
            })
            .finally(() => {
                setIsLoadingTransactionDetail(false);
            });
    };

    const _reloadSubTabData = () => {
        _reloadTransactionDetail();
    };

    const _onFinish = (values: any) => {
        const payload = {
            ...values,
            show_additional_info: values.show_additional_info ? true : false,
        };

        setIsSavingGreensheetSetting(true);
        updateGreensheet(contractID, payload)
            .then(() => {
                showMessage('success', 'New setting has been saved.');
                setIsEditModalOpen(false);
                _reloadSubTabData();
            })
            .finally(() => {
                setIsSavingGreensheetSetting(false);
            });
    };

    const _onSelectTitlecoContact = (contactID: any) => {
        const additionalContact = contactOptions.find(c => c.contact_id === contactID);
        const _formValue = form.getFieldsValue();
        if (additionalContact) {
            form.setFieldsValue({
                ..._formValue,
                titleco_contact_id: additionalContact.contact_id,
                titleco_company: additionalContact.company,
                titleco_address: additionalContact.address,
                titleco_fax: additionalContact.fax,
                titleco_email: additionalContact.email,
                titleco_mobile: additionalContact.telephone,
            });
        } else {
            form.setFieldsValue({
                ..._formValue,
                titleco_contact_id: '',
                titleco_company: '',
                titleco_address: '',
                titleco_fax: '',
                titleco_email: '',
                titleco_mobile: '',
            });
        }
    };

    const _onSelectLendercoContact = (contactID: any) => {
        const additionalContact = contactOptions.find(c => c.contact_id === contactID);
        const _formValue = form.getFieldsValue();
        if (additionalContact) {
            form.setFieldsValue({
                ..._formValue,
                lenderco_contact_id: additionalContact.contact_id,
                lenderco_company: additionalContact.company,
                lenderco_address: additionalContact.address,
                lenderco_fax: additionalContact.fax,
                lenderco_email: additionalContact.email,
                lenderco_mobile: additionalContact.telephone,
            });
        } else {
            form.setFieldsValue({
                ..._formValue,
                lenderco_contact_id: '',
                lenderco_company: '',
                lenderco_address: '',
                lenderco_fax: '',
                lenderco_email: '',
                lenderco_mobile: '',
            });
        }
    };

    const { triggerRefetchDataList, setTriggerRefetchDataList } = useContext(HomeContext);
    const hasTriggerRefetchData = triggerRefetchDataList.includes('additional_contact');

    const _fetchTransactionContact = () => {
        getTransactionContacts(contractID).then(({ data }) => {
            const _transactionContact = (data as TransactionContactItemType[]) || [];
            setContactOptions(_transactionContact);
            if (hasTriggerRefetchData) {
                const newTriggerRefetchDataList = triggerRefetchDataList.filter(
                    key => key !== 'additional_contact'
                );
                setTriggerRefetchDataList(newTriggerRefetchDataList);
            }
        });
    };

    useEffect(() => {
        if (isEditModalOpen) {
            _fetchTransactionContact();
            form.setFieldsValue({
                ...greensheet,
                show_additional_info: greensheet?.show_additional_info !== false ? true : false,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditModalOpen]);

    useEffect(() => {
        _reloadSubTabData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez_detail_greensheet">
            {!isLoadingTransactionDetail && transactionData ? (
                <>
                    <Row gutter={24} className="mt-2 mb-3">
                        <Col span={24} className="text-right">
                            <Modal
                                title="Edit Green Sheet Setting"
                                visible={isEditModalOpen}
                                onOk={() => form.submit()}
                                onCancel={() => setIsEditModalOpen(false)}
                                closable
                                maskClosable
                                cancelText="Close"
                                okText={isSavingGreensheetSetting ? 'Saving...' : 'Save Setting'}
                                okButtonProps={{
                                    disabled: isSavingGreensheetSetting,
                                }}
                                width={1000}
                                style={{ top: '40px' }}
                            >
                                <Form form={form} layout="vertical" onFinish={_onFinish}>
                                    <GreenSheetEditModalContent
                                        additionalContacts={contactOptions}
                                        onSelectTitlecoContact={_onSelectTitlecoContact}
                                        onSelectLendercoContact={_onSelectLendercoContact}
                                    />
                                </Form>
                            </Modal>
                            {!isTransactionDeleted && (
                                <Button
                                    className="ez-action-btn"
                                    icon={<EditOutlined />}
                                    onClick={() => setIsEditModalOpen(true)}
                                >
                                    Edit Green Sheet Setting
                                </Button>
                            )}
                            <Divider type="vertical" />
                            <Button type="primary" icon={<PrinterOutlined />} onClick={handlePrint}>
                                Print
                            </Button>
                        </Col>
                    </Row>
                    <Row ref={componentRef} gutter={24} className="mt-2 greensheet-content">
                        <Col
                            span={24}
                            style={{ marginBottom: marginBottomHeaderTitle }}
                            className="greensheet-div-header mt-4"
                        >
                            <Typography.Title level={4}>Transaction Summary Sheet</Typography.Title>
                            <Text>Transaction Email Address: </Text>
                            <Text code>
                                {transactionData.account_id}_{transactionData.contract_id}
                                @ezcoordinator.com
                            </Text>
                        </Col>
                        <Col
                            className="pl-5 pr-5 greensheet-div-summary"
                            span={12}
                            sm={24}
                            md={24}
                            lg={12}
                            xl={12}
                        >
                            <Row gutter={rowFormViewGutter}>
                                <Col span={colLabelSpan}>
                                    <Text strong>Address:</Text>
                                </Col>
                                <Col span={colValueSpan}>
                                    <div className="">
                                        {transactionData.address || (
                                            <Text type="secondary" italic>
                                                Address is empty
                                            </Text>
                                        )}
                                    </div>
                                    <div className="">
                                        {transactionData.city || (
                                            <Text type="secondary" italic>
                                                City is empty
                                            </Text>
                                        )}
                                        ,{' '}
                                        {transactionData.state || (
                                            <Text type="secondary" italic>
                                                State is empty
                                            </Text>
                                        )}
                                        ,{' '}
                                        {transactionData.zip || (
                                            <Text type="secondary" italic>
                                                ZIP is empty
                                            </Text>
                                        )}
                                    </div>
                                </Col>
                                <Col span={colLabelSpan}>
                                    <Text strong>Status:</Text>
                                </Col>
                                <Col span={colValueSpan}>
                                    {getTransactionStatusCategory(transactionData.status_id)}
                                    <Badge className="mr-1 ml-2" color={'black'} />
                                    <Button
                                        className={`ez-btn-contract-status ez-tr-status-${transactionData.status_id}`}
                                        type="text"
                                        shape="round"
                                        style={{
                                            backgroundColor: `${transactionData.contract_status?.color}`,
                                            color: 'white',
                                        }}
                                        size="small"
                                    >{`${transactionData.contract_status?.status}`}</Button>
                                </Col>
                                <Col span={colLabelSpan}>
                                    <Text strong>MLS:</Text>
                                </Col>
                                <Col span={colValueSpan}>{transactionData.mis || '-'}</Col>
                                <Col span={colLabelSpan}>
                                    <Text strong>ESCROW:</Text>
                                </Col>
                                <Col span={colValueSpan}>{transactionData.escrow || '-'}</Col>
                                <Col span={colLabelSpan}>
                                    <Text strong>Type:</Text>
                                </Col>
                                <Col span={colValueSpan}>
                                    {transactionData.contract_property_type?.type_name || '-'}
                                </Col>
                                <Col span={colLabelSpan}>
                                    <Text strong>Sales Type:</Text>
                                </Col>
                                <Col span={colValueSpan}>
                                    <TransactionSalesType
                                        contract_id={transactionData.contract_id}
                                        sale_types_id={transactionData.sale_types_id}
                                    />
                                </Col>
                                <Col span={colLabelSpan}>
                                    <Text strong>Flag:</Text>
                                </Col>
                                <Col span={colValueSpan}>
                                    {transactionData.flag_id !== 1 ? (
                                        <FlagTwoTone
                                            twoToneColor={transactionData.contract_flag?.flag_color}
                                        />
                                    ) : (
                                        <StopOutlined />
                                    )}
                                    <span className="ml-2">
                                        {transactionData.contract_flag?.flag}
                                    </span>
                                </Col>
                                <Col span={colLabelSpan} className="ez-form-label">
                                    Year Built:
                                </Col>
                                <Col span={colValueSpan}>
                                    {transactionData?.contract_date?.year_built || '-'}
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            className="pl-2 pr-5 greensheet-div-summary"
                            span={12}
                            sm={24}
                            md={24}
                            lg={12}
                            xl={12}
                        >
                            <Row gutter={rowFormViewGutter}>
                                {!isRental && (
                                    <>
                                        <Col span={colLabelSpan} className="ez-form-label">
                                            List Price:
                                        </Col>
                                        <Col span={colValueSpan}>
                                            <EZCurrencyFormat
                                                value={transactionData?.contract_price?.listprice}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                displayType={'text'}
                                            />
                                        </Col>
                                        <Col span={colLabelSpan} className="ez-form-label">
                                            Sales Price:
                                        </Col>
                                        <Col span={colValueSpan}>
                                            <EZCurrencyFormat
                                                value={transactionData?.contract_price?.salesprice}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                displayType={'text'}
                                            />
                                        </Col>
                                        <Col span={colLabelSpan} className="ez-form-label">
                                            Closing Cost Credit:
                                        </Col>
                                        <Col span={colValueSpan}>
                                            <EZCurrencyFormat
                                                value={transactionData?.contract_price?.cccredit}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                displayType={'text'}
                                            />
                                        </Col>
                                        <Col span={colLabelSpan} className="ez-form-label">
                                            Deposit:
                                        </Col>
                                        <Col span={colValueSpan}>
                                            <EZCurrencyFormat
                                                value={transactionData?.contract_price?.deposits}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                displayType={'text'}
                                            />
                                        </Col>
                                    </>
                                )}
                                {isRental && (
                                    <>
                                        <Col span={colLabelSpan} className="ez-form-label">
                                            Marketed Price:
                                        </Col>
                                        <Col span={colValueSpan}>
                                            <EZCurrencyFormat
                                                value={
                                                    transactionData?.contract_price?.marketedprice
                                                }
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                displayType={'text'}
                                            />
                                        </Col>
                                        <Col span={colLabelSpan} className="ez-form-label">
                                            Rental Price:
                                        </Col>
                                        <Col span={colValueSpan}>
                                            <EZCurrencyFormat
                                                value={transactionData?.contract_price?.rentalprice}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                displayType={'text'}
                                            />
                                        </Col>
                                        <Col span={colLabelSpan} className="ez-form-label">
                                            Credits:
                                        </Col>
                                        <Col span={colValueSpan}>
                                            <EZCurrencyFormat
                                                value={transactionData?.contract_price?.credits}
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                displayType={'text'}
                                            />
                                        </Col>
                                        <Divider />
                                        <Col span={colLabelSpan} className="ez-form-label">
                                            Lease Term(s):
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {transactionData?.contract_date?.lease_term || '-'}
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Col>
                        <Divider />
                        <Col className="pl-5 pr-5" span={24} sm={24} md={24} lg={24} xl={24}>
                            <Row gutter={rowFormViewGutter}>
                                {!isRental && (
                                    <>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Contract Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.contract_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Contract Effective Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.effective_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Close Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.close_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Listing Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.listing_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Listing Live Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.listing_live_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Listing Expiration Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.expiration_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Inspection Contingency Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.inspection_contingency_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Appraisal Contingency Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.appraisal_contingency_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Financing Contingency Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.financing_contingency_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                                {isRental && (
                                    <>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Application Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.application_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Lease Signed Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.lease_signed_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Lease Start:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.lease_start_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Lease End:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.lease_end_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="" span={12} sm={12} md={12} lg={12} xl={12}>
                                            <Row>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    Move In Date:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <EZDateFormat
                                                        value={
                                                            transactionData?.contract_date
                                                                ?.move_in_date
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )}
                                {transactionData?.contract_date_additionals?.length ? (
                                    <>
                                        {transactionData.contract_date_additionals.map(
                                            (dateAdditional, index) => (
                                                <Col
                                                    className=""
                                                    span={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Row key={`contract_date_additionals_${index}`}>
                                                        <Col
                                                            span={colLabelSpan}
                                                            className="ez-form-label"
                                                        >
                                                            <span
                                                                style={{
                                                                    textTransform: 'capitalize',
                                                                }}
                                                            >
                                                                {dateAdditional.date_name}
                                                            </span>
                                                        </Col>
                                                        <Col span={colValueSpan}>
                                                            <EZDateFormat
                                                                value={dateAdditional?.val_date}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        )}
                                    </>
                                ) : (
                                    ''
                                )}
                            </Row>
                        </Col>
                        <Col
                            span={24}
                            style={{ marginBottom: marginBottomHeaderTitle }}
                            className="greensheet-div-header mt-5"
                        >
                            <Typography.Title level={4}>Transaction Info</Typography.Title>
                        </Col>
                        {!isRental ? (
                            <Col span={24}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '140px' }}></th>
                                            <th style={{ width: '180px' }}>Fullname</th>
                                            <th style={{ width: '280px' }}>
                                                Phone - Email - Address
                                            </th>
                                            <th style={{ width: '220px' }}>Company</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionData?.contract_buyers?.length ? (
                                            transactionData.contract_buyers.map((record, index) => (
                                                <tr>
                                                    {index === 0 ? (
                                                        <td
                                                            rowSpan={
                                                                transactionData?.contract_buyers
                                                                    ?.length || undefined
                                                            }
                                                        >
                                                            <b>Buyer</b>
                                                        </td>
                                                    ) : (
                                                        ''
                                                    )}
                                                    <td>{record.buyer_name || '-'}</td>
                                                    <td>
                                                        <Space direction="vertical">
                                                            <span>{record.telephone || '-'}</span>
                                                            <span>{record.email || '-'}</span>
                                                            <span>&nbsp;</span>
                                                            <span>
                                                                {[
                                                                    record.address || '-',
                                                                    record.city,
                                                                    record.state,
                                                                ]
                                                                    .filter(item => item)
                                                                    .join(', ')}
                                                            </span>
                                                        </Space>
                                                    </td>
                                                    <td>{record.company || '-'}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td>Buyer</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                        {transactionData?.contract_sellers?.length ? (
                                            transactionData.contract_sellers.map(
                                                (record, index) => (
                                                    <tr>
                                                        {index === 0 ? (
                                                            <td
                                                                rowSpan={
                                                                    transactionData
                                                                        ?.contract_sellers
                                                                        ?.length || undefined
                                                                }
                                                            >
                                                                <b>Seller</b>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <td>{record.seller_name || '-'}</td>
                                                        <td>
                                                            <Space direction="vertical">
                                                                <span>
                                                                    {record.telephone || '-'}
                                                                </span>
                                                                <span>{record.email || '-'}</span>
                                                                <span>&nbsp;</span>
                                                                <span>
                                                                    {[
                                                                        record.address || '-',
                                                                        record.city,
                                                                        record.state,
                                                                    ]
                                                                        .filter(item => item)
                                                                        .join(', ')}
                                                                </span>
                                                            </Space>
                                                        </td>
                                                        <td>{record.company || '-'}</td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td>Seller</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <table className="table mt-5 mb-3">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '140px' }}></th>
                                            <th style={{ width: '180px' }}>Fullname</th>
                                            <th style={{ width: '160px' }}>Commission</th>
                                            <th style={{ width: '280px' }}>
                                                Phone - Email - Address
                                            </th>
                                            <th style={{ width: '220px' }}>Company</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionData?.contract_buyer_agents?.length ? (
                                            transactionData.contract_buyer_agents.map(
                                                (record, index) => (
                                                    <tr>
                                                        {index === 0 ? (
                                                            <td
                                                                rowSpan={
                                                                    transactionData
                                                                        ?.contract_buyer_agents
                                                                        ?.length || undefined
                                                                }
                                                            >
                                                                <b>Buyer's agent(s)</b>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <td>{record.agent_name || '-'}</td>
                                                        <td>
                                                            <CommissionInfo
                                                                commission_base={
                                                                    transactionData?.contract_price
                                                                        ?.salesprice
                                                                }
                                                                commission_rate={
                                                                    record.commission_rate
                                                                }
                                                                commission_amount={
                                                                    record.commission_amount
                                                                }
                                                                ifEmptyText={'-'}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Space direction="vertical">
                                                                <span>
                                                                    {record.telephone || '-'}
                                                                </span>
                                                                <span>{record.email || '-'}</span>
                                                                <span>&nbsp;</span>
                                                                <span>
                                                                    {[
                                                                        record.address || '-',
                                                                        record.city,
                                                                        record.state,
                                                                    ]
                                                                        .filter(item => item)
                                                                        .join(', ')}
                                                                </span>
                                                            </Space>
                                                        </td>
                                                        <td>{record.company || '-'}</td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td>Buyer's agent(s)</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                        {transactionData?.contract_seller_agents?.length ? (
                                            transactionData.contract_seller_agents.map(
                                                (record, index) => (
                                                    <tr>
                                                        {index === 0 ? (
                                                            <td
                                                                rowSpan={
                                                                    transactionData
                                                                        ?.contract_seller_agents
                                                                        ?.length || undefined
                                                                }
                                                            >
                                                                <b>Seller's Agents</b>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <td>{record.broker_name || '-'}</td>
                                                        <td>
                                                            <CommissionInfo
                                                                commission_base={
                                                                    transactionData?.contract_price
                                                                        ?.salesprice
                                                                }
                                                                commission_rate={
                                                                    record.commission_rate
                                                                }
                                                                commission_amount={
                                                                    record.commission_amount
                                                                }
                                                                ifEmptyText={'-'}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Space direction="vertical">
                                                                <span>
                                                                    {record.telephone || '-'}
                                                                </span>
                                                                <span>{record.email || '-'}</span>
                                                                <span>&nbsp;</span>
                                                                <span>
                                                                    {[
                                                                        record.address || '-',
                                                                        record.city,
                                                                        record.state,
                                                                    ]
                                                                        .filter(item => item)
                                                                        .join(', ')}
                                                                </span>
                                                            </Space>
                                                        </td>
                                                        <td>{record.company || '-'}</td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td>Seller's Agents</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Col>
                        ) : (
                            <Col span={24}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '140px' }}></th>
                                            <th style={{ width: '180px' }}>Fullname</th>
                                            <th style={{ width: '280px' }}>
                                                Phone - Email - Address
                                            </th>
                                            <th style={{ width: '180px' }}>Company</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionData?.contract_tenants?.length ? (
                                            transactionData.contract_tenants.map(
                                                (record, index) => (
                                                    <tr>
                                                        {index === 0 ? (
                                                            <td
                                                                rowSpan={
                                                                    transactionData
                                                                        ?.contract_tenants
                                                                        ?.length || undefined
                                                                }
                                                            >
                                                                <b>Tenant</b>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <td>{record.tenant_name || '-'}</td>
                                                        <td>
                                                            <Space direction="vertical">
                                                                <span>
                                                                    {record.telephone || '-'}
                                                                </span>
                                                                <span>{record.email || '-'}</span>
                                                                <span>&nbsp;</span>
                                                                <span>
                                                                    {[
                                                                        record.address || '-',
                                                                        record.city,
                                                                        record.state,
                                                                    ]
                                                                        .filter(item => item)
                                                                        .join(', ')}
                                                                </span>
                                                            </Space>
                                                        </td>
                                                        <td>{record.company || '-'}</td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td>Tenant</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                        {transactionData?.contract_landlords?.length ? (
                                            transactionData.contract_landlords.map(
                                                (record, index) => (
                                                    <tr>
                                                        {index === 0 ? (
                                                            <td
                                                                rowSpan={
                                                                    transactionData
                                                                        ?.contract_landlords
                                                                        ?.length || undefined
                                                                }
                                                            >
                                                                <b>Landlord</b>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <td>{record.landlord_name || '-'}</td>
                                                        <td>
                                                            <Space direction="vertical">
                                                                <span>
                                                                    {record.telephone || '-'}
                                                                </span>
                                                                <span>{record.email || '-'}</span>
                                                                <span>&nbsp;</span>
                                                                <span>
                                                                    {[
                                                                        record.address || '-',
                                                                        record.city,
                                                                        record.state,
                                                                    ]
                                                                        .filter(item => item)
                                                                        .join(', ')}
                                                                </span>
                                                            </Space>
                                                        </td>
                                                        <td>{record.company || '-'}</td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td>Landlord</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <table className="table mt-5 mb-3">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '140px' }}></th>
                                            <th style={{ width: '180px' }}>Fullname</th>
                                            <th style={{ width: '160px' }}>Commission</th>
                                            <th style={{ width: '280px' }}>
                                                Phone - Email - Address
                                            </th>
                                            <th style={{ width: '220px' }}>Company</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionData?.contract_referral_sources?.length ? (
                                            transactionData.contract_referral_sources.map(
                                                (record, index) => (
                                                    <tr>
                                                        {index === 0 ? (
                                                            <td
                                                                rowSpan={
                                                                    transactionData
                                                                        ?.contract_referral_sources
                                                                        ?.length || undefined
                                                                }
                                                            >
                                                                <b>Referral Source(s)</b>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <td>
                                                            {record.referral_source_name || '-'}
                                                        </td>
                                                        <td>
                                                            <CommissionInfo
                                                                commission_base={
                                                                    transactionData?.contract_price
                                                                        ?.marketedprice
                                                                }
                                                                commission_rate={
                                                                    record.commission_rate
                                                                }
                                                                commission_amount={
                                                                    record.commission_amount
                                                                }
                                                                ifEmptyText={'-'}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Space direction="vertical">
                                                                <span>
                                                                    {record.telephone || '-'}
                                                                </span>
                                                                <span>{record.email || '-'}</span>
                                                                <span>&nbsp;</span>
                                                                <span>
                                                                    {[
                                                                        record.address || '-',
                                                                        record.city,
                                                                        record.state,
                                                                    ]
                                                                        .filter(item => item)
                                                                        .join(', ')}
                                                                </span>
                                                            </Space>
                                                        </td>
                                                        <td>{record.company || '-'}</td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td>Referral Source(s)</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                        {transactionData?.contract_leasing_agents?.length ? (
                                            transactionData.contract_leasing_agents.map(
                                                (record, index) => (
                                                    <tr>
                                                        {index === 0 ? (
                                                            <td
                                                                rowSpan={
                                                                    transactionData
                                                                        ?.contract_leasing_agents
                                                                        ?.length || undefined
                                                                }
                                                            >
                                                                <b>Leasing Agents</b>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <td>{record.leasing_agent_name || '-'}</td>
                                                        <td>
                                                            <CommissionInfo
                                                                commission_base={
                                                                    transactionData?.contract_price
                                                                        ?.marketedprice
                                                                }
                                                                commission_rate={
                                                                    record.commission_rate
                                                                }
                                                                commission_amount={
                                                                    record.commission_amount
                                                                }
                                                                ifEmptyText={'-'}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Space direction="vertical">
                                                                <span>
                                                                    {record.telephone || '-'}
                                                                </span>
                                                                <span>{record.email || '-'}</span>
                                                                <span>&nbsp;</span>
                                                                <span>
                                                                    {[
                                                                        record.address || '-',
                                                                        record.city,
                                                                        record.state,
                                                                    ]
                                                                        .filter(item => item)
                                                                        .join(', ')}
                                                                </span>
                                                            </Space>
                                                        </td>
                                                        <td>{record.company || '-'}</td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td>Leasing Agents</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                                <td>-</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </Col>
                        )}
                        {!isRental && (
                            <>
                                <Col
                                    span={24}
                                    style={{ marginBottom: marginBottomHeaderTitle }}
                                    className="greensheet-div-header mt-5"
                                >
                                    <Typography.Title level={4}>
                                        {greensheet?.titleco_contact?.contact_id
                                            ? `${greensheet?.titleco_contact?.contact_role?.role} (${greensheet?.titleco_contact?.contactname})`
                                            : `Title Co.`}
                                    </Typography.Title>
                                </Col>
                                <Col
                                    className="pl-5 pr-5 greensheet-div-summary"
                                    span={12}
                                    sm={24}
                                    md={24}
                                    lg={12}
                                    xl={12}
                                >
                                    <Row gutter={rowFormViewGutter}>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Company:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.titleco_company || '-'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Closing Officer:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.titleco_closing_officer || '-'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Address:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.titleco_address || '-'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Fax:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.titleco_fax || '-'}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    className="pl-5 pr-5 greensheet-div-summary"
                                    span={12}
                                    sm={24}
                                    md={24}
                                    lg={12}
                                    xl={12}
                                >
                                    <Row gutter={rowFormViewGutter}>
                                        <Col span={colLabelSpan}>
                                            <Text strong>E-mail:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.titleco_email || '-'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Office #:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.titleco_office_num || '-'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Mobile:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.titleco_mobile || '-'}
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )}
                        {!isRental && (
                            <>
                                <Col
                                    span={24}
                                    style={{ marginBottom: marginBottomHeaderTitle }}
                                    className="greensheet-div-header mt-5"
                                >
                                    <Typography.Title level={4}>
                                        {greensheet?.lenderco_contact?.contact_id
                                            ? `${greensheet?.lenderco_contact?.contact_role?.role} (${greensheet?.lenderco_contact?.contactname})`
                                            : `Lender Co.`}
                                    </Typography.Title>
                                </Col>
                                <Col
                                    className="pl-5 pr-5 greensheet-div-summary"
                                    span={12}
                                    sm={24}
                                    md={24}
                                    lg={12}
                                    xl={12}
                                >
                                    <Row gutter={rowFormViewGutter}>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Cash Deal:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.lenderco_cash_deal ? 'Yes' : 'No'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Company:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.lenderco_company || '-'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Loan Officer:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.lenderco_loan_officer || '-'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Fax:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.titleco_fax || '-'}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    className="pl-5 pr-5 greensheet-div-summary"
                                    span={12}
                                    sm={24}
                                    md={24}
                                    lg={12}
                                    xl={12}
                                >
                                    <Row gutter={rowFormViewGutter}>
                                        <Col span={colLabelSpan}>
                                            <Text strong>E-mail:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.lenderco_email || '-'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Office #:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.lenderco_office_num || '-'}
                                        </Col>
                                        <Col span={colLabelSpan}>
                                            <Text strong>Mobile:</Text>
                                        </Col>
                                        <Col span={colValueSpan}>
                                            {greensheet?.lenderco_mobile || '-'}
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )}
                        {greensheet?.show_additional_info !== false ? (
                            <>
                                <Col
                                    span={24}
                                    style={{ marginBottom: marginBottomHeaderTitle }}
                                    className="greensheet-div-header mt-5"
                                >
                                    <Typography.Title level={4}>Additional Info</Typography.Title>
                                </Col>
                                <Col span={24} className="pl-5 pr-5">
                                    <Row gutter={[64, 0]}>
                                        {transactionData?.contract_additional_info_categories?.map(
                                            (categoryObject, idx) => (
                                                <Col
                                                    span={24}
                                                    key={`additional_info_fields-${idx}`}
                                                    className="mb-5"
                                                >
                                                    <Row gutter={rowFormViewGutter}>
                                                        <Col span={24} className="">
                                                            <Row className="">
                                                                <Col
                                                                    span={12}
                                                                    className="text-title-secondary"
                                                                >
                                                                    {categoryObject.category?.trim() ||
                                                                        '-'}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Divider />
                                                        <Col span={24}>
                                                            <Row
                                                                gutter={rowFormViewGutter}
                                                                className="row-wrapper"
                                                            >
                                                                {categoryObject.contract_additional_info_preferences?.map(
                                                                    (fieldObject, idx) => (
                                                                        <React.Fragment
                                                                            key={`contract_additional_info_preferences-${idx}`}
                                                                        >
                                                                            <Col
                                                                                span={colLabelSpan}
                                                                                className="ez-form-label"
                                                                            >
                                                                                {fieldObject.newname ||
                                                                                    '-'}
                                                                                :
                                                                            </Col>
                                                                            <Col
                                                                                span={colValueSpan}
                                                                            >
                                                                                <span>
                                                                                    {fieldObject.field_value ? (
                                                                                        fieldObject
                                                                                            .contract_additional_info_field
                                                                                            .class ===
                                                                                        'date' ? (
                                                                                            <EZDateFormat
                                                                                                value={
                                                                                                    fieldObject.field_value
                                                                                                }
                                                                                            />
                                                                                        ) : fieldObject
                                                                                              .contract_additional_info_field
                                                                                              .class ===
                                                                                          'money' ? (
                                                                                            <EZCurrencyFormat
                                                                                                value={
                                                                                                    fieldObject.field_value
                                                                                                }
                                                                                                thousandSeparator={
                                                                                                    true
                                                                                                }
                                                                                                prefix={
                                                                                                    '$ '
                                                                                                }
                                                                                                displayType={
                                                                                                    'text'
                                                                                                }
                                                                                            />
                                                                                        ) : (
                                                                                            fieldObject.field_value
                                                                                        )
                                                                                    ) : (
                                                                                        '-'
                                                                                    )}
                                                                                </span>
                                                                            </Col>
                                                                        </React.Fragment>
                                                                    )
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )
                                        )}
                                    </Row>
                                </Col>
                            </>
                        ) : (
                            <></>
                        )}
                        <Col
                            span={24}
                            style={{ marginBottom: marginBottomHeaderTitle }}
                            className="greensheet-div-header mt-5"
                        >
                            <Typography.Title level={4}>Notes</Typography.Title>
                        </Col>
                        <Col span={24} className="pl-5 pr-5">
                            {greensheet?.notes || '-'}
                        </Col>
                    </Row>
                </>
            ) : (
                <></>
            )}
            {isLoadingTransactionDetail && <EZOverlaySpinner style={{ height: '120px' }} />}
        </div>
    );
};
