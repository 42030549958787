export const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/;

export const ACCOUNT_ROLES = [
    {
        accountrole_id: 1,
        accountrole: 'Owner',
        rank: 1,
    },
    {
        accountrole_id: 5,
        accountrole: 'Manager',
        rank: 1,
    },
    {
        accountrole_id: 2,
        accountrole: 'Broker',
        rank: 2,
    },
    {
        accountrole_id: 3,
        accountrole: 'Coordinator',
        rank: 3,
    },
    {
        accountrole_id: 4,
        accountrole: 'Agent',
        rank: 4,
    },
    {
        accountrole_id: 6,
        accountrole: '3rd Party',
        rank: 5,
    },
];

export const ACCOUNT_ROLE_ID = {
    OWNER: 1,
    BROKER: 2,
    COORDINATOR: 3,
    AGENT: 4,
    MANAGER: 5,
    THIRD_PARTY: 6,
};

export const ACCOUNT_ROLE_HAS_ACCESS_TO_ADMIN_AREA = [
    ACCOUNT_ROLE_ID.OWNER,
    ACCOUNT_ROLE_ID.MANAGER,
    ACCOUNT_ROLE_ID.AGENT,
    ACCOUNT_ROLE_ID.BROKER,
    ACCOUNT_ROLE_ID.COORDINATOR,
    ACCOUNT_ROLE_ID.THIRD_PARTY,
];

export const ACCOUNT_ROLE_HAS_ACCESS_TO_DETAIL_ADMIN_AREA = [
    ACCOUNT_ROLE_ID.OWNER,
    ACCOUNT_ROLE_ID.MANAGER,
];

export enum PERMISSION_TYPE {
    NONE = 0,
    VIEW_ONLY = 1,
    FULL_ACCESS = 2,
    NO = 0,
    YES = 1,
}

export type AccountCompactDataType = {
    account_id: number;
    email: string;
    fullname?: string;
    role_id?: number;
    accountrole_id: number;

    accountrole: AccountRoleType;
};

export type AccountDataType = {
    account_id: number;
    email: string;
    login: string;
    account_type: 'free' | 'paid' | 'unpaid';
    user_type_id: number;
    status: number;
    date_posted: Date;
    date_changed?: Date;
    date_modified?: Date;
    code?: string;
    fullname?: string;
    company: string;
    faxphone: string;
    cellphone: string;
    workphone: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    notes: string;
    hint?: string;
    role_id?: number;
    parent_account_id: number;
    accountrole_id: number;
    dd?: number;
    referred_by?: number;
    fromfb?: number;
    timezone: string;
    company_id: number;
    docusign_email?: string;
    docusign_accountid?: string;
    google_calendar_id?: string;
    google_email?: string;
    docusign_baseurl?: string;
    promocode: string;

    max_no_of_subusers?: number;
};

export type AccountRoleType = {
    accountrole_id: number;
    accountrole: string;
    rank: number;

    accountrole_permissions?: AccountRolePermissionType[];
};

export type AgentCommissionRateType = {
    agent_commission_rate_id: number;
    account_id?: number;
    rate?: number;
};

export type OfficeType = {
    office_id: number;
    parent_account_id: number;
    office: string;
    date_added: Date;
    isparent: number;
};

export type SubUserDataType = AccountDataType & {
    accountrole: AccountRoleType;

    agent_commission_rate?: AgentCommissionRateType;

    offices: OfficeType[];
};

export type AccountRolePermissionType = {
    accountroles_permission_id: number;
    account_id: number;
    toggle: number;
    contacts_toggle: number;
    documents_toggle: number;
    tasks_toggle: number;
    notes_toggle: number;
    accountrole_id: number;
    parent_account_id: number;
    assigntasks_toggle: number;
    emails_toggle: number;
};

export type AccountPermissionType = {
    accountrole_id: number;

    account_id: number;
    parent_account_id: number;

    toggle: number;
    contacts_toggle: number;
    documents_toggle: number;
    tasks_toggle: number;
    notes_toggle: number;
    assigntasks_toggle: number;
    emails_toggle: number;

    active?: number;
};
