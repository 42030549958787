import { Alert, Button } from 'antd';
import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { resendVerifyEmail, setNotification, verifyEmail } from '../../utils/ez_api/loginUtil';
import '../../styles/login.scss';
import { LogoImg } from './LogoImg';
import { InputPlaceholder } from '../input/inputPlaceholder';
import { ErrorMessage } from './ErrorMessage';

export const VerifyEmail = memo(function VerifyEmail(props: any) {
    const [otp, setOtp] = useState<string | null>(null);
    const [isError, setErrorState] = useState(false);
    const [resendSuccess, setResendSuccess] = useState(false);
    const history = useHistory();

    const updateOTP = (e: any) => {
        setOtp(e.target.value);
    };
    const sendVerificationEmail = () => {
        if (otp && otp?.length >= 4) {
            verifyEmail(parseInt(otp))
                .then(res => {
                    console.log(res);
                    setNotification(
                        'Account verification is successful. \nYou can now login to your account!'
                    );
                    history.push('/home');
                })
                .catch(err => {
                    console.log('reset', err);
                    setErrorState(true);
                });
        }
    };
    const resendEmail = (e: any) => {
        resendVerifyEmail().then(res => {
            console.log(res);
            setResendSuccess(true);
            setTimeout(() => setResendSuccess(false), 3000);
        });
    };

    const goToLogin = () => {
        history.goBack();
    };

    return (
        <div className="popup-page">
            <div className="success-toaster">
                {resendSuccess ? (
                    <Alert
                        message="New 4-digit verification code has been sent to your email successfully!"
                        type="success"
                        showIcon
                    />
                ) : (
                    ''
                )}
            </div>
            <div className="popup-box">
                <div className="back-button" onClick={goToLogin}>
                    <div className="back-icon">{'<'}</div>
                    Back
                </div>
                <div className="popup-wrapper">
                    <LogoImg />
                    <div className="popup-title">Email Verification</div>
                    <div className="popup-subtitle">
                        We’ve emailed you a 4 digit code. Please check your email and enter the code
                        here to complete the verification.
                    </div>
                    <div className="input-section verify-otp-input">
                        <InputPlaceholder
                            onChange={updateOTP}
                            name="OTP"
                            placeholder="Enter 4-digit code"
                            hideLabel={true}
                            maxLength={4}
                        />
                        <Button
                            onClick={sendVerificationEmail}
                            className="login-buttom"
                            type="primary"
                            ghost
                        >
                            <span>Verify</span>
                        </Button>
                    </div>
                    <ErrorMessage
                        isError={isError}
                        errorMessage="You’ve entered a wrong OTP. Please try again."
                    />
                    <div className="popup-footer">
                        Didn’t receive the code?{' '}
                        <span className="login-link" onClick={resendEmail}>
                            Resend
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
});
