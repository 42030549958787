import React, { useEffect, useState } from 'react';

import { Row, Col, Typography, Alert } from 'antd';

import { EMAIL_INTEGRATION_ERR_CODE, EmailCredentialType, SENDMAIL_ONBEHALF } from 'types/email';
import { getMyEmailCredential } from 'utils/ez_api/emailLogAPIs';
import { checkEmailCredential } from 'utils/emailUtil';

const { Text } = Typography;

export const EmailIntegrationIndicator: React.FC<{}> = ({ ...props }) => {
    const [emailCredential, setEmailCredential] = useState<EmailCredentialType>();
    const [isEmailCredentialSet, setIsEmailCredentialSet] = useState<boolean>(false);
    const [emailIntegrationErrCode, setEmailIntegrationErrCode] =
        useState<EMAIL_INTEGRATION_ERR_CODE | null>();

    const _fetchMyCredentials = () => {
        getMyEmailCredential().then(resp => {
            const emailCredentialTemp = resp.data as EmailCredentialType;

            setEmailCredential(emailCredentialTemp);

            const checkResult = checkEmailCredential(emailCredentialTemp);
            setEmailIntegrationErrCode(checkResult.emailIntegrationErrCode);
            setIsEmailCredentialSet(checkResult.isEmailIntegrationSet);
        });
    };

    useEffect(() => {
        _fetchMyCredentials();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row gutter={[16, 0]}>
                {emailIntegrationErrCode &&
                    emailIntegrationErrCode === EMAIL_INTEGRATION_ERR_CODE.RELAY_TYPE_IS_UNSET && (
                        <Col span={24} className="pull-left text-left">
                            <Alert
                                message={
                                    <>
                                        You haven't set your email integration. Click <b>Email</b>{' '}
                                        menu and navigate to tab - 'Integration' to set your sender
                                        profile.
                                    </>
                                }
                                type="error"
                                showIcon
                                className="mb-3"
                            />
                        </Col>
                    )}
                {emailIntegrationErrCode &&
                    (emailIntegrationErrCode ===
                        EMAIL_INTEGRATION_ERR_CODE.GOOGLE_REFRESTOKEN_EXPIRED ||
                        emailIntegrationErrCode ===
                            EMAIL_INTEGRATION_ERR_CODE.MS365_REFRESHTOKEN_EXPIRED) && (
                        <Col span={24} className="pull-left text-left">
                            <Alert
                                message={
                                    <>
                                        Your 3rd-party email integration is expired. Click{' '}
                                        <b>Email</b> menu and navigate to tab - 'Integration' to
                                        reconnect the selected sender profile.
                                    </>
                                }
                                type="error"
                                showIcon
                                className="mb-3"
                            />
                        </Col>
                    )}

                {isEmailCredentialSet && emailCredential && (
                    <Col span={24} className="pull-left text-left">
                        <Alert
                            message={
                                <>
                                    You currently use&nbsp;
                                    <b>
                                        {emailCredential?.relay_type_id === SENDMAIL_ONBEHALF.GMAIL
                                            ? emailCredential?.google_email
                                            : emailCredential?.relay_type_id ===
                                              SENDMAIL_ONBEHALF.MS_365
                                            ? emailCredential.ms_email
                                            : emailCredential?.relay_type_id ===
                                              SENDMAIL_ONBEHALF.CUSTOM_SMTP
                                            ? emailCredential.smtp_outgoing_username
                                            : '-'}
                                    </b>{' '}
                                    (
                                    {emailCredential?.relay_type_id === SENDMAIL_ONBEHALF.GMAIL
                                        ? 'Gmail integration'
                                        : emailCredential?.relay_type_id ===
                                          SENDMAIL_ONBEHALF.MS_365
                                        ? 'Microsoft 365 integration'
                                        : emailCredential?.relay_type_id ===
                                          SENDMAIL_ONBEHALF.CUSTOM_SMTP
                                        ? 'Custom SMTP integration'
                                        : '-'}
                                    ) for sending your email.
                                </>
                            }
                            type="info"
                            showIcon
                            className="mb-3"
                        />
                    </Col>
                )}
            </Row>
        </>
    );
};
