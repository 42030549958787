// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Typography,
    List,
    Avatar,
    Button,
    Popconfirm,
    Modal,
    Popover,
    Input,
} from 'antd';
import { ContainerOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
import { ProspectDetailTabProps } from 'types/prospect';
import { ProspectNoteType } from 'types/prospectNote';
// EZ web-app components import
import { EZList, EZDateFormat } from 'components';
import { IListQueryParams } from 'components/list/EZList';
import {
    deleteProspectNote,
    getProspectNotes,
    updateProspectNote,
} from 'utils/ez_api/prospect/prospectNoteAPIs';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;

const defaultQueryParams: IListQueryParams = {
    searchKeyword: '',
    orderParams: [['note_id', 'desc']],
    whereParams: {
        // category: 'note',
    },
    whereFilterParams: {},
};

const EditNoteComponent: React.FC<{
    noteRecord?: ProspectNoteType;
    saveNote: (note: string, note_id?: number) => Promise<any>;
}> = ({ noteRecord, saveNote }) => {
    const isEditMode = noteRecord && noteRecord.note_id ? true : false;

    const [isVisible, setIsVisible] = useState(false);
    const [noteContent, setNoteContent] = useState('');

    const _saveNote = () => {
        if (noteContent.trim().length > 0) {
            saveNote(noteContent.trim(), noteRecord?.note_id).then(() => {
                setIsVisible(false);
                setNoteContent('');
            });
        }
    };

    // the effect is to populate "noteContent" whenever there is a change of "noteRecord"
    useEffect(() => {
        setNoteContent(noteRecord ? noteRecord.notes : '');
    }, [noteRecord]);

    return (
        <Popover
            visible={isVisible}
            content={
                <div className="add-new-note-popover">
                    <Row gutter={[0, 10]}>
                        <Col span={24}>
                            <Input.TextArea
                                rows={4}
                                value={noteContent}
                                onChange={e => setNoteContent(e?.currentTarget?.value)}
                            ></Input.TextArea>
                        </Col>
                        <Col span={24} style={{ textAlign: 'right' }}>
                            <Button
                                type="default"
                                className="mr-2"
                                onClick={() => setIsVisible(false)}
                            >
                                Close
                            </Button>
                            <Button type="primary" onClick={() => _saveNote()}>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
            overlayClassName=""
            title={<>{isEditMode ? <span>Edit Note</span> : <span>Add New Note</span>}</>}
            trigger={'click'}
            showArrow={false}
            placement="left"
        >
            {isEditMode ? (
                <Button
                    className="p-0 mr-2"
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => setIsVisible(true)}
                    title="Edit this note"
                >
                    Edit
                </Button>
            ) : (
                <Button
                    className="ez-action-btn ml-3"
                    icon={<PlusOutlined />}
                    onClick={() => setIsVisible(true)}
                >
                    Add New Prospect's Note
                </Button>
            )}
        </Popover>
    );
};

export const ProspectSubTab_Notes: React.FC<ProspectDetailTabProps> = ({
    prospectID,
    manualReloadCount,
    reloadProspectStat,
}) => {
    const [, contextHolder] = Modal.useModal();

    const [queryParams, setQueryParams] = useState<IListQueryParams>(defaultQueryParams);

    const _fetchNotes: (EZQueryParams: string) => Promise<any> = async EZQueryParams => {
        const result = await getProspectNotes(prospectID, EZQueryParams);
        return result;
    };

    const _reloadSubTabData = () => {
        setQueryParams({
            ...defaultQueryParams,
        });
    };

    const _saveNote = async (note: string, note_id?: number) => {
        return updateProspectNote(prospectID, note, note_id)
            .then(() => {
                _reloadSubTabData();

                if (!note_id) {
                    reloadProspectStat?.(prospectID);
                }
            })
            .finally(() => {});
    };

    const _deleteNote = async (note_id: number) => {
        return deleteProspectNote(prospectID, note_id)
            .then(() => {
                reloadProspectStat?.(prospectID);
                _reloadSubTabData();
            })
            .finally(() => {});
    };

    useEffect(() => {
        _reloadSubTabData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez-component-documents">
            <div>{contextHolder}</div>
            <Row gutter={[0, 18]}>
                <Col span={24}>
                    <EZList
                        rowKey="note_id"
                        paginationConfig={{
                            pageSize: 20,
                        }}
                        queryParams={queryParams}
                        fetchData={_fetchNotes}
                        toolBarComponents={[<EditNoteComponent saveNote={_saveNote} />]}
                        renderItem={(record: ProspectNoteType) => (
                            <List.Item
                                actions={[
                                    <EditNoteComponent saveNote={_saveNote} noteRecord={record} />,
                                    <Popconfirm
                                        placement="bottomRight"
                                        title={`Delete this note?`}
                                        okText="Yes"
                                        cancelText="Cancel"
                                        onConfirm={() => _deleteNote(record.note_id)}
                                    >
                                        <Button
                                            className="p-0"
                                            type="link"
                                            style={{ color: 'red' }}
                                            icon={<DeleteOutlined />}
                                        >
                                            Delete
                                        </Button>
                                    </Popconfirm>,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            style={{ backgroundColor: '#E6F7FF' }}
                                            icon={
                                                <ContainerOutlined style={{ color: '#096DD9' }} />
                                            }
                                            size={42}
                                        />
                                    }
                                    title={
                                        <span>
                                            <Text strong className="mr-1">
                                                {record.posted_account?.fullname}
                                            </Text>
                                            <Text className="mr-1">
                                                {record.posted_account?.email
                                                    ? `(${record.posted_account.email})`
                                                    : ``}
                                            </Text>
                                            <Text type="secondary" className="mr-2">
                                                On{' '}
                                                <EZDateFormat
                                                    value={record.date_posted}
                                                    format="MMM DD, YYYY - hh:mma"
                                                />
                                            </Text>
                                        </span>
                                    }
                                    description={
                                        <Paragraph
                                            style={{ whiteSpace: 'pre-wrap' }}
                                            ellipsis={{
                                                rows: 3,
                                                expandable: true,
                                            }}
                                            title={`${record.notes}`}
                                        >
                                            {record.notes}
                                        </Paragraph>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </div>
    );
};
