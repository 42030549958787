// Core React libs import
import React, { useRef, useState } from 'react';

// Core ANTD and 3rd party libs import
import { Button, Divider, Input, InputRef, Select, Space } from 'antd';
// import type { SelectProps, BaseOptionType, DefaultOptionType } from 'antd/es/select';
import type { SelectProps } from 'antd/es/select';
import { PlusOutlined } from '@ant-design/icons';
import { showMessage } from 'utils/notificationUtil';
import { addNewContactRole } from 'utils/ez_api/contactAPIs';

// EZ level libs import

type EZSelectContactRoleProps = SelectProps & {
    onAddNewRole?: () => void;
};

export const EZSelectContactRole: React.FC<EZSelectContactRoleProps> = ({
    onAddNewRole,
    ...props
}) => {
    const [newRole, setNewRole] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const inputRef = useRef<InputRef>(null);

    const onNewRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewRole(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        const newRoleVal = newRole.trim();
        if (!newRoleVal) return showMessage('error', "Role can't be empty.");
        // onAddNewRole

        setIsSubmitting(true);
        addNewContactRole({ role: newRoleVal })
            .then(() => {
                showMessage('success', 'New role has been added.');
                onAddNewRole?.();
            })
            .finally(() => {
                setIsSubmitting(false);
                setNewRole('');
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 0);
            });
    };

    const dropdownRender: SelectProps['dropdownRender'] = menu => (
        <>
            {menu}
            <Divider style={{ margin: '8px 0' }} />
            <Space style={{ padding: '0 8px 4px' }}>
                <Input
                    placeholder="Enter new role title"
                    ref={inputRef}
                    value={newRole}
                    onChange={onNewRoleChange}
                />
                <Button
                    type="text"
                    icon={<PlusOutlined />}
                    className="ez-action-btn"
                    size="middle"
                    onClick={addItem}
                >
                    Add
                </Button>
            </Space>
        </>
    );

    return (
        <Select
            style={{ width: '100%' }}
            showSearch
            allowClear
            showArrow
            optionFilterProp="label"
            placeholder="Search or select a role"
            dropdownRender={dropdownRender}
            {...props}
        ></Select>
    );
};
