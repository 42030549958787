import { Col, Row } from 'antd';
import '../../../styles/login.scss';
import { LogoImg } from '../LogoImg';
import { memo } from 'react';

interface Props {
    width?: string;
    children?: React.ReactNode;
}

export const SocialBoxWrapper = memo((props: Props) => {
    const { width = '30em', children } = props;

    return (
        <Row justify="center">
            <Col style={{ width }}>
                <div className="login-wrapper">
                    <Col className="login-page">
                        <LogoImg />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: 'white',
                                padding: '2em',
                                borderRadius: '.5em',
                                gap: '.5em',
                            }}
                        >
                            {children}
                        </div>
                    </Col>
                </div>
            </Col>
        </Row>
    );
});
