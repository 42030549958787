// Core React libraries import
import React, { useEffect, useImperativeHandle, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import qs from 'qs';
import { Button, Col, Input, List, Row, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import type { ListProps } from 'antd';
import debounce from 'lodash/debounce';

// EZ types import
import { IPaginationConfig } from 'types/base';
// EZ utils import
import { showNotification } from 'utils/notificationUtil';

export type { ProListMetas } from '@ant-design/pro-components';

export interface IListQueryParams {
    searchKeyword: string;
    orderParams: [string, string][];
    whereParams: any;
    whereFilterParams: any;
}

export interface IProListPaginationParams {
    pagination?: IPaginationConfig;
    total?: number;
    sortField?: string;
    sortOrder?: string;
}

export type IOnChangeCallbackProps = (data: any[], pagination: IPaginationConfig) => void;

export interface IProListPropsExtended {
    paginationConfig?: IPaginationConfig;
    fetchData?: (params: string) => Promise<any>;
    onChangeCallback?: IOnChangeCallbackProps;
    toolBarComponents?: React.ReactNode[];
    disableNativeSearch?: boolean;
    queryParams?: IListQueryParams;
}

export type IProListProps = ListProps<any> & IProListPropsExtended;

export type forwardRefEZListProps = {
    modifyData: (record: any, idValue: React.Key) => void;
    deleteByKey: (idValue: React.Key) => void;

    reload: () => void;

    replaceAllData: (records: any[]) => void;
    getData: () => any[] | [];
    getDataByKey: (idValue: React.Key) => any;
};

export const EZList = React.forwardRef<forwardRefEZListProps | undefined, IProListProps>(
    (
        {
            paginationConfig,
            fetchData,
            onChangeCallback,
            toolBarComponents,
            disableNativeSearch,
            queryParams,
            ...props
        },
        ref
    ) => {
        const [data, setData] = useState<any[]>([]);
        const [loading, setLoading] = useState(false);

        const [pagination, setPagination] = useState<IPaginationConfig>(
            Object.assign(
                {
                    current: 1,
                    pageSize: 20,
                    pageSizeOptions: [10, 20],
                },
                { ...paginationConfig }
            )
        );
        const [sort, setSort] = useState<[string, string]>();
        const [keyword, setKeyword] = useState<string>('');

        const _callFetchData = (params: IProListPaginationParams = {}, keyword?: string) => {
            if (loading) return;

            if (fetchData) {
                setLoading(true);
                fetchData(
                    qs.stringify({
                        page: params.pagination?.current,
                        pageSize: params.pagination?.pageSize,
                        keyword: disableNativeSearch ? queryParams?.searchKeyword : keyword,
                        order: queryParams?.orderParams?.length
                            ? queryParams.orderParams
                            : params.sortOrder && params.sortField
                            ? [[params.sortField, params.sortOrder === 'descend' ? 'desc' : 'asc']]
                            : null,
                        where: queryParams?.whereParams,
                        whereFilter: queryParams?.whereFilterParams,
                        WebPaginationSetting: params.pagination,
                    })
                )
                    .then(response => {
                        if (response?.pagination) {
                            const newTotal = response?.pagination?.total || 0;
                            const newTotalFiltered = response?.pagination?.totalFiltered;

                            setData(response.data);
                            setPagination({
                                ...params.pagination,
                                total: newTotal,
                            });
                            setImmediate(() => {
                                onChangeCallback?.(response.data, {
                                    ...params.pagination,
                                    total: newTotal,
                                    totalFiltered: newTotalFiltered,
                                });
                            });
                        }
                    })
                    .catch(err => {
                        console.log('err', err);
                        showNotification(
                            'error',
                            'Unexpected error happened. Please try again later.'
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        };

        const _triggerUpdateKeywordDelayed = debounce((newKeyword: string) => {
            _callFetchData({ pagination, sortField: sort?.[0], sortOrder: sort?.[1] }, newKeyword);
            setKeyword(newKeyword);
        }, 500);

        const _refetch = (resetPagination: boolean = false) => {
            if (resetPagination) {
                _callFetchData({ pagination: { ...pagination, current: 1 } });
            } else _callFetchData({ pagination });
        };

        const _changePagination = (page: number, pageSize: number) => {
            const newPagination = { ...pagination, current: page, pageSize };
            setPagination(newPagination);

            _callFetchData(
                {
                    pagination: newPagination,
                },
                keyword
            );
        };

        const _generatePaginationLabel = () => {
            const current = pagination?.current || 0;
            const pageSize = pagination?.pageSize || 0;
            const total = pagination?.total || 0;

            const filteredFrom = pagination.totalFiltered
                ? `(filtered from ${pagination.totalFiltered} total entries)`
                : '';

            if (!total) {
                return `0 item ${filteredFrom}`;
            }

            if (current === 1 && total < current * pageSize) {
                return `${total} items ${filteredFrom}`;
            }

            return pagination
                ? `${1 + (current - 1) * pageSize}-${Math.min(
                      total,
                      current * pageSize
                  )} of ${total} items ${filteredFrom}`
                : '';
        };

        useImperativeHandle(ref, () => ({
            modifyData: (record: any, idValue: React.Key) => {
                let dataIdx = data?.findIndex(item => item[props.rowKey as string] === idValue);

                if (dataIdx !== -1) {
                    data[dataIdx] = { ...record };
                    setData([...data]);
                }
            },
            deleteByKey: (idValue: React.Key) => {
                setData([...data.filter(item => item[props.rowKey as string] !== idValue)]);
            },
            reload: () => {
                _refetch(true);
            },
            replaceAllData: (newData: any[]) => {
                setData([...newData]);
            },
            getData: () => {
                return data;
            },
            getDataByKey: (idValue: React.Key) => {
                return data.find(item => item[props.rowKey as string] === idValue);
            },
        }));

        useEffect(() => {
            _refetch(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [queryParams]);

        return (
            <Row gutter={[0, 0]}>
                <Col span={10} style={{ textAlign: 'left' }}>
                    <>
                        <span>
                            <Typography.Text type="secondary" className="ez-list-pagination-info">
                                Shows: {_generatePaginationLabel()}
                            </Typography.Text>
                        </span>
                    </>
                </Col>
                <Col span={14} style={{ textAlign: 'right' }}>
                    <>
                        {!disableNativeSearch && (
                            <Input.Search
                                placeholder="Enter the keyword(s) to search"
                                style={{ width: '300px' }}
                                size="middle"
                                // className="mr-3"
                                allowClear
                                onChange={e => _triggerUpdateKeywordDelayed(e.target?.value)}
                            />
                        )}
                        <Button
                            className="ml-3"
                            icon={<ReloadOutlined />}
                            // size="small"
                            loading={loading}
                            onClick={() => _refetch()}
                        ></Button>
                        {toolBarComponents?.map((elm, idx) => elm)}
                    </>
                </Col>
                <Col span={24}>
                    <List
                        className="ez-list"
                        loading={loading}
                        itemLayout="horizontal"
                        dataSource={data}
                        pagination={{
                            onChange: _changePagination,
                            ...pagination,
                        }}
                        {...props}
                    />
                </Col>
            </Row>
        );
    }
);
