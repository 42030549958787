// Core React libraries import
import React, { useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Input, Form, Col, Mentions } from 'antd';
import qs from 'qs';

// EZ web-app utils import
import { validateEmailTemplateRecipients } from 'utils/formUtil';
// EZ web-app APIs import
// EZ web-app types import
// EZ web-app components import
import { EZSelectDocument, EZSelectEmailRecipient, EmailEditorFormItem } from 'components';
import { EmailTemplateType } from 'types/emailTemplate';
import { TransactionDocItemType } from 'types/transaction';
import { getTransactionDocs } from 'utils/ez_api/documentAPIs';

// EZ web-app styles and assets import

export const Partial_EmailTemplateFormItems: React.FC<{
    formType: 'EMAIL_TEMPLATE' | 'EMAIL_FORM';
    formMode?: 'ADD' | 'EDIT';
    onSelectTemplate?: (emailTemplate: EmailTemplateType) => void;
    excludedTemplateIDs?: number[];
    showAttachmentField?: boolean;
    contractID?: number;
    docList?: TransactionDocItemType[];
}> = ({
    formType,
    formMode,
    onSelectTemplate,
    excludedTemplateIDs,
    showAttachmentField,
    contractID,
}) => {
    const [docList, setDocList] = useState<TransactionDocItemType[]>([]);

    const _fetchDocList = () => {
        if (contractID && showAttachmentField)
            getTransactionDocs(
                contractID,
                qs.stringify({
                    pageSize: 500,
                })
            ).then(result => {
                setDocList(result?.data || []);
            });
    };

    useEffect(() => {
        if (showAttachmentField) _fetchDocList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Col span={24}>
                <Form.Item
                    label="Email Subject"
                    name="msg_subject"
                    rules={[
                        {
                            required: formType === 'EMAIL_FORM' ? true : false,
                            message: "Email subject can't be empty",
                        },
                    ]}
                    extra={
                        'Type { to add custom email code such as transaction/listing address. Currently, only address can be added.'
                    }
                >
                    {/* <Input placeholder="Enter the subject" /> */}
                    <Mentions
                        style={{ width: '100%' }}
                        prefix={['{']}
                        onKeyPress={e => {
                            e.key === 'Enter' && e.preventDefault();
                        }}
                        rows={1}
                        placeholder="Enter the subject"
                    >
                        <Mentions.Option value="{ address }}">Address</Mentions.Option>
                    </Mentions>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label="Email Recipient(s)"
                    name="msg_to"
                    rules={[
                        {
                            required: formType === 'EMAIL_FORM' ? true : false,
                            message: "Email recipient(s) can't be empty",
                        },
                        {
                            validator: (_, value: string[]) => {
                                return validateEmailTemplateRecipients(value);
                            },
                        },
                    ]}
                >
                    <EZSelectEmailRecipient showTransactionOptions showMe />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Email CC(s)"
                    name="msg_cc"
                    rules={[
                        {
                            validator: (_, value: string[]) => {
                                return validateEmailTemplateRecipients(value);
                            },
                        },
                    ]}
                >
                    <EZSelectEmailRecipient showTransactionOptions showMe />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label="Email BCC(s)"
                    name="msg_bcc"
                    rules={[
                        {
                            validator: (_, value: string[]) => {
                                return validateEmailTemplateRecipients(value);
                            },
                        },
                    ]}
                >
                    <EZSelectEmailRecipient showTransactionOptions showMe />
                </Form.Item>
            </Col>
            <Col span={24} className="email-body-container">
                <EmailEditorFormItem
                    isRequired
                    showTemplateDropdown
                    showEmailSignatureDropdown
                    formMode={formMode}
                    onSelectTemplate={onSelectTemplate}
                    excludedTemplateIDs={excludedTemplateIDs}
                />
            </Col>
            {showAttachmentField && (
                <Col span={24}>
                    <Form.Item label="Attachments" name="msg_attachments">
                        <EZSelectDocument
                            options={docList as any[]}
                            onChange={(values: TransactionDocItemType, options: any) => {
                                // _onChangeSelectedDocIDs(values, options)
                            }}
                            notFoundContent={
                                <span>
                                    No documents/files uploaded on <b>"Documents and Files"</b> tab.
                                </span>
                            }
                            // value={[]}
                        />
                    </Form.Item>
                </Col>
            )}
        </>
    );
};
