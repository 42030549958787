// Core React libraries import
import React from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Typography, Alert } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
// EZ web-app components import
import { Partial_EmailLogs } from 'components/appPage/partials/emailLogs';

// EZ web-app styles and assets import

export const SubTab_EmailLogs: React.FC<{}> = ({}) => {
    return (
        <div className="ez_email_logs">
            <Row gutter={24} className="mt-2 mb-3">
                <Col span={24} className="">
                    <Alert
                        message="All the drafts and sent emails you own from listing/transaction's tasklists will be shown on the below table."
                        type="info"
                        showIcon
                        className="mb-3"
                    />
                    <Partial_EmailLogs />
                </Col>
            </Row>
        </div>
    );
};
