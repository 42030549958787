// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Typography, List, Avatar, Alert } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
import { AppMyAlertItemType, NotificationInfoType } from 'types/notification';
import { DocReviewTabIcon, TAB_CATEGORY } from 'types/webAppTab';
// EZ web-app components import
import { HomeContext } from 'context/EZContext';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;

export const AppMyInbox: React.FC<{ notificationInfo?: NotificationInfoType }> = ({
    notificationInfo,
}) => {
    const HomeCtx = useContext(HomeContext);

    const [data, setData] = useState<AppMyAlertItemType[]>([]);

    const _openDocReviewTab = () => {
        HomeCtx.openMainTab(TAB_CATEGORY.DOC_REVIEW, true);
    };

    useEffect(() => {
        if (notificationInfo?.docReview) {
            setData([
                {
                    key: 1,
                    category: 'DOC_REVIEW',
                    title: (
                        <>
                            <b>{notificationInfo?.docReview} transaction(s)</b> have documents for
                            your review.
                        </>
                    ),
                    description: (
                        <>
                            Click{' '}
                            <Text
                                onClick={() => _openDocReviewTab()}
                                className="cursor-pointer"
                                type="secondary"
                                underline
                                strong
                            >
                                here
                            </Text>{' '}
                            to start the review process.
                        </>
                    ),
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationInfo]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez_detail_myinbox">
            <Row gutter={[0, 0]}>
                <Col span={24} className="mb-3">
                    {data.length ? (
                        <List
                            dataSource={data}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar
                                                style={{ backgroundColor: '#E6F7FF' }}
                                                icon={
                                                    <DocReviewTabIcon
                                                        style={{ color: '#096DD9' }}
                                                    />
                                                }
                                                size={32}
                                            />
                                        }
                                        title={item.title}
                                        description={item.description}
                                    ></List.Item.Meta>
                                </List.Item>
                            )}
                        />
                    ) : (
                        <>
                            <Alert description="No notification at the moment" />
                        </>
                    )}
                </Col>
            </Row>
        </div>
    );
};
