import { SubscriptionTypeItem } from 'types/payment';
import { getAllSubscriptionTypes } from './ez_api/paymentAPIs';

let SUBSCRIPTION_TYPES: SubscriptionTypeItem[] = [];

let PAID_SUBSCRIPTION_TYPES: SubscriptionTypeItem[] = [];

let MONTHLY_PAID_SUBSCRIPTION_TYPES: SubscriptionTypeItem[] = [];

let ANNUALLY_PAID_SUBSCRIPTION_TYPES: SubscriptionTypeItem[] = [];

export const loadSubscriptionListFromServer = async () => {
    if (!SUBSCRIPTION_TYPES.length) {
        const { data } = await getAllSubscriptionTypes();
        const subs = data as SubscriptionTypeItem[];

        SUBSCRIPTION_TYPES = subs;

        PAID_SUBSCRIPTION_TYPES = SUBSCRIPTION_TYPES.filter(item => item.is_paid_plan);

        MONTHLY_PAID_SUBSCRIPTION_TYPES = SUBSCRIPTION_TYPES.filter(
            item => item.is_monthly && item.is_paid_plan && item.is_active
        );

        ANNUALLY_PAID_SUBSCRIPTION_TYPES = SUBSCRIPTION_TYPES.filter(
            item => item.is_annually && item.is_paid_plan && item.is_active
        );
    }
};

export const getSubscriptionList = () => {
    return SUBSCRIPTION_TYPES;
};

export const getMonthlyPaidSubscriptionList = () => {
    return MONTHLY_PAID_SUBSCRIPTION_TYPES;
};

export const getAnnuallyPaidSubscriptionList = () => {
    return ANNUALLY_PAID_SUBSCRIPTION_TYPES;
};
