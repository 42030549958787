// APIs to be used for entire page or data store

import { AccountDataType } from 'types/account';

let userData: any = null;

export const setUserData = (val: any) => {
    userData = val;
};

export const getUserData = () => {
    return userData;
};

export const summarizeText = (text: string, maxLength = 10) => {
    return text.substring(0, maxLength) + (text.length > maxLength ? '...' : '');
};

export const getInitialFromFullname = (fullName: string) => {
    if (!fullName) return '';
    const allNames = fullName.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
    }, '');
    return initials;
};

export function formatBytes(bytes: number, decimals: number = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const getFileExtension = (filename?: string | undefined) => {
    return filename?.split('.').pop();
};

export const getParentAccountID = (account: AccountDataType) => {
    return account.parent_account_id || account.account_id;
};

export const SELECT_OPTGROUP_FILTEROPT = (
    inputValue: string,
    option: {
        label: string;
        options:
            | {
                  label: string;
                  value: number;
              }[]
            | undefined;
    }
) => {
    if (option.options?.length) {
        return false;
    } else if (option.label) {
        return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ? true : false;
    }
};
