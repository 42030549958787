var graph = require('@microsoft/microsoft-graph-client')

function getAuthenticatedClient(accessToken) {
    const client = graph.Client.init({
        authProvider: (done) => {
            done(null, accessToken.accessToken)
        }
    })
    return client
}

export async function getUserDetails(accessToken) {
    const client = getAuthenticatedClient(accessToken);
    const user = client.api('/me').get()
    return user;
}

export async function getMessages(accessToken, emailFilterState) {
    const source = emailFilterState === 'INBOX' ? 'Inbox': 'SentItems'
    const client = getAuthenticatedClient(accessToken);
    const messages = await client.api(`/me/mailFolders/${source}/messages`).get();
    return messages
}

export async function sendMSGraphEmail(accessToken, formData) {
    const client = getAuthenticatedClient(accessToken);
    const data = {
        message: {
        toRecipients: [{
            emailAddress: {
                address: formData.to,
            }
        }],
        subject: formData.subject,
        body: {
          contentType: 'HTML',
          content: formData.message
        },
      },
      saveToSentItems: 'false'
    };
      
      await client.api('/me/sendMail')
          .post(data)
}