import { EmailCodeTreeDataChildType } from 'types/email';
import { COMMA_TEMPLATE } from './consts';

const _getPartyChilds = (title: string, parent: string): { title: string; value: string }[] => {
    const gen_template = (field: string) =>
        `#${parent}}}{{#${field}}}{{${field}}}{{/${field}}}${COMMA_TEMPLATE}{{/${parent}`;

    return [
        {
            title: `${title} Names`,
            value: gen_template(`${title.toLowerCase()}_name`),
        },
        {
            title: `${title} Emails`,
            value: gen_template('email'),
        },
        {
            title: `${title} Phones`,
            value: gen_template('telephone'),
        },
    ];
};

export const EMAIL_TEMPLATE_CODES: EmailCodeTreeDataChildType[] = [
    {
        title: 'Transaction - Basic',
        value: 'Transaction - Basic',
        selectable: false,
        children: [
            {
                title: 'Address',
                value: 'address',
            },
            {
                title: 'City',
                value: 'city',
            },
            {
                title: 'State',
                value: 'state',
            },
            {
                title: 'ZIP',
                value: 'zip',
            },
            {
                title: 'ESCROW',
                value: 'escrow',
            },
            {
                title: 'MIS',
                value: 'mis',
            },
            {
                title: 'Year Built',
                value: 'year_built',
            },
        ],
    },
    {
        title: 'Lease - Basic',
        value: 'Lease - Basic',
        selectable: false,
        children: [
            {
                title: 'Lease Term(s)',
                value: 'lease_term',
            },
            {
                title: 'Lease Signed Date',
                value: 'lease_signed_date',
            },
            {
                title: 'Lease Start Date',
                value: 'lease_start_date',
            },

            {
                title: 'Lease End Date',
                value: 'lease_end_date',
            },
            {
                title: 'Marketed Price',
                value: 'marketedprice',
            },
            {
                title: 'Credits',
                value: 'credits',
            },
            {
                title: 'Move In Date',
                value: 'move_in_date',
            },
        ],
    },
    {
        title: 'Transaction - Dates',
        value: 'Transaction - Dates',
        selectable: false,
        children: [
            {
                title: 'Contract Date',
                value: 'contract_date',
            },
            {
                title: 'Close Date',
                value: 'close_date',
            },
            {
                title: 'Inspection Contingency Date',
                value: 'inspection_contingency_date',
            },
            {
                title: 'Financing Contingency Date',
                value: 'financing_contingency_date',
            },
            {
                title: 'Appraisal Contingency Date',
                value: 'appraisal_contingency_date',
            },
            {
                title: 'Expiration Date',
                value: 'expiration_date',
            },
            {
                title: 'Application Date',
                value: 'application_date',
            },
            {
                title: 'Listing Date',
                value: 'listing_date',
            },
            {
                title: 'Effective Date',
                value: 'effective_date',
            },
            {
                title: 'Listing Agreement Date',
                value: 'listing_agreement_date',
            },
            {
                title: 'Listing Live Date',
                value: 'listing_live_date',
            },
        ],
    },
    {
        title: 'Transaction - Prices',
        value: 'Transaction - Prices',
        selectable: false,
        children: [
            {
                title: 'Listing Price',
                value: 'listprice',
            },
            {
                title: 'Sales Price',
                value: 'salesprice',
            },
            {
                title: 'Closing Cost Credit',
                value: 'cccredit',
            },
            {
                title: 'Deposit',
                value: 'deposits',
            },
        ],
    },
    {
        title: 'Transaction - Parties',
        value: 'Transaction - Parties',
        selectable: false,
        children: [
            {
                title: 'Listing',
                value: 'listing',
                children: [
                    {
                        title: 'Buyers',
                        value: 'buyers',
                        children: _getPartyChilds('Buyer', 'contract_buyers'),
                    },
                    {
                        title: `Buyer's Agents`,
                        value: 'buyers_agents',
                        children: _getPartyChilds('Buyer Agent', 'contract_buyer_agents'),
                    },
                    {
                        title: 'Sellers',
                        value: 'sellers',
                        children: _getPartyChilds('Seller', 'contract_sellers'),
                    },
                    {
                        title: `Seller's Agents`,
                        value: 'sellers_agents',
                        children: _getPartyChilds('Seller Agent', 'contract_seller_agents'),
                    },
                ],
            },
            {
                title: `Rental`,
                value: 'rental',
                children: [
                    {
                        title: 'Tenants',
                        value: 'tenants',
                        children: _getPartyChilds('Tenant', 'contract_tenants'),
                    },
                    {
                        title: `Referral Sources`,
                        value: 'referral_source',
                        children: _getPartyChilds('Referral Source', 'contract_referral_sources'),
                    },
                    {
                        title: 'Landlords',
                        value: 'landlords',
                        children: _getPartyChilds('Landlord', 'contract_landlords'),
                    },
                    {
                        title: `Leasing Agents`,
                        value: 'leasing_agents',
                        children: _getPartyChilds('Leasing Agent', 'contract_leasing_agents'),
                    },
                ],
            },
        ],
    },
];
