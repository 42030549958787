// Core React libraries import
import React, { useContext } from 'react';
import { useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Typography,
    Tabs,
    Alert,
    Space,
    Tooltip,
    Dropdown,
    Menu,
    Button,
    Divider,
    Popconfirm,
    Table,
    Tag,
    Form,
    Input,
    Modal,
} from 'antd';
import {
    BulbOutlined,
    CaretDownOutlined,
    DeleteFilled,
    DeleteOutlined,
    DeliveredProcedureOutlined,
    EditOutlined,
    EllipsisOutlined,
    PlusOutlined,
    TeamOutlined,
    UsergroupDeleteOutlined,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';

// EZ web-app utils import
import { showMessage, showNotification } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    addNewEmailTemplate,
    addNewEmailTemplateFolder,
    deleteEmailTemplate,
    deleteEmailTemplateFolder,
    editEmailTemplate,
    editEmailTemplateFolder,
    generateEmailTemplates,
    getEmailTemplateFolders,
    getEmailTemplates,
    moveSelectedEmailTemplates,
    toggleSharingTemplateFolder,
} from 'utils/ez_api/emailTemplateAPIs';
// EZ web-app types import
import { EZTable, ITableQueryParams, forwardRefEZTableProps } from 'components/table/EZProTable';
import { EmailTemplateFolderType, EmailTemplateType } from 'types/emailTemplate';
// EZ web-app components import
import { HomeContext } from 'context/EZContext';
import { EZDateFormat } from 'components';
import { EditEmailTemplateModalContent } from '../modal/emailTemplateEditDetail';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;

const defaultTableParam: ITableQueryParams = {
    searchKeyword: '',
    orderParams: [],
    // orderParams: [['$doc_folder.rank$', 'desc']],
    whereParams: {},
    whereFilterParams: {},
};

export const SubTab_EmailTemplates: React.FC<{}> = ({}) => {
    const HomeCtx = useContext(HomeContext);
    const UserInfo = HomeCtx.userInfo;

    const [form] = Form.useForm();

    const EZTableRef = useRef<forwardRefEZTableProps>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [queryParams, setQueryParams] = useState<ITableQueryParams>(defaultTableParam);

    async function _fetchData(EZTableQueryParams: string): Promise<any> {
        return getEmailTemplates(EZTableQueryParams).then(result => result);
    }
    const _fetchTemplateFolders = () => {
        getEmailTemplateFolders({ order: [['folder_id', 'asc']] }).then(resp => {
            if (resp.data) {
                const folders = resp.data as EmailTemplateFolderType[];

                const myFolders: EmailTemplateFolderType[] = [];
                const foldersOwnedByOthers: EmailTemplateFolderType[] = [];

                for (let index = 0; index < folders.length; index++) {
                    const folder = folders[index];

                    if (folder.account_id === UserInfo?.account_id) {
                        myFolders.push(folder);
                    } else {
                        foldersOwnedByOthers.push(folder);
                    }
                }

                setTemplateFolders([...myFolders, ...foldersOwnedByOthers]);

                if (!folders.length || !myFolders || !myFolders.length) {
                    setShowGenerateFolderTemplates(true);
                } else {
                    setShowGenerateFolderTemplates(false);
                }
            }
        });
    };

    const [templateFolders, setTemplateFolders] = useState<EmailTemplateFolderType[]>([]);
    const [templateFolderFilter, setTemplateFolderFilter] = useState<string>('-1');
    const [showGenerateFolderTemplates, setShowGenerateFolderTemplates] = useState<boolean>(false);

    const _columns: ProColumns<EmailTemplateType>[] = [
        {
            title: 'Template Name',
            dataIndex: 'template_name',
            width: '82px',
            sorter: true,
            fixed: 'left',
            render: (_, record) => (
                <>
                    <Text title={record.template_name}>{record.template_name || '-'}</Text>
                </>
            ),
        },
        {
            title: 'Folder',
            dataIndex: 'template_folder_id',
            width: '72px',
            sorter: true,
            ellipsis: true,
            valueType: 'select',
            fieldProps: {
                options: templateFolders.map((item, idx) => ({
                    label: `${item.is_shared ? <TeamOutlined className="mr-2" /> : <></>}${
                        item.folder_name
                    }`,
                    key: item.folder_id,
                    value: item.folder_id,
                })),
            },
            render: (_, record) => (
                <>
                    <Tag>
                        {record.email_template_folder?.is_shared ? (
                            <TeamOutlined className="mr-2" />
                        ) : (
                            <></>
                        )}
                        {record.email_template_folder?.folder_name}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'template_desc',
            width: '100px',
            sorter: true,
            // ellipsis: true,
            render: (_, record) => (
                <>
                    <Text title={record.template_desc}>{record.template_desc || '-'}</Text>
                </>
            ),
        },
        {
            title: 'Email Subject',
            dataIndex: 'msg_subject',
            width: '100px',
            sorter: true,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text title={record.msg_subject}>{record.msg_subject || '-'}</Text>
                </>
            ),
        },
        {
            title: 'Last Updated By',
            dataIndex: 'updated_by_account.fullname',
            width: '50px',
            editable: false,
            sorter: false,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text title={record.updated_by_account?.fullname}>
                        {record.updated_by_account?.fullname || '-'}
                    </Text>
                </>
            ),
        },
        {
            title: 'Last Updated At',
            dataIndex: 'updated_at',
            width: '60px',
            editable: false,
            sorter: true,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text>
                        <EZDateFormat value={record.updated_at} format="MMM Do, YYYY hh:mma" />
                    </Text>
                </>
            ),
        },
        {
            title: 'Created By',
            dataIndex: 'account.fullname',
            width: '50px',
            editable: false,
            sorter: false,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text title={record.account?.fullname}>{record.account?.fullname || '-'}</Text>
                </>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            width: '60px',
            editable: false,
            sorter: true,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text>
                        <EZDateFormat value={record.created_at} format="MMM Do, YYYY hh:mma" />
                    </Text>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '45px',
            valueType: 'option',
            fixed: 'right',
            render: (_, record, index, action) => (
                <Space size="small" className="pl-1">
                    <Button
                        className="p-0 mr-1"
                        type="link"
                        icon={<EditOutlined />}
                        title="Edit this template"
                        onClick={() => {
                            _showEditTemplateDetailModal(record, 'EDIT');
                        }}
                    >
                        Edit
                    </Button>
                    <Divider type="vertical" />
                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu>
                                <Menu.Item key="2" danger onClick={() => _deleteTemplate(record)}>
                                    <DeleteOutlined className="mr-3" />
                                    Delete
                                </Menu.Item>
                            </Menu>
                        }
                        placement="bottomRight"
                    >
                        <span onClick={e => e.preventDefault()}>
                            <Button size="small" type="link" icon={<EllipsisOutlined />} />
                        </span>
                    </Dropdown>
                </Space>
            ),
        },
    ];

    const _applyFilter = (templateFolderFilter?: string) => {
        const currentWhereFilterParams: any = {};

        if (templateFolderFilter === '-1') {
            if (currentWhereFilterParams['template_folder_id']) {
                delete currentWhereFilterParams['template_folder_id'];
            }
        } else if (templateFolderFilter) {
            currentWhereFilterParams['template_folder_id'] = templateFolderFilter?.replace(
                'tab-templatefolder-',
                ''
            );
        }

        setQueryParams({
            ...queryParams,
            whereFilterParams: { ...currentWhereFilterParams },
            resetPagination: true,
        });
    };

    const _showEditTemplateDetailModal = (
        record: Partial<EmailTemplateType>,
        mode: 'EDIT' | 'ADD' = 'EDIT'
    ) => {
        const editTemplateDetailModal = Modal.confirm({
            title: (
                <Typography.Title level={4}>
                    {mode === 'ADD' ? 'Add New Template' : 'Edit Template Detail'}
                </Typography.Title>
            ),
            content: (
                <EditEmailTemplateModalContent
                    emailTemplate={record}
                    form={form}
                    mode={mode}
                    templateFolders={templateFolders}
                    UserInfo={UserInfo}
                />
            ),
            okText: mode === 'EDIT' ? 'Save' : 'Add',
            icon: <></>,
            style: { top: '40px' },
            width: '80%',
            autoFocusButton: null,
            onOk: async () => {
                editTemplateDetailModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Saving...',
                });
                return form
                    .validateFields()
                    .then(async formValues => {
                        const formParams = formValues as Partial<EmailTemplateType>;

                        if (mode === 'ADD') {
                            return addNewEmailTemplate(formParams)
                                .then(resp => {
                                    const emailTemplateRecord = resp.data as EmailTemplateType;
                                    editTemplateDetailModal.destroy();

                                    _fetchTemplateFolders();
                                    setTemplateFolderFilter(
                                        `tab-templatefolder-${emailTemplateRecord.template_folder_id}`
                                    );
                                    EZTableRef.current?.reload();

                                    showNotification(
                                        'success',
                                        'New template has been created successfully.'
                                    );

                                    return emailTemplateRecord;
                                })
                                .catch(err => {
                                    throw err;
                                });
                        } else {
                            formParams.email_template_id = record.email_template_id;
                            return editEmailTemplate(formParams)
                                .then(resp => {
                                    const emailTemplateRecord = resp.data as EmailTemplateType;
                                    editTemplateDetailModal.destroy();

                                    _fetchTemplateFolders();
                                    setTemplateFolderFilter(
                                        `tab-templatefolder-${emailTemplateRecord.template_folder_id}`
                                    );
                                    EZTableRef.current?.reload();

                                    showNotification(
                                        'success',
                                        'New changes have been applied successfully.'
                                    );

                                    return emailTemplateRecord;
                                })
                                .catch(err => {
                                    throw err;
                                });
                        }
                    })
                    .catch(err => {
                        console.log('🚀 ~ file: subTabs_Templates.tsx:287 ~ onOk: ~ err:', err);

                        editTemplateDetailModal.update({
                            okButtonProps: {
                                disabled: false,
                            },
                            okText: mode === 'EDIT' ? 'Save' : 'Add',
                        });
                        throw err;
                    });
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _showAddNewTemplateModal = () => {
        _showEditTemplateDetailModal(
            {
                template_folder_id: parseInt(
                    templateFolderFilter?.replace('tab-templatefolder-', '')
                ),
            },
            'ADD'
        );
    };

    const _doBulkAction_moveTemplates = (
        selectedRowKeys: React.Key[],
        newFolderID: number,
        newFolderTitle?: string
    ) => {
        const noOfKeys = selectedRowKeys.length;

        moveSelectedEmailTemplates(selectedRowKeys as number[], newFolderID).then(resp => {
            showNotification(
                'success',
                `${noOfKeys} selected template(s) have been moved to ${newFolderTitle}.`
            );
            setImmediate(() => {
                _applyFilter(templateFolderFilter);

                setSelectedRowKeys([]);
            });
        });
    };

    const _deleteTemplate = (record: EmailTemplateType) => {
        if (UserInfo?.account_id !== record.account_id) {
            return showMessage('error', `Only the original owner can delete the template.`);
        }

        const deleteConfirmationModal = Modal.confirm({
            title: `Delete confirmation`,
            content: (
                <>
                    Are you sure to delete template: <b>{record.template_name}</b>?
                </>
            ),
            onOk: () => {
                deleteConfirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Deleting...',
                });
                deleteEmailTemplate(record.email_template_id).then(() => {
                    deleteConfirmationModal.destroy();

                    showNotification(
                        'success',
                        `Template: "${record.template_name}" has been successfully deleted.`
                    );
                    EZTableRef.current?.reload();
                });
                return true;
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _showAddNewOrEditFolderModal = (
        record?: EmailTemplateFolderType,
        mode: 'EDIT' | 'ADD' = 'EDIT'
    ) => {
        form.setFieldValue('folder_name', record && record.folder_name ? record.folder_name : '');

        const newFolderModal = Modal.confirm({
            title: (
                <Typography.Title level={4}>
                    {mode === 'ADD' ? 'Add New Folder' : 'Edit Folder Name'}
                </Typography.Title>
            ),
            content: (
                <>
                    <Form layout="vertical" form={form} requiredMark={false}>
                        <Row gutter={[16, 0]}>
                            <Col span={24}>
                                <Form.Item
                                    label="Folder Name"
                                    name="folder_name"
                                    rules={[{ required: true, message: "Name can't be empty" }]}
                                >
                                    <Input placeholder="Enter the folder name" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>
            ),
            okText: mode === 'EDIT' ? 'Save' : 'Add',
            icon: <></>,
            style: { top: '40px' },
            width: '450px',
            autoFocusButton: null,
            onOk: async () => {
                newFolderModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Saving...',
                });
                return form
                    .validateFields()
                    .then(async formValues => {
                        const formParams = formValues as Partial<EmailTemplateFolderType>;

                        if (mode === 'ADD') {
                            return addNewEmailTemplateFolder(formParams.folder_name || '')
                                .then(resp => {
                                    const folderRecord = resp.data as EmailTemplateFolderType;

                                    newFolderModal.destroy();

                                    _fetchTemplateFolders();
                                    setTemplateFolderFilter(
                                        `tab-templatefolder-${folderRecord.folder_id}`
                                    );
                                    EZTableRef.current?.reload();

                                    showNotification(
                                        'success',
                                        'New folder has been created successfully.'
                                    );

                                    return folderRecord;
                                })
                                .catch(err => {
                                    throw err;
                                });
                        } else {
                            formParams.folder_id = record?.folder_id;
                            return editEmailTemplateFolder(formParams)
                                .then(resp => {
                                    const folderRecord = resp.data as EmailTemplateFolderType;

                                    newFolderModal.destroy();

                                    _fetchTemplateFolders();
                                    setTemplateFolderFilter(
                                        `tab-templatefolder-${folderRecord.folder_id}`
                                    );
                                    EZTableRef.current?.reload();

                                    showNotification(
                                        'success',
                                        'New folder name has been saved successfully.'
                                    );

                                    return folderRecord;
                                })
                                .catch(err => {
                                    throw err;
                                });
                        }
                    })
                    .catch(err => {
                        console.log('🚀 ~ file: subTabs_Templates.tsx:498 ~ onOk: ~ err:', err);
                        newFolderModal.update({
                            okButtonProps: {
                                disabled: false,
                            },
                            okText: mode === 'EDIT' ? 'Save' : 'Add',
                        });

                        throw err;
                    });
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _showShareOrUnshareConfirmationModal = (record: EmailTemplateFolderType) => {
        if (UserInfo?.account_id !== record.account_id) {
            return showMessage('error', `Only the original owner can perform the function.`);
        }

        const confirmationModal = Modal.confirm({
            title: <Typography.Title level={4}>Toggle Sharing Confirmation</Typography.Title>,
            content: (
                <Row>
                    <Col span={24}>
                        You are about to {record.is_shared ? 'unshare' : 'share'} a folder and all
                        its linked templates with your team members.{' '}
                        {record.is_shared ? (
                            ''
                        ) : (
                            <>
                                <br />
                                Once shared, other team members can see the folder you created and
                                they will be able to add and edit the linked templates.
                            </>
                        )}
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24}>
                        Are you sure to {record.is_shared ? 'unshare' : 'share'} the folder name:{' '}
                        <b>{record.folder_name}</b>?
                    </Col>
                </Row>
            ),
            okText: 'Continue',
            style: { top: '40px' },
            width: '550px',
            onOk: async () => {
                confirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Performing action...',
                });

                return toggleSharingTemplateFolder(record)
                    .then(() => {
                        confirmationModal.destroy();
                        showNotification(
                            'success',
                            `The folder: ${record.folder_name} has been ${
                                record.is_shared ? 'unshared' : 'shared'
                            } successfully.`
                        );

                        _fetchTemplateFolders();

                        return record;
                    })
                    .catch(err => {
                        throw err;
                    });
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _showDeleteFolderConfirmationModal = (record: EmailTemplateFolderType) => {
        if (UserInfo?.account_id !== record.account_id) {
            return showMessage('error', `Only the original owner can delete the folder.`);
        }

        if (templateFolders.length <= 1) {
            showMessage('error', `You can't delete the last folder.`);
            return;
        }

        form.setFieldValue('folder_name', record && record.folder_name ? record.folder_name : '');

        const deletionConfirmationModal = Modal.confirm({
            title: <Typography.Title level={4}>Deletion Confirmation</Typography.Title>,
            content: (
                <Row>
                    <Col span={24}>
                        You are about to delete a folder, and all its linked templates. You will not
                        be able to restore them once done.
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24}>
                        Are you sure to delete the folder: <b>{record.folder_name}</b>?
                    </Col>
                </Row>
            ),
            okText: 'Delete',
            style: { top: '40px' },
            width: '550px',
            onOk: async () => {
                deletionConfirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Deleting...',
                });

                return deleteEmailTemplateFolder(record)
                    .then(() => {
                        deletionConfirmationModal.destroy();
                        showNotification(
                            'success',
                            `The folder: ${record.folder_name} has been deleted successfully.`
                        );

                        _fetchTemplateFolders();
                        setTemplateFolderFilter('-1');
                        return record;
                    })
                    .catch(err => {
                        throw err;
                    });
            },
            closable: true,
            maskClosable: true,
        });
    };

    const _onEditFolderTabs = (action: 'add' | 'remove') => {
        if (action === 'add') {
            _showAddNewOrEditFolderModal(undefined, 'ADD');
        } else {
        }
    };

    const _confirmGenerateDefaultFolders = () => {
        const generateDefaultFoldersConfirmationModal = Modal.confirm({
            title: <Typography.Title level={4}>Confirmation</Typography.Title>,
            content: (
                <Row>
                    <Col span={24}>
                        You are about to generate the default folders, which are prefilled with
                        recommended email templates crafted by our team.
                    </Col>
                    <Col span={24}>
                        <Divider />
                    </Col>
                    <Col span={24}>Are you sure to continue?</Col>
                </Row>
            ),
            okText: 'Yes',
            // style: { top: '40px' },
            width: '550px',
            onOk: async () => {
                generateDefaultFoldersConfirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Generating...',
                });

                return generateEmailTemplates()
                    .then(() => {
                        generateDefaultFoldersConfirmationModal.destroy();
                        showNotification(
                            'success',
                            `Default templates have been generated successfully.`
                        );

                        _fetchTemplateFolders();
                        // setTemplateFolderFilter('-1');
                        return true;
                    })
                    .catch(err => {
                        throw err;
                    });
            },
            closable: true,
            maskClosable: true,
        });
    };

    useEffect(() => {
        _applyFilter(templateFolderFilter);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateFolderFilter]);

    useEffect(() => {
        _fetchTemplateFolders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez_email_logs">
            <Row gutter={24} className="mt-2 mb-3">
                <Col span={24} className="">
                    <Tabs
                        type="editable-card"
                        defaultActiveKey="-1"
                        activeKey={templateFolderFilter}
                        onChange={val => {
                            setTemplateFolderFilter(val);
                            setSelectedRowKeys([]);
                        }}
                        addIcon={
                            <Tooltip title="Add New Folder">
                                <PlusOutlined />
                            </Tooltip>
                        }
                        tabBarExtraContent={
                            showGenerateFolderTemplates ? (
                                <span className="ml-3">
                                    <Tooltip
                                        title={`Click to generate the default folders, prefilled with recommended email templates.`}
                                    >
                                        <Button
                                            type="link"
                                            className="ez-action-btn"
                                            icon={<BulbOutlined />}
                                            onClick={() => _confirmGenerateDefaultFolders()}
                                        >
                                            Generate Default Folders
                                        </Button>
                                    </Tooltip>
                                </span>
                            ) : (
                                <></>
                            )
                        }
                        onEdit={(e: any, action) => _onEditFolderTabs(action)}
                    >
                        <Tabs.TabPane tab="All Templates" key="-1" closable={false}></Tabs.TabPane>
                        {templateFolders.map(folderItem => (
                            <Tabs.TabPane
                                tab={
                                    <span>
                                        {folderItem.is_shared ? (
                                            <Tooltip
                                                title={`Shared by ${folderItem.account?.fullname}`}
                                            >
                                                <TeamOutlined />
                                            </Tooltip>
                                        ) : (
                                            <></>
                                        )}
                                        {folderItem.folder_name}
                                        {templateFolderFilter ===
                                            `tab-templatefolder-${folderItem.folder_id}` && (
                                            <Dropdown
                                                overlay={
                                                    <Menu
                                                        items={[
                                                            {
                                                                label: 'Rename',
                                                                key: 'rename_folder',
                                                                icon: <EditOutlined />,
                                                                disabled:
                                                                    folderItem.account_id !==
                                                                    UserInfo?.account_id,
                                                                onClick: () =>
                                                                    _showAddNewOrEditFolderModal(
                                                                        folderItem,
                                                                        'EDIT'
                                                                    ),
                                                            },
                                                            {
                                                                label: folderItem.is_shared
                                                                    ? 'Unshare'
                                                                    : 'Share',
                                                                key: 'share_folder',
                                                                icon: !folderItem.is_shared ? (
                                                                    <TeamOutlined />
                                                                ) : (
                                                                    <UsergroupDeleteOutlined />
                                                                ),
                                                                disabled:
                                                                    folderItem.account_id !==
                                                                    UserInfo?.account_id,
                                                                onClick: () =>
                                                                    _showShareOrUnshareConfirmationModal(
                                                                        folderItem
                                                                    ),
                                                            },
                                                            { type: 'divider' },
                                                            {
                                                                label: 'Delete Folder',
                                                                key: 'delete_folder',
                                                                danger:
                                                                    folderItem.account_id ===
                                                                    UserInfo?.account_id,
                                                                disabled:
                                                                    folderItem.account_id !==
                                                                    UserInfo?.account_id,
                                                                onClick: () =>
                                                                    _showDeleteFolderConfirmationModal(
                                                                        folderItem
                                                                    ),
                                                            },
                                                        ]}
                                                    />
                                                }
                                                trigger={['click']}
                                            >
                                                <Space>
                                                    <CaretDownOutlined className="ml-4 mr-0" />
                                                </Space>
                                            </Dropdown>
                                        )}
                                    </span>
                                }
                                key={`tab-templatefolder-${folderItem.folder_id}`}
                                closable={false}
                            ></Tabs.TabPane>
                        ))}
                    </Tabs>
                </Col>
                <Col span={24}>
                    <Row
                        className={`table-selected-indicator ${
                            selectedRowKeys?.length ? 'has-row-selected' : 'no-row-selected'
                        }`}
                    >
                        <Col span={24}>
                            <Alert
                                style={{ minWidth: '500px' }}
                                message={
                                    selectedRowKeys && selectedRowKeys.length ? (
                                        <Space>
                                            <Tooltip title="Move the selected doc(s)">
                                                <Dropdown
                                                    trigger={['click']}
                                                    overlayStyle={{
                                                        minWidth: '200px',
                                                    }}
                                                    overlay={
                                                        <Menu
                                                            onClick={e =>
                                                                _doBulkAction_moveTemplates(
                                                                    selectedRowKeys,
                                                                    parseInt(e.key),
                                                                    templateFolders.find(
                                                                        d =>
                                                                            d.folder_id ===
                                                                            parseInt(e.key)
                                                                    )?.folder_name
                                                                )
                                                            }
                                                            items={templateFolders.map(
                                                                (item, idx) => ({
                                                                    label: item.folder_name,
                                                                    key: item.folder_id,
                                                                    disabled:
                                                                        templateFolderFilter ===
                                                                        `tab-templatefolder-${item.folder_id}`,
                                                                })
                                                            )}
                                                        />
                                                    }
                                                    placement="bottomLeft"
                                                >
                                                    <Button icon={<DeliveredProcedureOutlined />}>
                                                        Move To
                                                    </Button>
                                                </Dropdown>
                                            </Tooltip>
                                            {/* <Divider type="vertical" />
                                            <Tooltip title="Remove selected template(s)">
                                                <Popconfirm
                                                    arrowPointAtCenter
                                                    title="Are you sure to delete the selected template(s)?"
                                                    okText="Yes"
                                                    cancelText="No"
                                                    placement="bottomLeft"
                                                    onConfirm={
                                                        () => {}
                                                        // _deleteDocuments(
                                                        //     selectedRowKeys as number[]
                                                        // )
                                                    }
                                                >
                                                    <Button danger icon={<DeleteFilled />}></Button>
                                                </Popconfirm>
                                            </Tooltip> */}
                                        </Space>
                                    ) : (
                                        ''
                                    )
                                }
                            />
                        </Col>
                    </Row>
                    <EZTable
                        ref={EZTableRef}
                        className="eztable-hide-delete-editable"
                        pagination={{
                            pageSize: 50,
                            pageSizeOptions: [20, 50, 100],
                            position: ['bottomLeft', 'topLeft'],
                        }}
                        queryParams={queryParams}
                        columns={_columns}
                        size="small"
                        rowKey="email_template_id"
                        fetchData={_fetchData}
                        scroll={{ x: 2000 }}
                        // rowSelection={{
                        //     selectedRowKeys: selectedRowKeys,
                        //     onChange: (newSelectedRowKeys: React.Key[]) => {
                        //         setSelectedRowKeys(newSelectedRowKeys);
                        //     },
                        //     alwaysShowAlert: true,
                        //     columnWidth: '20px',
                        //     selections: [Table.SELECTION_NONE],
                        // }}
                        tableAlertRender={false}
                        toolBarComponents={[
                            <Button
                                key={`toolBarComponents-2-task-list`}
                                className="ml-2 mr-2 ez-action-btn"
                                onClick={() => _showAddNewTemplateModal()}
                            >
                                <PlusOutlined />
                                Add New Template
                            </Button>,
                        ]}
                    />
                </Col>
            </Row>
        </div>
    );
};
