// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Typography } from 'antd';
import { Gutter } from 'antd/lib/grid/row';

// EZ web-app utils import
// EZ web-app APIs import
import { getTransactionDetail, updateTransactionDetail } from 'utils/ez_api/transactionAPIs';
// EZ web-app types import
import {
    STATUS_LIST_RENTAL,
    TransactionDetailTabProps,
    TransactionDetailType,
} from 'types/transaction';
// EZ web-app components import
import { EZCopyable, EZCurrencyFormat, EZDateFormat, EZInputNumber } from 'components';
import { showNotification } from 'utils/notificationUtil';

import { GeneralInformationSection } from '../partials/ContractInfo_GeneralInformation';
import { PartiesSection } from '../partials/ContractInfo_Parties';
import { CustomFieldsSection } from '../partials/ContractInfo_CustomFields';

// EZ web-app styles and assets import

const { Text } = Typography;

const colLabelSpan = 14;
const colValueSpan = 10;
const rowFormViewGutter: [Gutter, Gutter] = [12, 6];
const section1RowGutter: [Gutter, Gutter] = [48, 8];
const section2RowGutter: [Gutter, Gutter] = [48, 48];

export const SubTab_ContractInfo: React.FC<TransactionDetailTabProps> = ({
    contractID,
    manualReloadCount,
    setIsLoadingSubTabData,
    isRentalProperty,
}) => {
    const [transactionData, setTransactionData] = useState<TransactionDetailType>();

    const _reloadSubTabData = (displayLoadingIndicator: boolean = false) => {
        if (displayLoadingIndicator) setIsLoadingSubTabData(true);
        getTransactionDetail(contractID, 'contract_info')
            .then(res => {
                if (res?.data?.contract_id) {
                    const transactionDataTemp: TransactionDetailType = res.data;

                    if (transactionDataTemp.contract_additional_info_categories?.length) {
                        transactionDataTemp.contract_additional_info_categories.forEach(
                            category => {
                                category.contract_additional_info_preferences?.forEach(field => {
                                    if (field.contract_additional_info_field.class === 'dropdown') {
                                        const optValue =
                                            field.contract_additional_info_value?.field_value;
                                        if (optValue) {
                                            const optObject =
                                                field.contract_additional_info_options.find(
                                                    optValObj =>
                                                        optValObj.additional_info_fields_option_id ===
                                                        parseInt(optValue)
                                                );
                                            if (optObject) {
                                                field.field_value = optObject.option_value;
                                                field.field_option_id =
                                                    optObject.additional_info_fields_option_id;
                                            }
                                        }
                                    } else {
                                        field.field_value =
                                            field.contract_additional_info_value?.field_value;
                                    }
                                });
                            }
                        );
                    }

                    setTransactionData(transactionDataTemp);
                }
            })
            .finally(() => {
                setIsLoadingSubTabData(false);
            });
    };

    useEffect(() => {
        _reloadSubTabData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez_detail_contract_info">
            <Row>
                <Col span={24}>
                    <Row gutter={section1RowGutter}>
                        <Col span={8}>
                            <GeneralInformationSection
                                contractID={contractID}
                                transactionData={transactionData}
                                reloadSubTabData={_reloadSubTabData}
                                isRentalProperty={isRentalProperty}
                            />
                        </Col>
                        <PartiesSection
                            contractID={contractID}
                            transactionData={transactionData}
                            reloadSubTabData={_reloadSubTabData}
                            isRentalProperty={isRentalProperty}
                        />
                    </Row>
                </Col>
                <Col span={24} className="mt-5 pt-4">
                    <CustomFieldsSection
                        contractID={contractID}
                        transactionData={transactionData}
                        reloadSubTabData={_reloadSubTabData}
                        isRentalProperty={isRentalProperty}
                    />
                </Col>
            </Row>
        </div>
    );
};
