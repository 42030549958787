// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Button, Col, Divider, Form, Input, Popconfirm, Row, Typography } from 'antd';
import {
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    InfoCircleOutlined,
    SaveFilled,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';

// EZ web-app utils import
import { showMessage } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    addNewDocumentFolder,
    addNewOffice,
    deleteDocumentFolder,
    deleteOffice,
    getDocumentFolders,
    updateDocumentFolder,
    updateOffice,
} from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import { DocFolderType } from 'types/transaction';
import { getAllOffices } from 'utils/ez_api/officeAPIs';
import { OfficeType } from 'types/account';
import { EZDateFormat } from 'components';
// EZ web-app components import

// EZ web-app styles and assets import

export const OfficeSetting: React.FC<{ tabKey: string; activeTabKey: string }> = ({}) => {
    const [form] = Form.useForm();

    const [isFetchingOffices, setIsFetchingOffices] = useState(false);

    const [offices, setOffices] = useState<OfficeType[]>([]);

    const columns: ProColumns<OfficeType>[] = [
        {
            title: 'Office Name',
            dataIndex: 'office',
            key: 'office',
            width: '220px',
            formItemProps: (form, config) => {
                return {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                };
            },
            render: (_, record) => <Typography.Text strong>{record.office}</Typography.Text>,
        },
        {
            title: 'Date Added',
            dataIndex: 'date_added',
            key: 'date_added',
            width: '220px',
            editable: false,
            sorter: true,
            renderText: (_, record) => {
                return <EZDateFormat value={record.date_added} format="MM/DD/YYYY hh:mma" />;
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '180px',
            key: 'x',
            valueType: 'option',
            render: (_, record, index, action) => (
                <span>
                    {record.isparent !== 1 ? (
                        <>
                            <Button
                                className="p-0"
                                type="link"
                                icon={<EditFilled />}
                                title="Delete this list"
                                size="small"
                                onClick={() => {
                                    action?.startEditable(record.office_id);
                                }}
                            >
                                Edit Name
                            </Button>
                            <Divider type="vertical" />
                            <Popconfirm
                                placement="left"
                                title={
                                    <>
                                        Delete <b>{record.office}</b>?
                                    </>
                                }
                                onConfirm={() => _deleteOffice(record)}
                            >
                                <Button
                                    className="p-0 mr-1"
                                    type="link"
                                    icon={<DeleteOutlined />}
                                    title="Delete this office"
                                    size="small"
                                    danger
                                ></Button>
                            </Popconfirm>
                        </>
                    ) : (
                        <></>
                    )}
                </span>
            ),
        },
    ];

    const _deleteOffice = async (office: OfficeType) => {
        const resp = await deleteOffice(office.office_id);
        _fetchOffices();
        setTimeout(() => {
            showMessage('success', `Office: ${office.office} has been successfully deleted.`);
        }, 100);
        return resp;
    };

    const _saveNewName = async (office: OfficeType) => {
        const resp = await updateOffice(office.office_id, {
            office: office.office,
        });
        _fetchOffices();
        setTimeout(() => {
            showMessage(
                'success',
                `New Office Name: ${office.office} has been successfully saved.`
            );
        }, 100);
        return resp;
    };

    const _onFinish = (value: any) => {
        const newOffice = value.office;

        addNewOffice({
            office: newOffice,
        }).then(() => {
            _fetchOffices();
            form.resetFields();
            setTimeout(() => {
                showMessage(
                    'success',
                    `New Office Name: ${newOffice} has been successfully added.`
                );
            }, 100);
        });
    };

    const _fetchOffices = () => {
        setIsFetchingOffices(true);
        getAllOffices()
            .then(({ data }) => {
                setOffices(data || []);
            })
            .finally(() => {
                setIsFetchingOffices(false);
            });
    };

    useEffect(() => {
        _fetchOffices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={10} className="mt-3 pt-3">
                <Row gutter={[0, 12]}>
                    <Col span={24}>
                        <Form layout="inline" form={form} onFinish={_onFinish} requiredMark={false}>
                            <Form.Item
                                name="office"
                                rules={[{ required: true, message: "Office name can't be empty" }]}
                            >
                                <Input
                                    style={{ width: '280px' }}
                                    allowClear
                                    placeholder="Enter the new office name"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Add New Office
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={18} style={{ textAlign: 'left' }} className="text-help">
                        <InfoCircleOutlined /> Tip: If you only have one office, we recommend using
                        the Main office and not adding additional offices. You cannot delete the
                        Main office.
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}></Col>
                </Row>
            </Col>
            <Col span={14} className="mt-3">
                <ProTable
                    rowKey="office_id"
                    showSorterTooltip={false}
                    search={false}
                    loading={isFetchingOffices}
                    columns={columns}
                    pagination={{ pageSize: 100 }}
                    editable={{
                        actionRender: (_, __, defaultDom) => [defaultDom.save, defaultDom.cancel],
                        type: 'multiple',
                        onSave: async (_, newData: OfficeType, oldData) => {
                            return _saveNewName({
                                ...newData,
                                office: newData.office,
                            });
                        },
                        saveText: (
                            <>
                                <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                    Save
                                </Button>
                            </>
                        ),
                        cancelText: (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0 text-grey"
                                    type="text"
                                    title="Cancel and back"
                                    icon={<CloseOutlined />}
                                ></Button>
                            </>
                        ),
                    }}
                    dataSource={offices}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                />
            </Col>
        </Row>
    );
};
