// Core React libraries import
import React, {
    forwardRef,
    ReactText,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

// Core ANTD and 3rdparty libraries import
import { Input } from 'antd';
import type { TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult, SortOrder } from 'antd/es/table/interface';
import qs from 'qs';
import type { ActionType, ProTableProps } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import debounce from 'lodash/debounce';

// EZ types import
import { ITablePaginationConfig } from 'types/base';
// EZ utils import
import { showNotification } from 'utils/notificationUtil';
import { cloneDeep } from 'lodash';

const { Search } = Input;

export interface IAntdTablePaginationParams {
    pagination?: TablePaginationConfig;
    sorter?: SorterResult<any> | SorterResult<any>[];
    total?: number;
    sortField?: string;
    sortOrder?: string;
}

export interface IDataType {
    name: string;
    key: string;
}

export interface ITableQueryParams {
    searchKeyword: string;
    orderParams: [string, string][];
    whereParams: any;
    whereFilterParams: any;
    resetPagination?: boolean;
    silentLoading?: boolean;
}

export type IOnChangeCallbackProps = (
    data: any[],
    pagination: ITablePaginationConfig,
    replaceTableRecords?: (data: any[]) => void
) => void;

export interface IAntdProTablePropsExtended {
    fetchData?: (params: string) => Promise<any>;
    onChangeCallback?: IOnChangeCallbackProps;
    queryParams?: ITableQueryParams;
    toolBarComponents?: React.ReactNode[];
    disableNativeSearch?: boolean;

    // keyColumnName: string;
}

export type IAntdProTableProps = ProTableProps<any, any, any> & IAntdProTablePropsExtended;

export type forwardRefEZTableProps = {
    modifyData: (record: any, idValue: React.Key) => void;
    deleteByKey: (idValue: React.Key) => void;

    replaceRecords: (newData: any[]) => void;

    reload: () => void;
};

export const EZTable = React.forwardRef<forwardRefEZTableProps | undefined, IAntdProTableProps>(
    (
        {
            fetchData,
            queryParams,
            onChangeCallback,
            toolBarComponents,
            disableNativeSearch = false,
            ...props
        },
        ref
    ) => {
        const EZTableRef = useRef<ActionType>();

        const [data, setData] = useState<any[]>([]);
        const [loading, setLoading] = useState(false);

        const useAjax = fetchData ? true : false;

        const [pagination, setPagination] = useState<ITablePaginationConfig>(
            Object.assign(
                {
                    current: 1,
                    pageSize: 20,
                    position: ['topLeft', 'bottomLeft'],
                    pageSizeOptions: [10, 20],
                },
                { ...props.pagination }
            )
        );
        const [keyword, setKeyword] = useState<string>('');
        const [sort, setSort] = useState<[string, string]>();

        const _callFetchData = (params: IAntdTablePaginationParams = {}, keyword?: string) => {
            if (!useAjax) return;
            if (loading) return;
            if (fetchData) {
                setLoading(true);
                const arrOrderParams: [string, string][] = queryParams?.orderParams?.length
                    ? cloneDeep(queryParams.orderParams)
                    : [];
                if (params.sortOrder && params.sortField) {
                    arrOrderParams.splice(0, arrOrderParams.length);
                    arrOrderParams.push([
                        params.sortField,
                        params.sortOrder === 'descend' ? 'desc' : 'asc',
                    ]);
                }
                fetchData(
                    qs.stringify({
                        page: params.pagination?.current,
                        pageSize: params.pagination?.pageSize,
                        keyword: disableNativeSearch ? queryParams?.searchKeyword : keyword,
                        order: arrOrderParams,
                        where: queryParams?.whereParams,
                        whereFilter: queryParams?.whereFilterParams,
                        WebPaginationSetting: params.pagination,
                    })
                )
                    .then(response => {
                        if (response?.pagination) {
                            const newTotal = response?.pagination?.total || 0;
                            const newTotalFiltered = response?.pagination?.totalFiltered;
                            setData(response.data);
                            setPagination({
                                ...params.pagination,
                                total: newTotal,
                                totalFiltered: newTotalFiltered,
                            });
                            if (onChangeCallback) {
                                onChangeCallback(response.data || [], {
                                    ...params.pagination,
                                    total: newTotal,
                                    totalFiltered: newTotalFiltered,
                                });
                            }
                        }
                        // return response;
                    })
                    .catch(err => {
                        console.log('err', err);
                        showNotification(
                            'error',
                            'Unexpected error happened. Please try again later.'
                        );
                        // throw err;
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        };

        // const _request = async (
        //     params: {
        //         pageSize: number;
        //         current: number;
        //     },
        //     sort: Record<string, SortOrder>,
        //     filter: Record<string, ReactText[] | null>
        // ) => {
        //     if (fetchData) {
        //         const arrOrderParams: [string, string][] = queryParams?.orderParams?.length
        //             ? queryParams.orderParams
        //             : [];

        //         if (sort && Object.values(sort)[0]) {
        //             arrOrderParams.splice(0, arrOrderParams.length);
        //             arrOrderParams.push([
        //                 Object.keys(sort)[0],
        //                 Object.values(sort)[0] === 'descend' ? 'desc' : 'asc',
        //             ]);
        //         }
        //         console.log(
        //             '🚀 ~ file: EZProTable.tsx ~ line 201 ~ arrOrderParams',
        //             queryParams?.orderParams?.length ? queryParams.orderParams : ''
        //         );

        //         return fetchData(
        //             qs.stringify({
        //                 page: params.current || 1,
        //                 pageSize: params.pageSize || 10,
        //                 keyword: disableNativeSearch ? queryParams?.searchKeyword : keyword,
        //                 order: arrOrderParams,
        //                 where: queryParams?.whereParams,
        //                 whereFilter: queryParams?.whereFilterParams,
        //                 WebPaginationSetting: pagination,
        //             })
        //         )
        //             .then(async response => {
        //                 setAjaxLoadCount(ajaxLoadCount + 1);
        //                 if (response?.pagination) {
        //                     const newTotal = response?.pagination?.total || 0;
        //                     const newTotalFiltered = response?.pagination?.totalFiltered;

        //                     return Promise.resolve({
        //                         total: newTotal,
        //                         data: transformData
        //                             ? await transformData(response.data)
        //                             : response.data,
        //                         success: true,
        //                     });
        //                 } else {
        //                     return Promise.resolve({
        //                         total: 0,
        //                         data: [],
        //                         success: false,
        //                     });
        //                 }
        //             })
        //             .catch(err => {
        //                 console.log('err', err);
        //                 showNotification(
        //                     'error',
        //                     'Unexpected error happened. Please try again later.'
        //                 );
        //                 return Promise.resolve({
        //                     total: 0,
        //                     data: [],
        //                     success: false,
        //                 });
        //             });
        //     }

        //     return Promise.resolve({
        //         total: 0,
        //         data: [],
        //         success: true,
        //     });
        // };

        const _onDataSourceChange = (dataSource: any[]) => {};

        const _handleTableChange = (
            newPagination: TablePaginationConfig,
            filters: Record<string, FilterValue | null>,
            sorter: SorterResult<any> | SorterResult<any>[]
        ) => {
            const sortField = (sorter as SorterResult<any>).field as string;
            const sortOrder = (sorter as SorterResult<any>).order as string;

            if (useAjax) {
                _callFetchData({
                    sortField: sortField,
                    sortOrder: sortOrder,
                    pagination: newPagination,
                    ...filters,
                });
            }

            if (sortField) {
                setSort([sortField, sortOrder]);
            } else {
                setSort(undefined);
            }
        };

        const _refetch = (resetPagination: boolean = false) => {
            if (resetPagination) {
                _callFetchData(
                    {
                        pagination: { ...pagination, current: 1 },
                        sortField: sort?.[0],
                        sortOrder: sort?.[1],
                    },
                    keyword
                );
            } else
                _callFetchData({ pagination, sortField: sort?.[0], sortOrder: sort?.[1] }, keyword);
        };

        const _reload = () => {
            if (queryParams) {
                queryParams.silentLoading = false;
            }

            _callFetchData({ pagination, sortField: sort?.[0], sortOrder: sort?.[1] }, keyword);
        };

        const _triggerUpdateKeywordDelayed = debounce((newKeyword: string) => {
            _callFetchData({ pagination, sortField: sort?.[0], sortOrder: sort?.[1] }, newKeyword);
            setKeyword(newKeyword);
        }, 500);

        useImperativeHandle(ref, () => ({
            modifyData: (record: any, idValue: React.Key) => {
                let dataIdx = data?.findIndex(item => item[props.rowKey as string] === idValue);

                if (dataIdx !== -1) {
                    data[dataIdx] = { ...record };
                    setData([...data]);
                }
            },
            deleteByKey: (idValue: React.Key) => {
                setData([...data.filter(item => item[props.rowKey as string] !== idValue)]);
            },
            replaceRecords: (newData: any[]) => {
                setData([...newData]);
            },
            reload: () => {
                _reload();
            },
        }));

        useEffect(() => {
            if (useAjax) {
                _refetch(queryParams?.resetPagination === false ? false : true);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [queryParams]);

        return (
            <ProTable
                actionRef={EZTableRef}
                showSorterTooltip={false}
                search={false}
                dataSource={data}
                // request={_request}
                loading={queryParams?.silentLoading ? false : loading}
                onDataSourceChange={_onDataSourceChange}
                onChange={_handleTableChange}
                options={{
                    density: false,
                    // reload: true,
                    reload: _reload,
                }}
                toolBarRender={() => [
                    !disableNativeSearch ? (
                        <Search
                            // autoFocus
                            className="no-print"
                            allowClear
                            placeholder="Enter the keyword(s)"
                            style={{ width: '300px' }}
                            size="middle"
                            onChange={e => _triggerUpdateKeywordDelayed(e?.target?.value)}
                        />
                    ) : undefined,
                    toolBarComponents?.length ? toolBarComponents : '',
                ]}
                {...props}
                pagination={pagination}
                className={`${props.className ? props.className : ''} ez-pro-table ${
                    pagination?.total && pagination.total > 0 ? 'has-rows' : 'no-row'
                } ${
                    pagination?.position?.length
                        ? pagination?.position?.map(item => item.toString()).join(' ')
                        : ''
                } ${loading ? 'loading' : ''} `}
            />
        );
    }
);
