import {
    ACCOUNT_ROLES,
    ACCOUNT_ROLE_HAS_ACCESS_TO_ADMIN_AREA,
    AccountPermissionType,
    PERMISSION_TYPE,
} from 'types/account';
import { TAB_CATEGORY } from 'types/webAppTab';
import { showMessage } from './notificationUtil';

export function hasPermission(
    permission: AccountPermissionType,
    tabCategory: string | 'DOCUMENT' | 'TASK' | 'ASSIGN' | 'CONTACT',
    showFeedback: boolean = false,
    allowedAccess: number[] = [
        PERMISSION_TYPE.YES,
        PERMISSION_TYPE.VIEW_ONLY,
        PERMISSION_TYPE.FULL_ACCESS,
    ]
) {
    let isAllowed = false;

    if (!permission) return false;

    // owner -> accountrole_id === 1
    if (permission.accountrole_id === 1) {
        isAllowed = true;
    } else {
        switch (tabCategory) {
            case TAB_CATEGORY.SUMMARY:
            case TAB_CATEGORY.MY_PROFILE:
                isAllowed = true;
                break;

            case TAB_CATEGORY.CALENDAR:
            case TAB_CATEGORY.REPORT:
            case TAB_CATEGORY.TRANSACTION:
                if (allowedAccess.includes(permission.toggle)) {
                    isAllowed = true;
                }
                break;

            case 'CONTACT':
            case TAB_CATEGORY.ADDRESS_BOOK:
            case TAB_CATEGORY.PROSPECT:
                if (allowedAccess.includes(permission.contacts_toggle)) {
                    isAllowed = true;
                }
                break;

            case TAB_CATEGORY.DOC_LIBRARY:
            case TAB_CATEGORY.DOC_REVIEW:
            case 'DOCUMENT':
                if (allowedAccess.includes(permission.documents_toggle)) {
                    isAllowed = true;
                }
                break;

            case 'NOTES':
                if (allowedAccess.includes(permission.notes_toggle)) {
                    isAllowed = true;
                }
                break;

            case TAB_CATEGORY.TASK:
            case 'TASK':
                if (allowedAccess.includes(permission.tasks_toggle)) {
                    isAllowed = true;
                }
                break;

            case 'ASSIGN':
                if (allowedAccess.includes(permission.assigntasks_toggle)) {
                    isAllowed = true;
                }
                break;

            case TAB_CATEGORY.EMAIL:
                if (allowedAccess.includes(permission.emails_toggle)) {
                    isAllowed = true;
                }
                break;

            case TAB_CATEGORY.ADMIN_SETTING: {
                if (ACCOUNT_ROLE_HAS_ACCESS_TO_ADMIN_AREA.includes(permission.accountrole_id)) {
                    isAllowed = true;
                }
                break;
            }

            default:
                break;
        }
    }

    if (showFeedback && !isAllowed) {
        showMessage(
            'error',
            `You aren't allowed to access the page. Please ask your administrator for granting you the access.`
        );
    }

    return isAllowed;
}

export const getAccountRole = (accountRoleID?: number) => {
    if (accountRoleID) {
        const role = ACCOUNT_ROLES.find(role => role.accountrole_id === accountRoleID);
        return role?.accountrole;
    } else {
        return '';
    }
};
