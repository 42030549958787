import React, { useCallback, useState } from 'react';

import { AutoComplete, AutoCompleteProps } from 'antd';
import { debounce } from 'lodash';

import { AddressbookType } from 'types/contact';

import { getAllAddressBooks } from 'utils/ez_api/contactAPIs';

type EZAutocompletePartyProps = AutoCompleteProps<any> & {
    ifEmptyText?: string;
};

type EZAutocompletePartyOptProps = AddressbookType & {
    label: string;
    value: string;
    key: React.Key;
};

export const EZAutocompleteParty: React.FC<EZAutocompletePartyProps> = ({
    ifEmptyText = '-',
    ...props
}) => {
    const [searchOptions, setSearchOptions] = useState<EZAutocompletePartyOptProps[]>([]);

    const _onSearch = useCallback(
        debounce(searchKeyword => {
            getAllAddressBooks({
                keyword: searchKeyword,
                where: {
                    '!fullname': null,
                },
            })
                .then((response: any) => {
                    if (response.data?.length) {
                        setSearchOptions(
                            response.data?.map((iData: AddressbookType) => ({
                                ...iData,
                                label: iData.fullname,
                                value: iData.fullname,
                                key: iData.addressbook_id,
                            }))
                        );
                    } else {
                        setSearchOptions([]);
                    }
                })
                .finally(() => {});
        }, 500),
        []
    );

    return (
        <AutoComplete
            options={searchOptions}
            onSearch={_onSearch}
            placeholder="Enter the name"
            {...props}
        />
    );
};
