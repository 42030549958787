// Core React libraries import
import React, { useContext, useEffect, useRef, useState } from 'react';

// Core ANTD and other libs import
import { Avatar, Button, Col, Divider, Form, List, Row, Select, Tag, Typography } from 'antd';
import {
    CheckCircleOutlined,
    CloudServerOutlined,
    FlagTwoTone,
    MailOutlined,
    PhoneOutlined,
    PicLeftOutlined,
    PlusOutlined,
    SolutionOutlined,
    StopOutlined,
} from '@ant-design/icons';

// EZ web-app types import
import { ITabContentProps } from 'types/webAppTab';
import { EZList, forwardRefEZListProps, IListQueryParams } from 'components/list/EZList';
import {
    ProspectDataType,
    ProspectFlagType,
    ProspectFollowupCodeType,
    ProspectGroupType,
} from 'types/prospect';

// EZ web-app APIs importutils/ez_api/prospectAPIs/prospectAPIs
import {
    generateDefaultQuestions,
    getAllProspects,
    getProspectMetaOptions,
    getProspectQuickStatsByIDs,
} from 'utils/ez_api/prospect/prospectAPIs';

// Core EZ app level import
import { HomeContext } from 'context/EZContext';
import { EZDateFormat } from 'components';
import { getInitialFromFullname } from 'utils/commonUtil';

import './Prospect.scss';
import { AddNewProspectModal } from './modal/Modal_addNewProspectModal';

const { Text, Paragraph } = Typography;
const { Option } = Select;

const defaultQueryParams: IListQueryParams = {
    searchKeyword: '',
    orderParams: [['prospect_id', 'desc']],
    whereParams: {},
    whereFilterParams: {},
};

const ProspectTab: React.FC<ITabContentProps> = () => {
    const HomeCtx = useContext(HomeContext);

    const listRef = useRef<forwardRefEZListProps>();

    const [form] = Form.useForm();

    const [queryParams, setQueryParams] = useState<IListQueryParams>(defaultQueryParams);

    const [groups, setGroups] = useState<ProspectGroupType[]>([]);
    const [flags, setFlags] = useState<ProspectFlagType[]>([]);
    const [followupCodes, setFollowupCodes] = useState<ProspectFollowupCodeType[]>([]);

    const [isModalAddProspectOpen, setIsModalAddProspectOpen] = useState(false);

    const _fetchProspects: (EZQueryParams: string) => Promise<any> = async EZQueryParams => {
        const result = await getAllProspects(EZQueryParams);
        return result;
    };

    const _viewProspectDetail = (record: ProspectDataType) => {
        HomeCtx.openProspectDetailTab?.(record.prospect_id, record.fullname, { ...record });
    };

    const _fetchMetaOptions = () => {
        Promise.all([
            getProspectMetaOptions('groups', true).then(({ data }) => data),
            getProspectMetaOptions('flags', true).then(({ data }) => data),
            getProspectMetaOptions('followup_codes', true).then(({ data }) => data),
        ]).then(resolveValues => {
            setGroups(resolveValues?.[0] || []);
            setFlags(resolveValues?.[1] || []);
            setFollowupCodes(resolveValues?.[2] || []);
        });
    };

    const _resetFilter = () => {
        setQueryParams(defaultQueryParams);
        form.resetFields();
    };

    const _onValuesChange = (changedValues: any, values: any) => {
        const fieldName = Object.keys(changedValues)?.[0];
        const fieldValue = changedValues[fieldName];

        if (fieldName) {
            setQueryParams({
                ...queryParams,
                whereFilterParams: {
                    ...queryParams.whereFilterParams,
                    [fieldName]: fieldValue,
                },
            });
        }
    };

    const _onChangeCallback = (records: ProspectDataType[]) => {
        if (records.length) {
            getProspectQuickStatsByIDs(records.map(item => item.prospect_id)).then(resp => {
                const respQuickStats = resp.data as {
                    complete_doc_count: number;
                    complete_task_count: number;
                    incomplete_doc_count: number;
                    incomplete_task_count: number;
                    prospect_id: number;
                    prospect_note_count: number;
                }[];

                if (respQuickStats && respQuickStats.length) {
                    respQuickStats.forEach(stat => {
                        const correspondingRecord = records.find(
                            r => r.prospect_id === stat.prospect_id
                        );

                        if (correspondingRecord) {
                            correspondingRecord.quickStat = {
                                taskStatInfo: {
                                    incomplete: stat.incomplete_task_count,
                                    complete: stat.complete_task_count,
                                    total: stat.incomplete_task_count + stat.complete_task_count,
                                },
                                documentStatInfo: {
                                    incomplete: stat.incomplete_doc_count,
                                    complete: stat.complete_doc_count,
                                    total: stat.incomplete_doc_count + stat.complete_doc_count,
                                },
                                noteStatInfo: {
                                    total: stat.prospect_note_count,
                                },

                                priceRange: '',
                            };
                        }
                    });
                    listRef.current?.replaceAllData(records);
                }
            });
        }
    };

    const _generateDefaultQuestions = () => {
        generateDefaultQuestions();
    };

    const _showAddProspectModal = () => {
        setIsModalAddProspectOpen(true);
    };

    const _onSuccess = () => {
        listRef.current?.reload();
    };

    useEffect(() => {
        _fetchMetaOptions();
        _generateDefaultQuestions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez-tab-prospects">
            <>
                <AddNewProspectModal
                    isModalVisible={isModalAddProspectOpen}
                    setIsModalVisible={setIsModalAddProspectOpen}
                    onSuccess={_onSuccess}
                />
            </>
            <div className="mb-4 ez-sub-content-wrapper ez-sub-content-header-section">
                <Form form={form} onValuesChange={_onValuesChange}>
                    <Row gutter={16}>
                        <Col span={24} className="text-right">
                            <Button type="link" onClick={_resetFilter}>
                                <Text underline>Reset Filter</Text>
                            </Button>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="inquirytype_id" noStyle>
                                <Select
                                    style={{ width: '100%' }}
                                    listHeight={400}
                                    placeholder={
                                        <span>
                                            <Text>Client Type: </Text>
                                            <Text strong>All</Text>
                                        </span>
                                    }
                                    allowClear
                                >
                                    <Option value="1">
                                        <span>
                                            <Text>Buyer</Text>
                                        </span>
                                    </Option>
                                    <Option value="2">
                                        <span>
                                            <Text>Seller</Text>
                                        </span>
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="followup_id" noStyle>
                                <Select
                                    style={{ width: '100%' }}
                                    listHeight={400}
                                    placeholder={
                                        <span>
                                            <Text>Follow-up Code: </Text>
                                            <Text strong>All</Text>
                                        </span>
                                    }
                                    options={followupCodes}
                                    fieldNames={{
                                        value: 'followup_id',
                                        label: 'code',
                                    }}
                                    allowClear
                                ></Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="group_id" noStyle>
                                <Select
                                    style={{ width: '100%' }}
                                    listHeight={400}
                                    placeholder={
                                        <span>
                                            <Text>Group: </Text>
                                            <Text strong>All</Text>
                                        </span>
                                    }
                                    options={groups}
                                    fieldNames={{
                                        value: 'group_id',
                                        label: 'group_name',
                                    }}
                                    allowClear
                                ></Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="flag_id" noStyle>
                                <Select
                                    style={{ width: '100%' }}
                                    listHeight={400}
                                    placeholder={
                                        <span>
                                            <Text>Flag: </Text>
                                            <Text strong>All</Text>
                                        </span>
                                    }
                                    allowClear
                                >
                                    {flags?.map(flag => (
                                        <Select.Option value={flag.flag_id}>
                                            {flag.flag_id !== 0 ? (
                                                <FlagTwoTone
                                                    twoToneColor={
                                                        flag.prospects_default_flag?.flag_color
                                                    }
                                                />
                                            ) : (
                                                <StopOutlined />
                                            )}
                                            <span className="ml-2 text-capitalize">
                                                {flag.prospects_default_flag?.flag_color}
                                            </span>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col span={8}>
                            <Input.Group compact>
                                <Form.Item name="filter_date_type" noStyle>
                                    <Select
                                        style={{ width: '41%' }}
                                        placeholder="Choose Date Filter"
                                        defaultActiveFirstOption
                                        allowClear
                                    >
                                        <Option value="1">
                                            <span>
                                                <Text>Date Added</Text>
                                            </span>
                                        </Option>
                                        <Option value="2">
                                            <span>
                                                <Text>Last Contacted Date</Text>
                                            </span>
                                        </Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item name="transaction_date_range" noStyle>
                                    <DatePicker.RangePicker
                                        style={{ width: '59%' }}
                                        format="MMM DD, YYYY"
                                    />
                                </Form.Item>
                            </Input.Group>
                        </Col> */}
                    </Row>
                </Form>
            </div>
            <div className="ez-sub-content-wrapper ez-sub-content-section">
                <Row>
                    <Col span={24}>
                        <EZList
                            ref={listRef}
                            onChangeCallback={_onChangeCallback}
                            itemLayout="vertical"
                            rowKey="prospect_id"
                            paginationConfig={{
                                pageSize: 20,
                            }}
                            queryParams={queryParams}
                            fetchData={_fetchProspects}
                            toolBarComponents={[
                                <Button
                                    className="ez-action-btn ml-3"
                                    onClick={() => _showAddProspectModal()}
                                >
                                    <PlusOutlined />
                                    Add New Prospect
                                </Button>,
                                // <AddNewProspect />,
                            ]}
                            renderItem={(record: ProspectDataType) => (
                                <List.Item
                                    actions={[
                                        <Button
                                            className="p-0 mr-2"
                                            type="link"
                                            icon={<PicLeftOutlined />}
                                            title="View detail this prospect"
                                            onClick={() => _viewProspectDetail(record)}
                                        >
                                            View Detail
                                        </Button>,
                                    ]}
                                >
                                    <List.Item.Meta
                                        avatar={
                                            <Avatar size={42}>
                                                {record.fullname
                                                    ? getInitialFromFullname(record.fullname)
                                                    : 'N/A'}
                                            </Avatar>
                                        }
                                        title={
                                            <span>
                                                <Text
                                                    strong
                                                    className="mr-2 cursor-pointer"
                                                    underline
                                                    onClick={() => _viewProspectDetail(record)}
                                                >
                                                    {record.fullname || '-'}
                                                </Text>
                                                {record.inquirytype_id ? (
                                                    <Tag>
                                                        {record.inquirytype_id === 1
                                                            ? 'Buyer'
                                                            : 'Seller'}
                                                    </Tag>
                                                ) : (
                                                    ''
                                                )}
                                            </span>
                                        }
                                        description={
                                            <div>
                                                <Row>
                                                    <Col span={8}>
                                                        <Row gutter={[0, 4]}>
                                                            <Col span={24}>
                                                                <Text className="mr-2">
                                                                    <MailOutlined className="mr-2" />
                                                                    {record.email ? (
                                                                        <Text copyable>
                                                                            {record.email}
                                                                        </Text>
                                                                    ) : (
                                                                        <Text
                                                                            type="secondary"
                                                                            italic
                                                                        >
                                                                            Email is empty
                                                                        </Text>
                                                                    )}
                                                                </Text>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Text className="mr-2">
                                                                    {record.prospects_phones
                                                                        ?.length ? (
                                                                        <span className="">
                                                                            {record.prospects_phones.map(
                                                                                phone => (
                                                                                    <>
                                                                                        <span>
                                                                                            <PhoneOutlined className="mr-2" />
                                                                                            {phone.category ? (
                                                                                                <Text
                                                                                                    code
                                                                                                >
                                                                                                    {
                                                                                                        phone.category
                                                                                                    }
                                                                                                </Text>
                                                                                            ) : (
                                                                                                ''
                                                                                            )}
                                                                                            &nbsp;
                                                                                            {
                                                                                                phone.phone
                                                                                            }
                                                                                        </span>
                                                                                        <br />
                                                                                    </>
                                                                                )
                                                                            )}
                                                                        </span>
                                                                    ) : (
                                                                        <>
                                                                            <PhoneOutlined className="mr-2" />
                                                                            <Text
                                                                                type="secondary"
                                                                                italic
                                                                            >
                                                                                Phone is empty
                                                                            </Text>
                                                                        </>
                                                                    )}
                                                                </Text>
                                                            </Col>
                                                            <Col span={24}>
                                                                <div className="mt-1">
                                                                    <Text
                                                                        className="mr-2"
                                                                        type="secondary"
                                                                    >
                                                                        <span className="mr-2">
                                                                            Registration Date:
                                                                        </span>
                                                                        <EZDateFormat
                                                                            value={
                                                                                record.registration_date
                                                                            }
                                                                            format="MMM DD, YYYY"
                                                                        />
                                                                    </Text>
                                                                </div>
                                                                <div className="mt-1">
                                                                    <Text
                                                                        className="mr-2"
                                                                        type="secondary"
                                                                    >
                                                                        <span className="mr-2">
                                                                            Last Contacted:
                                                                        </span>
                                                                        <EZDateFormat
                                                                            value={
                                                                                record.prospects_last_visit_date_id
                                                                            }
                                                                            format="MMM DD, YYYY"
                                                                        />
                                                                    </Text>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Divider
                                                        type="vertical"
                                                        style={{ height: 'auto' }}
                                                    ></Divider>
                                                    <Col span={8}>
                                                        <div>
                                                            <Tag className={`tag-rounded mr-1 `}>
                                                                <Text
                                                                    type={
                                                                        record.quickStat
                                                                            ?.taskStatInfo.total
                                                                            ? undefined
                                                                            : 'secondary'
                                                                    }
                                                                >
                                                                    <CheckCircleOutlined className="mr-2" />
                                                                    {`${
                                                                        record.quickStat
                                                                            ?.taskStatInfo
                                                                            .complete || 0
                                                                    } / ${
                                                                        record.quickStat
                                                                            ?.taskStatInfo.total ||
                                                                        0
                                                                    } `}
                                                                    tasks are completed
                                                                </Text>
                                                            </Tag>
                                                        </div>
                                                        {/* <div>
                                                            <Tag
                                                                className={`tag-rounded mr-1 mt-2`}
                                                            >
                                                                <HomeOutlined className="mr-2" />3
                                                                Listing(s)
                                                            </Tag>
                                                        </div> */}
                                                        {/* <div>
                                                            <Tag
                                                                className={`tag-rounded mr-1 mt-2`}
                                                            >
                                                                <Text
                                                                    type={
                                                                        record.quickStat?.priceRange
                                                                            ? undefined
                                                                            : 'secondary'
                                                                    }
                                                                >
                                                                    <DollarCircleOutlined className="mr-2" />
                                                                    {`${
                                                                        record.quickStat
                                                                            ?.priceRange || '-'
                                                                    } `}
                                                                    Price Range
                                                                </Text>
                                                            </Tag>
                                                        </div> */}
                                                        <div>
                                                            <Tag
                                                                className={`tag-rounded mr-1 mt-2`}
                                                            >
                                                                <Text
                                                                    type={
                                                                        record.quickStat
                                                                            ?.documentStatInfo
                                                                            ?.total
                                                                            ? undefined
                                                                            : 'secondary'
                                                                    }
                                                                >
                                                                    <CloudServerOutlined className="mr-2" />
                                                                    {`${
                                                                        record.quickStat
                                                                            ?.documentStatInfo
                                                                            ?.total || 0
                                                                    } `}
                                                                    Document(s)
                                                                </Text>
                                                            </Tag>
                                                        </div>
                                                        <div>
                                                            <Tag
                                                                className={`tag-rounded mr-1 mt-2`}
                                                            >
                                                                <Text
                                                                    type={
                                                                        record.quickStat
                                                                            ?.noteStatInfo?.total
                                                                            ? undefined
                                                                            : 'secondary'
                                                                    }
                                                                >
                                                                    <SolutionOutlined className="mr-2" />
                                                                    {`${
                                                                        record.quickStat
                                                                            ?.noteStatInfo?.total ||
                                                                        0
                                                                    } `}
                                                                    Note(s)
                                                                </Text>
                                                            </Tag>
                                                        </div>
                                                    </Col>
                                                    <Divider
                                                        type="vertical"
                                                        style={{ height: 'auto' }}
                                                    ></Divider>
                                                    <Col span={6}>
                                                        <div>
                                                            <Paragraph>
                                                                {record.address ? (
                                                                    [record.address, record.city]
                                                                        .filter(item => item)
                                                                        .join(', ')
                                                                ) : (
                                                                    <Text type="secondary" italic>
                                                                        Address is empty
                                                                    </Text>
                                                                )}
                                                            </Paragraph>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ProspectTab;
