// Core React libs import
import React, { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rd party libs import
import { Select } from 'antd';
import { cloneDeep } from 'lodash';

// import type { SelectProps, BaseOptionType, DefaultOptionType } from 'antd/es/select';
import type { SelectProps } from 'antd/es/select';

// EZ level libs import
import { PROPERTY_TYPES } from 'types/transaction';
import { getPropertyTypeSetting } from 'utils/ez_api/AdminAPIs';
import { PropTypePreference } from 'types/admin';
import { HomeContext } from 'context/EZContext';

let defaultPropTypes = cloneDeep(PROPERTY_TYPES);

let hasSettingAPIBeenCalled = false;

export interface EZSelectPropertyTypeProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {}

export function EZSelectPropertyType({ ...props }) {
    const [options, setOptions] = useState<typeof PROPERTY_TYPES>(defaultPropTypes);

    const { triggerRefetchDataList, setTriggerRefetchDataList } = useContext(HomeContext);
    const hasTriggerRefetchData = triggerRefetchDataList.includes('property_type');

    const _fetchSetting = () => {
        if (hasSettingAPIBeenCalled && !hasTriggerRefetchData) return;

        getPropertyTypeSetting().then(({ data }) => {
            hasSettingAPIBeenCalled = true;
            if (data) {
                const propTypePreference = (data as PropTypePreference[])?.map(
                    pref => pref.type_id || []
                );

                if (propTypePreference?.length) {
                    defaultPropTypes = [
                        ...defaultPropTypes.filter(
                            opt => !propTypePreference.includes(opt.type_id)
                        ),
                    ];

                    setOptions([...defaultPropTypes]);
                }
            }
            if (hasTriggerRefetchData) {
                const newTriggerRefetchDataList = triggerRefetchDataList.filter(
                    key => key !== 'property_type'
                );
                setTriggerRefetchDataList(newTriggerRefetchDataList);
            }
        });
    };

    useEffect(() => {
        _fetchSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            style={{ width: '100%' }}
            showSearch
            allowClear
            placeholder="Select Property Type"
            fieldNames={{
                label: 'type_name',
                value: 'type_id',
            }}
            {...props}
            options={options}
        ></Select>
    );
}
