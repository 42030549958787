export enum CacheEnum {
    OpenTabs = 'OpenTabs',
}

export function saveJson(key: CacheEnum, data: Object) {
    localStorage.setItem(key, JSON.stringify(data));
}
export function getJson(key: CacheEnum): Object {
    return JSON.parse(localStorage.getItem(key) ?? '{}');
}

export function saveObjectToLocalStorage(key: string, data: object) {
    localStorage.setItem(key, JSON.stringify(data));
}

export function getObjectFromLocalStorage(key: string): object {
    return JSON.parse(localStorage.getItem(key) ?? '{}');
}
