// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Modal, Form, Input, Select, Typography, Divider, Button } from 'antd';
import moment from 'moment';

// EZ web-app utils import
// EZ web-app types import
// EZ web-app components import
import { showNotification } from 'utils/notificationUtil';
import { EZAutocompleteCity, EZDatePicker, EZSelectUSState } from 'components';
import { AccountCompactDataType } from 'types/account';
import { SelectProps } from 'antd/es/select';
import {
    ProspectDataType,
    ProspectFlagType,
    ProspectFollowupCodeType,
    ProspectGroupType,
} from 'types/prospect';
import { getProspectMetaOptions, updateProspectDetail } from 'utils/ez_api/prospect/prospectAPIs';
import { FlagTwoTone, MinusCircleOutlined, PlusOutlined, StopOutlined } from '@ant-design/icons';

// EZ web-app styles and assets import

const { Text } = Typography;

export const PHONE_CATEGORY_OPTS: SelectProps['options'] = [
    {
        label: 'Best',
        value: 'Best',
    },
    {
        label: 'Cell',
        value: 'Cell',
    },
    {
        label: 'Fax',
        value: 'Fax',
    },
    {
        label: 'Work',
        value: 'Work',
    },
];

export const EditProspectFormContent: React.FC<{
    mode?: 'ADD' | 'EDIT';
    setIsBuyer?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsBuyer, mode = 'EDIT' }) => {
    const [groups, setGroups] = useState<ProspectGroupType[]>([]);
    const [flags, setFlags] = useState<ProspectFlagType[]>([]);
    const [followupCodes, setFollowupCodes] = useState<ProspectFollowupCodeType[]>([]);

    const _fetchMetaOptions = () => {
        Promise.all([
            getProspectMetaOptions('groups', true).then(({ data }) => data),
            getProspectMetaOptions('flags', true).then(({ data }) => data),
            getProspectMetaOptions('followup_codes', true).then(({ data }) => data),
        ]).then(resolveValues => {
            setGroups(resolveValues?.[0] || []);
            setFlags(resolveValues?.[1] || []);
            setFollowupCodes(resolveValues?.[2] || []);
        });
    };

    useEffect(() => {
        _fetchMetaOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            {/* <Divider orientation="left" className="">
                <b>Personal Information</b>
            </Divider> */}
            <Row gutter={20}>
                <Col span={18}>
                    <Form.Item
                        label="Fullname"
                        name="fullname"
                        rules={[{ required: true, message: "Name can't be empty" }]}
                    >
                        <Input placeholder="Enter the fullname" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Client Type" name="inquirytype_id">
                        <Select allowClear showArrow placeholder="Select Type">
                            <Select.Option value={1}>Buyer</Select.Option>
                            <Select.Option value={2}>Seller</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                        <Input placeholder="Enter the email" />
                    </Form.Item>
                </Col>
                <Col span={15}>
                    <Form.Item label="Phone(s)" className="mb-0">
                        <Form.List name="prospects_phones">
                            {(fields, { add, remove }) => (
                                <Row gutter={[0, 8]}>
                                    {fields.map((field, index) => (
                                        <Col span={24}>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'category']}
                                                noStyle
                                            >
                                                <Select
                                                    style={{ width: '45%' }}
                                                    options={PHONE_CATEGORY_OPTS}
                                                    placeholder="Select Category"
                                                ></Select>
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'phone']}
                                                noStyle
                                            >
                                                <Input
                                                    placeholder="Enter the phone number"
                                                    style={{ width: '45%' }}
                                                    className="ml-2"
                                                />
                                            </Form.Item>

                                            {index > 0 ? (
                                                <MinusCircleOutlined
                                                    onClick={() => remove(field.name)}
                                                    className="ml-2"
                                                />
                                            ) : (
                                                <MinusCircleOutlined
                                                    onClick={() => remove(field.name)}
                                                    className="ml-2"
                                                />
                                            )}
                                        </Col>
                                    ))}
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                Add Phone
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </Form.List>
                    </Form.Item>
                </Col>
                <Col span={18}>
                    <Form.Item
                        label="Address"
                        name="address"
                        // rules={[{ required: true, message: "Address can't be empty" }]}
                    >
                        <Input placeholder="Enter the address" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Country" name="country">
                        <Input placeholder="Enter the country" />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label="City" name="city">
                        <EZAutocompleteCity />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label="State" name="state">
                        <EZSelectUSState />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="ZIP" name="zip">
                        <Input placeholder="Enter the ZIP" />
                    </Form.Item>
                </Col>
            </Row>
            <Divider orientation="left" className="">
                <b>Prospect Information</b>
            </Divider>
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item label="Registration Date" name="registration_date">
                        <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Last Contacted Date" name="prospects_last_visit_date_id">
                        <EZDatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Group"
                        name="group_id"
                        tooltip="Edit the options on Admin Area page."
                    >
                        <Select
                            allowClear
                            showArrow
                            placeholder="Select Group"
                            options={groups}
                            fieldNames={{
                                value: 'group_id',
                                label: 'group_name',
                            }}
                        ></Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Follow-up Code"
                        name="followup_id"
                        tooltip="Edit the options on Admin Area page."
                    >
                        <Select
                            allowClear
                            showArrow
                            placeholder="Select Followup Code"
                            options={followupCodes}
                            fieldNames={{
                                value: 'followup_id',
                                label: 'code',
                            }}
                        ></Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label="Flag"
                        name="flag_id"
                        tooltip="Edit the options on Admin Area page."
                    >
                        <Select allowClear showArrow placeholder="Select Flag">
                            {flags?.map(flag => (
                                <Select.Option value={flag.flag_id}>
                                    {flag.flag_id !== 0 ? (
                                        <FlagTwoTone
                                            twoToneColor={flag.prospects_default_flag?.flag_color}
                                        />
                                    ) : (
                                        <StopOutlined />
                                    )}
                                    <span className="ml-2 text-capitalize">
                                        {flag.prospects_default_flag?.flag_color}
                                    </span>
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export const ModalEditProspectInfo: React.FC<{
    prospectData: ProspectDataType;
    setProspectData: React.Dispatch<React.SetStateAction<ProspectDataType>>;
    isModalVisible?: boolean;
    setIsModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    reloadProspectDetail: (showLoadingProgress: boolean) => void;
}> = ({
    prospectData,
    isModalVisible = false,
    setIsModalVisible = () => {},
    setProspectData,
    reloadProspectDetail,
}) => {
    const [form] = Form.useForm();

    const [SubmittingData, SetSubmittingData] = useState(false);

    const _onSubmitForm = (formValues?: any) => {
        SetSubmittingData(true);

        const payload = {
            ...formValues,
        };

        updateProspectDetail(prospectData.prospect_id, payload)
            .then(res => {
                if (res.data) {
                    reloadProspectDetail(false);
                    setIsModalVisible(false);
                    showNotification(
                        'success',
                        'Basic prospect info has been successfully updated.'
                    );
                }
            })
            .finally(() => {
                SetSubmittingData(false);
            });
    };

    const _setFormFieldsValue = () => {
        if (prospectData && prospectData.prospect_id) {
            const formPayload = prospectData as any;

            const registrationDateteMoment = prospectData.registration_date
                ? moment.utc(prospectData.registration_date)
                : '';
            const prospects_last_visit_date_idMoment = prospectData.prospects_last_visit_date_id
                ? moment.utc(prospectData.prospects_last_visit_date_id)
                : '';

            form.setFieldsValue({
                ...formPayload,
                registration_date:
                    registrationDateteMoment && registrationDateteMoment.isValid()
                        ? registrationDateteMoment
                        : null,
                prospects_last_visit_date_id:
                    prospects_last_visit_date_idMoment &&
                    prospects_last_visit_date_idMoment.isValid()
                        ? prospects_last_visit_date_idMoment
                        : null,

                group_id: prospectData.group_id || null,
                inquirytype_id: prospectData.inquirytype_id || null,
                followup_id: prospectData.followup_id || null,
                flag_id: prospectData.flag_id || null,
                type_id: prospectData.type_id || null,
            });
        } else {
            form.setFieldsValue({
                status_id: 1,
                flag_id: 1,
            });
        }
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (isModalVisible) {
            _setFormFieldsValue();
        }
    }, [prospectData]);

    useEffect(() => {
        if (isModalVisible) {
            _setFormFieldsValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal
                title={`Edit basic information`}
                visible={isModalVisible}
                onOk={() => form.submit()}
                onCancel={() => setIsModalVisible(false)}
                okText="Save Changes"
                confirmLoading={SubmittingData}
                width={700}
                style={{ top: 40 }}
                // bodyStyle={{ overflowY: 'auto', height: '600px' }}
            >
                <Form layout="vertical" form={form} onFinish={_onSubmitForm} requiredMark={false}>
                    <EditProspectFormContent />
                </Form>
            </Modal>
        </>
    );
};
