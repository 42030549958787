import { apiUrl } from '../../envUtil';
import { APIRequest } from '../../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getProspectContacts = (prospect_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `prospect/${prospect_id}/contacts` + (params ? `?${params}` : ''),
    });
};

export const updateProspectContact = (prospect_id: number, payload: any, contact_id?: number) => {
    if (contact_id) {
        Mixpanel.track('app.edit_prospect', {
            prospect_id,
            sub_tab: 'additional_contacts',
            action: 'edit',
        });
    } else {
        Mixpanel.track('app.edit_prospect', {
            prospect_id,
            sub_tab: 'additional_contacts',
            action: 'add',
        });
    }

    return APIRequest({
        method: contact_id ? 'put' : 'post',
        url:
            apiUrl +
            (contact_id
                ? `prospect/${prospect_id}/contact/${contact_id}`
                : `prospect/${prospect_id}/contacts`),
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteProspectContact = (prospect_id: number, contact_id: number) => {
    Mixpanel.track('app.edit_prospect', {
        prospect_id,
        sub_tab: 'additional_contacts',
        action: 'delete',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `prospect/${prospect_id}/contact/${contact_id}`,
        useDefaultErrorNotif: true,
    });
};
