// CSS and styles import
import 'antd/dist/antd.css';
import '@ant-design/pro-components/dist/components.css';
import './index.scss';

// Core React libraries import
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// ANTD libs and 3rd parties import
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ConfigProvider } from 'antd';
import enUSIntl from 'antd/lib/locale/en_US';
import { GoogleOAuthProvider } from '@react-oauth/google';

// EZ app level import
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ForgotPassword } from './components/social/ForgotPassword';
import { ResetPassword } from './components/social/ResetPassword';
import { VerifyEmail } from './components/social/VerifyEmail';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { DocusignRedirect } from './pages/DocusignRedirect';
import { Home } from './pages/Home';
import { GOOGLE_CLIENTID, SENTRY_DSN, SENTRY_ENV, msalConfig } from './utils/envUtil';
import * as Sentry from '@sentry/react';
import { hotjar } from 'react-hotjar';

const msalInstance = new PublicClientApplication(msalConfig);

if (window.location.hash !== '') {
    console.log('hash found' + window.location.hash);
} else
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENV,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

const AppContent = () => {
    const HOTJAR_ID = Number(process.env.REACT_APP_HOTJAR_ID);
    const HOTJAR_SV = 6;
    const shouldInitializeHotjar = Boolean(HOTJAR_ID);

    // Initialize Hotjar
    React.useEffect(() => {
        if (!shouldInitializeHotjar) {
            return;
        }

        hotjar.initialize(HOTJAR_ID, HOTJAR_SV);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.StrictMode>
            <ConfigProvider locale={enUSIntl}>
                <MsalProvider instance={msalInstance}>
                    <Router basename="/">
                        <Switch>
                            <Route exact path="/" component={App} />
                            <Route path="/forgot-password" component={ForgotPassword} />
                            <Route path="/reset-password" component={ResetPassword} />
                            <Route path="/verify-email" component={VerifyEmail} />
                            <Route path="/home" component={Home} />
                            <Route path="/transaction-portal/:contract_id" component={Home} />
                            <Route path="/login" component={Login} />
                            <Route path="/register" component={Register} />
                            <Route path="/success/:sessionId" component={Home} />
                            <Route
                                path="/docusign-redirect/:docID?/:envelopeID?"
                                component={DocusignRedirect}
                            />
                        </Switch>
                    </Router>
                </MsalProvider>
            </ConfigProvider>
        </React.StrictMode>
    );
};

ReactDOM.render(<AppContent />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
