import React, { useCallback, useState } from 'react';

import { AutoComplete, AutoCompleteProps } from 'antd';
import { debounce } from 'lodash';

import { getUSCityList } from 'utils/ez_api/locationAPIs';

type EZAutocompleteCityProps = AutoCompleteProps<any> & {
    ifEmptyText?: string;
};

export const EZAutocompleteCity: React.FC<EZAutocompleteCityProps> = ({
    ifEmptyText = '-',
    ...props
}) => {
    const [citySearchOptions, setCitySearchOptions] = useState<{ label: string; value: string }[]>(
        []
    );

    const _onSearchCity = useCallback(
        debounce(searchKeyword => {
            getUSCityList(searchKeyword)
                .then((response: any) => {
                    if (response.data?.data.length) {
                        setCitySearchOptions(
                            response.data?.data.map((iData: { city: string }) => ({
                                label: iData.city,
                                value: iData.city,
                            }))
                        );
                    } else {
                        setCitySearchOptions([]);
                    }
                })
                .finally(() => {});
        }, 500),
        []
    );

    return (
        <AutoComplete
            options={citySearchOptions}
            onSearch={_onSearchCity}
            placeholder="Enter the city"
            {...props}
        />
    );
};
