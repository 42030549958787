import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getTransactionNotes = (contract_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}/notes` + (params ? `?${params}` : ''),
    });
};

export const getTransactionEmailNotes = (contract_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}/email-notes` + (params ? `?${params}` : ''),
    });
};

export const updateContactNote = (contract_id: number, notes: string, note_id?: number) => {
    if (note_id) {
        Mixpanel.track('app.edit_transaction', {
            contract_id,
            sub_tab: 'notes',
            action: 'edit',
        });
    } else {
        Mixpanel.track('app.edit_transaction', {
            contract_id,
            sub_tab: 'notes',
            action: 'add',
        });
    }

    return APIRequest({
        method: note_id ? 'put' : 'post',
        url:
            apiUrl +
            (note_id
                ? `transaction/${contract_id}/note/${note_id}`
                : `transaction/${contract_id}/notes`),
        data: {
            notes: notes,
        },
        useDefaultErrorNotif: true,
    });
};

export const deleteContactNote = (contract_id: number, note_id: number) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        sub_tab: 'notes',
        action: 'delete',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `transaction/${contract_id}/note/${note_id}`,
        useDefaultErrorNotif: true,
    });
};

export const deleteContactEmailNote = (contract_id: number, m_id: number) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        sub_tab: 'email-notes',
        action: 'delete',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `transaction/${contract_id}/email-note/${m_id}`,
        useDefaultErrorNotif: true,
    });
};
