import qs from 'qs';

import { apiUrl } from '../../envUtil';
import { APIRequest } from '../../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getProspectTaskTabsDetail = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'admin/settings/prospect-default-tasktabs',
        useDefaultErrorNotif: true,
    });
};

export const updateProspectDefaultTaskTabData = (defaulttasktab_id: number, payload: any) => {
    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/prospect-default-tasktab/${defaulttasktab_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateProspectDefaultTaskData = (
    defaulttasktab_id?: number,
    defaulttask_id?: number,
    payload?: any
) => {
    return APIRequest({
        method: 'put',
        url:
            apiUrl +
            `admin/settings/prospect-default-tasktab/${defaulttasktab_id}/default-task/${defaulttask_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const addNewProspectDefaultTaskTab = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'task_templates',
        action: 'add_tasktab',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/prospect-default-tasktabs`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const addNewProspectDefaultTaskData = (defaulttasktab_id?: number, payload?: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'task_templates',
        action: 'add_task',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/prospect-default-tasktab/${defaulttasktab_id}/default-tasks`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteProspectDefaultTask = (defaulttasktab_id: number, defaulttask_id: number) => {
    return APIRequest({
        method: 'delete',
        url:
            apiUrl +
            `admin/settings/prospect-default-tasktab/${defaulttasktab_id}/default-task/${defaulttask_id}`,
        useDefaultErrorNotif: true,
    });
};

export const deleteProspectDefaultTaskTab = (defaulttasktab_id: number) => {
    return APIRequest({
        method: 'delete',
        url: apiUrl + `admin/settings/prospect-default-tasktab/${defaulttasktab_id}`,
        useDefaultErrorNotif: true,
    });
};
