// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Form, FormInstance, Col, Alert } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
// EZ web-app components import
import { EZDateFormat, EmailEditorFormItem } from 'components';
import { EmailLogType, SENDMAIL_ONBEHALF } from 'types/email';
import { getEmailLogDetail } from 'utils/ez_api/emailLogAPIs';

// EZ web-app styles and assets import

export const EmailLogModalContent: React.FC<{
    contactRoles?: any;
    emailLog: Partial<EmailLogType>;
    form: FormInstance<any>;
}> = ({ contactRoles, emailLog, form }) => {
    const [activeEmailLog, setActiveEmailLog] = useState<EmailLogType>(emailLog as EmailLogType);
    const [checkingActiveDraft, setCheckingActiveDraft] = useState<boolean>(true);

    const isEditable = false;

    const _getDetail = (messageId: number) => {
        setCheckingActiveDraft(true);
        getEmailLogDetail(messageId)
            .then(({ data }) => {
                const emailLog = data as EmailLogType;

                if (contactRoles) {
                    for (const key in contactRoles) {
                        if (Object.hasOwnProperty.call(contactRoles, key)) {
                            const value = `${contactRoles[key]}}}`;
                            const search = `${key.toLowerCase()}}}`;
                            emailLog.msg_body = emailLog.msg_body?.replaceAll(search, value);
                        }
                    }
                }

                setActiveEmailLog(emailLog);
                form.setFieldsValue({
                    ...emailLog,
                });
            })
            .finally(() => {
                setCheckingActiveDraft(false);
            });
    };

    const _setFieldValues = () => {
        form.resetFields();

        form.setFieldsValue({
            ...emailLog,
        });
    };

    useEffect(() => {
        _setFieldValues();

        if (emailLog.message_id) {
            _getDetail(emailLog.message_id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form layout="vertical" form={form} requiredMark={true}>
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Alert
                        message="See more detailed information on the sent folder of your selected email integration (i.e.: Your Gmail / Microsoft 365 / Custom email domain)."
                        type="info"
                        showIcon
                        className="mb-3"
                    />
                </Col>
                <Col span={12}>
                    <Form.Item label="Dispatched By">
                        {emailLog.relayed_at ? (
                            <>
                                {emailLog.relayed_by_account?.fullname || <i>Empty</i>} on&nbsp;
                                <EZDateFormat
                                    value={emailLog.relayed_at}
                                    format="MMM Do, YYYY [at] hh:mma"
                                />
                            </>
                        ) : (
                            <>-</>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Sent Using">
                        {activeEmailLog.relay_type_id ? (
                            <>
                                <span>{activeEmailLog.msg_sender || '-'}</span> (
                                {activeEmailLog.relay_type_id === SENDMAIL_ONBEHALF.GMAIL
                                    ? 'Gmail integration'
                                    : activeEmailLog.relay_type_id === SENDMAIL_ONBEHALF.MS_365
                                    ? 'Microsoft 365 integration'
                                    : activeEmailLog.relay_type_id === SENDMAIL_ONBEHALF.CUSTOM_SMTP
                                    ? 'Custom SMTP integration'
                                    : '-'}
                                )
                            </>
                        ) : (
                            <>-</>
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Email Subject" name="msg_subject">
                        {emailLog.msg_subject || '-'}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Email Recipient(s)">
                        {emailLog?.msg_to
                            ?.map((msg: any) =>
                                msg.startsWith('ROLE_ID') ? `{{ ${contactRoles[msg]} }}` : msg
                            )
                            .join(', ') || '-'}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Email CC(s)">
                        {emailLog?.msg_cc
                            ?.map((msg: any) =>
                                msg.startsWith('ROLE_ID') ? `{{ ${contactRoles[msg]} }}` : msg
                            )
                            .join(', ') || '-'}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Email BCC(s)">
                        {emailLog?.msg_bcc
                            ?.map((msg: any) =>
                                msg.startsWith('ROLE_ID') ? `{{ ${contactRoles[msg]} }}` : msg
                            )
                            .join(', ') || '-'}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <EmailEditorFormItem readOnly={!isEditable} hideToolbar hideToolbarInsertCode />
                </Col>
                <Col span={24}>
                    <Form.Item label="Attachments (From Documents and Files)">
                        {emailLog.docs?.length ? (
                            <ul>
                                {emailLog.docs.map(doc => (
                                    <li>{doc.filename}</li>
                                ))}
                            </ul>
                        ) : (
                            <span>-</span>
                        )}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Linked Transaction">
                        {activeEmailLog.contract?.address || '-'}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Linked Task">
                        {activeEmailLog.task_todolist?.title || '-'}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Last Updated By">
                        {activeEmailLog.updated_by_account?.fullname} on&nbsp;
                        <EZDateFormat
                            value={activeEmailLog.updated_at}
                            format="MMM Do, YYYY [at] hh:mma"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Created By">
                        {activeEmailLog.account?.fullname} on&nbsp;
                        <EZDateFormat
                            value={activeEmailLog.created_at}
                            format="MMM Do, YYYY [at] hh:mma"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
