import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getAccountRolePermission = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'account-role-permissions',
    });
};

export const getPermissionByAccountRoleID = (accountrole_id: number) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `account-role-permission/${accountrole_id}`,
    });
};

export const updatePermissionByAccountRoleID = (accountrole_id: number, payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'team_access_management',
        sub_section: 'global_permissions',
        action: 'edit',
        accountrole_id,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `account-role-permission/${accountrole_id}`,
        data: payload,
    });
};
