import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getTransactionContacts = (contract_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}/contacts` + (params ? `?${params}` : ''),
    });
};

export const getContactRoles = (contract_id: number, queryParams?: any) => {
    const params: string = qs.stringify(queryParams);

    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}/contact-roles` + (params ? `?${params}` : ''),
    });
};

export const addNewTransactionContact = (contract_id: number, payload: any) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        sub_tab: 'additional_contacts',
        action: 'add',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/contacts`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateTransactionContact = (contract_id: number, contact_id: number, payload: any) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        sub_tab: 'additional_contacts',
        action: 'edit',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/contact/${contact_id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteTransactionContact = (contract_id: number, contact_id: number) => {
    Mixpanel.track('app.edit_transaction', {
        contract_id,
        sub_tab: 'additional_contacts',
        action: 'delete',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `transaction/${contract_id}/contact/${contact_id}`,
        useDefaultErrorNotif: true,
    });
};
