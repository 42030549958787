import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';

export const getOnboardingList = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `onboarding-list`,
        useDefaultErrorNotif: true,
    });
};

export const updateOnboardingCompleted = (payload: any) => {
    return APIRequest({
        method: 'put',
        url: apiUrl + `onboarding-completed`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};
