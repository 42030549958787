import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';
import { DataTableQueryType } from 'types/base';

export const getTransactionTasks = (contract_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}/tasks` + (params ? `?${params}` : ''),
    });
};

export const getTransactionTaskLists = (contract_id: number, params?: DataTableQueryType) => {
    return APIRequest({
        method: 'get',
        url:
            apiUrl +
            `transaction/${contract_id}/tasklists` +
            (params ? `?${qs.stringify(params)}` : ''),
    });
};

export const getAllTasks = (params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `tasks` + (params ? `?${params}` : ''),
    });
};

export const addNewTask = (
    contract_id: number,
    payload: any,
    formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
) => {
    Mixpanel.track('app.add_transaction_task', {
        contract_id,
        ...payload,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/task?formType=${formType}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const updateTask = (
    contract_id: number,
    id: number,
    payload: any,
    formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
) => {
    Mixpanel.track('app.edit_transaction_task', {
        contract_id,
        task_id: id,
        action: 'edit',
        ...payload,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/task/${id}?formType=${formType}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const deleteTask = (contract_id: number, id: number) => {
    Mixpanel.track('app.delete_transaction_task', {
        contract_id,
        task_id: id,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `transaction/${contract_id}/task/${id}`,
        useDefaultErrorNotif: true,
    });
};

export const getTaskDetail = (contract_id: number, id: number) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}/task/${id}`,
        useDefaultErrorNotif: true,
    });
};

export const linkTasksToDocs = (contract_id: number, taskIDs: number[], docIDs: number[]) => {
    Mixpanel.track('app.edit_transaction_task', {
        contract_id,
        action: 'link-to-docs',
        task_ids: taskIDs,
        doc_ids: docIDs,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/tasks/link-to-docs`,
        data: {
            doc_ids: docIDs,
            task_ids: taskIDs,
        },
    });
};

export const addNewTasklist = (contract_id: number, payload: any) => {
    Mixpanel.track('app.add_transaction_tasklist', {
        contract_id,
        ...payload,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/tasklist`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const updateTasklist = (contract_id: number, id: number, payload: any) => {
    Mixpanel.track('app.edit_transaction_tasklist', {
        contract_id,
        tasklist_id: id,
        action: 'edit',
        ...payload,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/tasklist/${id}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const deleteTasklist = (contract_id: number, id: number) => {
    Mixpanel.track('app.delete_transaction_tasklist', {
        contract_id,
        tasklist_id: id,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `transaction/${contract_id}/tasklist/${id}`,
        useDefaultErrorNotif: true,
    });
};
