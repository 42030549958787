import { Button, Col, Form, Input, Row, Typography } from 'antd';
import React, { memo } from 'react';
import { resetPassword, verifyResetPasswordCode } from '../../utils/ez_api/loginUtil';
import '../../styles/login.scss';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { SocialBoxWrapper } from './Fragments/SocialBoxWrapper';
import { PASSWORD_REGEX } from 'types/account';
import { showNotification } from 'utils/notificationUtil';

const { Text, Title } = Typography;
export const ResetPassword = memo(function ForgotPassword(props: any) {
    const [form] = Form.useForm();

    const history = useHistory();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const resetPasswordCode = query.get('code') || '';

    const updatePassword = (values: any) => {
        const { newPassword } = values;
        resetPassword(resetPasswordCode, newPassword)
            .then(res => {
                showNotification(
                    'success',
                    'Password reset successful. \nYou can now use your new password to login to your account!'
                );
                history.push('/');
            })
            .catch(err => {
                const message = err?.response?.data?.message;
                showNotification('error', message);
            });
    };

    const checkVerifyResetPasswordCode = () => {
        verifyResetPasswordCode(resetPasswordCode)
            .then(res => {})
            .catch(err => {
                history.push('/forgot-password');
                const message = err?.response?.data?.message;
                showNotification('error', message);
            });
    };

    // check Verify Reset Password Code when component on mount
    React.useEffect(() => {
        checkVerifyResetPasswordCode();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SocialBoxWrapper>
            <Title level={3}>Reset Password</Title>
            <Text type="secondary" style={{ textAlign: 'center', marginBottom: '1em' }}>
                Create a new password to access your account!
            </Text>
            <Form
                form={form}
                name="forgot_password"
                requiredMark={false}
                layout="vertical"
                style={{ width: '100%' }}
                onFinish={updatePassword}
            >
                <Form.Item
                    label="New Password"
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password!',
                        },
                        {
                            pattern: PASSWORD_REGEX,
                            message: 'Weak password is detected.',
                        },
                    ]}
                >
                    <Input.Password type={'email'} placeholder="Enter a new password" />
                </Form.Item>
                <Form.Item
                    label="Confirm New Password"
                    name="newConfirmPassword"
                    dependencies={['newPassword']}
                    rules={[
                        {
                            required: true,
                            message: 'Please input your confirm new password!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error('The two passwords that you entered do not match!')
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password type={'email'} placeholder="Confirm your new password" />
                </Form.Item>

                <Row style={{ color: 'grey', paddingTop: '1em' }}>
                    <Col span={24}>
                        <span>Password must:</span>
                    </Col>
                    <Col span={12}>
                        <span>- have at least one letter</span>
                        <br />
                        <span>- have at least one capital letter</span>
                        <br />
                        <span>- have at least one number</span>
                        <br />
                    </Col>
                    <Col span={12}>
                        <span>- have at least one special symbol that is #?!@$%^&*-</span>
                        <br />
                        <span>- be at least 8 characters</span>
                        <br />
                    </Col>
                </Row>
                <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !form.isFieldsTouched(true) ||
                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                            style={{ width: '100%', marginTop: '1em' }}
                        >
                            Reset Password
                        </Button>
                    )}
                </Form.Item>
            </Form>

            <span style={{ paddingTop: '1em' }}>
                Already have an account?{' '}
                <Link to="/login">
                    <span className="">Sign In</span>
                </Link>
            </span>
        </SocialBoxWrapper>
    );
});
