// Core React libs import
import React from 'react';

// Core ANTD and 3rd party libs import
import { Select } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { FlagTwoTone, StopOutlined } from '@ant-design/icons';

// EZ level libs import
import { TRANSACTION_FLAGS } from 'types/transaction';

export interface EZSelectFlagProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {}

const { Option } = Select;

const noFlagItem = TRANSACTION_FLAGS[0];

export const TransactionFlag: React.FC<{ contract_id: number; flag_id: number | null }> = ({
    contract_id,
    flag_id,
}) => {
    const contractFlag = TRANSACTION_FLAGS.find(flag => flag.flag_id === flag_id) || noFlagItem;

    return (
        <span>
            {!flag_id || flag_id === 1 ? (
                <StopOutlined />
            ) : (
                <FlagTwoTone twoToneColor={contractFlag.flag_color} />
            )}
            <span className="ml-2">{contractFlag.flag}</span>
        </span>
    );
};

export function EZSelectFlag<
    ValueType extends {
        key?: string;
        // label: React.ReactNode;
        // value: string | number;
    } = any
>({ ...props }: EZSelectFlagProps<any>) {
    return (
        <Select
            style={{ width: '100%' }}
            showSearch
            allowClear
            placeholder="Select Transaction Flag"
            {...props}
        >
            {TRANSACTION_FLAGS.map(item => (
                <Option key={item.flag_id} value={item.flag_id}>
                    {item.flag_id !== 1 ? (
                        <FlagTwoTone twoToneColor={item.flag_color} />
                    ) : (
                        <StopOutlined />
                    )}
                    <span className="ml-2">{item.flag}</span>
                </Option>
            ))}
        </Select>
    );
}
