import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';

export const getAllSubscriptionTypes = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + 'subscriptions',
    });
};
