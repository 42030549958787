// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Alert,
    Avatar,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Space,
    Tag,
    Typography,
} from 'antd';
import {
    EditOutlined,
    LockOutlined,
    MailOutlined,
    SafetyOutlined,
    SaveOutlined,
} from '@ant-design/icons';
import { Gutter } from 'antd/lib/grid/row';

// EZ web-app utils import
import { getInitialFromFullname } from 'utils/commonUtil';
import { showNotification } from 'utils/notificationUtil';
// EZ web-app APIs import
import { getAddressbookRoles } from 'utils/ez_api/contactAPIs';
import {
    changePassword,
    getLoggedAccountData,
    updateMyAccountData,
} from 'utils/ez_api/accountAPIs';
// EZ web-app types import
import { ITabContentProps } from 'types/webAppTab';
import { AccountDataType, PASSWORD_REGEX } from 'types/account';
import { ContactRoleType } from 'types/transaction';
// EZ web-app components import
import {
    EZAutocompleteCity,
    EZDateFormat,
    EZInputMasked,
    EZOverlaySpinner,
    EZSelectContactRole,
    EZSelectUSState,
} from 'components';

// EZ web-app styles and assets import
import './Account.scss';
import { getSubscriptionList } from 'utils/paymentUtil';

const colLabelSpan = 14;
const colValueSpan = 10;
const rowFormViewGutter: [Gutter, Gutter] = [12, 6];

const ChangePasswordComponent: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSavingPassword, setIsSavingPassword] = useState(false);

    const [hint, setHint] = useState('');

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const _onFinish = (values: any) => {
        setIsSavingPassword(true);
        changePassword({
            password: values.current_password,
            new_password: values.new_password,
            hint: values.hint,
        })
            .then(() => {
                showNotification('success', 'Your new password has been successfully saved.');
                setIsModalOpen(false);
            })
            .finally(() => {
                setIsSavingPassword(false);
            });
    };

    const _fetchMyProfile = () => {
        getLoggedAccountData().then(({ data }) => {
            if (data) {
                const hintResp = data.hint;
                form.setFieldValue('hint', hintResp);
            }
        });
    };

    useEffect(() => {
        if (!isModalOpen) form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen]);

    useEffect(() => {
        form.resetFields();
        _fetchMyProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Button
                className="ez-action-btn"
                size="small"
                icon={<LockOutlined />}
                onClick={showModal}
            >
                Change Password
            </Button>
            <Modal
                title="Change Password"
                visible={isModalOpen}
                onOk={() => form.submit()}
                onCancel={handleCancel}
                width={400}
                okText={isSavingPassword ? 'Submitting...' : 'Submit'}
                okButtonProps={{
                    disabled: isSavingPassword,
                }}
            >
                <Form form={form} layout="vertical" onFinish={_onFinish}>
                    <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="New Password"
                                name="new_password"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: PASSWORD_REGEX,
                                        message:
                                            'Password is not complex enough. Click the question mark icon for more detail.',
                                    },
                                ]}
                                tooltip={
                                    <span>
                                        <span>Password must:</span>
                                        <br />
                                        <span>- have at least one letter</span>
                                        <br />
                                        <span>- have at least one capital letter</span>
                                        <br />
                                        <span>- have at least one number</span>
                                        <br />
                                        <span>
                                            - have at least one special symbol that is #?!@$%^&*-
                                        </span>
                                        <br />
                                        <span>- be at least 8 characters</span>
                                        <br />
                                    </span>
                                }
                            >
                                <Input.Password placeholder="Enter your new password" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Confirm Password"
                                name="confirm_password"
                                rules={[
                                    // {
                                    //     required: true,
                                    //     message: 'Please confirm your password!',
                                    // },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('new_password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'The two passwords that you entered do not match!'
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Enter the confirm password" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Hint" name="hint">
                                <Input type="text" placeholder="Enter the hint on the login page" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Divider orientation="left">
                                <b>
                                    <SafetyOutlined /> Authentication
                                </b>
                            </Divider>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Current Password"
                                name="current_password"
                                rules={[{ required: true }]}
                            >
                                <Input.Password placeholder="Enter your current password" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

const MyProfileTab: React.FC<ITabContentProps> = () => {
    const [form] = Form.useForm();

    const [isSavingProfile, setIsSavingProfile] = useState(false);
    const [isFetchingAccountData, setIsFetchingAccountData] = useState(false);

    const [roles, setRoles] = useState<ContactRoleType[]>([]);
    const [userData, setUserData] = useState<AccountDataType>();

    const subscriptionList = getSubscriptionList();

    const _reloadContactRoles = () => {
        getAddressbookRoles().then(response => {
            setRoles(response.data || []);
        });
    };

    const _fetchMyProfile = () => {
        setIsFetchingAccountData(true);
        getLoggedAccountData()
            .then(({ data }) => {
                if (data) {
                    const respUserData = data as AccountDataType;
                    setUserData(respUserData);

                    if (respUserData.role_id === 0) {
                        delete respUserData.role_id;
                    }

                    form.setFieldsValue({ ...respUserData });
                }
            })
            .finally(() => {
                setIsFetchingAccountData(false);
            });
    };

    const _onFormFinish = (values: any) => {
        setIsSavingProfile(true);
        updateMyAccountData({ ...values, role_id: values.role_id || 0 })
            .then(() => {
                showNotification('success', 'Your profile has been successfully updated.');
            })
            .finally(() => {
                setIsSavingProfile(false);
            });
    };

    const _onAddNewRole = () => {
        _reloadContactRoles();
    };

    useEffect(() => {
        _fetchMyProfile();
        _reloadContactRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ez-component-my-profile">
            <div className="mb-5 ez-sub-content-wrapper">
                <Row align="middle">
                    <Col span={12}>
                        <Space align="center">
                            <Avatar size={48} className="mr-3">
                                {userData?.fullname
                                    ? getInitialFromFullname(userData.fullname)
                                    : 'USER'}
                            </Avatar>
                            <span>
                                <Typography.Text strong>{userData?.fullname}</Typography.Text>
                                <br />
                                <Typography.Text>{userData?.email}</Typography.Text>
                            </span>
                        </Space>
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Space align="center">
                            <Tag>
                                <Typography.Text strong>
                                    {
                                        subscriptionList.find(
                                            sub => sub.user_type_id === userData?.user_type_id
                                        )?.plan_name
                                    }
                                </Typography.Text>
                            </Tag>
                        </Space>
                    </Col>
                    <Col span={24}>
                        <Alert
                            className="mt-3"
                            message={
                                <>
                                    We are currently improving our payment experience. Therefore, we
                                    are sorry that{' '}
                                    <b>
                                        payment information changes and cancellation can only be
                                        done by contacting our support
                                    </b>
                                    .
                                </>
                            }
                            showIcon
                        />
                    </Col>
                </Row>
            </div>
            <div className="ez-sub-content-wrapper " style={{ position: 'relative' }}>
                {isFetchingAccountData && <EZOverlaySpinner />}
                <Row gutter={72}>
                    <Col span={12}>
                        <Form layout="vertical" form={form} onFinish={_onFormFinish}>
                            <Row>
                                <Col span={24} className="">
                                    <Row className="">
                                        <Col span={12} className="">
                                            <Typography.Title level={5}>
                                                General Information
                                            </Typography.Title>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            {userData?.account_id ? (
                                                <Button
                                                    // className="ez-action-btn"
                                                    size="small"
                                                    type="primary"
                                                    icon={<SaveOutlined />}
                                                    onClick={() => form.submit()}
                                                    loading={isSavingProfile}
                                                    disabled={isSavingProfile}
                                                >
                                                    {isSavingProfile ? 'Saving...' : 'Save'}
                                                </Button>
                                            ) : (
                                                <></>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider className="mb-4" style={{ margin: '8px 0' }} />
                                <Col span={24}>
                                    <Row gutter={20}>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Name"
                                                name="fullname"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Name can't be empty",
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter your name" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Role" name="role_id">
                                                <EZSelectContactRole
                                                    options={roles.map(role => ({
                                                        label: role.role,
                                                        value: role.role_id,
                                                    }))}
                                                    onAddNewRole={_onAddNewRole}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Company" name="company">
                                                <Input placeholder="Enter the company name" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Cell Phone" name="cellphone">
                                                {/* <EZInputMasked /> */}
                                                <Input placeholder="Enter the cell phone number" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Work Phone" name="workphone">
                                                <Input placeholder="Enter the work phone number" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Fax Phone" name="faxphone">
                                                <Input placeholder="Enter the fax phone number" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={24}>
                                            <Form.Item label="Street" name="street">
                                                <Input placeholder="Enter the street detail" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item label="City" name="city">
                                                <EZAutocompleteCity placement="topRight" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item label="State" name="state">
                                                <EZSelectUSState placement="topRight" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item label="ZIP" name="zip">
                                                <Input placeholder="Enter the ZIP" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Timezone" name="timezone">
                                                <Select
                                                    placeholder="Select the timezone"
                                                    style={{ width: '100%' }}
                                                    placement="topRight"
                                                >
                                                    <Select.Option value="AST">
                                                        America/Puerto_Rico
                                                    </Select.Option>
                                                    <Select.Option value="EDT">
                                                        America/New_York
                                                    </Select.Option>
                                                    <Select.Option value="CDT">
                                                        America/Chicago
                                                    </Select.Option>
                                                    <Select.Option value="MDT">
                                                        America/Boise
                                                    </Select.Option>
                                                    <Select.Option value="MST">
                                                        America/Phoenix
                                                    </Select.Option>
                                                    <Select.Option value="PDT">
                                                        America/Los_Angeles
                                                    </Select.Option>
                                                    <Select.Option value="AKDT">
                                                        America/Juneau
                                                    </Select.Option>
                                                    <Select.Option value="HST">
                                                        Pacific/Honolulu
                                                    </Select.Option>
                                                    <Select.Option value="ChST">
                                                        Pacific/Guam
                                                    </Select.Option>
                                                    <Select.Option value="SST">
                                                        Pacific/Samoa
                                                    </Select.Option>
                                                    <Select.Option value="WAKT">
                                                        Pacific/Wake
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col span={24}>
                                            <Form.Item label="Notes" name="notes">
                                                <Input.TextArea
                                                    placeholder="Enter the notes"
                                                    rows={3}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form layout="vertical">
                            <Row>
                                <Col span={24} className="">
                                    <Row className="">
                                        <Col span={10} className="">
                                            <Typography.Title level={5}>
                                                Credential and Login History
                                            </Typography.Title>
                                        </Col>
                                        <Col span={14} style={{ textAlign: 'right' }}>
                                            <ChangePasswordComponent />
                                            {/* <Button
                                                disabled
                                                className="ml-2"
                                                // className="ml-2 ez-action-btn"
                                                size="small"
                                                icon={<MailOutlined />}
                                            >
                                                Change Email
                                            </Button> */}
                                        </Col>
                                    </Row>
                                </Col>
                                <Divider className="mb-4" style={{ margin: '8px 0' }} />
                                <Col span={24}>
                                    <Form.Item label="Registered On">
                                        <EZDateFormat
                                            value={userData?.date_posted}
                                            format={'MM/DD/YYYY hh:mma'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Last Modified On">
                                        <EZDateFormat
                                            value={userData?.date_modified}
                                            format={'MM/DD/YYYY hh:mma'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Last Logged-In On">-</Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default MyProfileTab;
