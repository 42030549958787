// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Alert, Button, Checkbox, Col, Divider, Form, Input, Row, Space } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
import {
    getPropertyStatusSetting,
    getPropertyTypeSetting,
    getSaleTypeSetting,
    updateTransactionSetting,
} from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import { PropStatusPreference, PropTypePreference, SaleTypePreference } from 'types/admin';
import { showNotification } from 'utils/notificationUtil';
import { PROPERTY_TYPES, SALE_TYPES, TRANSACTION_STATUSES } from 'types/transaction';
import { InfoCircleOutlined } from '@ant-design/icons';
import { updateOnboardingStep } from 'utils/onboardingUtil';
import { HomeContext } from 'context/EZContext';
// EZ web-app components import

// EZ web-app styles and assets import

export const TransactionStatusSetting: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();

    const HomeCtx = useContext(HomeContext);

    const _fetchTransactionSetting = () => {
        getPropertyStatusSetting().then(({ data }) => {
            if (data) {
                const propStatusPreference = data as PropStatusPreference[];
                propStatusPreference.forEach(preferenceObj => {
                    form.setFieldValue([`property_status`, `${preferenceObj.status_id}`], false);
                });
            }
        });
    };

    const _onFinishForm = (values: any) => {
        updateTransactionSetting(values).then(() => {
            showNotification('success', 'New setting has been successfully applied.');

            // set trigger to refetch data by key
            HomeCtx.setTriggerRefetchDataList(prev => [...prev, 'property_status']);
            // update onboarding step to completed
            updateOnboardingStep('customize_transaction_status', HomeCtx.refetchOnboardingList);
        });
    };

    useEffect(() => {
        _fetchTransactionSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form form={form} onFinish={_onFinishForm}>
            <Row>
                <Col span={24} className="mb-5">
                    <Alert
                        message={`You need to hit the button "Save Changes" to save all the changes you have made.`}
                        type="info"
                        showIcon
                        action={
                            <Space>
                                <Button type="primary" onClick={() => form.submit()}>
                                    Save Changes
                                </Button>
                            </Space>
                        }
                    />
                </Col>
                <Col span={24}>
                    <Row gutter={[64, 64]}>
                        {TRANSACTION_STATUSES.map((statusCategory, idx) => (
                            <Col span={8} key={`statusCategory_${idx}`}>
                                <Row gutter={[0, 8]}>
                                    <Col span={24} className="text-title-secondary">
                                        Status - {statusCategory.status_category_label}
                                    </Col>
                                    <Divider className="mt-2 mb-3" />
                                    {statusCategory.status_list.map((status, sIdx) => (
                                        <Col span={24} key={`status_list-${sIdx}`}>
                                            <Form.Item
                                                name={[`property_status`, `${status.status_id}`]}
                                                noStyle
                                                valuePropName="checked"
                                                initialValue={true}
                                            >
                                                <Checkbox>{status.status}</Checkbox>
                                            </Form.Item>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};
