import React from 'react';

import { InputNumber, InputNumberProps } from 'antd';

type EZInputNumberProps = InputNumberProps & {
    numberType?: 'currency' | 'percentage';
};

const EZInputNumber: React.FC<EZInputNumberProps> = ({ numberType, ...props }) => {
    const config: InputNumberProps = { min: 0, step: numberType === 'percentage' ? 1 : 50000 };
    // let max = ;

    if (numberType)
        switch (numberType) {
            case 'currency':
                config.formatter = (value: any) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                break;

            case 'percentage':
                config.min = 0;
                config.max = 100;
                // config.formatter = (value: any) =>
                //     `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                config.addonAfter = '%';
                break;

            default:
                break;
        }

    return (
        <>
            <InputNumber {...config} {...props} />
        </>
    );
};

export default EZInputNumber;
