import { STATUS_LIST_RENTAL, TransactionDetailType, TRANSACTION_STATUSES } from 'types/transaction';

export const getTransactionStatusCategory = (status_id: number) => {
    for (let index = 0; index < TRANSACTION_STATUSES.length; index++) {
        const categoryObj = TRANSACTION_STATUSES[index];

        const opt = categoryObj.status_list.find(sItem => sItem.status_id === status_id);
        if (opt) return categoryObj.status_category_label;
    }

    return '';
};

export const isRentalProperty = (status_id: number) => {
    if (!status_id) return false;
    return STATUS_LIST_RENTAL.find(status => status.status_id === status_id) ? true : false;
};

export const isProspectABuyer = (inquirytype_id: number) => {
    return inquirytype_id === 1;
};

export const transformContractCustomFields = (transactionData: TransactionDetailType) => {
    if (transactionData.contract_additional_info_categories?.length) {
        transactionData.contract_additional_info_categories.forEach(category => {
            category.contract_additional_info_preferences?.forEach(field => {
                if (field.contract_additional_info_field.class === 'dropdown') {
                    const optValue = field.contract_additional_info_value?.field_value;
                    if (optValue) {
                        const optObject = field.contract_additional_info_options.find(
                            optValObj =>
                                optValObj.additional_info_fields_option_id === parseInt(optValue)
                        );
                        if (optObject) {
                            field.field_value = optObject.option_value;
                            field.field_option_id = optObject.additional_info_fields_option_id;
                        }
                    }
                } else {
                    field.field_value = field.contract_additional_info_value?.field_value;
                }
            });
        });
    }
    return transactionData;
};
