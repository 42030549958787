// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Modal, Form, Input } from 'antd';

// EZ web-app utils import
// EZ web-app types import
import { ContactRoleType } from 'types/transaction';
import { AddressbookType } from 'types/contact';
// EZ web-app components import
import { EZAutocompleteCity, EZSelectUSState, EZSelectContactRole } from 'components';
import { showNotification } from 'utils/notificationUtil';
import { addNewAddressbookContact, updateAddressbookContact } from 'utils/ez_api/contactAPIs';

// EZ web-app styles and assets import

export const ModalEditAddressBook: React.FC<{
    contact?: AddressbookType;
    roles?: ContactRoleType[];
    isModalVisible?: boolean;
    setIsModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    reloadSubTabData: () => void;
}> = ({
    contact,
    roles = [],
    isModalVisible = false,
    setIsModalVisible = () => {},
    reloadSubTabData,
}) => {
    const isEditMode: boolean = contact && contact.addressbook_id ? true : false;

    const [form] = Form.useForm();

    const [submittingData, setSubmittingData] = useState(false);

    const _onSubmitForm = (formValues?: any) => {
        setSubmittingData(true);
        if (formValues.role_id) {
            formValues.role =
                roles.find(role => role.role_id.toString() === formValues.role_id.toString())
                    ?.role || '';
        }

        const reqPromise = isEditMode
            ? updateAddressbookContact(contact?.addressbook_id || 0, formValues)
            : addNewAddressbookContact(formValues);

        reqPromise
            .then(resp => {
                showNotification(
                    'success',
                    isEditMode
                        ? 'Selected contact data has been updated.'
                        : 'New contact has been added.'
                );
                reloadSubTabData();
                setIsModalVisible(false);
            })
            .finally(() => {
                setSubmittingData(false);
            });
    };

    const _setFormFieldsValue = () => {
        if (isEditMode) {
            form.setFieldsValue(contact);
        } else {
            form.resetFields();
        }
    };

    const _resetModalStates = () => {
        setSubmittingData(false);
    };

    const _onAddNewRole = () => {
        reloadSubTabData();
    };

    useEffect(() => {
        if (isModalVisible) {
            _resetModalStates();
            _setFormFieldsValue();
        } else {
            _resetModalStates();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal
                title={`${isEditMode ? 'Edit Contact' : 'Add New Contact'}`}
                visible={isModalVisible}
                onOk={() => form.submit()}
                onCancel={() => setIsModalVisible(false)}
                okText="Save Changes"
                confirmLoading={submittingData}
                width={700}
                style={{ top: 40 }}
                bodyStyle={{ overflowY: 'auto', height: '600px' }}
            >
                <Form layout="vertical" form={form} onFinish={_onSubmitForm} requiredMark={false}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item label="Role" name="role_id">
                                <EZSelectContactRole
                                    options={roles.map(role => ({
                                        label: role.role,
                                        value: role.role_id,
                                    }))}
                                    onAddNewRole={_onAddNewRole}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Name"
                                name="fullname"
                                rules={[{ required: true, message: "Name can't be empty" }]}
                            >
                                <Input placeholder="Enter the name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Main Email" name="email" rules={[{ type: 'email' }]}>
                                <Input placeholder="Enter the main email" type="email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Email 2" name="email2" rules={[{ type: 'email' }]}>
                                <Input placeholder="Enter the secondary email" type="email" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Main Phone" name="telephone">
                                <Input placeholder="Enter the main phone number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Phone 2" name="telephone2">
                                <Input placeholder="Enter the secondary phone" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Company" name="company">
                                <Input placeholder="Enter the company name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Fax" name="fax">
                                <Input placeholder="Enter the fax" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label="Address" name="address">
                                <Input placeholder="Enter the address" />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label="City" name="city">
                                <EZAutocompleteCity />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label="State" name="state">
                                <EZSelectUSState />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="ZIP" name="zip">
                                <Input placeholder="Enter the ZIP" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={24}>
                            <Form.Item label="Notes" name="notes">
                                <Input.TextArea placeholder="Enter the notes" rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
