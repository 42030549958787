// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Form, Col, Tabs, Skeleton } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
import {
    getEmailLogDetail,
    getEmailLogDetailByTransactionTaskID,
    getMyEmailCredential,
    sendEmailByEmailLogID,
    updateEmailDraft,
} from 'utils/ez_api/emailLogAPIs';
// EZ web-app types import
import {
    EMAIL_INTEGRATION_ERR_CODE,
    EmailCredentialType,
    EmailLogType,
    SENDMAIL_ONBEHALF,
} from 'types/email';
import { TransactionTaskItemType } from 'types/transaction';
// EZ web-app components import
import { Partial_EmailLogs } from '../partials/emailLogs';
import { Partial_EmailForm } from 'pages/tabs/email/partials/emailForm';

// EZ web-app styles and assets import

const { TabPane } = Tabs;

export enum EMAIL_TAB {
    COMPOSE_TAB = 'compose_new_email',
    EMAIL_LIST = 'list_of_sent_mails',
}

const EDIT_EMAIL_TABKEY_PREFIX = 'edit_email';

const generateEditEmailKey = (logID: number) => {
    return `${EDIT_EMAIL_TABKEY_PREFIX}_logID`;
};

type TaskEmailModalContentProps = {
    taskRecord: TransactionTaskItemType;
};

export const TaskEmailModalContent: React.FC<TaskEmailModalContentProps> = ({ taskRecord }) => {
    const contractID = taskRecord.task_list?.contract_id || 0;

    const [activeEmailLog, setActiveEmailLog] = useState<EmailLogType>();
    const [checkingActiveDraft, setCheckingActiveDraft] = useState<boolean>(true);

    const [activeTabKey, setActiveTabKey] = useState<string>(EMAIL_TAB.COMPOSE_TAB);

    const _fetchActiveLinkedDraftByTaskID = (skipTemplate: boolean = false) => {
        getEmailLogDetailByTransactionTaskID(taskRecord.id, skipTemplate)
            .then(({ data }) => {
                const emailLog = data as EmailLogType;

                if (emailLog?.message_id) {
                    setActiveEmailLog({ ...emailLog });
                }
            })
            .finally(() => {
                setCheckingActiveDraft(false);
            });
    };

    const _editLogDetail = (emailLog: EmailLogType) => {
        setActiveTabKey(EMAIL_TAB.COMPOSE_TAB);

        setActiveEmailLog({ ...emailLog });
    };

    const _onSendEmail = () => {
        _fetchActiveLinkedDraftByTaskID(true);
    };

    useEffect(() => {
        if (taskRecord.email_template_id && !taskRecord.message_log_id) {
            _fetchActiveLinkedDraftByTaskID();
        } else {
            setCheckingActiveDraft(false);
            _fetchActiveLinkedDraftByTaskID();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form layout="vertical" requiredMark={false}>
            <Row gutter={[16, 0]} style={{ minHeight: '400px' }}>
                <Col span={24}>
                    {checkingActiveDraft && <Skeleton active />}
                    {!checkingActiveDraft && (
                        <Tabs
                            type="editable-card"
                            activeKey={activeTabKey}
                            onChange={val => setActiveTabKey(val as EMAIL_TAB)}
                            hideAdd
                            className="tabs-with-leftmargin"
                        >
                            <TabPane
                                tab={
                                    <>
                                        Draft and Sent Mails of <b>This Task</b>
                                    </>
                                }
                                key={EMAIL_TAB.EMAIL_LIST}
                                closable={false}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Partial_EmailLogs
                                            contractTaskID={taskRecord.id}
                                            editLogDetail={_editLogDetail}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane
                                tab={<>Compose Email</>}
                                key={EMAIL_TAB.COMPOSE_TAB}
                                closable={false}
                            >
                                <Partial_EmailForm
                                    contractID={contractID}
                                    emailLog={activeEmailLog}
                                    onSendEmail={_onSendEmail}
                                />
                            </TabPane>
                        </Tabs>
                    )}
                </Col>
            </Row>
        </Form>
    );
};
