import React, {
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactQuill from 'react-quill';
import { TeamOutlined } from '@ant-design/icons';
import { Form, Select, TreeSelect } from 'antd';
import { HomeContext } from 'context/EZContext';
import { EMAIL_TEMPLATE_CODES } from 'static/emailCodes';
import { DataTableQueryType } from 'types/base';
import { EmailCodeTreeDataChildType, TemplateListTreeDataChildType } from 'types/email';
import { EmailSignatureType } from 'types/emailSignature';
import { EmailTemplateFolderType, EmailTemplateType } from 'types/emailTemplate';
import { getEmailSignatures } from 'utils/ez_api/emailSignatureAPIs';
import { getFoldersWithEmailTemplates } from 'utils/ez_api/emailTemplateAPIs';
import { EZRichTextEditorForwardedRefProps, EZRichTextEditorProps } from './richTextEditor';

import 'react-quill/dist/quill.snow.css';
import {
    AdditionalInfoFieldType,
    ContactRoleType,
    contractAdditionalInfoCategoryType,
} from 'types/transaction';
import { getContactRoles } from 'utils/ez_api/contactAPIs';
import { COMMA_TEMPLATE } from 'static/consts';
import { getContractCustomFields } from 'utils/ez_api/AdminAPIs';

const defaultToolbar: any = {
    container: '#toolbar',
    handlers: {},
};

let contactRoles: ContactRoleType[] = [];
let customSections: contractAdditionalInfoCategoryType[] = [];

const emailTemplateCodes: EmailCodeTreeDataChildType[] = [...EMAIL_TEMPLATE_CODES];

type EmailEditorFormItemProps = EZRichTextEditorProps & {
    onSelectTemplate?: (emailTemplate: EmailTemplateType) => void;
    hideToolbar?: boolean;
    hideToolbarInsertCode?: boolean;
    excludedTemplateIDs?: number[];
    formMode?: 'ADD' | 'EDIT';
};

export const EmailEditorFormItem = React.forwardRef<
    EZRichTextEditorForwardedRefProps,
    EmailEditorFormItemProps | undefined
>(
    (
        {
            showTemplateDropdown,
            showEmailSignatureDropdown,
            isRequired = false,
            onSelectTemplate,
            excludedTemplateIDs,
            hideToolbar = false,
            hideToolbarInsertCode = false,
            formMode = 'ADD',
            ...props
        },
        forwardedRef
    ) => {
        const reactQuillRef = useRef<ReactQuill>(null);
        const HomeCtx = useContext(HomeContext);
        const UserInfo = HomeCtx.userInfo;

        const [signatureListData, setSignatureListData] = useState<EmailSignatureType[]>([]);
        const [templateListTreeData, setTemplateListTreeData] = useState<
            TemplateListTreeDataChildType[]
        >([]);
        const [emailCodesTreeData, setEmailCodesTreeData] = useState<EmailCodeTreeDataChildType[]>(
            []
        );
        const [selectedSignature, setSelectedSignature] = useState<{
            length: number;
            content: any;
        }>({
            length: 0,
            content: null,
        });

        const activeSignature = signatureListData.find(el => el.is_active);

        useImperativeHandle(forwardedRef, () => ({
            getEditorInstance: () => reactQuillRef.current?.getEditor(),
        }));

        const [editorLastIndex, setEditorLastIndex] = useState(0);

        if (showTemplateDropdown) {
        }

        const modules = useMemo(
            () => ({
                toolbar: !hideToolbar ? defaultToolbar : false,
            }),
            []
        );

        const _fetchEmailTemplates = () => {
            const whereParams: DataTableQueryType = {};

            if (excludedTemplateIDs && excludedTemplateIDs.length) {
                whereParams.where = {
                    '!$email_templates.email_template_id$': excludedTemplateIDs,
                };
            }

            getFoldersWithEmailTemplates(
                {
                    ...whereParams,
                },
                true
            ).then(({ data }) => {
                let folderWithEmailTemplates = data as EmailTemplateFolderType[];

                // const myFolders: EmailTemplateFolderType[] = [];
                // const foldersOwnedByOthers: EmailTemplateFolderType[] = [];

                // for (let index = 0; index < folderWithEmailTemplates.length; index++) {
                //     const folder = folderWithEmailTemplates[index];

                //     if (folder.account_id === UserInfo?.account_id) {
                //         myFolders.push(folder);
                //     } else {
                //         foldersOwnedByOthers.push(folder);
                //     }
                // }

                // folderWithEmailTemplates = [...myFolders, ...foldersOwnedByOthers];

                const templateListData: TemplateListTreeDataChildType[] =
                    folderWithEmailTemplates?.map(folder => ({
                        title: (
                            <>
                                {folder.is_shared && <TeamOutlined className="mr-2" />}
                                {folder.folder_name}
                                {folder.is_shared ? ` - shared by ${folder.account?.fullname}` : ``}
                            </>
                        ),
                        value: `folder_${folder.folder_id}`,
                        key: `folder_${folder.folder_id}`,
                        selectable: false,
                        children: folder.email_templates?.map(emailTemplate => ({
                            title: emailTemplate.template_name,
                            key: emailTemplate.email_template_id,
                            value: emailTemplate.template_name,
                            meta: emailTemplate,
                        })),
                    }));
                setTemplateListTreeData(templateListData);
            });
        };

        const _fetchEmailSignatures = () => {
            getEmailSignatures().then(({ data }) => {
                const emailSignatures = data as EmailSignatureType[];
                setSignatureListData(emailSignatures);
            });
        };

        const loadSelectedTemplate = (selectedNode: TemplateListTreeDataChildType) => {
            const quill = reactQuillRef.current?.getEditor();
            if (quill) {
                if (selectedNode.meta?.msg_body) {
                    const delta = quill.clipboard.convert(selectedNode.meta?.msg_body || '');

                    const start_idx_sign =
                        selectedNode.meta?.msg_body.indexOf('<p><br></p><p>---</p>');
                    const existing_signature =
                        selectedNode.meta?.msg_body.substring(start_idx_sign);

                    if (start_idx_sign !== -1) {
                        setSelectedSignature({
                            length: start_idx_sign,
                            content: existing_signature,
                        });
                    } else {
                        setSelectedSignature({
                            length: 0,
                            content: '',
                        });
                    }

                    quill.setContents(delta, 'silent');
                } else {
                    quill.setText('');
                }
            }

            if (selectedNode.meta) onSelectTemplate?.(selectedNode.meta);
        };

        const loadSelectedSignature = (value: any, option: any) => {
            const quill = reactQuillRef.current?.getEditor();

            if (quill) {
                if (option.data?.signature_body) {
                    const content = { index: 0, body: '' };

                    const quillContent = quill.root.innerHTML;
                    const startIdxSign = quillContent.indexOf('<p><br></p><p>---</p>');
                    const signature = `<p><br></p><p>---</p>${option.data?.signature_body}` || '';

                    if (
                        startIdxSign === -1 ||
                        selectedSignature.length === 0 ||
                        !quillContent.includes(selectedSignature.content)
                    ) {
                        const existing_signature = quillContent.substring(startIdxSign);

                        // Append the signature at the end of the Quill content.
                        content.body = signature;
                        content.index = quill.getLength();

                        if (startIdxSign !== -1) {
                            // Replace the existing signature with the new one.
                            content.index = 0;
                            content.body = quillContent.replace(existing_signature, signature);

                            // Clear Quill content before inserting the new signature.
                            quill.deleteText(0, quill.getLength());
                        }
                    } else {
                        // Replace the existing signature with the new one.
                        content.index = 0;
                        content.body = quillContent.replace(selectedSignature.content, signature);

                        // Clear Quill content before inserting the new signature.
                        quill.deleteText(0, quill.getLength());
                    }

                    // Insert signature HTML into Quill.
                    quill.clipboard.dangerouslyPasteHTML(content.index, content.body, 'silent');

                    // Set the selected siganture
                    setSelectedSignature({
                        length: signature.length,
                        content: signature,
                    });
                } else {
                    quill.setText('');
                }
            }
        };

        const insertChosenValue = (originalVal: string, node: EmailCodeTreeDataChildType) => {
            const quill = reactQuillRef.current?.getEditor();
            if (quill) {
                const strValue = `{{ ${originalVal} }}`;
                const cursorPosition = quill.getSelection()?.index;

                if (cursorPosition) {
                    // quill.insertText(cursorPosition, strValue);
                    // quill.setSelection(cursorPosition + strValue.length, 0);
                    quill.insertEmbed(
                        cursorPosition,
                        //Insert the TemplateMarker in the same range as the cursor is

                        'CodeTemplateMarker',
                        //This is the name of the Embed

                        {
                            colour: 'yellow',
                            marker: strValue,
                            title: strValue,
                        }
                        //These are the variables to enter
                    );
                } else {
                    // quill.insertText(editorLastIndex, strValue);

                    quill.insertEmbed(
                        editorLastIndex,
                        //Insert the TemplateMarker in the same range as the cursor is

                        'CodeTemplateMarker',
                        //This is the name of the Embed

                        {
                            colour: 'code',
                            marker: strValue,
                            title: node.title,
                        }
                        //These are the variables to enter
                    );
                }
            }
        };

        const _fetchContactRoles = () => {
            return new Promise<EmailCodeTreeDataChildType>((resolve, reject) => {
                getContactRoles()
                    .then(({ data }) => {
                        contactRoles = (data as ContactRoleType[]) || [];
                        resolve({
                            title: 'Additional Contact',
                            value: 'Additional Contact',
                            selectable: false,
                            children: [
                                {
                                    title: 'Roles',
                                    value: 'roles',
                                    children: contactRoles.map((el: ContactRoleType) => {
                                        return {
                                            title: el.role,
                                            value: el.role_id,
                                            selectable: false,
                                            children: [
                                                {
                                                    title: `Contact ${el.role} - Full Name`,
                                                    value: `#contract_contacts}}{{#role_id_${el.role_id}}}{{contactname}}${COMMA_TEMPLATE}{{/role_id_${el.role_id}}}{{/contract_contacts`,
                                                },
                                                {
                                                    title: `Contact ${el.role} - First Name`,
                                                    value: `#contract_contacts}}{{#role_id_${el.role_id}}}{{firstname}}${COMMA_TEMPLATE}{{/role_id_${el.role_id}}}{{/contract_contacts`,
                                                },
                                                {
                                                    title: `Contact ${el.role} - Main Email`,
                                                    value: `#contract_contacts}}{{#role_id_${el.role_id}}}{{email}}${COMMA_TEMPLATE}{{/role_id_${el.role_id}}}{{/contract_contacts`,
                                                },
                                                {
                                                    title: `Contact ${el.role} - Main Phone`,
                                                    value: `#contract_contacts}}{{#role_id_${el.role_id}}}{{telephone}}${COMMA_TEMPLATE}{{/role_id_${el.role_id}}}{{/contract_contacts`,
                                                },
                                                {
                                                    title: `Contact ${el.role} - Company`,
                                                    value: `#contract_contacts}}{{#role_id_${el.role_id}}}{{company}}${COMMA_TEMPLATE}{{/role_id_${el.role_id}}}{{/contract_contacts`,
                                                },
                                            ],
                                        };
                                    }),
                                },
                            ],
                        });
                    })
                    .catch(error => {
                        console.log('error _fetchContactRoles :', error);
                        reject(error);
                    });
            });
        };

        const _fetchContractCustomFields = () => {
            return new Promise<EmailCodeTreeDataChildType>((resolve, reject) => {
                getContractCustomFields()
                    .then(({ data }) => {
                        customSections = (data as contractAdditionalInfoCategoryType[]) || [];
                        resolve({
                            title: 'Custom Section & Fields',
                            value: 'Custom Section & Fields',
                            selectable: false,
                            children: customSections.map(
                                (el: contractAdditionalInfoCategoryType) => {
                                    return {
                                        title: el.category,
                                        value: el.category,
                                        selectable: false,
                                        children: el.contract_additional_info_preferences.map(
                                            (el: AdditionalInfoFieldType) => {
                                                return {
                                                    title: `${el.newname}`,
                                                    value: `contract_additional_info_values.${el.additional_info_field_id}`,
                                                };
                                            }
                                        ),
                                    };
                                }
                            ),
                        });
                    })
                    .catch(error => {
                        console.log('error _fetchContractCustomFields :', error);
                        reject(error);
                    });
            });
        };

        useEffect(() => {
            if (showTemplateDropdown) {
                _fetchEmailTemplates();
            }

            if (showEmailSignatureDropdown) {
                _fetchEmailSignatures();
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            Promise.all([_fetchContactRoles(), _fetchContractCustomFields()])
                .then(results => {
                    console.log('Email Codes Tree Data:', results);
                    setEmailCodesTreeData([...emailTemplateCodes, ...results]);
                })
                .catch(error => {
                    console.error('Error in Promise.all:', error);
                });
        }, []);

        useEffect(() => {
            if (activeSignature && formMode === 'ADD') {
                const { value, option } = {
                    value: activeSignature.email_signature_id,
                    option: {
                        data: activeSignature,
                    },
                };

                loadSelectedSignature(value, option);
            }
        }, [activeSignature]);

        return (
            <div className="ezrichtext-placeholder">
                <label className="required-label" title="Email Body">
                    {' '}
                    Email Body{' '}
                </label>

                {!hideToolbar && (
                    <div id="toolbar">
                        <span className="ql-formats">
                            <select className="ql-header" defaultValue="3">
                                <option value="1">Heading</option>
                                <option value="2">Subheading</option>
                                <option value="3">Normal</option>
                            </select>
                        </span>
                        <span className="ql-formats">
                            <button className="ql-bold" />
                            <button className="ql-italic" />
                            <button className="ql-underline" />
                            <button className="ql-strike" />
                            <button className="ql-blockquote" />
                        </span>
                        <span className="ql-formats">
                            <button className="ql-list" value="ordered" />
                            <button className="ql-list" value="bullet" />
                            <button className="ql-indent" value="-1" />
                            <button className="ql-indent" value="+1" />
                        </span>

                        <span className="ql-formats">
                            <button className="ql-link" />
                            <button className="ql-image" />
                        </span>
                        <span className="ql-formats">
                            <button className="ql-clean" />
                        </span>

                        <span className="ql-formats custom-margin-right">
                            <div className="email-msg-editor-toolbar">
                                {showTemplateDropdown && (
                                    <TreeSelect
                                        style={{ width: '180px', marginRight: '8px' }}
                                        value={null}
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            width: '500px',
                                            maxWidth: '500px',
                                            overflow: 'auto',
                                        }}
                                        placeholder={<b>Load from Template</b>}
                                        allowClear
                                        showSearch
                                        treeDefaultExpandAll={false}
                                        disabled={props.readOnly}
                                        onSelect={(
                                            value: any,
                                            node: TemplateListTreeDataChildType
                                        ) => {
                                            if (node.children?.length) {
                                                return;
                                            }
                                            loadSelectedTemplate(node);
                                        }}
                                        treeData={templateListTreeData}
                                        treeLine={{ showLeafIcon: false }}
                                    />
                                )}
                                {!hideToolbarInsertCode && (
                                    <TreeSelect
                                        style={{ width: '250px', marginRight: '8px' }}
                                        value={null}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder={<b>Insert Code</b>}
                                        allowClear
                                        showSearch
                                        treeDefaultExpandAll={false}
                                        disabled={props.readOnly}
                                        onSelect={(
                                            value: any,
                                            node: EmailCodeTreeDataChildType
                                        ) => {
                                            if (node.children?.length) {
                                                return;
                                            }

                                            insertChosenValue(value as string, node);
                                        }}
                                        treeData={emailCodesTreeData}
                                        treeLine={{ showLeafIcon: false }}
                                    />
                                )}
                            </div>
                        </span>
                        <span className="ql-formats">
                            <div className="email-msg-editor-toolbar">
                                {showEmailSignatureDropdown && (
                                    <Select
                                        style={{ width: '180px' }}
                                        showSearch
                                        placeholder={<b>Select Signature</b>}
                                        value={[]}
                                        optionFilterProp="label"
                                        options={signatureListData?.map(el => ({
                                            label: el.signature_name,
                                            value: el.email_signature_id,
                                            data: el,
                                        }))}
                                        disabled={props.readOnly}
                                        onSelect={loadSelectedSignature}
                                    ></Select>
                                )}
                            </div>
                        </span>
                    </div>
                )}

                <Form.Item
                    name="msg_body"
                    rules={[
                        { required: isRequired, message: 'Email body is required' },
                        {
                            validator: (_, value) => {
                                const parser = new DOMParser();

                                const { textContent } = parser.parseFromString(
                                    value,
                                    'text/html'
                                ).documentElement;

                                if (!textContent?.trim()) {
                                    return Promise.reject("Email body can't be empty");
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        },
                    ]}
                >
                    <ReactQuill
                        ref={reactQuillRef}
                        style={{ width: '100%' }}
                        modules={modules}
                        theme="snow"
                        onBlur={previousSelection => {
                            setEditorLastIndex(previousSelection?.index || 0);
                        }}
                        {...props}
                    />
                </Form.Item>
            </div>
        );
    }
);
