// Core React libs import
import React from 'react';

// Core ANTD and 3rd party libs import
import { Select } from 'antd';
import type { SelectProps } from 'antd/es/select';

type EZSelectContactProps = SelectProps & {};

export const EZSelectContact: React.FC<EZSelectContactProps> = ({ ...props }) => {
    return (
        <Select
            style={{ width: '100%' }}
            placeholder="Select a contact"
            optionFilterProp="contactname"
            fieldNames={{
                label: 'contactname',
                value: 'contact_id',
            }}
            showSearch
            showArrow
            {...props}
        />
    );
};
