// Core React libraries import
import React, { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Form, Col, Skeleton, Row, Button, Tooltip } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
// EZ web-app components import
import { EmailIntegrationIndicator } from 'components/partials/emailIntegrationIndicator';
import { Partial_EmailTemplateFormItems } from './PartialEmailTemplateFormItems';
import { EMAIL_INTEGRATION_ERR_CODE, EmailCredentialType, EmailLogType } from 'types/email';
import { EmailTemplateType } from 'types/emailTemplate';
import {
    createEmailDraft,
    getEmailLogDetail,
    getMyEmailCredential,
    sendEmailByEmailLogID,
    updateEmailDraft,
} from 'utils/ez_api/emailLogAPIs';
import { showMessage, showNotification } from 'utils/notificationUtil';
import { checkEmailCredential } from 'utils/emailUtil';
import { InfoCircleOutlined } from '@ant-design/icons';
// EZ web-app styles and assets import

export const Partial_EmailForm: React.FC<{
    contractID?: number;

    emailLog?: EmailLogType;

    onSendEmail?: () => void;
}> = ({ contractID, emailLog, onSendEmail }) => {
    const [form] = Form.useForm();

    const [emailCredential, setEmailCredential] = useState<EmailCredentialType>();
    const [activeEmailLog, setActiveEmailLog] = useState<EmailLogType>(emailLog as EmailLogType);
    const [loadingLogDetail, setLoadingLogDetail] = useState<boolean>(false);
    const [isEmailCredentialSet, setIsEmailCredentialSet] = useState<boolean>(false);
    const [isFailedSendEmail, setIsFailedSendEmail] = useState<boolean>(false);

    const _onFormFinish = (values: any) => {
        console.log('🚀 ~ file: taskEmailModal.tsx:41 ~ values:', values);
    };

    const _getDetail = (messageId: number) => {
        setLoadingLogDetail(true);
        getEmailLogDetail(messageId)
            .then(({ data }) => {
                const emailLogResponse = data as EmailLogType;

                if (emailLogResponse?.message_id) {
                    setActiveEmailLog(emailLogResponse);

                    if (emailLogResponse.is_draft) {
                        form.setFieldsValue(emailLogResponse);
                        form.setFieldValue(
                            'msg_attachments',
                            emailLogResponse?.docs?.length
                                ? emailLogResponse?.docs?.map(doc => doc.doc_id)
                                : []
                        );

                        form.setFields([{ name: 'msg_body', validating: false }]);
                    }
                }
            })
            .finally(() => {
                setLoadingLogDetail(false);
            });
    };

    const _sendPreview = () => {
        _sendEmail(false);
    };

    const _sendEmail = async (isNotPreview: boolean = true) => {
        form.validateFields()
            .then(async () => {
                if (!emailCredential?.relay_type_id) {
                    return showMessage('error', "Email integration hasn't been set.");
                }

                let payload: Partial<EmailLogType> = {
                    ...activeEmailLog,
                    ...form.getFieldsValue(),
                };

                if (!payload?.message_id && !isFailedSendEmail) {
                    payload.contract_id = contractID;
                    const { data } = await createEmailDraft(payload);

                    payload = { ...payload, ...data };
                } else {
                    updateEmailDraft(activeEmailLog.message_id, payload);
                }

                if (payload?.message_id) {
                    sendEmailByEmailLogID(payload.message_id, payload, isNotPreview)
                        .then(() => {
                            setIsFailedSendEmail(false);

                            showNotification(
                                'success',
                                `New ${
                                    isNotPreview ? 'email' : 'preview email'
                                } has been queued for delivery.`
                            );
                            if (isNotPreview) {
                                onSendEmail?.();
                            }
                        })
                        .catch(err => {
                            setActiveEmailLog({
                                ...activeEmailLog,
                                ...payload,
                                message_id: payload?.message_id || 0,
                            });

                            setIsFailedSendEmail(true);

                            if (
                                err.response &&
                                err.response.status === 422 &&
                                err.response.data?.message === 'email_recipient_is_empty'
                            ) {
                                return showNotification(
                                    'error',
                                    'Email recipient is empty. Please double-check your transaction/listing information.'
                                );
                            }
                        });
                }
            })
            .catch(() => {});
    };

    const _saveAsDraft = () => {
        const payload: Partial<EmailLogType> = {
            ...activeEmailLog,
            ...form.getFieldsValue(),
        };
        payload.is_draft = true;
        if (activeEmailLog?.message_id) {
            updateEmailDraft(activeEmailLog.message_id, payload).then(() => {
                showNotification('success', 'Changes have been saved to draft.');
            });
        } else {
            payload.contract_id = contractID;
            createEmailDraft(payload).then(({ data }) => {
                const emailLogResponse = data as EmailLogType;
                showNotification('success', 'Changes have been saved to draft.');

                setActiveEmailLog({ ...emailLogResponse });
            });
        }
    };

    const _onSelectEmailTemplate = (emailTemplate: EmailTemplateType) => {
        if (activeEmailLog && emailTemplate) {
            activeEmailLog.email_template = emailTemplate;
            activeEmailLog.email_template_id = emailTemplate.email_template_id;
            setActiveEmailLog({ ...activeEmailLog });
        }
    };

    const _fetchMyCredentials = () => {
        getMyEmailCredential().then(resp => {
            const emailCredentialTemp = resp.data as EmailCredentialType;

            setEmailCredential(emailCredentialTemp);

            const checkResult = checkEmailCredential(emailCredentialTemp);
            setIsEmailCredentialSet(checkResult.isEmailIntegrationSet);
        });
    };

    useEffect(() => {
        _fetchMyCredentials();

        if (emailLog?.message_id) {
            _getDetail(emailLog.message_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailLog]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form
                layout="vertical"
                form={form}
                onFinish={_onFormFinish}
                requiredMark={true}
                // validateTrigger={['onSubmit']}
            >
                {loadingLogDetail && <Skeleton active />}
                {!loadingLogDetail && (
                    <>
                        <EmailIntegrationIndicator />
                        <Row gutter={[16, 0]}>
                            <Partial_EmailTemplateFormItems
                                formType="EMAIL_FORM"
                                onSelectTemplate={_onSelectEmailTemplate}
                                showAttachmentField
                                contractID={contractID}
                            />
                            {activeEmailLog?.email_template?.email_template_id && (
                                <Col span={24}>
                                    <Form.Item label="Note(s) from Linked Email Template">
                                        {activeEmailLog?.email_template?.template_note || '-'}
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={24}>
                                <Row align="middle">
                                    <Col span={12} className="pull-left text-left">
                                        <Button
                                            // type="primary"
                                            onClick={() => _sendPreview()}
                                            disabled={!isEmailCredentialSet}
                                        >
                                            Send Me the Preview
                                        </Button>
                                        <Tooltip title="Preview email will be sent to your current email address. Click the avatar icon at the top right corner of your screen to see your email.">
                                            <InfoCircleOutlined className="ml-2" />
                                        </Tooltip>
                                    </Col>
                                    <Col span={12} className="pull-right text-right">
                                        <Button
                                            onClick={() => _saveAsDraft()}
                                            className="mr-3"
                                            disabled={
                                                emailLog?.message_id && !activeEmailLog?.is_draft
                                                    ? true
                                                    : false
                                            }
                                        >
                                            Save as draft
                                        </Button>
                                        <Button
                                            type="primary"
                                            onClick={() => _sendEmail()}
                                            disabled={!isEmailCredentialSet}
                                        >
                                            Send
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </Form>
        </>
    );
};
