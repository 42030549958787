// Core React libraries import
import { useContext, useEffect } from 'react';

// Core ANTD and 3rdparty libraries import
import { Col, Row, Tabs } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
import { ProspectTabIcon, TransactionTabIcon } from 'types/webAppTab';
// EZ web-app components import
import { TransactionSetting } from './subTabs/transactionSetting';
import { TeamMemberSetting } from './subTabs/teamMemberSetting';

// EZ web-app styles and assets import
import './Admin.scss';
import { ProspectSetting } from './subTabs/prospectSetting';
import { ACCOUNT_ROLE_HAS_ACCESS_TO_DETAIL_ADMIN_AREA } from 'types/account';
import { HomeContext } from 'context/EZContext';

const AdminSettingTab: React.FC<{}> = ({}) => {
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const HomeCtx = useContext(HomeContext);
    const accountrole_id = HomeCtx.userPermission?.accountrole_id;

    const hasAccessToDetailAdmin = accountrole_id
        ? ACCOUNT_ROLE_HAS_ACCESS_TO_DETAIL_ADMIN_AREA.includes(accountrole_id)
        : false;
    return (
        <div className="ez_admin-setting">
            <div className="mb-4 ez-sub-content-wrapper ez-sub-content-section">
                <Row>
                    <Col span={24}>
                        <b>Admin Area - Web App Setting and Customization</b>
                    </Col>
                </Row>
            </div>
            <div className=" ez-sub-content-wrapper ez-sub-content-section">
                <Row>
                    <Col span={24}>
                        <Tabs className="mb-4" defaultActiveKey="1">
                            <Tabs.TabPane
                                tab={
                                    <span>
                                        <TransactionTabIcon /> Transaction
                                    </span>
                                }
                                key="admin-transaction-setting"
                            >
                                <TransactionSetting />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <span
                                        style={{
                                            display: hasAccessToDetailAdmin ? 'inherit' : 'none',
                                        }}
                                    >
                                        <ProspectTabIcon /> Prospect
                                    </span>
                                }
                                key="admin-prospect-setting"
                            >
                                <ProspectSetting />
                            </Tabs.TabPane>
                            <Tabs.TabPane
                                tab={
                                    <span
                                        style={{
                                            display: hasAccessToDetailAdmin ? 'inherit' : 'none',
                                        }}
                                    >
                                        <UsergroupAddOutlined /> Team and Access Management
                                    </span>
                                }
                                key="admin-member-setting"
                            >
                                <TeamMemberSetting />
                            </Tabs.TabPane>
                            {/* <Tabs.TabPane
                                tab="Integration (i.e.: BrokerSumo, etc)"
                                key="admin-integration-setting"
                            >
                                <IntegrationSetting />
                            </Tabs.TabPane> */}
                        </Tabs>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AdminSettingTab;
