import { Quill } from 'react-quill';

var Embed = Quill.import('blots/embed');

export class CodeTemplateMarker extends Embed {
    static create(value: any) {
        let node = super.create(value);

        node.setAttribute('class', 'quill-template-code badge badge-' + value.colour);
        //Set up the badge, and badge colour

        node.setAttribute('data-marker', value.marker);

        node.setAttribute('data-title', value.title);

        //The title is what the user sees in their editor
        const codeTitleInnerSpan = document.createElement('span');
        codeTitleInnerSpan.setAttribute('title', value.title);
        codeTitleInnerSpan.setAttribute('class', 'code-title');

        //The code-value is what the user sees in their sent mail
        const codeValueSpan = document.createElement('span');
        codeValueSpan.setAttribute('class', 'code-value');
        codeValueSpan.innerHTML = value.marker;

        node.appendChild(codeTitleInnerSpan);
        node.appendChild(codeValueSpan);

        return node;
    }

    static value(node: any) {
        return {
            marker: node.getAttribute('data-marker'),
            title: node.getAttribute('data-title'),
        };
    }
}

CodeTemplateMarker.blotName = 'CodeTemplateMarker';
CodeTemplateMarker.tagName = 'codeTemplate';
