// Core React libraries import
import React, { useContext, useEffect, useRef, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import {
    Row,
    Col,
    Space,
    Menu,
    Button,
    Tabs,
    Typography,
    Dropdown,
    Divider,
    Select,
    UploadProps,
    UploadFile,
    Collapse,
    Modal,
} from 'antd';
import { ProColumns } from '@ant-design/pro-components';
import { RenderExpandIcon } from 'rc-table/lib/interface';
import {
    DeleteOutlined,
    DownOutlined,
    FieldTimeOutlined,
    InboxOutlined,
    CheckCircleOutlined,
    CheckCircleFilled,
    RightOutlined,
    CloseOutlined,
    EllipsisOutlined,
    SaveFilled,
    DownloadOutlined,
    FileSearchOutlined,
} from '@ant-design/icons';
import fileDownload from 'js-file-download';

// EZ web-app utils import
import { downloadFileRequest } from 'utils/apiUtil';
// EZ web-app APIs import
import { downloadLegacyDocument, getPresignedURLDoc } from 'utils/ez_api/documentAPIs';
import {
    addNewProspectDoc,
    deleteProspectDocument,
    getPresignedURLProspectDocUpload,
    getProspectDocs,
    updateProspectDocument,
} from 'utils/ez_api/prospect/prospectDocumentAPIs';
// EZ web-app types import
// EZ web-app components import
import { EZDateFormat, EZTable, EZUpload, UploadProgress } from 'components';
import {
    forwardRefEZTableProps,
    IOnChangeCallbackProps,
    ITableQueryParams,
} from 'components/table/EZProTable';
import { EZUploadProps } from 'components/upload/upload';
import { showMessage, showNotification } from 'utils/notificationUtil';
import { previewDoc } from 'components/appPage/documents';
import { ProspectDetailTabProps } from 'types/prospect';
import { ProspectDocumentType } from 'types/prospectDocument';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const defaultTableParam: ITableQueryParams = {
    searchKeyword: '',
    orderParams: [],
    // orderParams: [['$doc_folder.rank$', 'desc']],
    whereParams: {},
    whereFilterParams: {},
};

export const ProspectSubTab_Documents: React.FC<ProspectDetailTabProps> = ({
    prospectID,
    manualReloadCount,
    reloadProspectStat,
}) => {
    const EZTableRef = useRef<forwardRefEZTableProps>();

    const [fileUploadList, setFileUploadList] = useState<UploadFile[]>([]);

    const [queryParams, setQueryParams] = useState<ITableQueryParams>(defaultTableParam);

    async function _fetchData(EZTableQueryParams: string): Promise<any> {
        return getProspectDocs(prospectID, EZTableQueryParams).then(result => result);
    }

    const _handleFileUploadChange: UploadProps['onChange'] = ({ file, fileList }) => {
        // TODO: Find the alternative solution as the setState for array will trigger re-rendering the table, which is bad for user experience due to unresponsive scroll
        setFileUploadList([...fileList]);

        if (file.status === 'done') {
            if (prospectID) {
                addNewProspectDoc(prospectID, {
                    folder_id: 1,
                    filename: file.name,
                    u_filename: file.response?.newFilename,
                    bucket: file.response?.Bucket,
                    key: file.response?.Key,
                    size: file.size,
                    content_type: file.type,
                }).then(() => {
                    setQueryParams({
                        ...queryParams,
                        resetPagination: false,
                    });
                });
                setFileUploadList(fileList.filter(f => f.uid !== file.uid));

                reloadProspectStat?.(prospectID);
            }
        }
    };

    async function _fetchPresignURL(payload: any): Promise<any> {
        return getPresignedURLProspectDocUpload(prospectID, payload);
    }

    const _onChangeCallback: IOnChangeCallbackProps = () => {};

    const _reloadSubTabData = (resetPagination?: boolean, silentLoading?: boolean) => {
        setQueryParams({
            ...queryParams,
            resetPagination,
            silentLoading: silentLoading || undefined,
        });
    };

    const _downloadDocument = (doc: ProspectDocumentType) => {
        showMessage('success', 'Download has been initiated. Please wait...');

        // if bucket and doc property has value, the doc will be downloaded from AWS S3
        if (doc.bucket && doc.key) {
            getPresignedURLDoc('prospect_document', doc.doc_id, 'download').then(resp => {
                downloadFileRequest(resp.data?.presignedURL, doc.filename || 'untitled');
            });
        } else {
            downloadLegacyDocument('prospect_document', doc.doc_id).then((resp: any) => {
                fileDownload(resp, doc.filename || 'untitled');
            });
        }
    };

    const _saveDocumentRecord = async (
        newData: ProspectDocumentType,
        oldData: ProspectDocumentType,
        formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
    ) => {
        // setDocIDsEdit([...docIDsEdit, { docID: newData.doc_id }]);

        if (newData.filename === '' || newData.filename === null) {
            newData.filename = oldData.filename;
        }

        if (newData.doc_id) EZTableRef.current?.modifyData(newData, newData.doc_id);

        return updateProspectDocument(
            oldData.prospect_id,
            newData.doc_id,
            {
                ...newData,
            },
            formType
        )
            .then(() => {
                if (prospectID) {
                    reloadProspectStat?.(prospectID);
                }

                if (newData.status === 1 && oldData.status === 0) {
                    showNotification(
                        'success',
                        `Document: "${newData.filename}" has been marked as checked.`
                    );
                } else if (newData.status === 0 && oldData.status === 1) {
                    showNotification(
                        'success',
                        `Document: "${newData.filename}" has been marked as unchecked.`
                    );
                } else {
                    showNotification(
                        'success',
                        `Document: "${newData.filename}" has been successfully updated.`
                    );
                }

                if (oldData.folder_id !== newData.folder_id) {
                    _reloadSubTabData(false, true);
                }

                return newData;
            })
            .catch(err => {
                throw err;
            });
    };

    const _columns: ProColumns<ProspectDocumentType>[] = [
        {
            title: 'Checked?',
            dataIndex: 'status',
            width: '35px',
            sorter: false,
            editable: false,
            align: 'center',
            render: (_, record) => (
                <>
                    <Button
                        className="mr-1"
                        size="small"
                        type="default"
                        onClick={e => {
                            _saveDocumentRecord(
                                { ...record, status: record.status === 1 ? 0 : 1 },
                                record
                            );
                        }}
                    >
                        {record.status === 1 ? (
                            <CheckCircleFilled style={{ color: 'green' }} />
                        ) : (
                            <CheckCircleOutlined />
                        )}
                    </Button>
                </>
            ),
        },
        {
            title: 'Document Name',
            dataIndex: 'filename',
            width: '160px',
            sorter: true,
            ellipsis: true,
            // fixed: 'left',
            render: (_, record) => (
                <>
                    <Text
                        type={record.status === 1 ? 'secondary' : undefined}
                        title={record.filename}
                    >
                        {record.filename || '-'}
                    </Text>
                </>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: '160px',
            sorter: true,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text
                        type={record.status === 1 ? 'secondary' : undefined}
                        title={record.description}
                    >
                        {record.description || '-'}
                    </Text>
                </>
            ),
        },
        {
            title: 'Uploaded By',
            dataIndex: 'account.fullname',
            width: '70px',
            editable: false,
            sorter: false,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text
                        type={record.status === 1 ? 'secondary' : undefined}
                        title={record.uploadedby_account?.fullname}
                    >
                        {record.uploadedby_account?.fullname || '-'}
                    </Text>
                </>
            ),
        },
        {
            title: 'Uploaded On',
            dataIndex: 'posted_date',
            width: '80px',
            editable: false,
            sorter: false,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <Text type={record.status === 1 ? 'secondary' : undefined}>
                        <EZDateFormat value={record.date_uploaded} format="MMM Do, YYYY hh:mma" />
                    </Text>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '85px',
            valueType: 'option',
            render: (_, record, index, action) => (
                <Space size="small" className="pl-1">
                    <Button
                        className="p-0 mr-1"
                        type="link"
                        icon={<FieldTimeOutlined />}
                        title="Quick-edit this document"
                        onClick={() => {
                            action?.startEditable(record.doc_id);
                        }}
                    >
                        Quick Edit
                    </Button>
                    <Divider type="vertical" />
                    <Button
                        className="p-0 mr-1"
                        size="small"
                        type="link"
                        icon={<FileSearchOutlined />}
                        title="Preview"
                        onClick={() => {
                            previewDoc('prospect_document', [
                                {
                                    file_id: record.doc_id,
                                    prospect_id: prospectID,
                                },
                            ]);
                        }}
                    ></Button>
                    <Dropdown
                        trigger={['click']}
                        overlay={
                            <Menu>
                                <Menu.Item key="0" onClick={() => _downloadDocument(record)}>
                                    <DownloadOutlined className="mr-3" />
                                    <span className="">Download</span>
                                </Menu.Item>
                                <Menu.Divider />
                                <Menu.Item key="2" danger onClick={() => _deleteDocument(record)}>
                                    <DeleteOutlined className="mr-3" />
                                    Delete Document
                                </Menu.Item>
                            </Menu>
                        }
                        placement="bottomRight"
                    >
                        <span onClick={e => e.preventDefault()}>
                            <Button size="small" type="link" icon={<EllipsisOutlined />} />
                        </span>
                    </Dropdown>
                </Space>
            ),
            fixed: 'right',
        },
    ];

    const _expandIcon: RenderExpandIcon<ProspectDocumentType> = ({
        expanded,
        onExpand,
        record,
    }) => {
        return expanded ? (
            <DownOutlined
                id={`ez-tr-icon-expandable-c_${prospectID}-doc_${record.doc_id}`}
                onClick={(e: any) => onExpand(record, e)}
            />
        ) : (
            <RightOutlined
                id={`ez-tr-icon-expandable-c_${prospectID}-doc_${record.doc_id}`}
                onClick={(e: any) => onExpand(record, e)}
            />
        );
    };

    const _deleteDocument = (record: ProspectDocumentType) => {
        const deleteConfirmationModal = Modal.confirm({
            title: `Delete confirmation`,
            content: (
                <>
                    Are you sure to delete document/file: <b>{record.filename}</b>?
                </>
            ),
            onOk: () => {
                deleteConfirmationModal.update({
                    okButtonProps: {
                        disabled: true,
                    },
                    okText: 'Deleting...',
                });
                deleteProspectDocument(record.prospect_id, record.doc_id).then(() => {
                    deleteConfirmationModal.destroy();
                    if (reloadProspectStat && prospectID) {
                        reloadProspectStat(prospectID);
                    }

                    showNotification(
                        'success',
                        `Document/file: "${record.filename}" has been successfully deleted.`
                    );
                    _reloadSubTabData(false, true);
                });
                return true;
            },
            closable: true,
            maskClosable: true,
        });
    };

    const uploadProps: EZUploadProps = {
        fileList: fileUploadList,
        onChange: _handleFileUploadChange,
        getPresignedURL: _fetchPresignURL,
    };

    useEffect(() => {
        _reloadSubTabData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez-component-documents">
            <Row className="row-wrapper">
                <Col span={24} className="mb-3">
                    <div>
                        <UploadProgress
                            fileUploadList={fileUploadList}
                            setFileUploadList={setFileUploadList}
                        />
                    </div>
                    <EZUpload dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                    </EZUpload>
                </Col>
                <Col span={24}>
                    <EZTable
                        ref={EZTableRef}
                        className="eztable-hide-delete-editable"
                        pagination={{
                            pageSize: 50,
                            pageSizeOptions: [20, 50, 100],
                            position: ['bottomLeft', 'topLeft'],
                        }}
                        queryParams={queryParams}
                        columns={_columns}
                        size="small"
                        rowKey="doc_id"
                        fetchData={_fetchData}
                        scroll={{ x: 1400 }}
                        tableAlertRender={false}
                        editable={{
                            actionRender: (_, __, defaultDom) => [
                                defaultDom.save,
                                defaultDom.cancel,
                            ],
                            type: 'multiple',
                            onSave: async (_, data: ProspectDocumentType, oldData) => {
                                return _saveDocumentRecord(
                                    {
                                        ...data,
                                    },
                                    oldData
                                );
                            },
                            saveText: (
                                <>
                                    <Button
                                        className="p-0 pl-1"
                                        type="link"
                                        icon={<SaveFilled />}
                                        title="Edit this doc"
                                    >
                                        Save
                                    </Button>
                                </>
                            ),
                            cancelText: (
                                <>
                                    <Divider type="vertical" />
                                    <Button
                                        className="p-0 text-grey"
                                        type="text"
                                        title="Cancel and back"
                                        icon={<CloseOutlined />}
                                    ></Button>
                                </>
                            ),
                        }}
                        onChangeCallback={_onChangeCallback}
                    />
                </Col>
            </Row>
        </div>
    );
};
