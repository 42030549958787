import { AntTreeNodeProps } from 'antd/es/tree';
import { EmailTemplateType } from './emailTemplate';
import { AccountInfo } from '@azure/msal-browser';
import {
    TransactionDetailType,
    TransactionDocItemType,
    TransactionTaskItemType,
} from './transaction';

export enum SENDMAIL_ONBEHALF {
    EZ_SUPPORT = 1,
    GMAIL = 2,
    MS_365 = 3,
    CUSTOM_SMTP = 4,
}

export enum EMAIL_INTEGRATION_ERR_CODE {
    GOOGLE_REFRESTOKEN_EXPIRED = 1,
    MS365_REFRESHTOKEN_EXPIRED = 2,
    CUSTOM_SMT_ERR_CONNECTION = 4,
    RELAY_TYPE_IS_UNSET = 4,
}

export type EmailCodeTreeDataChildType = AntTreeNodeProps & {
    meta?: any;

    children?: EmailCodeTreeDataChildType[];
};

export type TemplateListTreeDataChildType = AntTreeNodeProps & {
    meta?: EmailTemplateType;

    children?: TemplateListTreeDataChildType[];
};

export type EmailCodeTreeDataType = EmailCodeTreeDataChildType[];

export type EmailLogType = {
    message_id: number;
    account_id: number;
    parent_account_id: number;
    msg_type: number;
    email_template_id?: number;
    is_draft?: boolean;
    msg_subject?: string;
    msg_body?: string;
    msg_sender?: string;
    msg_to?: string[];
    msg_bcc?: string[];
    msg_cc?: string[];
    contract_id?: number;
    contract_task_id?: number;
    prospect_id?: number;
    prospect_task_id?: number;
    relay_type_id?: number;

    relayed_at?: Date;
    relayed_by?: number;

    sent_at?: Date;
    delivered_at?: Date;
    read_at?: Date;
    msg_meta?: string;
    delivery_issue_type?: number;
    delivery_issue_at?: Date;

    created_at: Date;
    updated_at?: Date;
    updated_by: number;

    email_template?: EmailTemplateType;
    docs?: TransactionDocItemType[];

    account?: {
        email: string;
        fullname: string;
    };

    updated_by_account?: {
        email: string;
        fullname: string;
    };

    relayed_by_account?: {
        email: string;
        fullname: string;
    };

    contract?: TransactionDetailType;
    task_todolist?: TransactionTaskItemType;
};

export type EmailCredentialType = {
    credential_id: number;
    account_id: number;
    parent_account_id: number;

    relay_type_id?: number;

    google_email?: string;
    google_access_token?: string;
    google_last_issued?: Date; // UTC Date
    google_expires_in?: Date; // UTC Date
    google_rt_last_issued?: Date; // UTC Date
    google_rt_expires_in?: Date; // UTC Date
    google_token_type?: string;
    google_refresh_token?: string;
    google_scope?: string;

    ms_email?: string;
    ms_access_token?: string;
    ms_last_issued?: Date; // UTC Date
    ms_expires_in?: Date; // UTC Date
    ms_rt_last_issued?: Date; // UTC Date
    ms_rt_expires_in?: Date; // UTC Date
    ms_token_type?: string;
    ms_refresh_token?: string;
    ms_scope?: string;
    ms_account?: AccountInfo;

    smtp_outgoing_server?: string;
    smtp_outgoing_port?: number;
    smtp_outgoing_ssl?: Boolean;
    smtp_outgoing_auth?: number;
    smtp_outgoing_username?: string;
    smtp_outgoing_password?: string;

    smtp_incoming_server?: string;
    smtp_incoming_port?: number;
    smtp_incoming_ssl?: Boolean;
    smtp_incoming_auth?: number;
    smtp_incoming_username?: string;
    smtp_incoming_password?: string;
    smtp_incoming_del_fetch?: number;

    created_at: Date;
    updated_at?: Date;
};
