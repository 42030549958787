import React from 'react';

import { MaskedInput } from 'antd-mask-input';
import { MaskedInputProps, MaskType } from 'antd-mask-input/build/main/lib/MaskedInput';

type EZInputMaskedProps = Omit<MaskedInputProps, 'mask'> & {
    inputType?: 'USPhoneNumber' | 'USPCellphoneNumber';
    mask?: MaskType;
};

const EZInputMasked: React.FC<EZInputMaskedProps> = ({
    inputType,
    mask = '(000) 000-0000',
    ...props
}) => {
    return (
        <>
            <MaskedInput
                mask={
                    //  https://imask.js.org/guide.html#masked-pattern
                    mask
                }
                {...props}
            />
        </>
    );
};

export default EZInputMasked;
