import { apiUrl } from '../../envUtil';
import { APIRequest } from '../../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getProspectNotes = (prospect_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `prospect/${prospect_id}/notes` + (params ? `?${params}` : ''),
    });
};

export const updateProspectNote = (prospect_id: number, notes: string, note_id?: number) => {
    if (note_id) {
        Mixpanel.track('app.edit_prospect', {
            prospect_id,
            sub_tab: 'notes',
            action: 'edit',
        });
    } else {
        Mixpanel.track('app.edit_prospect', {
            prospect_id,
            sub_tab: 'notes',
            action: 'add',
        });
    }

    return APIRequest({
        method: note_id ? 'put' : 'post',
        url:
            apiUrl +
            (note_id ? `prospect/${prospect_id}/note/${note_id}` : `prospect/${prospect_id}/notes`),
        data: {
            notes: notes,
        },
        useDefaultErrorNotif: true,
    });
};

export const deleteProspectNote = (prospect_id: number, note_id: number) => {
    Mixpanel.track('app.edit_prospect', {
        prospect_id,
        sub_tab: 'notes',
        action: 'delete',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `prospect/${prospect_id}/note/${note_id}`,
        useDefaultErrorNotif: true,
    });
};
