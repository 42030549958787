// Core React libs import
import React, { useState, useEffect, useContext } from 'react';

// Core ANTD and other libs import
import moment from 'moment';
import {
    Typography,
    Row,
    Col,
    Descriptions,
    Button,
    Dropdown,
    Menu,
    Space,
    BackTop,
    Tabs,
    Select,
    Collapse,
    Modal,
    Tag,
} from 'antd';
import { Gutter } from 'antd/lib/grid/row';
import {
    EditOutlined,
    DownOutlined,
    DeleteOutlined,
    MailOutlined,
    CaretRightOutlined,
    InfoCircleOutlined,
    ReloadOutlined,
    FlagTwoTone,
} from '@ant-design/icons';

// EZ web-app APIs import
import {
    archiveProspect,
    getProspectDetail,
    getProspectQuickStats,
} from 'utils/ez_api/prospect/prospectAPIs';
// Core EZ app level import
import { HomeContext } from 'context/EZContext';
import { ITabContentProps } from 'types/webAppTab';
import { EZCopyable, EZDateFormat, EZOverlaySpinner } from 'components';
import { showNotification } from 'utils/notificationUtil';
import {
    ProspectDataType,
    ProspectDetailTabPane,
    ProspectDetailType,
    ProspectQuickStat,
} from 'types/prospect';
import {
    generateDocBadge,
    generateNoteBadge,
    generateTaskBadge,
} from '../transaction/ViewDetailTransaction';
import { ProspectSubTab_Notes } from './subTabs/SubTab_Notes';
import { ProspectSubTab_ProfilePreference } from './subTabs/SubTab_ProfilePreference';
import { ProspectSubTab_Tasks } from './subTabs/SubTab_Tasks';
import { ProspectSubTab_Contacts } from './subTabs/SubTab_Contacts';
import { ProspectSubTab_Documents } from './subTabs/SubTab_Documents';
import { isProspectABuyer } from 'utils/webAppUtil';
import { ModalEditProspectInfo } from './modal/Modal_editProspectInfo';

import './ProspectDetail.scss';

const { Text, Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const _subTabs: ProspectDetailTabPane[] = [
    {
        key: 'ez_prospect_household_profile',
        label: 'Preference and Household Profile',
        tabContent: ProspectSubTab_ProfilePreference,
    },
    {
        key: 'ez_prospect_tasks',
        label: <span>Tasks</span>,
        tabContent: ProspectSubTab_Tasks,
    },
    {
        key: 'ez_prospect_documents',
        label: <span>Documents</span>,
        tabContent: ProspectSubTab_Documents,
    },
    {
        key: 'ez_prospect_notes',
        label: 'Notes',
        tabContent: ProspectSubTab_Notes,
    },
    {
        key: 'ez_prospect_contacts',
        label: 'Additional Contacts',
        tabContent: ProspectSubTab_Contacts,
    },
];

const DefaultTransactionQuickStat = {
    taskStatInfo: {
        incomplete: 0,
        complete: 0,
        total: 0,
    },
    documentStatInfo: {
        incomplete: 0,
        complete: 0,
        total: 0,
    },
    noteStatInfo: {
        total: 0,
    },
};

const colLabelSpan = 6;
const colValueSpan = 18;
const rowFormViewGutter: [Gutter, Gutter] = [12, 12];

const ChangeSubTabLayoutElm: React.FC<{
    subTabLayout: string;
    setSubTabLayout: React.Dispatch<React.SetStateAction<string>>;
}> = ({ subTabLayout, setSubTabLayout }) => {
    return (
        <Select
            defaultValue={subTabLayout}
            style={{ width: 200 }}
            onChange={val => setSubTabLayout(val)}
        >
            <Option value="tabs">
                <span>
                    <Text>View Layout: </Text>
                    <Text strong>Tabs</Text>
                </span>
            </Option>
            <Option value="collapsible">
                <span>
                    <Text>View Layout: </Text>
                    <Text strong>Collapsible</Text>
                </span>
            </Option>
        </Select>
    );
};

const ProspectDetail: React.FC<ITabContentProps> = ({ tabKey, data }) => {
    const HomeCtx = useContext(HomeContext);

    const [isBuyer, setIsBuyer] = useState<boolean>(false);

    // custom reload counter to force the sub tab to re-load the data
    const [manualReloadCount, setManualReloadCount] = useState<number>(0);

    const subTabs = [..._subTabs];
    const [subTabLayout, setSubTabLayout] = useState<string>('tabs');
    // const [activeTabKey, setActiveTabKey] = useState(_subTabs[0].key);

    const [isLoadingProspectDetail, setIsLoadingProspectDetail] = useState<boolean>(false);
    const [isLoadingSubTabData, setIsLoadingSubTabData] = useState<boolean>(false);
    const [prospectData, setProspectData] = useState<ProspectDataType>(data as ProspectDataType);
    const [prospectQuickStat, setProspectQuickStat] = useState<ProspectQuickStat>(
        DefaultTransactionQuickStat
    );

    const [isModalEditProspectInfoVisible, setIsModalEditProspectInfoVisible] = useState(false);

    const _reloadProspectDetail = async (
        showLoadingProgress: boolean = false,
        detailType: ProspectDetailType = 'basic_info'
    ) => {
        if (showLoadingProgress) {
            setIsLoadingProspectDetail(true);
        }
        return getProspectDetail(prospectData.prospect_id, detailType)
            .then(resp => {
                if (resp.data) {
                    setProspectData({ ...prospectData, ...resp.data });
                    setIsBuyer(isProspectABuyer(resp.data.inquirytype_id));
                }
            })
            .catch(() => {
                showNotification('error', 'Error happened while reloading the transaction data.');
            })
            .finally(() => {
                if (showLoadingProgress) {
                    setIsLoadingProspectDetail(false);
                }
            });
    };

    const _reloadProspectStat = (prospect_id?: number) => {
        if (!prospect_id) return;

        getProspectQuickStats(prospect_id).then(resp => {
            if (resp.data) {
                setProspectQuickStat({
                    taskStatInfo: {
                        incomplete: resp.data?.incomplete_task_count || 0,
                        complete: resp.data?.complete_task_count || 0,
                        total:
                            resp.data?.complete_task_count + resp.data?.incomplete_task_count || 0,
                    },
                    documentStatInfo: {
                        incomplete: resp.data?.incomplete_doc_count || 0,
                        complete: resp.data?.complete_doc_count || 0,
                        total: resp.data?.complete_doc_count + resp.data?.incomplete_doc_count || 0,
                    },
                    noteStatInfo: {
                        total: resp.data?.prospect_note_count || 0,
                    },
                });
            }
        });
    };

    const _doActionDetailMenu = (menuKey: string) => {
        switch (menuKey) {
            case 'delete_prospect':
                const deleteConfirmationModal = Modal.confirm({
                    title: `Delete confirmation`,
                    content: (
                        <>
                            Are you sure to delete the prospect: <b>{prospectData.fullname}</b>?
                        </>
                    ),
                    onOk: () => {
                        deleteConfirmationModal.update({
                            closable: true,
                            maskClosable: true,
                            okButtonProps: {
                                disabled: true,
                            },
                            okText: 'Deleting...',
                        });
                        return archiveProspect(prospectData.prospect_id).then(() => {
                            HomeCtx.closeTab?.(tabKey);
                            showNotification(
                                'success',
                                `You have successfully deleted the prospect: ${prospectData.fullname}`
                            );
                            return true;
                        });
                    },
                    closable: true,
                    maskClosable: true,
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        _reloadProspectDetail(true, 'basic_info');
        _reloadProspectStat(prospectData.prospect_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="ez-tab-transaction-detail"
            id={`ez-tab-transaction-detail-${prospectData.prospect_id}`}
        >
            <>
                <ModalEditProspectInfo
                    prospectData={prospectData}
                    setProspectData={setProspectData}
                    isModalVisible={isModalEditProspectInfoVisible}
                    setIsModalVisible={setIsModalEditProspectInfoVisible}
                    reloadProspectDetail={_reloadProspectDetail}
                />
            </>

            <div>
                <div
                    className="mb-4 ez-sub-content-wrapper ez-sub-content-header-section"
                    style={{ position: 'relative' }}
                >
                    <div>
                        <div>{isLoadingProspectDetail && <EZOverlaySpinner />}</div>
                        <Row>
                            <Col span={24}>
                                <Descriptions
                                    title={
                                        <>
                                            {prospectData.fullname ? (
                                                prospectData.fullname
                                            ) : (
                                                <Text type="secondary" italic>
                                                    Fullname is empty
                                                </Text>
                                            )}
                                            {prospectData.inquirytype_id ? (
                                                prospectData.inquirytype_id === 1 ? (
                                                    <Tag className="tag-rounded ml-2">Buyer</Tag>
                                                ) : (
                                                    <Tag className="tag-rounded ml-2">Seller</Tag>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    }
                                    bordered={true}
                                    column={1}
                                    size="small"
                                    layout="horizontal"
                                    extra={
                                        <>
                                            <Button
                                                className="mr-3 ez-action-btn"
                                                icon={<ReloadOutlined />}
                                                loading={isLoadingProspectDetail}
                                                onClick={() => {
                                                    _reloadProspectDetail(true, 'basic_info');
                                                    setManualReloadCount(manualReloadCount + 1);
                                                }}
                                            >
                                                Reload
                                            </Button>
                                            <Button
                                                className="mr-3 ez-action-btn"
                                                icon={<EditOutlined />}
                                                onClick={() =>
                                                    setIsModalEditProspectInfoVisible(true)
                                                }
                                            >
                                                Edit
                                            </Button>
                                            <Dropdown
                                                trigger={['click']}
                                                className="ez-action-btn"
                                                placement="bottomRight"
                                                overlay={
                                                    <Menu
                                                        onClick={({ key }) =>
                                                            _doActionDetailMenu(key)
                                                        }
                                                        items={[
                                                            {
                                                                label: 'Delete Prospect',
                                                                key: 'delete_prospect',
                                                                danger: true,
                                                                icon: (
                                                                    <DeleteOutlined
                                                                        style={{ color: 'red' }}
                                                                    />
                                                                ),
                                                            },
                                                        ]}
                                                    />
                                                }
                                            >
                                                <Button>
                                                    <Space>
                                                        More actions
                                                        <DownOutlined />
                                                    </Space>
                                                </Button>
                                            </Dropdown>
                                        </>
                                    }
                                ></Descriptions>
                            </Col>
                        </Row>
                        <Row gutter={24} className="mt-2 mb-2">
                            <Col span={12} sm={24} md={24} lg={12} xl={12}>
                                <Row gutter={rowFormViewGutter}>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Email Address:</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {prospectData.email ? (
                                            <>
                                                {prospectData.email}
                                                <EZCopyable
                                                    text={prospectData.email}
                                                    className="ml-1"
                                                />
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Phone:</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {prospectData.prospects_phones?.length ? (
                                            <ul className="pl-4 mb-0">
                                                {prospectData.prospects_phones.map(phone => (
                                                    <li>
                                                        {phone.category ? (
                                                            <Text code>{phone.category}</Text>
                                                        ) : (
                                                            ''
                                                        )}
                                                        &nbsp;
                                                        {phone.phone}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            '-'
                                        )}
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Address:</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        <div className="">
                                            {prospectData.address || (
                                                <Text type="secondary" italic>
                                                    Address is empty
                                                </Text>
                                            )}
                                        </div>
                                        <div className="">
                                            {prospectData.city || (
                                                <Text type="secondary" italic>
                                                    City is empty
                                                </Text>
                                            )}
                                            ,{' '}
                                            {prospectData.state || (
                                                <Text type="secondary" italic>
                                                    State is empty
                                                </Text>
                                            )}
                                            ,{' '}
                                            {prospectData.zip || (
                                                <Text type="secondary" italic>
                                                    ZIP is empty
                                                </Text>
                                            )}
                                            <EZCopyable
                                                text={`${
                                                    prospectData.address || 'Address is empty'
                                                }\n${prospectData.city || 'City is empty'}, ${
                                                    prospectData.state || 'State is empty'
                                                }, ${prospectData.zip || 'ZIP is empty'}`}
                                                className="ml-1"
                                            />
                                        </div>
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Country:</Text>
                                    </Col>
                                    <Col span={colValueSpan}>{prospectData.country || '-'}</Col>
                                </Row>
                            </Col>
                            <Col span={12} sm={24} md={24} lg={12} xl={12}>
                                <Row gutter={rowFormViewGutter}>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Registration Date:</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        <EZDateFormat value={prospectData.registration_date} />
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Last Contacted Date:</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        <EZDateFormat
                                            value={prospectData.prospects_last_visit_date_id}
                                        />
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Follow-up Code:</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {prospectData.prospects_followupcode?.code || '-'}
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Flag:</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {prospectData.flag_id ? (
                                            <>
                                                <FlagTwoTone
                                                    twoToneColor={
                                                        prospectData.prospects_default_flag
                                                            ?.flag_color
                                                    }
                                                />
                                                <span className="ml-2 text-capitalize">
                                                    {
                                                        prospectData.prospects_default_flag
                                                            ?.flag_color
                                                    }
                                                </span>
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </Col>
                                    <Col span={colLabelSpan}>
                                        <Text strong>Group:</Text>
                                    </Col>
                                    <Col span={colValueSpan}>
                                        {prospectData.prospects_group?.group_name || '-'}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="mb-4 ez-sub-content-wrapper ez-sub-content-section">
                <Row>
                    <Col span={24}>
                        {isLoadingSubTabData && <EZOverlaySpinner />}
                        {subTabLayout === 'tabs' && (
                            <Tabs
                                id="ez-tab-transaction-detail-tab-wrapper-id"
                                className="ez-tab-transaction-detail-tab-wrapper"
                                style={{ marginBottom: 32 }}
                                defaultActiveKey={data?.subTabKey || subTabs[0].key}
                                tabBarExtraContent={
                                    <Row
                                        style={{
                                            paddingRight: '24px',
                                            paddingBottom: '2px',
                                        }}
                                    >
                                        <ChangeSubTabLayoutElm
                                            subTabLayout={subTabLayout}
                                            setSubTabLayout={setSubTabLayout}
                                        />
                                    </Row>
                                }
                                onChange={activeKey => {
                                    // setActiveTabKey(activeKey);
                                    if (window.scrollY >= 400) window.scrollTo(0, 387);
                                }}
                            >
                                {subTabs.map(subTab => (
                                    <TabPane
                                        tab={
                                            <>
                                                {subTab.label}
                                                <span>
                                                    {subTab.key === 'ez_prospect_tasks'
                                                        ? generateTaskBadge(prospectQuickStat)
                                                        : subTab.key === 'ez_prospect_documents'
                                                        ? generateDocBadge(prospectQuickStat)
                                                        : subTab.key === 'ez_prospect_notes'
                                                        ? generateNoteBadge(prospectQuickStat)
                                                        : ''}
                                                </span>
                                            </>
                                        }
                                        key={subTab.key}
                                    >
                                        <subTab.tabContent
                                            subTabs={subTabs}
                                            currentTab={subTab}
                                            prospectID={prospectData.prospect_id}
                                            isBuyer={isBuyer}
                                            manualReloadCount={manualReloadCount}
                                            isLoadingSubTabData={isLoadingSubTabData}
                                            setIsLoadingSubTabData={setIsLoadingSubTabData}
                                            reloadProspectStat={_reloadProspectStat}
                                        />
                                    </TabPane>
                                ))}
                            </Tabs>
                        )}
                        {subTabLayout === 'collapsible' && (
                            <>
                                <Row
                                    style={{
                                        position: 'absolute',
                                        right: '24px',
                                        paddingTop: '4px',
                                        zIndex: 80,
                                    }}
                                >
                                    <Col span={24}>
                                        <ChangeSubTabLayoutElm
                                            subTabLayout={subTabLayout}
                                            setSubTabLayout={setSubTabLayout}
                                        />
                                    </Col>
                                </Row>{' '}
                                <Collapse
                                    defaultActiveKey={subTabs[0].key}
                                    ghost
                                    expandIcon={({ isActive }) => (
                                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                                    )}
                                    onChange={activeKey => {}}
                                >
                                    {subTabs.map(subTab => (
                                        <Panel
                                            header={<Title level={5}>{subTab.label}</Title>}
                                            key={subTab.key}
                                        >
                                            <subTab.tabContent
                                                subTabs={subTabs}
                                                currentTab={subTab}
                                                prospectID={prospectData.prospect_id}
                                                isBuyer={isBuyer}
                                                manualReloadCount={manualReloadCount}
                                                isLoadingSubTabData={isLoadingSubTabData}
                                                setIsLoadingSubTabData={setIsLoadingSubTabData}
                                                reloadProspectStat={_reloadProspectStat}
                                            />
                                        </Panel>
                                    ))}
                                </Collapse>
                            </>
                        )}
                    </Col>
                    <BackTop />
                </Row>
            </div>
        </div>
    );
};

export default ProspectDetail;
