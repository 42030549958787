import {
    CloseOutlined,
    DeleteFilled,
    EditFilled,
    EyeInvisibleOutlined,
    EyeOutlined,
    FileOutlined,
    LinkOutlined,
    PlusOutlined,
    SaveFilled,
} from '@ant-design/icons';
import { ProColumns, ProTable } from '@ant-design/pro-components';
import { Alert, Button, Divider, Switch, Tag } from 'antd';
import { useState } from 'react';
import { DocFolderType, TemplateDocType } from 'types/transaction';
import {
    addNewDocumentTemplate,
    deleteDocumentTemplate,
    updateTemplateData,
} from 'utils/ez_api/AdminAPIs';
import { showMessage } from 'utils/notificationUtil';

export const ExpandedRowComponent: React.FC<{ Folder: DocFolderType }> = ({ Folder }) => {
    const [editableKeysTask, setEditableKeysTask] = useState<React.Key[]>([]);
    const [folderDoc, setfolderDoc] = useState<TemplateDocType[]>(Folder.docs_templates || []);

    const deleteTemplate = (id: number) => {
        try {
            const templateDocIndex = folderDoc.findIndex(t => t.docs_template_id === id);

            if (templateDocIndex !== undefined && templateDocIndex >= 0) {
                folderDoc.splice(templateDocIndex, 1);
                setfolderDoc([...folderDoc]);
            }
        } catch (error) {
            console.log('🚀 ~ file: ExpandedRowTable.tsx:36 ~ deleteTemplate ~ error:', error);
        }
    };

    const _deleteSelectedTemplate = async (Folder: DocFolderType, docs_template_id: number) => {
        return deleteDocumentTemplate(Folder.folder_id, docs_template_id)
            .then(() => {
                showMessage('success', 'Selected Template has been deleted.');

                deleteTemplate(docs_template_id);
            })
            .catch(() => {});
    };

    const addNewTask = (taskData: TemplateDocType) => {
        try {
            folderDoc.unshift(taskData);
            setfolderDoc([...folderDoc]);

            setEditableKeysTask([...editableKeysTask, taskData.docs_template_id]);
        } catch (error) {
            console.log('🚀 ~ file: docfoldersetting.tsx ~ addnewtemplate ~ error', error);
        }
    };

    const _saveNewTask = async () => {
        const docs_template_iddummy = Date.now();

        const payload = {
            docs_template_name: 'Untitled',
        };

        return addNewDocumentTemplate(Folder.folder_id, payload)
            .then(({ data }) => {
                showMessage(
                    'success',
                    `New task: "Untitled" has been added to Folder: "${Folder.folder_name}".`
                );

                addNewTask(data);
            })
            .catch(() => {
                deleteTemplate(docs_template_iddummy);
            });
    };

    const _saveTemplateData = async (
        Folder: DocFolderType,
        template: TemplateDocType,
        newRecordData: any
    ) => {
        return updateTemplateData(Folder.folder_id, template.docs_template_id, newRecordData).then(
            () => {
                modifyTaskData(template.docs_template_id, { ...template, ...newRecordData });

                showMessage('success', 'Selected template data has been updated.');
                return true;
            }
        );
    };

    const modifyTaskData = (taskID: number, newRecordData: any) => {
        try {
            const templateDocIndex = folderDoc?.findIndex(t => t.docs_template_id === taskID);

            if (
                templateDocIndex !== undefined &&
                templateDocIndex >= 0 &&
                folderDoc[templateDocIndex]
            ) {
                folderDoc![templateDocIndex] = {
                    ...folderDoc[templateDocIndex],
                    ...newRecordData,
                };

                if (newRecordData.toggle === false) {
                    folderDoc![templateDocIndex] = {
                        docs_template_id: Number(taskID.toString()),
                        folder_id: Folder.folder_id,
                        toggle: 1,
                        type: folderDoc![templateDocIndex].type,
                        docs_template_name: folderDoc![templateDocIndex].docs_template_name,
                        account_id: Folder.parent_account_id,
                        parent_account_id: Folder.parent_account_id,
                    };
                }

                setfolderDoc([...folderDoc]);
            }
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx:400 ~ modifyTaskData ~ error:', error);
        }
    };
    const taskColumns: ProColumns<TemplateDocType>[] = [
        {
            title: 'Status',
            dataIndex: 'toggle',
            key: 'toggle',
            width: '55px',
            editable: false,
            render(dom, entity, index, action, schema) {
                return (
                    <Switch
                        size="small"
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                        defaultChecked={entity.toggle === 1 ? true : false}
                        onChange={(val: boolean) => {
                            setTimeout(() => {
                                _saveTemplateData(Folder, entity, {
                                    toggle: val,
                                });
                            }, 500);
                        }}
                    />
                );
            },
        },
        {
            title: 'Document Title',
            dataIndex: 'docs_template_name',
            key: 'docs_template_name',
            width: '360px',
            fieldProps: (_, config) => {
                return {
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {},
                };
            },
            formItemProps: (form, config) => {
                return {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                };
            },
        },
        {
            title: 'Attachment Type',
            dataIndex: 'type',
            key: 'type',
            width: '360px',
            valueType: 'select',
            formItemProps: (_, config) => {
                return {
                    initialValue: config.type,
                };
            },
            fieldProps: (_, config) => {
                return {
                    style: {
                        width: 86,
                    },
                    placeholder: 'Select',
                    options: [
                        {
                            label: 'Weblink',
                            value: 'Weblink',
                        },
                        {
                            label: 'File',
                            value: 'file',
                        },
                    ],
                    onChange: (val: string) => {},
                };
            },
            render: (_, record) => (
                <Tag className={`mr-2 `}>
                    {record.type === 'weblink' ? (
                        <LinkOutlined className="" />
                    ) : (
                        <FileOutlined className="" />
                    )}
                    <span style={{ textTransform: 'capitalize' }}>{record.type}</span>
                </Tag>
            ),
        },
        {
            title: 'Action',
            width: '140px',
            dataIndex: '',
            valueType: 'option',
            fixed: 'right',
            render: (_, record, index, action) => (
                <span>
                    <Button
                        className="p-0"
                        type="link"
                        icon={<EditFilled />}
                        title="Edit this row"
                        size="small"
                        onClick={() => {
                            setEditableKeysTask([...editableKeysTask, record.docs_template_id]);
                        }}
                    >
                        Edit
                    </Button>
                </span>
            ),
        },
    ];

    return (
        <div className="expanded-row-with-table">
            <div>
                <Alert
                    message={
                        <>
                            Tip: Click the <b>Edit</b> button, and then hit the{' '}
                            <SaveFilled style={{ color: '#1890ff' }} /> icon to save your changes
                            when editing Document title and Attachment Type.
                        </>
                    }
                    type="info"
                    showIcon
                    className="mb-3"
                />
            </div>
            <ProTable
                className="mt-3"
                size="small"
                rowKey="docs_template_id"
                pagination={{ pageSize: 100 }}
                showSorterTooltip={false}
                search={false}
                columns={taskColumns}
                options={{
                    fullScreen: false,
                    reload: false,
                    setting: false,
                    density: false,
                }}
                dataSource={folderDoc}
                editable={{
                    editableKeys: editableKeysTask,
                    type: 'multiple',
                    onSave: async (key, newData: any, oldData) => {
                        return _saveTemplateData(Folder, newData, newData).then(() => {
                            setEditableKeysTask([
                                ...editableKeysTask.filter(keyItem => keyItem !== key),
                            ]);
                            return true;
                        });
                    },
                    onDelete: (_, row) => {
                        return _deleteSelectedTemplate(Folder, row.docs_template_id);
                    },
                    onCancel: (key, record, originRow, newLineConfig) => {
                        setEditableKeysTask([
                            ...editableKeysTask.filter(keyItem => keyItem !== key),
                        ]);
                        return Promise.resolve(true);
                    },
                    saveText: (
                        <>
                            <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}></Button>
                        </>
                    ),
                    deleteText: (
                        <>
                            <Button
                                className="p-0"
                                type="link"
                                icon={<DeleteFilled />}
                                title="Delete this task?"
                                size="small"
                                danger
                            ></Button>
                        </>
                    ),
                    deletePopconfirmMessage: 'Delete this task?',
                    cancelText: (
                        <>
                            <Divider type="vertical" />
                            <Button
                                className="p-0 text-grey"
                                type="text"
                                title="Cancel and back"
                                icon={<CloseOutlined />}
                            ></Button>
                        </>
                    ),
                }}
                toolBarRender={() => [
                    <Button
                        className="ez-action-btn"
                        size="large"
                        key="3"
                        onClick={() => _saveNewTask()}
                    >
                        <PlusOutlined />
                        Add Document
                    </Button>,
                ]}
            />
        </div>
    );
};
