import React, { FC, ReactNode } from 'react';
import {
    UnorderedListOutlined as TransactionTabIcon,
    CalendarOutlined as CalendarTabIcon,
    NumberOutlined as SummaryTabIcon,
    CheckSquareOutlined as TaskTabIcon,
    BookOutlined as ProspectTabIcon,
    DatabaseOutlined as DocumentTabIcon,
    FolderViewOutlined as DocReviewTabIcon,
    IdcardOutlined as AddressbookTabIcon,
    UserOutlined as MyProfileTabIcon,
    SettingOutlined as AdminSettingTabIcon,
    MailOutlined as EmailTabIcon,
} from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

// EZ web-app pages import
import SummaryTab from 'pages/tabs/summary';
import TaskListTab from 'pages/tabs/task';
import DocumentListTab from 'pages/tabs/document';
import TransactionList from 'pages/tabs/transaction/TransactionList';
import CalendarTab from 'pages/tabs/calendar';
import ProspectTab from 'pages/tabs/prospect/ProspectList';
import AddressbookTab from 'pages/tabs/contact/addressBook';
import DocumentReviewTab from 'pages/tabs/docReview/docReview';
import MyProfileTab from 'pages/tabs/account/myProfile';
import AdminSettingTab from 'pages/tabs/admin/admin';
import DocumentLibraryTab from 'pages/tabs/docLibrary/docLibrary';
import EmailTab from 'pages/tabs/email/email';

export {
    UnorderedListOutlined as TransactionTabIcon,
    CalendarOutlined as CalendarTabIcon,
    HomeOutlined as SummaryTabIcon,
    CheckSquareOutlined as TaskTabIcon,
    BookOutlined as ProspectTabIcon,
    IdcardOutlined as AddressbookTabIcon,
    DatabaseOutlined as DocumentTabIcon,
    FolderViewOutlined as DocReviewTabIcon,
    UserOutlined as MyProfileTabIcon,
    SettingOutlined as AdminSettingTabIcon,
    MailOutlined as EmailTabIcon,
} from '@ant-design/icons';

export enum TAB_CATEGORY {
    SUMMARY = 'Summary',
    TRANSACTION = 'Transactions',
    CALENDAR = 'Calendar',
    TASK = 'Tasks',
    PROSPECT = 'Prospects',
    DOC_REVIEW = 'Document Review',
    DOC_LIBRARY = 'Document Library',
    ADDRESS_BOOK = 'Address Book',
    REPORT = 'Report',
    MY_PROFILE = 'My Profile',
    ADMIN_SETTING = 'Admin Area',
    EMAIL = 'Email',
    MORE = 'More',
}

export interface ITabContentProps {
    appTabs: IWebAppTabProps[];
    addTab: (tab: IWebAppTabProps, shiftFocus?: boolean) => void;
    setActiveTabKey: React.Dispatch<React.SetStateAction<string>>;

    isCurrentTabActive: boolean;

    tabKey: string;

    data?: any;
}

export type OpenHomeDrawerType = (props: IMainDrawerProps) => void;

export interface IMainDrawerProps {
    content: ReactNode;
    container: HTMLElement;
}

export interface IWebAppTabProps {
    // antd required props for tab pane component
    title: string;
    key: string; // unique key
    content: FC<ITabContentProps> | FC<{}>;
    closeable?: boolean;
    disabled?: boolean;
    tabIcon?: JSX.Element | FC;
    tooltip?: string | JSX.Element;

    // app level props
    tabCategory: TAB_CATEGORY | string; // app tab identifier
    isMainMenu?: boolean; // app tab identifier
    recordId?: string | number; // app tab identifier

    data?: any;

    origin?: string; // from where the creation of new tab is triggered
}

export const MAIN_TAB_INSTANCES = [
    {
        label: 'Summary',
        key: TAB_CATEGORY.SUMMARY,
        tabIcon: () => {
            return <SummaryTabIcon />;
        },
        tabContent: SummaryTab,
        tabCategory: TAB_CATEGORY.SUMMARY,
        tabCloseable: true,
        isChildMenu: true,
    },
    {
        label: 'Transactions',
        key: TAB_CATEGORY.TRANSACTION,
        tabIcon: () => {
            return <TransactionTabIcon />;
        },
        tabContent: TransactionList,
        tabCategory: TAB_CATEGORY.TRANSACTION,
        tabCloseable: false,
    },
    {
        label: 'Tasks',
        key: TAB_CATEGORY.TASK,
        tabIcon: () => {
            return <TaskTabIcon />;
        },
        tabContent: TaskListTab,
        tabCategory: TAB_CATEGORY.TASK,
        tabCloseable: true,
    },
    {
        label: 'Calendar',
        key: TAB_CATEGORY.CALENDAR,
        tabIcon: () => {
            return <CalendarTabIcon />;
        },
        tabContent: CalendarTab,
        tabCategory: TAB_CATEGORY.CALENDAR,
        tabCloseable: true,
    },
    {
        label: 'Prospects',
        key: TAB_CATEGORY.PROSPECT,
        tabIcon: () => {
            return <ProspectTabIcon />;
        },
        tabContent: ProspectTab,
        tabCategory: TAB_CATEGORY.PROSPECT,
        tabCloseable: true,
    },
    {
        label: 'Document Review',
        key: TAB_CATEGORY.DOC_REVIEW,
        tabIcon: () => {
            return <DocReviewTabIcon />;
        },
        tabContent: DocumentReviewTab,
        tabCategory: TAB_CATEGORY.DOC_REVIEW,
        tabCloseable: true,
    },
    {
        label: 'Document Library',
        key: TAB_CATEGORY.DOC_LIBRARY,
        tabIcon: () => {
            return <DocumentTabIcon />;
        },
        tabContent: DocumentLibraryTab,
        tabCategory: TAB_CATEGORY.DOC_LIBRARY,
        tabCloseable: true,
    },
    {
        label: 'Address Book',
        key: TAB_CATEGORY.ADDRESS_BOOK,
        tabIcon: () => {
            return <AddressbookTabIcon />;
        },
        tabContent: AddressbookTab,
        tabCategory: TAB_CATEGORY.ADDRESS_BOOK,
        tabCloseable: true,
    },
    {
        label: 'My Profile',
        key: TAB_CATEGORY.MY_PROFILE,
        tabIcon: () => {
            return <MyProfileTabIcon />;
        },
        tabContent: MyProfileTab,
        tabCategory: TAB_CATEGORY.MY_PROFILE,
        tabCloseable: true,
    },
    {
        label: 'Admin Area',
        key: TAB_CATEGORY.ADMIN_SETTING,
        tabIcon: () => {
            return <AdminSettingTabIcon />;
        },
        tabContent: AdminSettingTab,
        tabCategory: TAB_CATEGORY.ADMIN_SETTING,
        tabCloseable: true,
    },
    {
        label: 'Email',
        key: TAB_CATEGORY.EMAIL,
        tabIcon: () => {
            return <EmailTabIcon />;
        },
        tabContent: EmailTab,
        tabCategory: TAB_CATEGORY.EMAIL,
        tabCloseable: true,
    },
];

export const WEB_APP_MAIN_MENU: ItemType[] = [
    {
        label: 'Transactions',
        key: TAB_CATEGORY.TRANSACTION,
    },
    {
        label: 'Tasks',
        key: TAB_CATEGORY.TASK,
    },
    { label: 'Email', key: TAB_CATEGORY.EMAIL, className: 'email-function-menu' },
    {
        label: 'Calendar',
        key: TAB_CATEGORY.CALENDAR,
    },
    {
        label: 'Prospects',
        key: TAB_CATEGORY.PROSPECT,
    },
    // { label: 'Summary', key: TAB_CATEGORY.SUMMARY },
    // { label: 'Address Book', key: TAB_CATEGORY.ADDRESS_BOOK },
    // { label: 'Document Library', key: TAB_CATEGORY.DOC_LIBRARY },

    {
        label: 'More',
        key: TAB_CATEGORY.MORE,
        children: [
            // {
            //     label: 'Document Review',
            //     key: TAB_CATEGORY.DOC_REVIEW,
            // },
            { label: 'Document Library', key: TAB_CATEGORY.DOC_LIBRARY },
            { type: 'divider' },
            {
                label: 'Address Book',
                key: TAB_CATEGORY.ADDRESS_BOOK,
            },
            { type: 'divider' },
            { label: 'Summary', key: TAB_CATEGORY.SUMMARY },
        ],
    },
];
