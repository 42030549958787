// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Typography, Divider, Form, Space, Button, Select } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
// EZ web-app components import
import { ProspectDataType, ProspectDetailTabProps } from 'types/prospect';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import {
    getProspectDetail,
    updateProspectQuestionValues,
} from 'utils/ez_api/prospect/prospectAPIs';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;

const colLabelSpan = 10;
const colValueSpan = 10;

type FormType = 'looking_for' | 'household_profile';

export const ProspectSubTab_ProfilePreference: React.FC<ProspectDetailTabProps> = ({
    prospectID,
    manualReloadCount,
}) => {
    const [formLookingFor] = Form.useForm();
    const [formHouseholdProfile] = Form.useForm();

    const [prospectDetail, setProspectDetail] = useState<ProspectDataType>();

    const [isLoadingSubTabData, setIsLoadingSubTabData] = useState<boolean>(false);

    const [isEditMode, setIsEditMode] = useState({
        lookingFor: false,
        householdProfile: false,
    });
    const [isSubmittingForm, setIsSubmittingForm] = useState({
        lookingFor: false,
        householdProfile: false,
    });

    const _onFinish = (formType: FormType, values: any) => {
        const payload: any = {};
        const accordion_id = formType === 'looking_for' ? 1 : 2;

        if (prospectDetail?.questions?.length) {
            const questionList = prospectDetail.questions.filter(
                q => q.accordion_id === accordion_id
            );

            for (let index = 0; index < questionList.length; index++) {
                const question = questionList[index];
                payload[`${question.question_id}`] =
                    values?.[question.accordion_id === 1 ? 'questions_1' : 'questions_2']?.[
                        question.question_id
                    ] || null;
            }
        }

        if (formType === 'looking_for')
            setIsSubmittingForm({ ...isSubmittingForm, lookingFor: true });
        else setIsSubmittingForm({ ...isSubmittingForm, householdProfile: true });

        updateProspectQuestionValues(prospectID, {
            questionListWithAnswer: payload,
        })
            .then(({ data }) => {
                _reloadSubTabData(false).then(() => {
                    if (formType === 'looking_for')
                        setIsEditMode({ ...isEditMode, lookingFor: false });
                    else setIsEditMode({ ...isEditMode, householdProfile: false });
                });
            })
            .finally(() => {
                if (formType === 'looking_for')
                    setIsSubmittingForm({ ...isSubmittingForm, lookingFor: false });
                else setIsSubmittingForm({ ...isSubmittingForm, householdProfile: false });
            });
    };

    const _reloadSubTabData = async (showLoadingProgress: boolean = true) => {
        if (showLoadingProgress) setIsLoadingSubTabData(true);
        try {
            const { data } = await getProspectDetail(prospectID, 'with_questions');
            if (data) {
                setProspectDetail(data as ProspectDataType);
            }
        } finally {
            setIsLoadingSubTabData(false);
        }
    };

    useEffect(() => {
        if (prospectDetail && prospectDetail.questions?.length) {
            const payload: { questions_1: any; questions_2: any } = {
                questions_1: {},
                questions_2: {},
            };

            for (let index = 0; index < prospectDetail.questions.length; index++) {
                const question = prospectDetail.questions[index];
                if (question.prospects_questions_value?.field_value) {
                    payload[question.accordion_id === 1 ? 'questions_1' : 'questions_2'][
                        `${question.question_id}`
                    ] = parseInt(question.prospects_questions_value.field_value);
                }
            }

            if (isEditMode.lookingFor) {
                formLookingFor.setFieldsValue({
                    questions_1: payload.questions_1,
                });
            }

            if (isEditMode.householdProfile) {
                formHouseholdProfile.setFieldsValue({
                    questions_2: payload.questions_2,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode]);

    useEffect(() => {
        _reloadSubTabData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez_prospect_cnotes">
            <Row gutter={[64, 0]}>
                <Col span={12} className="">
                    <Form
                        form={formLookingFor}
                        onFinish={(values: any) => _onFinish('looking_for', values)}
                    >
                        <Row gutter={[0, 12]} align="middle">
                            <Col span={10}>
                                <Text className="text-title-secondary">Looking For:</Text>
                            </Col>
                            <Col span={10} style={{ textAlign: 'right' }}>
                                {!isEditMode.lookingFor && (
                                    <Button
                                        className="ez-action-btn"
                                        size="small"
                                        icon={<EditOutlined />}
                                        onClick={() =>
                                            setIsEditMode({ ...isEditMode, lookingFor: true })
                                        }
                                    >
                                        Edit
                                    </Button>
                                )}

                                {isEditMode.lookingFor && (
                                    <Space>
                                        <Button
                                            className="mr-2"
                                            type="default"
                                            size="small"
                                            onClick={() =>
                                                setIsEditMode({ ...isEditMode, lookingFor: false })
                                            }
                                            disabled={isSubmittingForm.lookingFor}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className=""
                                            size="small"
                                            icon={<SaveOutlined />}
                                            type="primary"
                                            loading={isSubmittingForm.lookingFor}
                                            onClick={() => formLookingFor.submit()}
                                        >
                                            {isSubmittingForm.lookingFor ? 'Saving' : 'Save'}
                                        </Button>
                                    </Space>
                                )}
                            </Col>
                            <Col span={20}>
                                <Divider />
                            </Col>
                            {!isEditMode.lookingFor ? (
                                <>
                                    {prospectDetail?.questions
                                        ?.filter(question => question.accordion_id === 1)
                                        .map(question => (
                                            <>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    {question.name}:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    {question.prospects_questions_value?.field_value
                                                        ? question.prospects_questions_options?.find(
                                                              opt =>
                                                                  opt.option_id ===
                                                                  parseInt(
                                                                      question.prospects_questions_value!
                                                                          .field_value
                                                                  )
                                                          )?.option_value
                                                        : '-'}
                                                </Col>
                                            </>
                                        ))}
                                </>
                            ) : (
                                <>
                                    {prospectDetail?.questions
                                        ?.filter(question => question.accordion_id === 1)
                                        .map(question => (
                                            <>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    {question.name}:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item
                                                        name={[
                                                            'questions_1',
                                                            `${question.question_id}`,
                                                        ]}
                                                    >
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            options={
                                                                question.prospects_questions_options
                                                            }
                                                            fieldNames={{
                                                                label: 'option_value',
                                                                value: 'option_id',
                                                            }}
                                                            showArrow
                                                            allowClear
                                                            placeholder="Choose option"
                                                            optionFilterProp="label"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        ))}
                                </>
                            )}
                        </Row>
                    </Form>
                </Col>
                <Col span={12} className="">
                    <Form
                        form={formHouseholdProfile}
                        onFinish={(values: any) => _onFinish('household_profile', values)}
                    >
                        <Row gutter={[0, 12]} align="middle">
                            <Col span={10}>
                                <Text className="text-title-secondary">Household Profile:</Text>
                            </Col>
                            <Col span={10} style={{ textAlign: 'right' }}>
                                {!isEditMode.householdProfile && (
                                    <Button
                                        className="ez-action-btn"
                                        size="small"
                                        icon={<EditOutlined />}
                                        onClick={() =>
                                            setIsEditMode({ ...isEditMode, householdProfile: true })
                                        }
                                    >
                                        Edit
                                    </Button>
                                )}

                                {isEditMode.householdProfile && (
                                    <Space>
                                        <Button
                                            className="mr-2"
                                            type="default"
                                            size="small"
                                            onClick={() =>
                                                setIsEditMode({
                                                    ...isEditMode,
                                                    householdProfile: false,
                                                })
                                            }
                                            disabled={isSubmittingForm.householdProfile}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className=""
                                            size="small"
                                            icon={<SaveOutlined />}
                                            type="primary"
                                            loading={isSubmittingForm.householdProfile}
                                            onClick={() => formHouseholdProfile.submit()}
                                        >
                                            {isSubmittingForm.householdProfile ? 'Saving' : 'Save'}
                                        </Button>
                                    </Space>
                                )}
                            </Col>
                            <Col span={20}>
                                <Divider />
                            </Col>
                            {!isEditMode.householdProfile ? (
                                <>
                                    {prospectDetail?.questions
                                        ?.filter(question => question.accordion_id === 2)
                                        .map(question => (
                                            <>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    {question.name}:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    {question.prospects_questions_value?.field_value
                                                        ? question.prospects_questions_options?.find(
                                                              opt =>
                                                                  opt.option_id ===
                                                                  parseInt(
                                                                      question.prospects_questions_value!
                                                                          .field_value
                                                                  )
                                                          )?.option_value
                                                        : '-'}
                                                </Col>
                                            </>
                                        ))}
                                </>
                            ) : (
                                <>
                                    {prospectDetail?.questions
                                        ?.filter(question => question.accordion_id === 2)
                                        .map(question => (
                                            <>
                                                <Col span={colLabelSpan} className="ez-form-label">
                                                    {question.name}:
                                                </Col>
                                                <Col span={colValueSpan}>
                                                    <Form.Item
                                                        name={[
                                                            'questions_2',
                                                            `${question.question_id}`,
                                                        ]}
                                                    >
                                                        <Select
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            options={
                                                                question.prospects_questions_options
                                                            }
                                                            fieldNames={{
                                                                label: 'option_value',
                                                                value: 'option_id',
                                                            }}
                                                            showArrow
                                                            allowClear
                                                            placeholder="Choose option"
                                                            optionFilterProp="label"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                        ))}
                                </>
                            )}
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};
