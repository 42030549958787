import { Input } from 'antd';
import { memo } from 'react';

interface InputPlaceholderProps {
    name: string;
    placeholder: string;
    onChange: any;
    isPassword?: boolean;
    hideLabel?: boolean;
    maxLength?: number;
}

export const InputPlaceholder = memo(function InputPlaceholder(props: InputPlaceholderProps) {
    const { name, onChange, isPassword, hideLabel, maxLength, placeholder } = props;
    return (
        <div className="input-placeholder">
            {!hideLabel ? <label className="input-label">{name}</label> : ''}
            {isPassword ? (
                <Input.Password
                    className="input-box"
                    placeholder={placeholder}
                    onChange={onChange}
                    maxLength={maxLength}
                />
            ) : (
                <Input
                    className="input-box"
                    placeholder={placeholder}
                    onChange={onChange}
                    maxLength={maxLength}
                />
            )}
        </div>
    );
});
