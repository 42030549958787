// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import qs from 'qs';
import { Row, Col, Timeline, Typography, Alert, Skeleton, List, Button, Avatar } from 'antd';
import { SnippetsOutlined } from '@ant-design/icons';

// EZ web-app utils import
// EZ web-app APIs import
import { getTransactionNotes } from 'utils/ez_api/transactionNoteAPIs';
// EZ web-app types import
import { TransactionDetailTabProps, TransactionNoteItemType } from 'types/transaction';
// EZ web-app components import
import { EZDateFormat, EZList } from 'components';
import { IListQueryParams } from 'components/list/EZList';

// EZ web-app styles and assets import

const { Text, Paragraph } = Typography;

const defaultQueryParams: IListQueryParams = {
    searchKeyword: '',
    orderParams: [['note_id', 'desc']],
    whereParams: {
        category: 'log',
    },
    whereFilterParams: {},
};

export const SubTab_Changelogs: React.FC<TransactionDetailTabProps> = ({
    contractID,
    manualReloadCount,
}) => {
    const [queryParams, setQueryParams] = useState<IListQueryParams>(defaultQueryParams);

    const _fetchNotes: (EZQueryParams: string) => Promise<any> = async EZQueryParams => {
        const result = await getTransactionNotes(contractID || -1, EZQueryParams);
        return result;
    };

    const _reloadSubTabData = () => {
        setQueryParams({
            ...defaultQueryParams,
        });
    };

    useEffect(() => {
        _reloadSubTabData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualReloadCount]);

    return (
        <div className="ez_detail_changelogs">
            <Row gutter={[0, 0]}>
                <Col span={24} className="p-5">
                    <EZList
                        rowKey="note_id"
                        paginationConfig={{
                            pageSize: 20,
                        }}
                        queryParams={queryParams}
                        fetchData={_fetchNotes}
                        renderItem={(record: TransactionNoteItemType) => (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            style={{ backgroundColor: '#E6F7FF' }}
                                            icon={<SnippetsOutlined style={{ color: '#096DD9' }} />}
                                            size={42}
                                        />
                                    }
                                    title={
                                        <span>
                                            <Text strong className="mr-1">
                                                {record.posted_account?.fullname}
                                            </Text>
                                            <Text className="mr-1">
                                                {record.posted_account?.email
                                                    ? `(${record.posted_account.email})`
                                                    : ``}
                                            </Text>
                                            <Text type="secondary" className="mr-2">
                                                On{' '}
                                                <EZDateFormat
                                                    value={record.date_posted}
                                                    format="MMM DD, YYYY - hh:mma"
                                                />
                                            </Text>
                                        </span>
                                    }
                                    description={
                                        <Paragraph
                                            style={{ whiteSpace: 'pre-wrap' }}
                                            ellipsis={{
                                                rows: 3,
                                                expandable: true,
                                            }}
                                            title={`${record.notes}`}
                                        >
                                            {record.notes}
                                        </Paragraph>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </div>
    );
};
