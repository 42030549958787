// Core React libs import
import React, { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rd party libs import
import { Badge, Select } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { cloneDeep } from 'lodash';

// EZ level libs import
import { HomeContext } from 'context/EZContext';
import { PropStatusPreference } from 'types/admin';
import { TRANSACTION_STATUSES as DEFAULT_TRANSACTION_STATUSES } from 'types/transaction';
import { getPropertyStatusSetting } from 'utils/ez_api/AdminAPIs';
export { TRANSACTION_STATUSES } from 'types/transaction';

export interface EZSelectStatusProps<ValueType = any>
    extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
    showAllActiveStatusesOpt?: boolean;
    showArchivedFilterOpt?: boolean;
    showDeletedFilterOpt?: boolean;
}

const { Option, OptGroup } = Select;

let defaultTransactionStatuses = cloneDeep(DEFAULT_TRANSACTION_STATUSES);

let hasSettingAPIBeenCalled = false;

export function EZSelectStatus<
    ValueType extends {
        key?: string;
        label: React.ReactNode;
        value: string | number;
    } = any
>({ ...props }: EZSelectStatusProps<ValueType>) {
    const [statusOptions, setStatusOptions] = useState<typeof DEFAULT_TRANSACTION_STATUSES>(
        defaultTransactionStatuses
    );
    const { triggerRefetchDataList, setTriggerRefetchDataList } = useContext(HomeContext);
    const hasTriggerRefetchData = triggerRefetchDataList.includes('property_status');

    const _fetchTransactionStatusSetting = () => {
        if (hasSettingAPIBeenCalled && !hasTriggerRefetchData) return;

        getPropertyStatusSetting().then(({ data }) => {
            hasSettingAPIBeenCalled = true;
            if (data) {
                const propStatusPreference = (data as PropStatusPreference[])?.map(
                    pref => pref.status_id || []
                );
                defaultTransactionStatuses?.forEach(statusCategory => {
                    statusCategory.status_list = statusCategory.status_list.filter(
                        status => !propStatusPreference.includes(status.status_id)
                    );
                });
                setStatusOptions([...defaultTransactionStatuses]);
            }

            if (hasTriggerRefetchData) {
                const newTriggerRefetchDataList = triggerRefetchDataList.filter(
                    key => key !== 'property_status'
                );
                setTriggerRefetchDataList(newTriggerRefetchDataList);
            }
        });
    };

    useEffect(() => {
        _fetchTransactionStatusSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Select
            style={{ width: '100%' }}
            showSearch
            allowClear
            placeholder="Select Status"
            {...props}
        >
            {props.showAllActiveStatusesOpt && (
                <Option value="all_active_statuses">All Active Statuses</Option>
            )}
            {props.showArchivedFilterOpt && <Option value="archived_transaction">Archived</Option>}
            {props.showDeletedFilterOpt && <Option value="deleted_transaction">Deleted</Option>}
            {statusOptions?.map((statusCategory, idx) => (
                <OptGroup
                    label={statusCategory.status_category_label}
                    key={`statusCategory-${idx}`}
                >
                    {statusCategory.status_list.map(status => (
                        <Option key={status.status_id} value={status.status_id}>
                            <Badge color={status.color} text={status.status} />
                        </Option>
                    ))}
                </OptGroup>
            ))}
        </Select>
    );
}
