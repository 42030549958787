import React from 'react';

import { EZNumberFormat, EZCurrencyFormat } from 'components';
import { Typography } from 'antd';

type CommissionInfoProps = {
    ifEmptyText?: React.ReactNode;

    commission_base?: number;

    commission_rate?: number;
    commission_amount?: number;
};

export const CommissionInfo: React.FC<CommissionInfoProps> = ({
    ifEmptyText = '-',
    commission_base = 0,
    commission_rate = 0,
    commission_amount = 0,
}) => {
    const showCommissionRate = commission_rate
        ? true
        : commission_amount && commission_base
        ? true
        : false;

    if (commission_amount || (commission_rate && commission_base)) {
        return (
            <>
                <EZCurrencyFormat
                    value={
                        commission_amount
                            ? commission_amount
                            : commission_rate && commission_base
                            ? (commission_rate * commission_base) / 100
                            : undefined
                    }
                    thousandSeparator={true}
                    prefix={'$ '}
                    displayType={'text'}
                />
                {showCommissionRate ? (
                    <span className="ml-2">
                        (
                        <EZNumberFormat
                            value={
                                !commission_rate && commission_base
                                    ? (100 * commission_amount) / commission_base
                                    : commission_rate
                            }
                            suffix={'%'}
                            displayType={'text'}
                        />
                        )
                    </span>
                ) : (
                    ''
                )}
            </>
        );
    }

    // otherwise, return an empty element
    return <>{ifEmptyText ? <Typography.Text>$ - (- %)</Typography.Text> : ''}</>;
};
