import React, { useState } from 'react';

import { Upload, UploadProps } from 'antd';

import { generatePresignedURL, uploadToAWSS3 } from 'utils/ez_api/fileUploadAPIs';

import { showNotification } from 'utils/notificationUtil';
import { RcFile, UploadFile } from 'antd/lib/upload';
import axios, { AxiosRequestConfig } from 'axios';
import { UploadFileStatus } from 'antd/lib/upload/interface';

export type EZUploadFileProps = {
    uid: string;
    size?: number;
    name: string;
    fileName?: string;
    lastModified?: number;
    lastModifiedDate?: Date;
    url?: string;
    status?: UploadFileStatus;
    percent?: number;
    error?: any;
};

export type EZUploadProps = UploadProps & {
    contractID?: number;
    dragger?: boolean;

    getPresignedURL?: (payload: any) => Promise<any>;
};

const { Dragger } = Upload;

const defaultProps: UploadProps = {
    showUploadList: false,
    name: 'file',
    multiple: true,
};

export const EZUpload: React.FC<EZUploadProps> = ({
    contractID,
    getPresignedURL,
    dragger,
    ...props
}) => {
    const _customRequest: UploadProps['customRequest'] = ({
        file,
        onError,
        onSuccess,
        onProgress,
        data,
    }) => {
        const File: RcFile = file as RcFile;
        // const axiosTokenSource = axios.CancelToken.source();

        const payload = {
            original_name: File.name,
            mime_type: File.type,
            size: File.size,
        };

        if (getPresignedURL) {
            getPresignedURL(payload)
                .then(presignedURLResp => {
                    if (presignedURLResp?.data?.presignedURL) {
                        const presignedURL = presignedURLResp.data.presignedURL;

                        var options: AxiosRequestConfig = {
                            onUploadProgress: (event: ProgressEvent) => {
                                const { loaded, total } = event;
                                onProgress?.({
                                    percent: Math.round((loaded / total) * 100),
                                });
                            },
                            headers: {
                                'Content-Type': File.type,
                            },
                            // cancelToken: axiosTokenSource.token,
                        };

                        return uploadToAWSS3(presignedURL, File, options)
                            .then(resp => {
                                onSuccess?.({
                                    ...presignedURLResp.data,
                                    data,
                                });
                            })
                            .catch(err => {
                                throw err;
                            });
                    }
                })
                .catch(err => {
                    onError?.(err);
                    console.log('🚀 ~ file: upload.tsx ~ line 45 ~ beforeUpload ~ err', err);
                    showNotification('error', 'Error while uploading the selected file(s)');
                })

                .finally(() => {});
        } else {
            onError?.(new Error(`getPresignedURL function isn't set!`));
            showNotification('error', 'Error while uploading the selected file(s)');
        }
        return {
            abort() {
                console.log('upload progress is aborted.');
            },
        };
    };

    return (
        <React.Fragment>
            {dragger ? (
                <Dragger customRequest={_customRequest} {...defaultProps} {...props} />
            ) : (
                <Upload customRequest={_customRequest} {...defaultProps} {...props} />
            )}
        </React.Fragment>
    );
};
