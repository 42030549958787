// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Alert, Button, Checkbox, Col, Divider, Form, Row, Space } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
import { getPropertyTypeSetting, updateTransactionSetting } from 'utils/ez_api/AdminAPIs';
// EZ web-app types import
import { PropTypePreference } from 'types/admin';
import { showNotification } from 'utils/notificationUtil';
import { PROPERTY_TYPES } from 'types/transaction';
import { HomeContext } from 'context/EZContext';
import { updateOnboardingStep } from 'utils/onboardingUtil';
// EZ web-app components import

// EZ web-app styles and assets import

export const TransactionPropTypeSetting: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();

    const HomeCtx = useContext(HomeContext);

    const _fetchTransactionSetting = () => {
        getPropertyTypeSetting().then(({ data }) => {
            if (data) {
                const propTypePreference = data as PropTypePreference[];
                propTypePreference.forEach(preferenceObj => {
                    form.setFieldValue([`property_types`, `${preferenceObj.type_id}`], false);
                });
            }
        });
    };

    const _onFinishForm = (values: any) => {
        updateTransactionSetting(values).then(() => {
            showNotification('success', 'New setting has been successfully applied.');

            // set trigger to refetch data by key
            HomeCtx.setTriggerRefetchDataList(prev => [...prev, 'property_type']);
            // update onboarding step to completed
            updateOnboardingStep('customize_property_status', HomeCtx.refetchOnboardingList);
        });
    };

    useEffect(() => {
        _fetchTransactionSetting();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form form={form} onFinish={_onFinishForm}>
            <Row>
                <Col span={12} className="mb-5">
                    <Alert
                        message={`You need to hit the button "Save Changes" to save all the changes you have made.`}
                        type="info"
                        showIcon
                        action={
                            <Space>
                                <Button type="primary" onClick={() => form.submit()}>
                                    Save Changes
                                </Button>
                            </Space>
                        }
                    />
                </Col>
                <Col span={24}>
                    <Row gutter={[64, 64]}>
                        <Col span={12}>
                            <Row gutter={[0, 8]}>
                                <Col span={24} className="text-title-secondary">
                                    Property Type
                                </Col>
                                <Divider className="mt-2 mb-3" />
                                {PROPERTY_TYPES.map(pType => (
                                    <Col span={24}>
                                        <Form.Item
                                            name={[`property_types`, `${pType.type_id}`]}
                                            noStyle
                                            valuePropName="checked"
                                            initialValue={true}
                                        >
                                            <Checkbox>{pType.type_name}</Checkbox>
                                        </Form.Item>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};
