import { memo } from 'react';
import loginLogo from '../../assets/Logo.svg';

export const LogoImg = memo(function LogoImg(props: any) {
    return (
        <div className="login-logo">
            <img src={loginLogo} alt="Logo" />
        </div>
    );
});
