// Core React libs import
import React, { useState } from 'react';

// Core ANTD and 3rd party libs import
import { Button, Col, Row, Typography, Tabs, Progress, Divider } from 'antd';
import { CheckCircleTwoTone, RightOutlined } from '@ant-design/icons';
import { OnboardingStepType } from 'types/onboarding';

// EZ level libs import
const { Text, Title } = Typography;

export const EZOnboardingListContent: React.FC<{
    onboardingList: object;
    onSelectOnboading: (item: OnboardingStepType) => void;
    onClose: () => void;
}> = ({ onboardingList = {}, onSelectOnboading, onClose }) => {
    const [tabKey, setTabKey] = useState('learner');

    const generateOnboardingCompletedContent = (type: string) => {
        switch (type) {
            case 'learner': {
                return {
                    title: 'You’re really a Learner!',
                    description:
                        'This is just a beginning, in order to make your work more efficient with EZCoordinator, you can continue to become a master.',
                    leftButton: { label: 'Continue to Master', onClick: () => setTabKey('master') },
                    rightButton: { label: '', onClick: () => {} },
                };
            }
            case 'master': {
                return {
                    title: 'Congrats, You’ve become Master!',
                    description:
                        'You have the opportunity to make this work more efficient. Interested in using EZCoordinator for long term?',
                    leftButton: { label: '', onClick: () => {} },
                    rightButton: { label: 'Finish and Hide', onClick: () => onClose() },
                };
            }

            default:
                break;
        }
    };

    return (
        <Row
            style={{
                minWidth: '19em',
            }}
        >
            <Tabs
                type="line"
                style={{ width: '100%' }}
                activeKey={tabKey}
                onChange={_key => setTabKey(_key)}
            >
                {Object.entries(onboardingList).map(
                    ([key, stepList]: [any, any], index: number) => {
                        const countStepCompleted = stepList?.filter(
                            ({ onboarding_step_completed }: OnboardingStepType) =>
                                Boolean(onboarding_step_completed)
                        ).length;
                        const countSteps = stepList.length;
                        const stepProgress = (countStepCompleted / countSteps) * 100;
                        const allStepComplated = stepList.every(
                            ({ onboarding_step_completed }: OnboardingStepType) =>
                                Boolean(onboarding_step_completed)
                        );
                        const completedContent = generateOnboardingCompletedContent(key);

                        const [, prevStepList] = Object.entries(onboardingList)[index - 1] || [];
                        const prevStepCompleted = prevStepList
                            ? prevStepList.every(
                                  ({ onboarding_step_completed }: OnboardingStepType) =>
                                      Boolean(onboarding_step_completed)
                              )
                            : true;

                        return (
                            <Tabs.TabPane
                                disabled={!prevStepCompleted}
                                tab={
                                    <div
                                        style={{
                                            textTransform: 'capitalize',
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '5px',
                                                width: '1.7em',
                                                height: '1.7em',
                                                marginRight: '.5em',
                                                borderRadius: '50%',
                                                border: '1px solid',
                                                borderColor: 'inherit',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                display: 'flex',
                                            }}
                                        >
                                            {index + 1}
                                        </div>
                                        {key}
                                    </div>
                                }
                                key={key}
                                closable={false}
                            >
                                <div
                                    style={{ gap: '1em', display: 'flex', flexDirection: 'column' }}
                                >
                                    <Row justify="space-between" style={{ padding: '0px 0.5em' }}>
                                        <Text
                                            style={{ color: 'gray' }}
                                        >{`${countStepCompleted} of ${countSteps}`}</Text>
                                        <Col style={{ width: '75%' }}>
                                            <Progress percent={stepProgress} showInfo={false} />
                                        </Col>
                                    </Row>
                                    {allStepComplated && (
                                        <>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    padding: '0em .5em',
                                                    maxWidth: '18em',
                                                    gap: '.5em',
                                                }}
                                            >
                                                <Title level={5}>{completedContent?.title}</Title>
                                                <Text>{completedContent?.description}</Text>
                                                <Row align="middle" style={{ gap: '.5em' }}>
                                                    {completedContent?.leftButton?.label && (
                                                        <Button
                                                            type="primary"
                                                            onClick={
                                                                completedContent?.leftButton
                                                                    ?.onClick
                                                            }
                                                        >
                                                            {completedContent?.leftButton?.label}
                                                        </Button>
                                                    )}

                                                    {completedContent?.rightButton?.label && (
                                                        <Button
                                                            type={
                                                                completedContent?.leftButton?.label
                                                                    ? 'link'
                                                                    : 'primary'
                                                            }
                                                            onClick={
                                                                completedContent?.rightButton
                                                                    ?.onClick
                                                            }
                                                        >
                                                            {completedContent?.rightButton?.label}
                                                        </Button>
                                                    )}
                                                </Row>
                                            </div>
                                            <Divider style={{ margin: '.5em' }} />
                                        </>
                                    )}
                                    {stepList?.map((step: OnboardingStepType) => {
                                        const { title, onboarding_step_completed } = step;
                                        const stepCompleted = Boolean(onboarding_step_completed);
                                        return (
                                            <Button
                                                type="text"
                                                onClick={() => onSelectOnboading(step)}
                                            >
                                                <Row
                                                    gutter={16}
                                                    justify="space-between"
                                                    align="middle"
                                                >
                                                    <Row align="middle">
                                                        {stepCompleted ? (
                                                            <CheckCircleTwoTone twoToneColor="#597EF7" />
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    width: '13.5px',
                                                                    height: '13.5px',
                                                                    borderRadius: '50%',
                                                                    border: '1px solid #BFBFBF',
                                                                }}
                                                            />
                                                        )}
                                                        <Text
                                                            style={{ marginLeft: '1em' }}
                                                            delete={stepCompleted}
                                                        >
                                                            {title}
                                                        </Text>
                                                    </Row>
                                                    <RightOutlined />
                                                </Row>
                                            </Button>
                                        );
                                    })}
                                </div>
                            </Tabs.TabPane>
                        );
                    }
                )}
            </Tabs>
        </Row>
    );
};
