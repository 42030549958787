import React from 'react';

import { Popover, PopoverProps, Row, Col, Typography } from 'antd';

import Copyable from '../copyable';
import { EZNumberFormat, CommissionInfo } from 'components';

const { Text } = Typography;

interface ITransactionPartyDataType {
    title?: string;
    fullname?: string;
    phone?: string;
    email?: string;

    commission_base?: number;

    commission_rate?: number;
    commission_amount?: number;
}

type PopoverExtended = PopoverProps & {
    data: ITransactionPartyDataType;

    type?: 'transaction_party';
    emptyLabel?: string;

    showCommission?: boolean;
};

const EZPopupable: React.FC<PopoverExtended> = ({ emptyLabel, showCommission, data, ...props }) => {
    const _generateContent = () => {
        return (
            <Row style={{ width: '400px' }} gutter={[8, 4]}>
                <Col span={6}>Fullname</Col>
                <Col span={18}>
                    :&nbsp;
                    {data.fullname ? (
                        <span>
                            {data.fullname} <Copyable text={data.fullname} />
                        </span>
                    ) : (
                        '-'
                    )}
                </Col>
                <Col span={6}>Email</Col>
                <Col span={18}>
                    :&nbsp;
                    {data.email ? (
                        <span>
                            {data.email} <Copyable text={data.email} />
                        </span>
                    ) : (
                        '-'
                    )}
                </Col>
                <Col span={6}>Phone</Col>
                <Col span={18}>
                    :&nbsp;
                    {data.phone ? (
                        <span>
                            {data.phone} <Copyable text={data.phone} />
                        </span>
                    ) : (
                        '-'
                    )}
                </Col>
                {showCommission && (
                    <>
                        <Col span={6}>Commission</Col>
                        <Col span={18}>
                            :&nbsp;
                            <CommissionInfo
                                commission_base={data.commission_base}
                                commission_rate={data.commission_rate}
                                commission_amount={data.commission_amount}
                            />
                        </Col>
                    </>
                )}
            </Row>
        );
    };

    return (
        <>
            <Popover
                title="Additional info"
                trigger="hover"
                content={_generateContent()}
                autoAdjustOverflow
                showArrow={false}
                {...props}
            >
                {data.title ? (
                    <Text underline ellipsis>
                        {data.title}
                    </Text>
                ) : (
                    <i>{emptyLabel || 'Value'} isn't set</i>
                )}
            </Popover>
        </>
    );
};

export default EZPopupable;
