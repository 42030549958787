import qs from 'qs';

import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getSummaryData = (
    summaryType: string = 'by-transactions',
    date_start: string | undefined,
    date_end: string | undefined,
    browser_ts: number
) => {
    if (date_start) {
        Mixpanel.track('app.summary_custom_date', {
            date_start,
            date_end,
        });
    }

    const params: string = qs.stringify({
        date_start,
        date_end,
        browser_ts: browser_ts || Date.now(), // browser timestamp for filtering the transaction by date
    });

    return APIRequest({
        method: 'get',
        url: apiUrl + `summary/${summaryType}?` + params,
    });
};
