import {
    DefaultAdditionalContactStrOptions_Roles,
    DefaultMeStrOptions,
    DefaultTransactionStrOptions_Listing,
    DefaultTransactionStrOptions_Rental,
} from 'components/select/selectEmailRecipient';
import { SIMPLE_EMAIL_REGEX } from 'static/consts';

export enum CacheEnum {
    OpenTabs = 'OpenTabs',
}

export function saveJson(key: CacheEnum, data: Object) {
    localStorage.setItem(key, JSON.stringify(data));
}
export const validateEmailTemplateRecipients = (values?: string[]) => {
    if (values) {
        for (let index = 0; index < values.length; index++) {
            const recipientTagValue = values[index];

            if (
                !SIMPLE_EMAIL_REGEX.test(recipientTagValue) &&
                !DefaultMeStrOptions.includes(recipientTagValue) &&
                !DefaultTransactionStrOptions_Listing.includes(recipientTagValue) &&
                !DefaultTransactionStrOptions_Rental.includes(recipientTagValue) &&
                !recipientTagValue.startsWith(DefaultAdditionalContactStrOptions_Roles) // For Additional Contact - Roles
            ) {
                return Promise.reject('Invalid email(s) and or template code(s) has been found');
            }
        }
    }

    return Promise.resolve();
};
