// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Row, Col, Modal, Form, Input, Select, Typography } from 'antd';

// EZ web-app utils import
import { getAllTeamMembers } from 'utils/ez_api/memberAPIs';
import { updateTransactionDetail, DetailType } from 'utils/ez_api/transactionAPIs';
// EZ web-app types import
import { TransactionDetailType } from 'types/transaction';
// EZ web-app components import
import { showNotification } from 'utils/notificationUtil';
import {
    EZSelectFlag,
    EZSelectPropertyType,
    EZSelectStatus,
    EZSelectSaleType,
    EZAutocompleteCity,
    EZSelectUSState,
} from 'components';
import { AccountCompactDataType } from 'types/account';
import { SelectProps } from 'antd/es/select';
import { isRentalProperty } from 'utils/webAppUtil';

// EZ web-app styles and assets import

const { Text } = Typography;

export const EditTransactionFormContent: React.FC<{
    subUsers: AccountCompactDataType[];
    mode?: 'ADD' | 'EDIT';
    setIsRental?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ subUsers, setIsRental, mode = 'EDIT' }) => {
    const _onChangeStatus: SelectProps['onChange'] = (val: number) => {
        setIsRental?.(isRentalProperty(val));
    };

    return (
        <>
            <Row gutter={20}>
                <Col span={24}>
                    <Form.Item
                        label="Address"
                        name="address"
                        rules={[{ required: true, message: "Address can't be empty" }]}
                    >
                        <Input placeholder="Enter the Property Address" />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label="City" name="city">
                        <EZAutocompleteCity />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label="State" name="state">
                        <EZSelectUSState />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="ZIP" name="zip">
                        <Input placeholder="Enter the ZIP" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={24}>
                    <Form.Item
                        label="Transaction Status"
                        name="status_id"
                        rules={[{ required: true, message: "Status can't be empty" }]}
                    >
                        <EZSelectStatus
                            placeholder="Select Transaction Status"
                            allowClear={false}
                            onChange={_onChangeStatus}
                        />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label="MLS" name="mis">
                        <Input placeholder="Enter the MLS" />
                    </Form.Item>
                </Col>
                <Col span={15}>
                    <Form.Item label="ESCROW #" name="escrow">
                        <Input placeholder="Enter the ESCROW number" />
                    </Form.Item>
                </Col>
                <Col span={9}>
                    <Form.Item label="Property Type" name="type_id">
                        <EZSelectPropertyType
                            allowClear={false}
                            placeholder="Select Property Type"
                        />
                    </Form.Item>
                </Col>
                <Col span={15}>
                    <Form.Item label="Sale Type" name="sale_types_id">
                        <EZSelectSaleType placeholder="Select Sale Type" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label="Flag"
                        name="flag_id"
                        rules={[{ required: true, message: "Flag can't be empty" }]}
                    >
                        <EZSelectFlag allowClear={false} />
                    </Form.Item>
                </Col>
                {/* <Col span={9}>
        <Form.Item
            label="Office"
            name="office_id"
            tooltip={{
                title: 'Main office will be choosen automatically \n if you leave this field empty',
                icon: <InfoCircleOutlined />,
            }}
        >
            <Select
                style={{ width: '100%' }}
                options={offices}
                optionFilterProp="office"
                fieldNames={{
                    label: 'office',
                    value: 'office_id',
                }}
                defaultActiveFirstOption
                allowClear
                showArrow
                placeholder={'Select office'}
            ></Select>
        </Form.Item>
    </Col> */}
                <Col span={24}>
                    <Form.Item label="Assign To" name="assign_to_account_ids">
                        <Select
                            style={{ width: '100%' }}
                            mode="multiple"
                            options={subUsers?.map(user => ({
                                label: user.fullname,
                                value: user.account_id,
                            }))}
                            // options={subUsers as any}
                            // fieldNames={{ label: 'fullname', value: 'account_id' }}
                            // optionFilterProp="fullname"
                            showArrow
                            showSearch
                            placeholder="Choose user(s)"
                            optionFilterProp="label"
                            placement="topRight"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};

export const ModalEditTransactionInfo: React.FC<{
    transactionData: TransactionDetailType;
    setTransactionData: React.Dispatch<React.SetStateAction<TransactionDetailType>>;
    isModalVisible?: boolean;
    setIsModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    reloadTransactionDetail: (showLoadingProgress: boolean, detailType: DetailType) => void;
}> = ({
    transactionData,
    isModalVisible = false,
    setIsModalVisible = () => {},
    setTransactionData,
    reloadTransactionDetail,
}) => {
    const [form] = Form.useForm();

    const [subUsers, setSubUsers] = useState<AccountCompactDataType[]>([]);

    const [SubmittingData, SetSubmittingData] = useState(false);

    const _onSubmitForm = (formValues?: any) => {
        SetSubmittingData(true);

        const assign_to_account_ids: number[] = formValues.assign_to_account_ids;

        const payload = {
            ...formValues,
            sale_types_id: formValues.sale_types_id || null,
            assign_to_accounts: assign_to_account_ids
                ? subUsers
                      .filter(u => assign_to_account_ids.includes(u.account_id))
                      .map(su => ({
                          account_id: su.account_id,
                          accountrole_id: su.accountrole_id,
                      }))
                : [],
        };

        updateTransactionDetail(transactionData.contract_id, payload)
            .then(res => {
                if (res.data) {
                    reloadTransactionDetail(false, 'basic_info');
                    setIsModalVisible(false);
                    showNotification(
                        'success',
                        'Basic transaction info has been successfully updated.'
                    );
                }
            })
            .finally(() => {
                SetSubmittingData(false);
            });
    };

    const _fetchSubUsers = () => {
        getAllTeamMembers({
            pageSize: 1000,
        }).then(({ data }) => {
            setSubUsers((data as AccountCompactDataType[]) || []);
        });
    };

    const _setFormFieldsValue = () => {
        if (transactionData && transactionData.contract_id) {
            const formPayload = transactionData as any;
            formPayload.type_id = transactionData.type_id === 0 ? null : transactionData.type_id;
            formPayload.sale_types_id =
                transactionData.sale_types_id === 0 ? null : transactionData.sale_types_id;
            formPayload.assign_to_account_ids = transactionData.assigned_accounts?.map(
                account => account.account_id
            );
            form.setFieldsValue(formPayload);
        } else {
            form.setFieldsValue({
                status_id: 1,
                flag_id: 1,
            });
        }
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        if (isModalVisible) {
            _setFormFieldsValue();
        }
    }, [transactionData]);

    useEffect(() => {
        if (isModalVisible) {
            _fetchSubUsers();
            _setFormFieldsValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Modal
                title={`Edit basic information`}
                visible={isModalVisible}
                onOk={() => form.submit()}
                onCancel={() => setIsModalVisible(false)}
                okText="Save Changes"
                confirmLoading={SubmittingData}
                width={700}
                style={{ top: 40 }}
                bodyStyle={{ overflowY: 'auto', height: '600px' }}
            >
                <Form layout="vertical" form={form} onFinish={_onSubmitForm} requiredMark={false}>
                    <EditTransactionFormContent subUsers={subUsers} />
                </Form>
            </Modal>
        </>
    );
};
