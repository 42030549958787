// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Col, Row, Tabs } from 'antd';

// EZ web-app utils import
// EZ web-app APIs import
// EZ web-app types import
// EZ web-app components import
import { SubUserSetting } from './teamAndAccessManagement/subUserSetting';
import { OfficeSetting } from './teamAndAccessManagement/officeSetting';
import { AccountRolePermission } from './teamAndAccessManagement/accountRoleSetting';

// EZ web-app styles and assets import

export const TeamMemberSetting: React.FC<{}> = ({}) => {
    const [activeTabKey, setActiveTabKey] = useState('team-member-subuser');

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={24}>
                <Tabs
                    className="tab-team-member-setting"
                    defaultActiveKey="team-member-subuser"
                    type="card"
                    activeKey={activeTabKey}
                    onChange={key => setActiveTabKey(key)}
                    destroyInactiveTabPane
                >
                    <Tabs.TabPane tab={`Sub Users`} key={`team-member-subuser`}>
                        <SubUserSetting tabKey="team-member-subuser" activeTabKey={activeTabKey} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`Offices`} key={`team-member-office`}>
                        <OfficeSetting tabKey="team-member-office" activeTabKey={activeTabKey} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={`Global Permissions`} key={`account-role-permission`}>
                        <AccountRolePermission
                            tabKey="account-role-permission"
                            activeTabKey={activeTabKey}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Col>
        </Row>
    );
};
