// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Button, Col, Divider, Form, Popconfirm, Row, Typography } from 'antd';
import {
    CloseOutlined,
    DeleteOutlined,
    EditFilled,
    FlagTwoTone,
    SaveFilled,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';

// EZ web-app utils import
import { showMessage } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    getProspectFlagOptions,
    updateProspectFlag,
} from 'utils/ez_api/prospect/prospectAdminAPIs';
// EZ web-app types import
import { ProspectDefaultFlagType } from 'types/prospect';
// EZ web-app components import

// EZ web-app styles and assets import

export const ProspectFlagSetting: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();

    const [isFetchingData, setIsFetchingData] = useState(false);
    const [data, setData] = useState<ProspectDefaultFlagType[]>([]);

    const _fetchData = () => {
        setIsFetchingData(true);
        getProspectFlagOptions()
            .then(({ data }) => {
                const respData = data as ProspectDefaultFlagType[];
                setData(respData || []);
            })
            .finally(() => {
                setIsFetchingData(false);
            });
    };

    const columns: ProColumns<ProspectDefaultFlagType>[] = [
        {
            title: 'Flag',
            dataIndex: 'flag',
            key: 'flag',
            width: '80px',
            editable: false,
            render: (_, record) => <Typography.Text strong>{record.flag}</Typography.Text>,
        },
        {
            title: 'Flag Icon',
            dataIndex: 'flag',
            key: 'flag',
            width: '120px',
            editable: false,
            render: (_, record) => (
                <>
                    <FlagTwoTone twoToneColor={record.flag_color} />
                    <span className="ml-2">{record.flag}</span>
                </>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '220px',
            renderText(_, record) {
                return record.prospects_flag?.description;
            },
        },
        {
            title: 'Active?',
            dataIndex: 'new_active',
            key: 'new_active',
            width: '75px',
            valueType: 'switch',
            render: (_, record) => {
                return record.prospects_flag?.active ? 'Yes' : 'No';
            },
            fieldProps: (_, config) => {
                return {
                    checkedChildren: 'Yes',
                    unCheckedChildren: 'No',
                    defaultChecked: false,
                };
            },
            formItemProps: (_, config) => {
                return {
                    initialValue: config.entity.prospects_flag?.active ? true : false,
                };
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '120px',
            key: 'x',
            valueType: 'option',
            render: (_, record, index, action) => {
                return record.flag_id !== 1 ? (
                    <span>
                        <Button
                            className="p-0"
                            type="link"
                            icon={<EditFilled />}
                            title="Edit"
                            size="small"
                            onClick={() => {
                                action?.startEditable(record.flag_id);
                            }}
                        >
                            Edit
                        </Button>
                    </span>
                ) : (
                    <></>
                );
            },
        },
    ];

    const _updateData = async (record: ProspectDefaultFlagType) => {
        await updateProspectFlag(record.flag_id, {
            ...record,
        });
        _fetchData();
        setTimeout(() => {
            showMessage('success', `Selected data: ${record.flag} has been successfully saved.`);
        }, 100);
        return true;
    };

    useEffect(() => {
        _fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={20} className="mt-3">
                <ProTable
                    rowKey="flag_id"
                    showSorterTooltip={false}
                    search={false}
                    loading={isFetchingData}
                    columns={columns}
                    pagination={{ pageSize: 100 }}
                    editable={{
                        actionRender: (_, __, defaultDom) => [defaultDom.save, defaultDom.cancel],
                        type: 'multiple',
                        onSave: async (_, newData: any, oldData) => {
                            return _updateData({
                                ...newData,
                                active: newData.new_active ? 1 : 0,
                            });
                        },
                        saveText: (
                            <>
                                <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                    Save
                                </Button>
                            </>
                        ),
                        cancelText: (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0 text-grey"
                                    type="text"
                                    title="Cancel and back"
                                    icon={<CloseOutlined />}
                                ></Button>
                            </>
                        ),
                    }}
                    dataSource={data}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                />
            </Col>
        </Row>
    );
};
