import { CloseCircleOutlined } from '@ant-design/icons';

interface ErrorMessageProps {
    isError: boolean;
    errorMessage: string;
}

export const ErrorMessage = (props: ErrorMessageProps) => {
    const { isError, errorMessage } = props;
    return (
        <div className="popup-error">
            {isError ? (
                <div className="error-div">
                    <span className="error-icon">
                        <CloseCircleOutlined />
                    </span>
                    <span className="error-msg">{errorMessage}</span>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
