// Core React libraries import
import { useContext, useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Modal, Form } from 'antd';

// EZ web-app utils import
// EZ web-app types import
// EZ web-app components import
import { EditProspectFormContent } from './Modal_editProspectInfo';
import { showNotification } from 'utils/notificationUtil';
import { addNewProspect } from 'utils/ez_api/prospect/prospectAPIs';
import { HomeContext } from 'context/EZContext';
import { ProspectDataType } from 'types/prospect';

// EZ web-app styles and assets import

export const AddNewProspectModal: React.FC<{
    isModalVisible: boolean;
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess?: () => void;
}> = ({ isModalVisible, setIsModalVisible, onSuccess }) => {
    const HomeCtx = useContext(HomeContext);

    const [form] = Form.useForm();

    const [submittingData, setSubmittingData] = useState(false);

    const _onSubmitForm = (formValues: any) => {
        setSubmittingData(true);

        const payload = {
            ...formValues,
        };

        addNewProspect(payload)
            .then(res => {
                if (res.data) {
                    const prospect = res.data as ProspectDataType;

                    setIsModalVisible(false);
                    showNotification('success', 'New prospect has been successfully added.');
                    setTimeout(() => {
                        HomeCtx.openProspectDetailTab(prospect.prospect_id, prospect.fullname, {
                            ...prospect,
                        });
                    }, 10);
                }
                onSuccess?.();
            })
            .finally(() => {
                setSubmittingData(false);
            });
    };

    useEffect(() => {
        if (isModalVisible) {
        } else {
            form?.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    return (
        <>
            <>
                <Modal
                    title={`Add New Prospect`}
                    visible={isModalVisible}
                    onOk={() => form.submit()}
                    onCancel={() => setIsModalVisible(false)}
                    okText="Save and Edit Detail"
                    confirmLoading={submittingData}
                    width={800}
                    style={{ top: 40 }}
                    className="add-new-prospect-modal"
                >
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={_onSubmitForm}
                        requiredMark={true}
                    >
                        <EditProspectFormContent />
                    </Form>
                </Modal>
            </>
        </>
    );
};
