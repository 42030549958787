// Core React libs import
import React from 'react';

// Core ANTD and 3rd party libs import
import { Modal } from 'antd';
import { MarkdownRenderer } from 'components/markdown/MarkdownRenderer';

// EZ level libs import

export const EZOnboardingDetailModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    title: string;
    content: string;
}> = ({ isOpen, onClose, title, content = '' }) => {
    return (
        <Modal
            width="fit-content"
            closable
            maskClosable
            title={title}
            visible={isOpen}
            okButtonProps={{
                style: {
                    display: 'none',
                },
            }}
            cancelText="Close"
            onCancel={onClose}
            destroyOnClose
            zIndex={1001}
        >
            <div style={{ width: 800, overflow: 'auto' }}>
                <MarkdownRenderer content={content} />
            </div>
        </Modal>
    );
};
