import { intervalToDuration } from 'date-fns';

export const calculateDuration = (
    startDate: Date | string,
    endDate: Date | string,
    isReturnString: boolean = false
) => {
    try {
        const _startDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
        const _endDate = typeof endDate === 'string' ? new Date(endDate) : endDate;
        // get difference years, months and days from start to end
        const { years, months, days } = intervalToDuration({
            start: _startDate,
            end: _endDate,
        });

        if (isReturnString) {
            return `${years} years, ${months} months, ${days} days`;
        }

        return { years, months, days };
    } catch (error) {
        return { years: 0, months: 0, days: 0 };
    }
};
