import qs from 'qs';

import { apiUrl } from '../../envUtil';
import { APIRequest } from '../../apiUtil';
import { Mixpanel } from 'utils/mixPanelUtil';

// Prospect Group
export const addNewProspectGroup = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'groups',
        action: 'add_group',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/prospect-groups`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateProspectGroup = (id: number, payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'groups',
        action: 'edit_group',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/prospect-group/${id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteProspectGroup = (id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'groups',
        action: 'delete_group',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `admin/settings/prospect-group/${id}`,
        useDefaultErrorNotif: true,
    });
};
// End of Prospect Group

// Prospect Follow-up codes
export const addNewProspectFollowupCode = (payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'followup_codes',
        action: 'add_followup_code',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/prospect-followup-codes`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const updateProspectFollowupCode = (id: number, payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'followup_codes',
        action: 'edit_followup_code',
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/prospect-followup-code/${id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};

export const deleteProspectFollowupCode = (id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'followup_codes',
        action: 'delete_followup_code',
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `admin/settings/prospect-followup-code/${id}`,
        useDefaultErrorNotif: true,
    });
};
// End of Prospect Follow-up codes

// Prospect Flags
export const getProspectFlagOptions = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `admin/settings/prospect-flags`,
        useDefaultErrorNotif: true,
    });
};

export const updateProspectFlag = (id: number, payload: any) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'flags',
        action: 'edit_flag',
        id,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/prospect-flag/${id}`,
        data: payload,
        useDefaultErrorNotif: true,
    });
};
// End of Prospect Flags

// Prospect Questions
export const getDefaultQuestions = () => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `admin/settings/prospect-questions`,
        useDefaultErrorNotif: true,
    });
};
export const addNewDropdownOption = (question_id: number, option_value: string) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'questions',
        action: 'add_dropdown_option',
        question_id,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `admin/settings/prospect-question/${question_id}/options`,
        data: { option_value },
        useDefaultErrorNotif: true,
    });
};

export const updateDropdownOption = (
    question_id: number,
    option_id: number,
    option_value: string
) => {
    return APIRequest({
        method: 'put',
        url: apiUrl + `admin/settings/prospect-question/${question_id}/option/${option_id}`,
        data: { option_value },
        useDefaultErrorNotif: true,
    });
};

export const deleteDropdownOption = (question_id: number, option_id: number) => {
    Mixpanel.track('app.edit_admin_setting', {
        section: 'prospect',
        sub_section: 'questions',
        action: 'delete_dropdown_option',
        question_id,
        option_id,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `admin/settings/prospect-question/${question_id}/option/${option_id}`,
        useDefaultErrorNotif: true,
    });
};
// End of Prospect Flags
