import { apiUrl } from '../envUtil';
import { APIRequest } from '../apiUtil';
import { DocType } from 'components/appPage/documents';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getTransactionDocs = (contract_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}/documents` + (params ? `?${params}` : ''),
    });
};

export const getTransactionFolders = (contract_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `transaction/${contract_id}/folders` + (params ? `?${params}` : ''),
    });
};

export const getPresignedURLContractDocUpload = (
    contract_id: number,
    filePayload: {
        original_name: string;
        mime_type: string;
        size: number;
    }
) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/file-upload/presign-url`,
        data: {
            ...filePayload,
        },
    });
};

export const getPresignedURLDocsRead = (
    doc_type: DocType,
    doc_list: {
        contract_id?: number;
        prospect_id?: number;
        file_id: number;
    }[]
) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `documents/presign-urls`,
        data: {
            doc_type,
            doc_list,
        },
    });
};

export const addNewTransactionDoc = (
    contract_id: number,
    docPayload: {
        folder_id?: number;
        filename?: string;
        u_filename?: string;
        bucket?: string;
        key?: string;
        template_type?: string | null;
        doc_title?: string | null;
        docs_template_id?: number | null;
        content_type?: string;
        size?: number;
    }
) => {
    Mixpanel.track('app.add_transaction_doc', {
        contract_id,
        ...docPayload,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/documents`,
        data: docPayload,
    });
};

export const linkDocsToTasks = (contract_id: number, docIDs: number[], taskIDs: number[]) => {
    Mixpanel.track('app.edit_transaction_doc', {
        contract_id,
        action: 'link-to-docs',
        task_ids: taskIDs,
        doc_ids: docIDs,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/documents/link-to-tasks`,
        data: {
            doc_ids: docIDs,
            task_ids: taskIDs,
        },
    });
};

export const downloadLegacyDocument = (doc_type: DocType, file_id: number) => {
    return APIRequest({
        method: 'get',
        responseType: 'blob',
        url: apiUrl + `document/${file_id}/legacy-read?doc_type=${doc_type}&read_mode=download`,
    });
};

export const getPresignedURLDoc = (
    doc_type: DocType,
    file_id: number,
    read_mode: 'inline' | 'download'
) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `document/${file_id}/presign-url/${read_mode}?doc_type=${doc_type}`,
    });
};

export const deleteDocument = (contract_id: number, doc_id: number) => {
    Mixpanel.track('app.delete_transaction_doc', {
        contract_id,
        doc_id: doc_id,
        bulk: false,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `transaction/${contract_id}/document/${doc_id}`,
        useDefaultErrorNotif: true,
    });
};

export const deleteFile = (contract_id: number, doc_id: number) => {
    Mixpanel.track('app.delete_transaction_file', {
        contract_id,
        doc_id: doc_id,
        bulk: false,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/document/${doc_id}/delete-file`,
        useDefaultErrorNotif: true,
    });
};

export const getDocNotes = (doc_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `document/${doc_id}/notes` + (params ? `?${params}` : ''),
        useDefaultErrorNotif: true,
    });
};

export const addNewNote = (contract_id: number, doc_id: number, note: string) => {
    Mixpanel.track('app.edit_transaction_doc', {
        contract_id,
        doc_id,
        action: 'new_note',
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `document/${doc_id}/notes`,
        data: {
            contract_id: contract_id,
            note: note,
        },
        useDefaultErrorNotif: true,
    });
};

export const updateDocument = (
    contract_id: number,
    id: number,
    payload: any,
    formType: 'FULL' | 'PARTIAL' | 'SINGLE' = 'PARTIAL'
) => {
    Mixpanel.track('app.edit_transaction_doc', {
        contract_id,
        doc_id: id,
        action: 'edit',
        ...payload,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/document/${id}?formType=${formType}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const executeSignature = (
    contract_id: number,
    id: number,
    payload: any,
    formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
) => {
    Mixpanel.track('app.edit_transaction_doc', {
        contract_id,
        doc_id: id,
        action: 'edit',
        ...payload,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `transaction/${contract_id}/document/signatures/${id}?formType=${formType}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};

export const generateDocuSignEnvelope = (
    contract_id: number,
    doc_id: number,
    emailSubject: string,
    emailBlurb: string,
    recipients: { email: string; fullname: string }[]
) => {
    Mixpanel.track('app.generate_docusign_envelope', {
        contract_id,
        doc_id,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/document/${doc_id}/generate-docusign-envelope`,
        data: {
            emailSubject,
            emailBlurb,
            recipients,
        },
        useDefaultErrorNotif: true,
    });
};
export const getDocuSignConsentURL = (contract_id: number, doc_id: number) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/document/${doc_id}/get-docusign-consent-url`,
        useDefaultErrorNotif: true,
    });
};

// bulk actions
export const moveSelectedDocs = (contract_id: number, doc_ids: number[], folder_id: number) => {
    Mixpanel.track('app.edit_transaction_doc', {
        contract_id,
        doc_ids: doc_ids,
        action: 'move_docs',
        folder_id,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `transaction/${contract_id}/documents/bulk-move-docs`,
        data: {
            contract_id: contract_id,
            doc_ids: doc_ids,
            folder_id: folder_id,
        },
        useDefaultErrorNotif: true,
    });
};

export const deleteDocuments = (contract_id: number, doc_ids: number[]) => {
    Mixpanel.track('app.delete_transaction_doc', {
        contract_id,
        doc_ids: doc_ids,
        bulk: true,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `transaction/${contract_id}/documents`,
        data: {
            doc_ids: doc_ids,
        },
        useDefaultErrorNotif: true,
    });
};
