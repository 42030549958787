// Core React libraries import
import { useEffect, useState } from 'react';

// Core ANTD and 3rdparty libraries import
import { Button, Col, Divider, Form, Popconfirm, Row, Switch, Tag, Typography } from 'antd';
import {
    CheckSquareOutlined,
    CloseOutlined,
    DeleteFilled,
    DeleteOutlined,
    EditFilled,
    EyeInvisibleOutlined,
    EyeOutlined,
    InfoCircleOutlined,
    PlusOutlined,
    SaveFilled,
} from '@ant-design/icons';
import { ProColumns } from '@ant-design/pro-components';
import { ProTable } from '@ant-design/pro-components';
import debounce from 'lodash/debounce';

// EZ web-app utils import
import { showMessage } from 'utils/notificationUtil';
// EZ web-app APIs import
import {
    addNewProspectDefaultTaskData,
    addNewProspectDefaultTaskTab,
    deleteProspectDefaultTask,
    deleteProspectDefaultTaskTab,
    getProspectTaskTabsDetail,
    updateProspectDefaultTaskData,
    updateProspectDefaultTaskTabData,
} from 'utils/ez_api/prospect/prospectAdminTaskAPIs';
// EZ web-app types import
import { ProspectDefaultTaskTabType, ProspectDefaultTaskType } from 'types/prospectTask';

// EZ web-app components import

// EZ web-app styles and assets import

export const ProspectTaskSetting: React.FC<{}> = ({}) => {
    const [form] = Form.useForm();

    const [isFetchingTaskTabs, setIsFetchingTaskTabs] = useState(false);
    const [taskTabs, setTaskTabs] = useState<ProspectDefaultTaskTabType[]>([]);

    const [expandedRecordIDs, setExpandedRecordIDs] = useState<React.Key[]>([]);

    const _fetchTaskTabsDetail = () => {
        setIsFetchingTaskTabs(true);
        getProspectTaskTabsDetail()
            .then(({ data }) => {
                const respData = data as ProspectDefaultTaskTabType[];
                setTaskTabs(respData || []);
            })
            .finally(() => {
                setIsFetchingTaskTabs(false);
            });
    };

    const columns: ProColumns<ProspectDefaultTaskTabType>[] = [
        {
            title: 'On/Off',
            dataIndex: 'toggle',
            key: 'toggle',
            width: '80px',
            editable: false,
            render: (_, record) => (
                <Form.Item
                    name={[`${record.prospects_defaulttasktab_id}`, 'toggle']}
                    noStyle
                    valuePropName="checked"
                    initialValue={
                        !record.defaulttasktab_preference ||
                        record.defaulttasktab_preference?.toggle
                            ? true
                            : false
                    }
                >
                    <Switch
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                        defaultChecked={false}
                        onChange={(val: boolean) => {
                            _saveTaskTabData(record, {
                                toggle: val,
                            });
                        }}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'List Name',
            dataIndex: 'name',
            key: 'name',
            width: '280px',
            formItemProps: (form, config) => {
                return {
                    rules: [
                        {
                            required: true,
                        },
                    ],
                };
            },
            render: (_, record) => (
                <Typography.Text
                    onClick={() => setExpandedRecordIDs([record.prospects_defaulttasktab_id])}
                    strong
                    underline
                >
                    {record.name}
                </Typography.Text>
            ),
        },
        {
            title: 'Tasklist Information',
            dataIndex: '',
            key: 'x',
            editable: false,
            render: (_, record) => (
                <Tag
                    onClick={() => setExpandedRecordIDs([record.prospects_defaulttasktab_id])}
                    className="tag-rounded"
                >
                    {record.defaulttasks?.length} Task(s)
                </Tag>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '180px',
            key: 'x',
            valueType: 'option',
            render: (_, record, index, action) => (
                <span>
                    <Button
                        className="p-0"
                        type="link"
                        icon={<EditFilled />}
                        title="Edit list name"
                        size="small"
                        onClick={() => {
                            action?.startEditable(record.prospects_defaulttasktab_id);
                        }}
                    >
                        Edit List Name
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm
                        placement="bottomLeft"
                        title={
                            <>
                                Delete <b>{record.name}</b>?
                            </>
                        }
                        onConfirm={() => _deleteSelectedTaskTab(record.prospects_defaulttasktab_id)}
                    >
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<DeleteOutlined />}
                            title="Delete this list"
                            size="small"
                            danger
                        ></Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    const modifyTaskData = (
        TaskTab: ProspectDefaultTaskTabType,
        taskID: number,
        newRecordData: any
    ) => {
        try {
            const taskIndex = TaskTab.defaulttasks?.findIndex(
                t => t.prospects_defaulttask_id === taskID
            );

            if (taskIndex !== undefined && taskIndex >= 0 && TaskTab.defaulttasks?.[taskIndex]) {
                TaskTab.defaulttasks![taskIndex] = {
                    ...TaskTab.defaulttasks[taskIndex],
                    ...newRecordData,
                };

                if (newRecordData.toggle === false) {
                    TaskTab.defaulttasks![taskIndex].defaulttask_preference = {
                        prospects_defaulttask_id: taskID.toString(),
                        defaulttasks_preference_id:
                            TaskTab.defaulttasks![taskIndex].defaulttask_preference
                                ?.defaulttasks_preference_id || -1,
                        prospects_defaulttasktab_id: TaskTab.prospects_defaulttasktab_id,
                        toggle: 0,
                        parent_account_id: TaskTab.parent_account_id,
                    };
                }

                setTaskTabs([...taskTabs]);
            }
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx ~ line 263 ~ modifyTaskData ~ error', error);
        }
    };
    const modifyTaskTabData = (taskTabID: number, newRecordData: any) => {
        try {
            const taskTabIndex = taskTabs.findIndex(
                t => t.prospects_defaulttasktab_id === taskTabID
            );

            if (taskTabIndex !== undefined && taskTabIndex >= 0) {
                taskTabs[taskTabIndex] = { ...taskTabs[taskTabIndex], ...newRecordData };
                setTaskTabs([...taskTabs]);
            }
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx ~ line 288 ~ modifyTaskTabData ~ error', error);
        }
    };

    const deleteTask = (TaskTab: ProspectDefaultTaskTabType, taskID: number) => {
        try {
            const taskIndex = TaskTab.defaulttasks?.findIndex(
                t => t.prospects_defaulttask_id === taskID
            );

            if (taskIndex !== undefined && taskIndex >= 0) {
                TaskTab.defaulttasks!.splice(taskIndex, 1);
                setTaskTabs([...taskTabs]);
            }
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx ~ line 267 ~ deleteTask ~ error', error);
        }
    };

    const deleteTaskList = (taskListID: number) => {
        try {
            const taskListIndex = taskTabs.findIndex(
                t => t.prospects_defaulttasktab_id === taskListID
            );

            if (taskListIndex !== undefined && taskListIndex >= 0) {
                taskTabs.splice(taskListIndex, 1);
                setTaskTabs([...taskTabs]);
            }
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx ~ line 292 ~ deleteTaskList ~ error', error);
        }
    };

    const addNewTask = (TaskTab: ProspectDefaultTaskTabType, taskData: ProspectDefaultTaskType) => {
        try {
            if (!TaskTab.defaulttasks) {
                TaskTab.defaulttasks = [];
            }

            TaskTab.defaulttasks.unshift(taskData);
            setTaskTabs([...taskTabs]);
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx ~ line 267 ~ deleteTask ~ error', error);
        }
    };

    const addNewTaskTab = (taskTabData: ProspectDefaultTaskTabType) => {
        try {
            taskTabs.unshift(taskTabData);
            setTaskTabs([...taskTabs]);
        } catch (error) {
            console.log('🚀 ~ file: taskSetting.tsx ~ line 267 ~ deleteTask ~ error', error);
        }
    };

    const _saveTaskData = (
        TaskTab: ProspectDefaultTaskTabType,
        task: ProspectDefaultTaskType,
        newRecordData: any
    ) => {
        return updateProspectDefaultTaskData(
            TaskTab.prospects_defaulttasktab_id,
            task.prospects_defaulttask_id,
            newRecordData
        ).then(() => {
            setTimeout(() => {
                modifyTaskData(TaskTab, task.prospects_defaulttask_id, {
                    ...task,
                    ...newRecordData,
                });
            }, 1000);
            showMessage('success', 'Selected task data has been updated.');
            return true;
        });
    };

    const _saveTaskTabData = (TaskTab: ProspectDefaultTaskTabType, newRecordData: any) => {
        setTimeout(() => {
            modifyTaskTabData(TaskTab.prospects_defaulttasktab_id, {
                ...TaskTab,
                ...newRecordData,
            });
        }, 0);
        return updateProspectDefaultTaskTabData(
            TaskTab.prospects_defaulttasktab_id,
            newRecordData
        ).then(() => {
            showMessage('success', 'Selected task list data has been updated.');
            return true;
        });
    };

    const _deleteSelectedTask = (TaskTab: ProspectDefaultTaskTabType, taskID: number) => {
        return deleteProspectDefaultTask(TaskTab.prospects_defaulttasktab_id, taskID)
            .then(() => {
                showMessage('success', 'Selected task has been deleted.');
                deleteTask(TaskTab, taskID);
            })
            .catch(() => {});
    };

    const _deleteSelectedTaskTab = (taskTabID: number) => {
        return deleteProspectDefaultTaskTab(taskTabID)
            .then(() => {
                showMessage('success', 'Selected task list has been deleted.');
                deleteTaskList(taskTabID);
            })
            .catch(() => {});
    };

    const _saveNewTaskTab = () => {
        const payload = {
            name: 'Untitled',

            toggle: false,
        };

        return addNewProspectDefaultTaskTab(payload)
            .then(({ data }) => {
                showMessage('success', `New task list: "Untitled" has been added.`);
                addNewTaskTab(data);
            })
            .catch(() => {});
    };

    const _saveNewTask = (TaskTab: ProspectDefaultTaskTabType) => {
        const defaulttask_id_dummy = Date.now();

        const payload = {
            title: 'Untitled',

            num_days: 1,
            before_after: 'after',
            date: 'close_date',

            toggle: true,
        };

        return addNewProspectDefaultTaskData(TaskTab.prospects_defaulttasktab_id, payload)
            .then(({ data }) => {
                showMessage(
                    'success',
                    `New task: "Untitled" has been added to task list: "${TaskTab.name}".`
                );
                addNewTask(TaskTab, data);
            })
            .catch(() => {
                deleteTask(TaskTab, defaulttask_id_dummy);
            });
    };

    const _taskTitleHandler = debounce(
        (
            TaskTab: ProspectDefaultTaskTabType,
            task: ProspectDefaultTaskType,
            taskTitle?: string
        ) => {
            const trimmedTaskTitle = taskTitle?.toString().trim();

            if (trimmedTaskTitle) {
                _saveTaskData(TaskTab, task, {
                    title: trimmedTaskTitle,
                });
            }
        },
        1000
    );

    const _taskNumDaysHandler = debounce(
        (TaskTab: ProspectDefaultTaskTabType, task: ProspectDefaultTaskType, numDays?: number) => {
            _saveTaskData(TaskTab, task, {
                num_days: numDays,
            });
        },
        1000
    );

    const ExpandedRowComponent: React.FC<{ TaskTab: ProspectDefaultTaskTabType }> = ({
        TaskTab,
    }) => {
        const taskColumns: ProColumns<ProspectDefaultTaskType>[] = [
            {
                title: '',
                dataIndex: 'toggle',
                key: 'toggle',
                width: '40px',
                valueType: 'switch',
                align: 'right',
                fieldProps: (_, config) => {
                    return {
                        size: 'small',
                        checkedChildren: <EyeOutlined />,
                        unCheckedChildren: <EyeInvisibleOutlined />,
                        defaultChecked: false,
                        onChange: (val: boolean) => {
                            _saveTaskData(TaskTab, config.entity, {
                                toggle: val,
                            });
                        },
                    };
                },
                formItemProps: (_, config) => {
                    return {
                        initialValue:
                            !config.entity.defaulttask_preference ||
                            config.entity.defaulttask_preference?.toggle
                                ? true
                                : false,
                    };
                },
            },
            {
                title: 'Task Title',
                dataIndex: 'title',
                key: 'title',
                width: '260px',
                fieldProps: (_, config) => {
                    return {
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            _taskTitleHandler(TaskTab, config.entity, e?.target?.value);
                        },
                    };
                },
                formItemProps: (form, config) => {
                    return {
                        rules: [
                            {
                                required: true,
                            },
                        ],
                    };
                },
            },
            {
                title: 'Action',
                width: '70px',
                dataIndex: '',
                valueType: 'option',
                fixed: 'right',
                render: (_, record, index, action) => (
                    <span>
                        <Button
                            className="p-0"
                            type="link"
                            icon={<EditFilled />}
                            title="Delete this list"
                            size="small"
                            onClick={() => {
                                action?.startEditable(record.prospects_defaulttask_id);
                            }}
                        >
                            Edit
                        </Button>
                        <Divider type="vertical" />
                        <Button
                            className="p-0 mr-1"
                            type="link"
                            icon={<DeleteOutlined />}
                            title="Delete this list"
                            size="small"
                            danger
                        ></Button>
                    </span>
                ),
            },
        ];

        return (
            <div className="expanded-row-with-table">
                <ProTable
                    size="small"
                    rowKey="prospects_defaulttask_id"
                    pagination={{ pageSize: 100 }}
                    showSorterTooltip={false}
                    search={false}
                    columns={taskColumns}
                    options={{
                        fullScreen: false,
                        reload: false,
                        setting: false,
                        density: false,
                    }}
                    dataSource={TaskTab.defaulttasks || []}
                    editable={{
                        editableKeys: TaskTab.defaulttasks?.map(t => t.prospects_defaulttask_id),
                        actionRender: (_, __, defaultDom) => [defaultDom.delete],
                        type: 'multiple',
                        onSave: async (_, data: ProspectDefaultTaskType, oldData) => {
                            return _saveTaskData(TaskTab, data, data);
                        },
                        onDelete: (_, row) => {
                            return _deleteSelectedTask(TaskTab, row.prospects_defaulttask_id);
                        },
                        saveText: (
                            <>
                                <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                    Save
                                </Button>
                            </>
                        ),
                        deleteText: (
                            <>
                                <Button
                                    className="p-0"
                                    type="link"
                                    icon={<DeleteFilled />}
                                    title="Delete this task?"
                                    size="small"
                                    danger
                                ></Button>
                            </>
                        ),
                        deletePopconfirmMessage: 'Delete this task?',
                        cancelText: (
                            <>
                                <Divider type="vertical" />
                                <Button
                                    className="p-0 text-grey"
                                    type="text"
                                    title="Cancel and back"
                                    icon={<CloseOutlined />}
                                ></Button>
                            </>
                        ),
                    }}
                    toolBarRender={() => [
                        <Button
                            size="small"
                            key="3"
                            type="primary"
                            onClick={() => _saveNewTask(TaskTab)}
                        >
                            <CheckSquareOutlined />
                            New Task
                        </Button>,
                    ]}
                />
            </div>
        );
    };

    useEffect(() => {
        _fetchTaskTabsDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form layout="vertical" form={form} requiredMark={false}>
            <Row>
                <Col span={18} style={{ textAlign: 'left' }} className="text-help">
                    <InfoCircleOutlined /> Tip: Check the box in the On/Off column to have this task
                    list automatically added to all <b>new transactions</b>.
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <Button key="3" type="primary" onClick={() => _saveNewTaskTab()}>
                        <PlusOutlined />
                        New Task List
                    </Button>
                </Col>
                <Col span={24} className="mt-3">
                    <ProTable
                        rowKey="prospects_defaulttasktab_id"
                        showSorterTooltip={false}
                        search={false}
                        // scroll={{ x: 1100 }}
                        loading={isFetchingTaskTabs}
                        columns={columns}
                        expandable={{
                            expandedRowRender: (record: ProspectDefaultTaskTabType) => {
                                return <ExpandedRowComponent TaskTab={record} />;
                            },
                            expandedRowKeys: expandedRecordIDs,
                            onExpand: (expanded, record: ProspectDefaultTaskTabType) => {
                                if (expanded) {
                                    setExpandedRecordIDs([record.prospects_defaulttasktab_id]);
                                } else {
                                    setExpandedRecordIDs([]);
                                }
                            },
                        }}
                        editable={{
                            actionRender: (_, __, defaultDom) => [
                                defaultDom.save,
                                defaultDom.cancel,
                            ],
                            type: 'multiple',
                            onSave: async (_, newData: ProspectDefaultTaskTabType, oldData) => {
                                return _saveTaskTabData(oldData, { name: newData.name });
                            },
                            onDelete: (key, row) => {
                                return _deleteSelectedTaskTab(row.prospects_defaulttasktab_id);
                            },
                            saveText: (
                                <>
                                    <Button className="p-0 pl-1" type="link" icon={<SaveFilled />}>
                                        Save
                                    </Button>
                                </>
                            ),
                            cancelText: (
                                <>
                                    <Divider type="vertical" />
                                    <Button
                                        className="p-0 text-grey"
                                        type="text"
                                        title="Cancel and back"
                                        icon={<CloseOutlined />}
                                    ></Button>
                                </>
                            ),
                        }}
                        dataSource={taskTabs}
                        options={{
                            fullScreen: false,
                            reload: false,
                            setting: false,
                            density: false,
                        }}
                    />
                </Col>
            </Row>
        </Form>
    );
};
