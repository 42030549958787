import { apiUrl } from '../../envUtil';
import { APIRequest } from '../../apiUtil';
import { DocType } from 'components/appPage/documents';
import { Mixpanel } from 'utils/mixPanelUtil';

export const getProspectDocs = (prospect_id: number, params?: string) => {
    return APIRequest({
        method: 'get',
        url: apiUrl + `prospect/${prospect_id}/documents` + (params ? `?${params}` : ''),
    });
};

export const getPresignedURLProspectDocUpload = (
    prospect_id: number,
    filePayload: {
        original_name: string;
        mime_type: string;
        size: number;
    }
) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `prospect/${prospect_id}/file-upload/presign-url`,
        data: {
            ...filePayload,
            doc_type: 'prospect_document',
        },
    });
};

// TODO: port this API to prospect's document
export const getPresignedURLDocsRead = (
    doc_type: DocType,
    doc_list: { prospect_id?: number; file_id: number }[]
) => {
    return APIRequest({
        method: 'post',
        url: apiUrl + `documents/presign-urls`,
        data: {
            doc_type,
            doc_list,
        },
    });
};

export const addNewProspectDoc = (
    prospect_id: number,
    docPayload: {
        folder_id?: number;
        filename?: string;
        u_filename?: string;
        bucket?: string;
        key?: string;
        content_type?: string;
        size?: number;
    }
) => {
    Mixpanel.track('app.add_prospect_doc', {
        prospect_id,
        ...docPayload,
    });

    return APIRequest({
        method: 'post',
        url: apiUrl + `prospect/${prospect_id}/documents`,
        data: docPayload,
    });
};

export const deleteProspectDocument = (prospect_id: number, doc_id: number) => {
    Mixpanel.track('app.delete_prospect_doc', {
        prospect_id,
        doc_id: doc_id,
        bulk: false,
    });

    return APIRequest({
        method: 'delete',
        url: apiUrl + `prospect/${prospect_id}/document/${doc_id}`,
        useDefaultErrorNotif: true,
    });
};

export const updateProspectDocument = (
    prospect_id: number,
    id: number,
    payload: any,
    formType: 'FULL' | 'PARTIAL' = 'PARTIAL'
) => {
    Mixpanel.track('app.edit_prospect_doc', {
        prospect_id,
        doc_id: id,
        action: 'edit',
        ...payload,
    });

    return APIRequest({
        method: 'put',
        url: apiUrl + `prospect/${prospect_id}/document/${id}?formType=${formType}`,
        data: {
            ...payload,
        },
        useDefaultErrorNotif: true,
    });
};
